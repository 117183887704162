import * as React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import componentStyles from './Argon/styles';
import _ from 'lodash';
import { DateField } from 'react-admin';
import { palette } from '../assets/theme/theme';

const useStyles = makeStyles(componentStyles);

export const YesNoTextField = ({ source, record }) => {
    const classes = useStyles();

    return (
        <Box paddingTop=".35rem" paddingBottom=".35rem">
            <Box
                marginRight="10px"
                component="i"
                width=".375rem"
                height=".375rem"
                borderRadius="50%"
                display="inline-block"
                className={
                    classes.verticalAlignMiddle +
                    ' ' +
                    classes[record[source] === true ? 'bgSuccess' : 'bgError']
                }
            ></Box>
            {record[source] === true ? 'Yes' : 'No'}
        </Box>
    );
};

export const DateFieldWithDefaultValue = ({ source, label, record }) => {
    const defaultValue = () => {
        switch (source) {
            case 'last_login':
                return 'Not logged in';
            case 'reputation_tool_usage.date_last_review_invite':
                return 'N/A';
            default:
                return '';
        }
    };

    return (
        <Box>
            <DateField source={source} label={label} emptyText={defaultValue()} />
        </Box>
    );
};
