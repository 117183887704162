import { FormHelperText, Typography } from "@material-ui/core"
import { useState, useEffect } from "react"
import dataProvider from "../../dataProvider"
import { makeStyles } from "@material-ui/core/styles";
import { BooleanInput } from "react-admin";
import StepContentWrapper from "./StepContentWrapper";
import ArgonTextInput from "../../components/Argon/ArgonTextInput";
import SendTestEmail from './SendTestEmail'
import { CUSTOM_BULK_INVITE } from '../../constants';

const useStyles = makeStyles((theme) => ({
    checkBoxLabel: {
        marginTop: "1.5rem",
        '& .MuiFormControlLabel-label': {
            fontSize: "14px !important"
        },
    },
    titleFont: {
        fontSize: '20px',
    },
    dialogContentWrapper: {
        padding: "1rem"
    },
    smallText: {
        fontSize: '0.875rem',
        color: "#8898aa",
        fontWeight: 400
    },
    anchor: {
        color: theme.palette.secondary.light,
        textDecoration: 'none',
        fontSize: theme.spacing(2) - 2,
        '&:hover': {
            color: theme.palette.primary.contrastText,
        },
    },
}));

const Step5 = (props) => {

    const classes = useStyles()
    const [template, setTemplate] = useState(null)
    const [changedTemplate, setChangedTemplate] = useState(null)
    const [wasTemplateChanged, setWasTempleteChaged] = useState(false)

    const [emailSettings, setEmailSettings] = useState({
        shouldShowPhoneNumber : false,
        shouldShowFirmName: false,
        shouldShowEmail: false,
        shouldShowFirmLogo: false,
        shouldShowUnsubscribeLink: false,
    });

    useEffect(() => {
        async function fetchData() {
            const data = await dataProvider.getNone("template", { type: props.record?.invite_type, custom_bulk_invite: sessionStorage.getItem(CUSTOM_BULK_INVITE)})
            setTemplate(data.data?.template);

            setEmailSettings({
                shouldShowPhoneNumber : (data.data?.shouldShowPhoneNumber),
                shouldShowFirmName: (data.data?.shouldShowFirmName),
                shouldShowEmail: (data.data?.shouldShowEmail),
                shouldShowFirmLogo: (data.data?.shouldShowFirmLogo),
                shouldShowUnsubscribeLink: (data.data?.shouldShowUnsubscribeLink),
            })
        }
        fetchData()
    }, []);

    const validDisclaimer = (value) => {
        if (!value) {
            return 'Please check the disclaimer';
        }
        return undefined;
    };

    const validateTemplate = (rawValue) => {
        const value = rawValue ? rawValue.replace(/(\r\n|\n|\r)/gm, "") : rawValue;
        if (value) {
            if (!value.includes('[adviser url]')) {
                return 'Placeholder for adviser\'s url link is wrong or missing, please use [adviser url]';
            } else if (!value.includes('[first name]')) {
                return 'Placeholder for Client\'s First name is wrong or missing, please use [first name]';
            } else if (!value.includes('[adviser full name]')) {
                return 'Placeholder for adviser\'s full name is wrong or missing, please use [adviser full name]';
            } else if (!value.includes('[adviser phone]') && emailSettings.shouldShowPhoneNumber) {
                return 'Placeholder for adviser\'s phone is wrong or missing, please use [adviser phone]';
            } else if (!value.includes('[firm name]') && emailSettings.shouldShowFirmName) {
                return 'Placeholder for firm name is wrong or missing, please use [firm name]';
            } else if (!value.includes('[adviser email]') && emailSettings.shouldShowEmail) {
                return 'Placeholder for adviser Email is wrong or missing, please use [adviser email]';
            } else if (!value.includes('[firm logo]') && emailSettings.shouldShowFirmLogo) {
                return 'Placeholder for Firm\'s logo is wrong or missing, please use [firm logo]';
            } else if (!value.includes('[unsubscribe link]') && emailSettings.shouldShowUnsubscribeLink) {
                return 'Placeholder for Unsubscribe Link is wrong or missing, please use [unsubscribe link]';
            }else if (value.includes('[Openwork person and signature]')) {
                return 'Please replace the placeholder for [Openwork person and signature]';
            } else if (value.length > 4000) {
                return 'Sorry, you\'ve exceed our 4000 character limit, please shorten your email.';
            } else {
                return undefined
            }
        }
        return "Email template is required";
    };

    return (
        <StepContentWrapper title={'Step 5) Edit email and send'}>
            <Typography component="div"><strong>Subject:</strong> [Adviser Name] has requested that you leave them a review on VouchedFor</Typography>

            <ArgonTextInput
                onKeyUp={(e) => {
                    setChangedTemplate(e.target.value)
                    setWasTempleteChaged(true)
                }}
                source="template"
                resource="template"
                initialValue={template}
                multiline
                validate={validateTemplate}
                data-test={"email-template"}
            />
            <FormHelperText>*This will be automatically personalized for each client</FormHelperText>
            <FormHelperText>Please note: Replies to the review invite email will be sent to the adviser's email address</FormHelperText>

            <BooleanInput
                label={<span>I understand that I should have my clients' permission to contact them and that I may be asked to provide evidence of our relationship, in line with <a className={classes.anchor} href="//pro-support.vouchedfor.co.uk/article/230-review-verification-policy" target="_blank" >VouchedFor's review verification policy</a></span>}
                defaultValue={false}
                source="disclaimer"
                validate={validDisclaimer}
                className={classes.checkBoxLabel}
                data-test={"checkbox-disclaimer"}
            />

            <SendTestEmail validateTemplate={validateTemplate} template={wasTemplateChanged ? changedTemplate : template} />
        </StepContentWrapper>
    );
};

export default Step5;
