import * as React from 'react';
import SingleQuestion from './SingleQuestion';
import _, { wrap } from 'lodash';
import { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import componentStyles from '../../assets/theme/components/cards/cards/card-pricing-gradient-cards.js';
import buttonsStyles from '../../assets/theme/components/button';
import SurveyInfo from '../FirstImpressions/components/SurveyInfo';
import StarRatings from '../components/StarRatings';
import RisksAndOpportunities from './RisksAndOpportunities';
import { useACL } from '../../acl/acl';
import SmsIcon from '@material-ui/icons/Sms';
import DescriptionIcon from '@material-ui/icons/DescriptionRounded';
import SectionTitle from '../../SafeGuard/components/SectionTitle';
const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(buttonsStyles);
const useCustomStyles = makeStyles((theme) => ({
    typography: {
        marginBottom: '1rem',
    },
    private: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        maxWidth: 900,
        width: '100%'
    },
    public: {
        display: 'flex',
        gap: '4rem',
        [theme.breakpoints.down('sm')]: {
            gap: '1rem',
            flexDirection: 'column'
        },
    },
    publicQuestions: {
        maxWidth: 600,
        width: '100%'
    },
    title: {
        color: `${theme.palette.secondary.dark} !important`,
        '&.MuiTypography-h4': {
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: 1.235
        },
    },
    icon: {
        marginRight: '15px',
        verticalAlign: 'top',
        fontSize: '28px'
    },
    questionListCardContent: {
        '& .stats-card .MuiCardContent-root': {
            paddingLeft: '0 !important'
        }
    }
}));

const QuestionList = ({record}) => {
    const classes = {
        ...useStyles(),
        ...useStylesButtons(),
        ...useCustomStyles()
    };

    const questions = _.get(record, 'questions', []);
    const surveyType = _.get(record, 'reviewer_type', '');
    const isTransferredClientReview = _.get(record, 'is_transferred_client_review', false)

    const [publicFeedback, setPublicFeedback] = useState([]);
    const [privateFeedback, setPrivateFeedback] = useState([]);
    const { enabled: canViewSafeguard } = useACL('elevation');

    useEffect(() => {
        let publicItems = [];
        let privateItems = [];

        if (questions.length) {
            questions.map((item) => {
                if (item.question.visible_in_review_listing || item.visible_in_review) {
                    publicItems.push(item);
                } else {
                    privateItems.push(item);
                }
            });
        }

        setPublicFeedback(publicItems);
        setPrivateFeedback(privateItems);
    }, [record]);

    const renderList = (list, isPrivate) =>
        list.map((question) => {
            return <SingleQuestion isPrivate={isPrivate} key={question.id} question={question} surveyType={surveyType} />;
        });

    return (
        <Card>
            <CardContent className={classes.questionListCardContent}>
                <SurveyInfo record={record} />
                <SectionTitle title="Rating & Summary" className={classes.title} variant={"h4"} icon={<SmsIcon className={`${classes.icon}`} />} />
                <Typography className={classes.typography} variant="body2" >Feedback that will appear publicly if you're on our Verified membership</Typography>
                <div className={classes.public}>
                    {!isTransferredClientReview && <div><StarRatings record={record} /></div>}
                    <div className={classes.publicQuestions}>{renderList(publicFeedback, false)}</div>
                </div>

                { canViewSafeguard && (<>
                    <RisksAndOpportunities isOpportunity={false} {...record?.potential_risks} />
                    <RisksAndOpportunities isOpportunity={true} {...record?.opportunities} />
                </>) }
                {privateFeedback.length > 0 ?
                    <>
                        <br />
                        <SectionTitle title="Private feedback" className={classes.title} variant={"h4"} icon={<DescriptionIcon className={`${classes.icon}`} />} />
                        <div className={classes.private}>
                            {renderList(privateFeedback, true)}
                        </div>
                        </> 
                : null}

            </CardContent>
        </Card>
    );
};

export default QuestionList;
