import React from 'react';
import { Box } from '@material-ui/core';
import { ArgonSelectInput } from 'components/Argon/ArgonSelectInput';

const GoalSelect = (props) => {
    const { title, type, onChange, classes, goals, selectedGoal } = props;

    if (! goals.length) {return null}

    return (
        <Box className={classes.dropdownWrapper}>
            <ArgonSelectInput
                onChange={onChange}
                value={selectedGoal}
                name={type}
                className={classes.dropdown}
                data={goals}
                dataLabelName="name"
                dataKeyName="all"
                defaultItem={title}
                selectElementOnly={true}
                noForm={true}
                data-test={"select-goal"}
            />
        </Box>
    );
};

export default GoalSelect;
