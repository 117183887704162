
import React, { useState, useEffect } from "react"
import { Button, Box, OutlinedInput, Typography } from "@material-ui/core"
import DialogBase from "components/DialogBase";
import { makeStyles } from "@material-ui/core/styles";
import dataProvider from "../../dataProvider"
import { Alert } from "@material-ui/lab";
import { ArgonSelectInput } from "components/Argon/ArgonSelectInput";
import { CUSTOM_BULK_INVITE } from '../../constants';

const useStyles = makeStyles((theme) => ({
    dialogContentWrapper: {
        padding: "1rem"
    },
    text: {
        fontSize: "16px",
        marginBottom: "10px",
        color: "#8898aa"
    },
    title: {
        marginBottom: "1rem",
    },
    input: {
        marginBottom: "10px",
    }
}));

const SendTestEmail = ({ template, validateTemplate }) => {
    const [open, setOpen] = useState(false)
    const [email, setEmail] = useState('')
    const [clientName, setClientName] = useState('')
    const [advisers, setAdvisers] = useState([])
    const [selectedAdviser, setSelectedAdviser] = useState(0)
    const [emailValid, setEmailValid] = useState(true)
    const [message, setMessage] = useState('')
    const [messageType, setMessageType] = useState('success')
    const [submitting, setSubmitting] = useState(false)
    const classes = useStyles()

    const [sentTestEmail, setSentTestEmail] = useState(false)

    useEffect(async () => {
        const { data } = await dataProvider.getNone('professionals', {'filters[minimal_fields]' : true});
        setAdvisers(data)
    }, [])

    const sendTestEmail = async () => {
        setSubmitting(true)
        dataProvider.getNone("test-email", { email: email, template: template, clientName: clientName, adviserName: selectedAdviser.full_name, custom_bulk_invite: sessionStorage.getItem(CUSTOM_BULK_INVITE) }).then(res => {
            setSubmitting(false)
            setMessage("Test email sent! Please check your email and come back to finalise the invitations")
            setMessageType("success")
            setSentTestEmail(true)
        }).catch(err => {
            setSubmitting(false)
            setMessage("There was an issue with sending the message, please try again")
            setMessageType("error")
        })
    }

    const handleTestEmailSend = () => {
        const valid = email && validateEmail() ? true : false
        setEmailValid(valid)
        if (valid) {

            const validateTempl = validateTemplate(template)
            if (validateTempl) {
                setMessage(validateTempl)
                setMessageType("error")
            } else {
                sendTestEmail()
            }
        }

    }

    const validateEmail = () => {
        const mailformat = /\S+@\S+\.\S+/
        if (!email) return false
        return mailformat.test(email)
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
        if (!emailValid) {
            setEmailValid(validateEmail())
        }
    }

    const handleClose = () => {
        setSubmitting(false)
        setMessage("")
        setMessageType("success")
        setOpen(false)
    }

    return (
        <>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpen(true)}
            >
                Send test email
            </Button>
            <DialogBase
                submitting={submitting}
                onClose={handleClose}
                SaveButtonProps={{
                    onClick: handleTestEmailSend,
                    label: "Send test email"
                }}
                ErrorButtonProps={{
                    label: sentTestEmail ? 'Continue' : 'Cancel',
                    onClick: handleClose,
                }}
                open={open}>
                <Box className={classes.dialogContentWrapper}>
                    <Typography component="h5"
                        className={classes.title}
                        color="secondary">
                        Test email
                    </Typography>
                    <Typography component="div"
                        className={classes.text}
                        variant="body1">
                        Send a test email to ...
                    </Typography>
                    <OutlinedInput
                        className={classes.input}
                        fullWidth
                        error={!emailValid}
                        type="email"
                        onChange={handleEmailChange}
                    />

                    <Typography component="div"
                        className={classes.text}
                        variant="body1">
                        Client's first name
                    </Typography>
                    <OutlinedInput
                        className={classes.input}
                        fullWidth
                        onChange={(e) => setClientName(e.target.value)}
                    />

                    <ArgonSelectInput
                        onChange={(e) => setSelectedAdviser(e.target.value)}
                        value={selectedAdviser ? selectedAdviser : 0}
                        data={advisers}
                        dataLabelName="name_only"
                        dataKeyName="all"
                        selectElementOnly={true}
                        noForm={true}
                        defaultItem="Select an adviser to send email from"

                    />
                    {message ? <Alert severity={messageType}>{message}</Alert> : null}

                </Box>
            </DialogBase>
        </>
    )
}

export default SendTestEmail;