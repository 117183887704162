import _ from 'lodash';
import * as React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Typography} from '@material-ui/core';

export const OverallRatingField = (props) => {
    const {source} = props;
    const classes = useStyles();
    const isTransferredClientReview = _.get(props?.record, 'is_transferred_client_review', false);

    let rating = _.get(props.record, `${source}`, '0.0');

    if (rating === '5.0') {
        rating = '5';
    }

    if (rating === '0.0' || rating === '0%') {
        return null;
    }

    return (
        <Typography container className={classes.root}>
            {isTransferredClientReview ? <p>N.A.</p> : <span>{rating}</span>}
        </Typography>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: '14px'
    }

}))