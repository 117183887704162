import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SectionTitle from './components/SectionTitle';
import Carousel from 'react-material-ui-carousel';
import { Box, Typography, useTheme } from '@material-ui/core';
import insights1 from '../assets/img/insight_1_new.png';
import insights2 from '../assets/img/insight_2.png';
import insights3 from '../assets/img/insight_3.png';
import insights4 from '../assets/img/insight_4.png';

const useStyles = makeStyles(theme => ({
    wrapper: {
        marginTop: '4rem',
        width: '100%',
        height: 'calc(100vh - 15rem - 56px)',
        position: 'relative',
    },
    carousel: {
        width: '100%',
        height: '100%',
        '& .MuiIconButton-root': {
            backgroundColor: theme.palette.tertiary.main,
            "&:hover": {
                backgroundColor: theme.palette.tertiary.dark,
            },
        }
    },
    box: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    root: {
        marginTop: '8rem',
    },
    text: {
        textAlign: 'center',
        color: theme.palette.secondary.dark,
    }
}));

const Insights = () => {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <Box className={classes.root}>
            <SectionTitle title="Insights" header size="2.75rem" />
            <Box className={classes.wrapper}>
                <Carousel
                    navButtonsAlwaysVisible
                    height="100%"
                    autoPlay={false}
                    className={classes.carousel}
                >
                    <Box className={classes.box}>
                        <img style={{ maxHeight: '100%', margin: "auto", maxWidth: "100%" }} src={insights1} />
                    </Box>
                    <Box className={classes.box}>
                        <Typography variant="h1" className={classes.text}>
                            Most clients say they’d recommend, less than a third regularly do
                        </Typography>
                        <img style={{ maxHeight: '80%', margin: "auto", maxWidth: "100%" }} src={insights2} />
                    </Box>
                    <Box className={classes.box}>
                        <Typography variant="h1" className={classes.text}>
                            Many of the factors that make clients actually refer are central to the Consumer Duty
                        </Typography>
                        <img style={{ maxHeight: '80%', margin: "auto", maxWidth: "100%" }} src={insights3} />
                    </Box>
                    <Box className={classes.box}>
                        <Typography variant="h1" className={classes.text}>
                            Compounding these factors (and taking a thorough approach to Consumer Duty) is powerful
                        </Typography>
                        <img style={{ maxHeight: '100%', margin: "auto", maxWidth: "100%" }} src={insights4} />
                    </Box>
                </Carousel>
            </Box>
        </Box>
    );
};

export default Insights;
