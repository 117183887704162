import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useACL } from '../../acl/acl';

// core components
import CardStats from "../../components/Cards/Dashboard/CardStats.js";

import componentStyles from "../../assets/theme/components/headers/stats-header.js";
import {isNetworkView} from "../../helpers/safeguard";

const useStyles = makeStyles(componentStyles);

const StatsHeader = ({ data }) => {
  const classes = useStyles();
  const theme = useTheme();
  const network = isNetworkView();
  const { enabled: canViewSafeguard } = useACL('elevation');

  let totalReviews = 0.0
  let overallRating = 0
  let firstImpressionReviews = 0
  let reviews = 0
  let prospectIntentions = 0
  let prospectIntentionsPrc = "0%"

  if (data) {

    overallRating = parseFloat(data.reviews.overall_rating).toFixed(1)
    firstImpressionReviews = data.reviews.first_impression_count
    reviews = data.reviews.review_count
    totalReviews = parseFloat(firstImpressionReviews) + parseFloat(reviews)

    if (!network) {
      prospectIntentions = data.statistics.prospect_intentions
      prospectIntentionsPrc = data.statistics.prospect_intentions_prc
    }
  }

  return (
    <>
      <div className={classes.header}>
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <Grid
            container
            component={Box}
            alignItems="center"
            paddingTop="1.5rem"
            paddingBottom="1.5rem"
          >
            <Grid item xs={5} lg={6} component={Box} textAlign="right">
              {/*<Button*/}
              {/*  variant="contained"*/}
              {/*  size="small"*/}
              {/*  className={classes.buttonRoot}*/}
              {/*>*/}
              {/*  New*/}
              {/*</Button>*/}
              {/*<Button*/}
              {/*  variant="contained"*/}
              {/*  size="small"*/}
              {/*  className={classes.buttonRoot}*/}
              {/*>*/}
              {/*  Filters*/}
              {/*</Button>*/}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xl={3} lg={6} xs={12} data-test={"stats-rating"}>
              <CardStats
                subtitle="Overall Firm Rating"
                title={overallRating}
                icon={null}
                color="bgSuccess"
                footer={
                  <>
                    <Box
                      component="span"
                      fontSize=".875rem"
                      color={theme.palette.success.main}
                      marginRight=".5rem"
                      display="flex"
                      alignItems="center"
                    >
                    </Box>
                  </>
                }
              />
            </Grid>
            <Grid item xl={3} lg={6} xs={12} data-test={"stats-review-count"}>
              <CardStats
                subtitle="Total Review Count"
                title={totalReviews}
                icon={null}
                color="bgSuccess"
                footer={
                  <>
                    <Box component="span" fontFamily="Nunito">
                      {reviews} from clients and {firstImpressionReviews} from prospects
                    </Box>
                  </>
                }
              />
            </Grid>
            {canViewSafeguard ? null :
              <Grid item xl={3} lg={6} xs={12} data-test={"stats-client-intention"}>
                <CardStats
                  subtitle="Intends to become a client"
                  title={prospectIntentionsPrc}
                  icon={null}
                  color="bgSuccess"
                  footer={
                    <>
                      <Box component="span" fontFamily="Nunito">
                        of prospects are saying they intend to become a client
                      </Box>
                    </>
                  }
                />
              </Grid>
            }
          </Grid>
        </Container>
      </div>
    </>
  );
};

StatsHeader.propTypes = {
  section: PropTypes.string,
  subsection: PropTypes.string,
};

export default StatsHeader;
