import { Grid } from '@material-ui/core';
import ProfileEditInputs from './ProfileEditInputs';
import DisclaimerEdit from '../FirmDetails/DisclaimerEdit';
import AwardAndAccreditationsShow from '../Awards/AwardAndAccreditationsShow';
import * as React from 'react';
import ApprovedOnDate from "./ApprovedOnDate";

const ProfileEditLayout = (props) => {
    return (
        <Grid container spacing={4}>
            <ProfileEditInputs {...props} />
            <DisclaimerEdit {...props} />
            <ApprovedOnDate {...props} />
            <AwardAndAccreditationsShow />
        </Grid>
    );
};

export default ProfileEditLayout;
