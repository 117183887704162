export const tabs = () => {

    const firmAboutTab = {
        label: 'About Firm',
        link: '/profile',
    };

    const disclaimerTab = {
        label: 'Disclaimer',
        link: '/disclaimer',
    };

    const awardsTab = {
        label: 'Awards',
        link: '/awards',
    };

    return [firmAboutTab, disclaimerTab, awardsTab];
}

export const reviewTabs = () => {
    const ReviewsTab = {
        label: 'Reviews',
        link: '/reviews',
    };

    const FirstImpressionTab = {
        label: 'First Impressions',
        link: '/first-impressions',
    };
    
    return [ReviewsTab, FirstImpressionTab];
}
