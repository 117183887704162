import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
    Datagrid,
    TextField,
    FunctionField, useGetIdentity,
} from 'react-admin';
import { Box, Typography } from "@material-ui/core";
import ListWrapper from "../../components/List/ListWrapper";
import componentStyles from '../../assets/theme/components/cards/cards/card-button';
import { formatRange } from "utils";
import { getServiceTypeAsString } from '../../helpers/safeguard';
import ContentTooltip from "Dashboard/components/ContentTooltip";

const useStyles = makeStyles(componentStyles);
const useStylesCustom = makeStyles((theme) => ({
    link: {
        color: theme.palette.secondary.main,
        fontWeight: '700',
        fontSize: '1rem',
        textDecoration: 'none',
        textTransform: 'uppercase',
        letterSpacing: '1px',
    },
    list: {
        marginTop: 0,
        marginBottom: "10rem",
        '& .MuiTableRow-root' : {
            height: 45,
        }
    }
}));

const getConversionRateLabel = (goal) => {
    switch (goal) {
        case 1:
            return 'Prospect Conversion';
        case 2:
            return 'Passionate Advocacy';
        case 3:
            return 'Risk Score';
        default:
            return 'Conversion Rate';
    }
};

const AdviserTable = (props) => {
    const classes = { ...useStyles(), ...useStylesCustom() }
    const { goal, range, serviceType } = props
    const theme = useTheme()
    const {identity} = useGetIdentity();

    const getFilters = () => {
        let params = formatRange(range);
        if (serviceType) {
            params['service_type'] = getServiceTypeAsString(serviceType);
        }
        const listFilters = {
            ...params
        }

        return listFilters
    }

    const CustomButton = ({ record }) => {
        return <Link className={classes.link} to={{
            pathname: `/adviser-dashboard/${record.id}`,
            params: {serviceType, goal, range}
        }}
        >
            See Adviser Dashboard
        </Link>
    };

    return (
        <Box className={classes.list}>
            <ContentTooltip
                placement="left"
                title="Adviser requires a minimum of 5 reviews to appear in the table"
                disableFocusListener
                disableTouchListener
                interactive
                leaveDelay={200}>
                <Typography style={{color: theme.palette.primary.main}} variant="h1">Advisers</Typography>
            </ContentTooltip>
            <ListWrapper
                title={'Table'}
                exporter={false}
                basePath={`elevation/dashboard/advisers/${goal}`}
                resource={`elevation/dashboard/advisers/${goal}`}
                filterDefaultValues={getFilters()}
                sort={{ field: "avg", order: "DESC" }}
                safeguard
                noDataMessage="No adviser has 5 or more reviews for the goal and service type selected"
            >
                <Box className={classes.cardRoot}>
                    <Datagrid style={{tableLayout: 'fixed'}}>
                        <TextField label="Adviser name" source="name" />
                        <FunctionField
                            label={getConversionRateLabel(goal)}
                            render={record => `${Math.round(record.avg)}%`}
                            source="avg"
                        />
                        <TextField label="Number of reviews for this goal" source="count" />
                        {!identity?.hideIndividualDashboardLink && <CustomButton />}
                    </Datagrid>
                </Box>
            </ListWrapper>
        </Box>
    )
}



export default AdviserTable;
