import {makeStyles, Typography, useTheme} from '@material-ui/core';
import React from 'react';
import componentStyles from '../../../assets/theme/components/headers/stats-header';

const useStyles = makeStyles(componentStyles);

export const BarChartTooltip = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const { active, payload, fullXAxis, double, triple, displaySettings } = props;

    if (active && payload && payload.length) {
    let bar1Payload = payload[0];
    let bar2Payload = (double || triple) ? payload[1] : null;
    let bar3Payload = triple ? payload[2] : null;

        let tooltipData = '';
        if (displaySettings.tooltipType === 'driverAllAdvisers') {
            tooltipData = (
                <Typography variant="body2">
                    Adviser average of <span
                    style={{ color: theme.palette.primary.main }}>{bar1Payload.value}%</span>{'. '}
                    This is based on {bar1Payload.payload.reviews} reviews.
                </Typography>
            );
        } else if (double) {
            tooltipData = (
                <Typography variant="body2">
                    <span style={{ color: theme.palette.primary.main }}>{bar1Payload.value}% </span>
                    {bar1Payload.name} average vs. {bar2Payload.name} average of{' '}
                    <span style={{ color: '#d35b83' }}>{bar2Payload.value}%</span>. This
                    is based on {bar1Payload.payload.reviews} reviews.
                </Typography>
            );
        } else if (triple) {
            tooltipData = (<Typography variant="body2">
                {bar1Payload.name} average of{' '}
                <span style={{ color: theme.palette.primary.main }}>{bar1Payload.value}% </span>
                vs. a {bar2Payload.name.toLowerCase()} average of{' '}
                <span style={{ color: '#d35b83' }}>{bar2Payload.value}%</span>.
                & an {bar3Payload.name.toLowerCase()} average of{' '}
                <span style={{ color: theme.palette.primary.main }}>{bar3Payload.value}%</span>. This
                is based on {bar1Payload.payload.reviews} reviews.
            </Typography>);
        } else {
            tooltipData = (
                <Typography variant="body2">
                    <span style={{ color: theme.palette.primary.main }}>{bar1Payload.value}% </span>{' '}
                    {displaySettings.tooltip}
                    {bar1Payload.payload.reviews} reviews.
                </Typography>
            );
        }
        return (
            <div className={classes.customTooltip}>
                {!fullXAxis && (
                    <p className={classes.customDescriptionTooltip} variant="body1">
                        {bar1Payload.payload.xAxis}
                    </p>
                )}
                <p className={classes.customDescriptionTooltip}>
                    {tooltipData}
                </p>
            </div>
        );
    }
    return null;
};