import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import ReportTable from './ReportTable';
import RiskAndOpportunityTable from './RiskAndOpportunityTable';
import { useHistory, useLocation } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import { formatRange, formatUKDateStringToUS } from '../../utils';
import dataProvider from '../../dataProvider';

const getDateRangeFromUrl = (params) => {
    const from = params.get('from');
    const to = params.get('to');

    if (from && to) {
        return { from: formatUKDateStringToUS(from), to: formatUKDateStringToUS(to) };
    }
    return {};
};

export default ({ type }) => {
    const history = useHistory();
    const location = useLocation();
    const [filter, setFilter] = useState(parse(location.search));
    const search = history.location.search;
    const params = new URLSearchParams(search);
    const [adviser, setAdviser] = useState(null);



    const adviserFromUrl = params.get('adviser');

    const firmFromUrl = params.get('firm');
    const choiceFromUrl = params.get(type);
    const [dateRange, setDateRange] = useState(getDateRangeFromUrl(params));

    useEffect(() => {
        dataProvider.getMany('professionals', {ids: [params.get('adviser')]})
            .then(res => {
                setAdviser(res.data[0]);
            }).catch(e => {
            throw new Error(e);
        })
    }, [adviserFromUrl]);

    const handleAdviserChange = (value) => {
        updateFilters('adviser', value);
    };

    const handleFirmChange = (value) => {
        updateFilters('firm', value);
    };

    const handleOptionChange = (value) => {
        updateFilters(type, value);
    };

    const updateFilters = (filterParam, value) => {
        if (!value) {
            delete filter[filterParam];
            history.push({
                pathname: location.pathname,
                search: stringify(filter),
            });

            return;
        }

        setFilter({ ...filter, [filterParam]: value });
        history.push({
            pathname: location.pathname,
            search: stringify({ ...filter, [filterParam]: value }),
        });
    };

    const handleDateChange = (value) => {
        setDateRange(value);
        setFilter({ ...filter, from: formatRange(value).from, to: formatRange(value).to });
        history.push({
            pathname: location.pathname,
            search: stringify({ ...filter, from: formatRange(value).from, to: formatRange(value).to }),
        });
    };

    return (
        <Grid>
            <ReportTable
                type={type}
                handleAdviserChange={handleAdviserChange}
                handleDateChange={handleDateChange}
                handleFirmChange={handleFirmChange}
                adviserFromUrl={adviser}
                dateRange={dateRange}
                firmFromUrl={firmFromUrl}
            />
            <RiskAndOpportunityTable
                type={type}
                handleOptionChange={handleOptionChange}
                choiceFromUrl={choiceFromUrl}
                adviserFromUrl={adviserFromUrl}
                dateRange={dateRange}
                firmFromUrl={firmFromUrl}
            />
        </Grid>
    );
}
