import axios from 'axios';
import { useListContext, HttpError } from 'react-admin';
import Button from '@material-ui/core/Button';
import { CloudDownload } from '@material-ui/icons';
import * as React from 'react';
import API_KEYS from '../constants/api-keys';
import { getErrorMessage } from './helperFunctions';

export const downloadFile = (url, filename, finishDownloadEvent, showErrorMessage) => {
    axios({
        url,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const href = URL.createObjectURL(response.data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        finishDownloadEvent();
    })
        .catch(e => {
            const errorMessage = getErrorMessage(filename)
            finishDownloadEvent();
            showErrorMessage(errorMessage)
        });
};

export const getQueryString = (currentSort, filterValues) => {
    let queryString = [];
    if (filterValues) {
        for (let key in filterValues) {
            queryString.push(
                'filters%5B' + key + '%5D=' + filterValues[key],
            );
        }
    }

    const field = currentSort ? currentSort.field : null;
    const order = currentSort ? currentSort.order : null;
    queryString.push(encodeURI(`order_by[${field}]=${order}`));

    return `${queryString.join('&')}`;
};

export const DownloadCsvFile = ({ resource, startDownloadEvent, finishDownloadEvent, filename, showErrorMessage }) => {
    const { currentSort, filterValues, filter} = useListContext();

    const handleClick = async () => {
        startDownloadEvent();

        let queryString = getQueryString(currentSort, filterValues);

        if (filter !== undefined) {
        let filters = {};
        Object.assign(filters, filterValues, filter)
        queryString = getQueryString(currentSort, filters);
        }

        const token = localStorage.getItem('token');
        const url = `${API_KEYS.api_firm_url}/${resource}?lexiktoken=${token}&${queryString}`;

        downloadFile(url, filename, finishDownloadEvent, showErrorMessage);
    };

    return (
        <Button
            startIcon={<CloudDownload fontSize="small"/>}
            color="primary"
            onClick={handleClick}
        >
            Export
        </Button>
    );
};