import React from 'react';
import { Create, SimpleForm, TopToolbar } from 'react-admin';
import BackButton from '../components/Buttons/BackButton';
import AwardCreateLayout from './components/AwardCreateLayout';

const AwardCreate = (props) => {
    const profileUrl = '/profile';

    return (
        <Create
            {...props}
            actions={
                <TopToolbar>
                    <BackButton href={profileUrl} />
                </TopToolbar>
            }
        >
            <SimpleForm redirect={profileUrl}>
                <AwardCreateLayout {...props} />
            </SimpleForm>
        </Create>
    );
};

export default AwardCreate;
