import React from 'react';
import { Typography } from '@material-ui/core';
import FieldWithLabel from '../../../components/FieldWithLabel';

const PageSectionTitle = ({ title, description }) => {
    return (
        <div>
            {title && <FieldWithLabel label={title} />}
            {description &&
            <Typography
                style={{'marginBottom': '50px'}}>
                <span dangerouslySetInnerHTML={{__html: description}} /></Typography>}
        </div>
    );
};

export default PageSectionTitle;
