import React from 'react'
import StarWidget from "./StarWidget";
import {useQuery} from "react-admin";
import { Grid } from '@material-ui/core';
import {showWidget} from "../../utils";

const StarWidgetList = () => {

    const { data } = useQuery({
        type: 'getNone',
        resource: 'widget/stars',
    });

    if (!data) {
        return null;
    }

    const widgetToExclude = showWidget() ? [] : ['left', 'panel'];

    const renderedWidgetList = data.filter(widget => !widgetToExclude.includes(widget.id)).map((widget, index) => {
        return data ? (
            <Grid item key={index} xl={4} lg={6} sm={12} style={{minWidth: '350px'}}>
            <StarWidget
                key={widget.id}
                widget={widget}
            />
            </Grid>
        ) : null;
    })

    return (
        <>
            {renderedWidgetList}
            </>
    )
}

export default StarWidgetList;
