import FirstImpressionList from './FirstImpressionList';
import FirstImpressionShow from './FirstImpressionShow';

const first_impressions = {
    list: FirstImpressionList,
    show: FirstImpressionShow,
    options: { label: 'First Impressions' },
};

export default first_impressions;
