import React, { useState, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { DateRange, DateRangePicker } from 'react-date-range';
import Button from '@material-ui/core/Button';
import { formatRange } from '../../../utils';


const useStyles = makeStyles(theme => ({
    picker: {
        [theme.breakpoints.down("sm")]: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            '& .rdrMonth': {
                width: '100%'
            },
            '& .rdrMonthAndYearWrapper': {
                width: '100%'
            }
        },
        '& .rdrStaticRange, .rdrCalendarWrapper, .rdrDateRangeWrapper, input, select, option': {
            fontFamily: 'Nunito'
        }
    },
    dateRangeWrapper: {
        display: 'flex',
        flexDirection: 'column',
    }
}));
const now = new Date();
const rangeSelectShortcutButtons = {
    lastWeek: {
        label: "Last 7 Days",
        from: new Date((new Date()).setDate(now.getDate() - 7)),
        to: now,
        selected: false
    },
    lastMonth: {
        label: "Last 30 Days",
        from: new Date((new Date()).setMonth(now.getMonth() - 1)),
        to: now,
        selected: false
    },
    last3Months: {
        label: "Last 3 Month",
        from: new Date((new Date()).setMonth(now.getMonth() - 3)),
        to: now,
        selected: false
    },
    last6Months: {
        label: "Last 6 Month",
        from: new Date((new Date()).setMonth(now.getMonth() - 6)),
        to: now,
        selected: false
    },
    lastYear: {
        label: "Last 12 Month",
        from: new Date((new Date()).setFullYear(now.getFullYear() - 1)),
        to: now,
        selected: false
    },
    last3Years: {
        label: "Last 3 Years",
        from: new Date((new Date()).setFullYear(now.getFullYear() - 3)),
        to: now,
        selected: false
    },
    last5Years: {
        label: "Last 5 Years",
        from: new Date((new Date()).setFullYear(now.getFullYear() - 5)),
        to: now,
        selected: false
    },
    last10Years: {
        label: "Last 10 Years",
        from: new Date((new Date()).setFullYear(now.getFullYear() - 10)),
        to: now,
        selected: false
    },
};
const XTDShortcutButtons = {
    week: {
        label: "Week to Date",
        from: new Date((new Date()).setDate(now.getDate() - (now.getDay() !== 0 ? (now.getDay() - 1) : 6))),
        to: now,
        selected: false
    },
    month: {
        label: "Month to Date",
        from: new Date(now.getFullYear(), now.getMonth(), 1),
        to: now,
        selected: false
    },
    quarter: {
        label: "Quarter to Date",
        from: new Date(now.getFullYear(), Math.floor(now.getMonth() / 3) * 3, 1),
        to: now,
        selected: false
    },
    year: {
        label: "Year to Date",
        from: new Date(now.getFullYear(), 0, 1),
        to: now,
        selected: false
    },
}

const DateRangeCustom = props => {
    const { range, setRange, shortcutsToShow } = props;
    const [ranges, setRanges] = useState([{
        startDate: range.from ? range.from : new Date(),
        endDate: range.to,
        key: 'selection',
    }]);

    useEffect(() => {
        setRange({
            from: ranges[0].startDate,
            to: ranges[0].endDate
        });
    }, [ranges])

    const classes = useStyles();

    const onChange = (date) => {
        setRanges([date.selection])
    };

    const handleClearClick = () => {
        setRanges([{
            startDate: new Date(),
            endDate: null,
            key: 'selection',
        }])
    };

    const getStaticRanges = () => {
        let shortcuts = [];
        Object.keys(rangeSelectShortcutButtons).map(shortcutName => {
            if (!shortcutsToShow || shortcutsToShow?.length === 0 || !shortcutsToShow.includes(shortcutName)) {
                return true
            }
            shortcuts = [...shortcuts, {
             label: rangeSelectShortcutButtons[shortcutName].label,
             range: () => ({
                 startDate: rangeSelectShortcutButtons[shortcutName].from,
                 endDate: rangeSelectShortcutButtons[shortcutName].to
             }),
             isSelected(range) {
                 const definedRange = this.range();
                 return (
                     range.startDate === definedRange.startDate &&
                     range.endDate === definedRange.endDate
                 );
             }
            }]
            return true
        })
        Object.keys(XTDShortcutButtons).map(shortcutName => {
             shortcuts = [...shortcuts, {
                 label: XTDShortcutButtons[shortcutName].label,
                 range: () => ({
                     startDate: XTDShortcutButtons[shortcutName].from,
                     endDate: XTDShortcutButtons[shortcutName].to
                 }),
                 isSelected(range) {
                     const definedRange = this.range();
                     return (
                         range.startDate === definedRange.startDate &&
                         range.endDate === definedRange.endDate
                     );
                 }
             }]
             return true
        })
        return shortcuts
    }

    return (
        <Box className={classes.dateRangeWrapper}>
            <DateRangePicker
                className={classes.picker}
                ranges={ranges}
                startDatePlaceholder="From"
                endDatePlaceholder="To"
                maxDate={new Date()}
                onChange={onChange}
                editableDateInputs={true}
                showSelectionPreview={true}
                retainEndDateOnFirstSelection={true}
                months={1}
                direction="vertical"
                scroll={{ enabled: true }}
                fixedHeight={true}
                staticRanges={[...getStaticRanges()]}
                inputRanges={[]}
            />
            <Button
                label="ra.action.cancel"
                onClick={handleClearClick}
            >
                Clear interval
            </Button>
        </Box>
    );
};
export default DateRangeCustom;
