import React, { useState, useEffect } from 'react';
import FormButtonBase from './FormButtonBase';
import CustomFormInput from '../../components/Forms/CustomFormInput';
import { getClientTypeButtons } from './FormButtons';
import { Box} from "@material-ui/core";
import { useGetIdentity } from 'react-admin';

const FormButtonFieldsWithStyles = props => {

    const {meta} = props;
    const isError = meta.error && meta.touched;

    const {identity} = useGetIdentity();

    const buttonsToRender = getClientTypeButtons(identity?.showTransferredClientInvitation, identity?.showFollowUpFeedback)
    const [buttonValue, setButtonValue] = useState('');

    useEffect(() => {
        props.input.onChange(buttonValue);
    }, [props.input, buttonValue]);

    return (
        <>
            <Box>
                {
                    buttonsToRender.map(button => (
                        <FormButtonBase
                            value={button.type}
                            key={button.type}
                            title={button.title}
                            onClick={e => setButtonValue(button.type)}
                            buttonValue={buttonValue}
                            icon={button.icon}
                            description={button.description}
                       />
                    ))
                }
            </Box>
            {isError && <span style={{ color: 'red' }}>{meta.error}</span>}
        </>
    );
};

const FormButtonFields = ({ source, buttonsToRender, ...props }) => {
    return (
        <CustomFormInput
            label=''
            source={source}
            component={FormButtonFieldsWithStyles}
        />
    );
};

export default FormButtonFields;
