import merge from 'lodash/merge';
import theme from "./assets/theme/theme.js";
import * as dateRange from 'react-date-range/dist/styles.css'; // main style file
import * as dateRangeDefault from 'react-date-range/dist/theme/default.css'; // theme css file

export const accessibilityBgColor = '#000';

export const fonts = {
    reviewFont: 'Latin Modern Mono',
};

export const customStyles = {
    transition: 'all cubic-bezier(0.0, 0, 0.2, 1) 200ms',
};

const myTheme = merge({}, theme, dateRange, dateRangeDefault, {
    accessibilityBgColor,
    overrides: {
        MuiDrawer: {
            docked: {
                width: 250, // custom sidebar width
                '& > div:nth-of-type(1)': {
                    width: 250, // The default value is 240
                    closedWidth: 250, // The default value is 55
                },
            },
        },
        RaLoadingIndicator: {
            loader: {
                display: 'none',
            },
            loadedIcon: {
                display: 'none',
            }
        },
        RaAppBar: {
            menuButton: {
                '@media (min-width:600px)': {
                    display: 'none',
                },
                color: theme.palette.white.main,
            },
        },
        RaDatagrid: {
            headerCell: {
                whiteSpace: 'break-spaces'
            },
            rowCell: {
                whiteSpace: 'break-spaces'
            }
        },
        RaEdit: {
            noActions: {
                marginTop: 0
            }
        },
        RaCreate: {
            noActions: {
                marginTop: "0!important"
            }
        },
        // MuiFormControlLabel: {
        //     root: {
        //         width: '100%',
        //         margin: 10,
        //     },
        //     label: {
        //         margin: 10,
        //         width: '100%',
        //     }
        // },
        // MuiFormControl: {
        //     root: {
        //         width: '100%',
        //         marginTop: 4,
        //     },
        // },
        // MuiInputBase: {
        //     root: {
        //         width: '100%',
        //         maxWidth: 360,
        //     },
        //     multiline: {
        //         maxWidth: 800,
        //     },
        //     fullWidth: {
        //         maxWidth: '100%',
        //     },
        // },
        // MuiAppBar: {
        //     root: {
        //         boxShadow: 'none',
        //         paddingLeft: '2rem',
        //         paddingRight: '2rem',
        //     },
        //     colorSecondary: {
        //         color: palette.primary.contrastText,
        //         backgroundColor: defaultColours.darkBlue,
        //     },
        // },
        // MuiGrid: {
        //     item: {
        //         margin: '5px 0 5px 0',
        //     },
        // },
        // MuiTypography: {
        //     root: {
        //         width: 'auto !important',
        //         marginTop: '1em',
        //         marginBottom: '1em',
        //     },
        //     body1: {
        //         fontSize: '16px',
        //     },
        //     h1: {
        //         fontSize: '56px',
        //         color: palette.secondary.dark,
        //         fontWeight: 500,
        //         marginBottom: '16px',
        //
        //         [theme.breakpoints.down('sm')]: {
        //             fontSize: '40px',
        //         },
        //     },
        //     h3: {
        //         fontSize: '40px',
        //     },
        //     h4: {
        //         fontSize: '24px',
        //         color: palette.secondary.dark,
        //     },
        //     h5: {
        //         color: palette.secondary.main,
        //         fontSize: '18px',
        //         marginTop: '24px',
        //         marginBottom: '14px',
        //     },
        //     colorError: {
        //         color: palette.error.main
        //     },
        //     // h6: {
        //     //     paddingTop: '24px',
        //     //     paddingBottom: 0,
        //     // },
        //     subtitle1: {
        //         marginTop: '24px',
        //         marginBottom: '14px',
        //         fontSize: '18px',
        //         color: palette.secondary.dark,
        //         '& > div > span': {
        //             '@media (max-width: 1024px)': {
        //                 opacity: 1,
        //                 fontSize: '16px',
        //             },
        //         },
        //     },
        //     caption: {
        //         display: 'block',
        //         fontSize: '12px',
        //     },
        //     gutterBottom: {
        //         marginBottom: '16px',
        //     },
        // },
        // MuiPaper: {
        //     rounded: {
        //         padding: '24px',
        //     },
        //     root: {
        //         boxShadow: 'none',
        //         '& .mui-fixed': {
        //             '@media (max-width: 767px)': {
        //                 display: 'none',
        //             },
        //         },
        //         // button: {
        //         //     display: 'none'
        //         // }
        //     },
        // },
        // MuiCardContent: {
        //     root: {
        //         padding: 0,
        //         ' & > div ': {
        //             // alignItems: 'center',
        //         },
        //         '@media (max-width: 599px)': {
        //             padding: 0,
        //         },
        //     },
        // },
        // MuiTableHead: {
        //     root: {
        //         backgroundColor: "#000000"
        //     },
        // },
        // MuiTableRow: {
        //     root: {
        //         height: 75,
        //         "&:last-of-type": {
        //             "& th,& td": {
        //                 border: 0,
        //             },
        //         },
        //     },
        // },
        // MuiDivider: {
        //     root: {
        //         marginBottom: '24px',
        //     },
        // },

        // MuiTableRow: {
        //     head: {
        //         height: '24px',
        //     },
        // },
        // MuiToolbar: {
        //     gutters: {
        //         position: 'relative !important',
        //         '@media (min-width: 767px)': {
        //             paddingLeft: '24px',
        //             paddingRight: '24px',
        //             paddingBottom: '24px',
        //             paddingTop: '16px',
        //         },
        //         paddingLeft: 0,
        //         paddingRight: 0,
        //         // MuiButton: {
        //         //     marginLeft: 0,
        //         // },
        //         // '& button:first-child': {
        //         //     marginLeft: 0,
        //         // },
        //     },
        //     root: {
        //         backgroundColor: 'unset !important',
        //         '@media (max-width:599px)': {
        //             '&:last-of-type': {
        //                 position: 'relative!important',
        //                 justifyContent: 'center',
        //             },
        //         },
        //         '& .ra-delete-button': {
        //             display: 'none',
        //         },
        //         '& .MuiTablePagination-input': {
        //             width: 'auto',
        //         }
        //     },
        // },
        // MuiSnackbarContent: {
        //     root: {
        //         backgroundColor: palette.primary.main,
        //     },
        // },
        // MuiDrawer: {
        //     paper: {
        //         '@media (min-width:1280px)': {
        //             marginTop: '0!important',
        //             paddingTop: '24px!important',
        //         },
        //     },
        // },
        // MuiTable: {
        //     root: {
        //         width: '100% !important',
        //         margin: '24px 0',
        //     },
        // },
        // MuiTableCell: {
        //     root: {
        //         fontSize: '14px',
        //         // '& .MuiButton-label > svg': {
        //         //     display: 'none',
        //         // },
        //     },
        //     head: {
        //         cursor: 'pointer',
        //         color: palette.primary.dark,
        //         '& span:hover': {
        //             transition: customStyles.transition,
        //             color: palette.secondary.main,
        //         },
        //     },
        // },
        // MuiTablePagination: {
        //     select: {
        //         padding: '10px 20px 10px 10px!important',
        //     },
        //     selectIcon: {
        //         top: '50%',
        //         transform: 'translateY(-50%)',
        //     },
        // },
        // MuiButton: {
        //     root: {
        //         fontSize: '14px',
        //     },
        //     label: {
        //         '& > a': {
        //             textDecoration: 'none',
        //         },
        //     },
        //     sizeSmall: {
        //         fontSize: '14px',
        //         '& span': {
        //             paddingLeft: 0,
        //         },
        //     },
        //     fab: {
        //         width: '56px',
        //         '& svg': {
        //             display: 'block',
        //             padding: '0 4px',
        //         },
        //     },
        //     containedPrimary: {
        //         '&:hover': {
        //             backgroundColor: palette.secondary.dark,
        //         },
        //         '& svg': {
        //             display: 'none',
        //         },
        //     },
        //     containedSecondary: {
        //         color: palette.primary.contrastText,
        //     },
        //     textPrimary: {
        //         '& svg': {
        //             padding: '0 4px',
        //         },
        //     },
        // },
        // MuiSwitch: {
        //     root: {
        //         '&$checked': {
        //             color: palette.secondary.dark + '!important',
        //             '&$bar': {
        //                 backgroundColor: palette.secondary.dark + '!important'
        //             }
        //         }
        //     },
        // },
        // MuiChip: {
        //     root: {
        //         color: palette.primary.contrastText,
        //         backgroundColor: palette.secondary.main,
        //     },
        //     clickable: {
        //         '&:hover': {
        //             backgroundColor: palette.secondary.dark,
        //         },
        //     },
        // },
        // MuiTab: {
        //     root: {
        //         fontSize: '16px',
        //         opacity: 1,
        //     },
        // },
        // MuiFormHelperText: {
        //     root: {
        //         color: palette.error.main,
        //         lineHeight: 'initial',
        //     },
        // },
        // MuiListItem: {
        //     gutters: {
        //         '@media (max-width: 767px)': {
        //             paddingLeft: 0,
        //             paddingRight: 0,
        //         },
        //     },
        // },
        // MuiListItemText: {
        //     root: {
        //         '@media (max-width: 767px)': {
        //             paddingRight: 0,
        //         },
        //     },
        // },
        // MuiSkeleton: {
        //     pulse: {
        //         animation: 'pulse 1.5s ease-in-out 0.5s infinite',
        //     },
        // },
        // MuiPickersModal: {
        //     dialogRoot: {
        //         '& .MuiTypography-root': {
        //             margin: 0,
        //         },
        //     },
        // },
        // MuiPickersCalendarHeader: {
        //     daysHeader: {
        //         '& .MuiTypography-root': {
        //             margin: '0 2px',
        //             width: '36px !important',
        //         }
        //     },
        // },

        // MuiGrid: {
        //     container: {
        //         maxWidth: 1280,
        //         padding: '1rem 0',
        //     }
        // },
    },
});

export default myTheme;
