import * as React from 'react';
import {useState} from 'react';
import {
    Datagrid,
    DateField,
    SimpleList,
    TextField,
    useNotify,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { useACL } from '../acl/acl';
import { ArgonAdviser } from '../components/Argon/ArgonTableComponents/ArgonAdviser';
import ListWrapper from '../components/List/ListWrapper';
import UserHeader from '../components/Headers/UserHeader';
import NumberRating from '../components/NumberRating';
import ReviewsFilter from './ReviewsFilter';
import { useLocation,  useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { parse, stringify } from 'query-string';
import Button from "@material-ui/core/Button";
import {Box, CircularProgress }  from "@material-ui/core";
import { DownloadCsvFile } from '../helpers/fileDownload';

export const ReviewList = (props) => {
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const isMedium = useMediaQuery('(max-width:1231px)');
    const isLarge = useMediaQuery('(max-width:1439px)');

    const { show: canShow } = useACL('reviews');
    const { export: canExportReviews } = useACL('reviews');

    const history = useHistory()
    const location = useLocation();
    const [filter, setFilter] = useState(parse(location.search));
    const [isExporting, setIsExporting] = useState(false);
    const search = history.location.search;
    const params = new URLSearchParams(search);
    const adviserFromUrl = params.get('adviser');

    const notify = useNotify();

    const showErrorMessage = (message) => {
        notify(message, 'warning')
    }

    const exportComplete = () => {
        setIsExporting(false)
    };

    const startExporting = () => {
        setIsExporting(true);
    }

    const handleFilterChange = (value) => {
        setFilter({ ...filter, adviser: value });
        history.push({
            pathname: location.pathname,
            search: stringify({ ...filter, adviser: value }),
        });
    };

    const ShowReviewButton = ({canShow, record}) => {
        const reviewId = record.id ? record.id : false;
        return (
            canShow ?
                (<Button
                    href={`/reviews/${reviewId}/show`}
                    color="secondary"
                >
                    Read Review
                </Button>)
                : null
        );
    };

    return (
        <>
            <UserHeader
                title="Reviews"
            />
            <ListWrapper
                basePath="/reviews"
                resource="reviews"
                {...props}
                title="Reviews"
                actions={
                    canExportReviews ?
                        (isExporting ?
                            ( <Box marginRight="2rem">
                                <CircularProgress />
                            </Box>)
                            :
                                <Box marginRight="1rem">
                                    <DownloadCsvFile
                                    resource="reviews/csv"
                                    startDownloadEvent={startExporting}
                                    finishDownloadEvent={exportComplete}
                                    filename="reviews"
                                    showErrorMessage={showErrorMessage}
                                    />
                                </Box>
                        )
                        : false
                }
                bulkActionButtons={false}
                filters={<ReviewsFilter isXSmall={isXSmall} onFilterChange={handleFilterChange} adviser={adviserFromUrl}/>}
                sort={{ field: 'submitted_at', order: 'DESC' }}
            >
                {isXSmall ? (
                    <SimpleList
                        linkType="show"
                        style={{ 'word-break': 'break-all' }}
                        primaryText={(record) => `Client name: ${record.customer_name}`}
                        secondaryText={(record) => `Client email: ${record.customer_email}`}
                    />
                ) : isSmall ? (
                    <SimpleList
                        linkType="show"
                        primaryText={(record) => `Client name: ${record.customer_name}`}
                        secondaryText={(record) =>
                            (<>
                                Adviser name: {record.professional_full_name}
                                <br/>
                                Client email: {record.customer_email}
                            </>
                            )}
                        />
                    ) : isMedium ? (
                        <Datagrid>
                            <ArgonAdviser label="Adviser name" source="professional_full_name" />
                            <TextField label="Client email" source="customer_email" />
                            <ShowReviewButton canShow={canShow} />
                        </Datagrid>
                    ) : isLarge ? (
                        <Datagrid>
                            <DateField label="Date" source="submitted_at" />
                            <ArgonAdviser label="Adviser name" source="professional_full_name" />
                            <TextField label="Client name" source="customer_name" />
                            <TextField label="Client email" source="customer_email" />
                            <ShowReviewButton canShow={canShow} />
                        </Datagrid>
                    ) : (
                        <Datagrid>
                            <DateField label="Date" source="submitted_at" />
                            <ArgonAdviser label="Adviser name" source="professional_full_name" />
                            <TextField label="Client name" source="customer_name" />
                            <TextField label="Client email" source="customer_email" />
                            <NumberRating source="overall_rating" label="Overall rating" />
                            <ShowReviewButton canShow={canShow} />
                        </Datagrid>
                    )}
            </ListWrapper>
        </>
    );
};

const mapStateToProps = ({identity}) => {
    return {
        showVerifiedSidebar: identity.showVerifiedSidebar
    }
}

export default connect(mapStateToProps)(ReviewList);
