import React from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    item: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    card: {
        width: '100%',
        maxWidth: '400px',
        minHeight: '360px',
        padding: theme.spacing(3),
        textAlign: 'center',
        transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        border: '1px solid lightgray',
        borderRadius: '1%',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
    actionWrapper: {
        marginTop: theme.spacing(3),
        textAlign: 'center',
    },
    imgWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '-24px -24px 24px',
        minHeight: theme.spacing(23),

        [theme.breakpoints.down('sm')]: {
            position: 'inherit',
        },
    },
}));

const ReputationToolItem = ({ content, actions = null, img = null, CardProps = {} }) => {
    const classes = useStyles();

    return (
            <Card className={classes.card} {...CardProps} variant="outlined" elevation={0}>
                {img && (
                    <div className={classes.imgWrapper}>
                        <img src={img} alt="" />
                    </div>
                )}
                <div>{content}</div>
                {actions && <div className={classes.actionWrapper}>{actions}</div>}
            </Card>
    );
};

export default ReputationToolItem;
