import React from 'react';
import { Create, SimpleForm, TopToolbar } from 'react-admin';

import BackButton from '../components/Buttons/BackButton';
import AccreditationCreateLayout from './components/AccreditationCreateLayout';

const AccreditationCreate = (props) => {
    const profileUrl = '/profile';

    return (
        <Create
            {...props}
            actions={
                <TopToolbar>
                    <BackButton href={profileUrl} />
                </TopToolbar>
            }
        >
            <SimpleForm redirect={profileUrl}>
                <AccreditationCreateLayout {...props} />
            </SimpleForm>
        </Create>
    );
};

export default AccreditationCreate;
