import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { Text } from 'recharts';

export default function ChartXAxisLabel({safeToDisplay, ...props}) {
    const theme = useTheme();
    let medium = useMediaQuery(theme.breakpoints.up('xl'));
    medium = !safeToDisplay ? safeToDisplay : medium

    const { x, y, payload } = props;

    return (
        <Text
            x={x}
            y={y}
            style={{
                fontFamily: 'Nunito',
                fontSize: medium ? '0.9rem' : '0.7rem',
                color: theme.palette.blue.navyBlue
            }}
            width={medium ? 80 : 100}
            height={80}
            dy={medium ? 20 : 50}
            dx={medium ? 0 : -10}
            angle={medium ? 0 : -45}
            textAnchor="middle"
            verticalAnchor="start">
            {(payload.value !== 'auto') ? payload.value : 'N/A'}
        </Text>
    );
};