const componentStyles = theme => ({
    header: {
        position: 'relative',
        paddingBottom: '2rem',
        marginTop: '6rem',
    },
    containerRoot: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: '30px',
            paddingRight: '30px',
        },
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center',
    },
    buttonRoot: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.white.main,
        borderColor: theme.palette.white.main,
        '&:first-of-type': { marginRight: '.75rem' },
        '&:hover': {
            backgroundColor: theme.palette.white.main,
            borderColor: theme.palette.white.main,
            color: theme.palette.dark.main,
        },
    },
    displayInlineBlock: {
        display: 'inline-block',
    },
    mb0: {
        marginBottom: 0,
    },
    breadcrumbRoot: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            marginLeft: '1.5rem',
            display: 'inline-block',
        },
    },
    breadcrumbOl: {
        background: 'transparent',
        marginBottom: 0,
    },
    breadcrumbLi: {
        '& a': {
            color: theme.palette.gray[100],
            textDecoration: 'none',
            fontWeight: 600,
            '&:hover': {
                color: theme.palette.white.main,
                textDecoration: 'none',
            },
        },
    },
    breadcrumbSeparator: {
        color: theme.palette.white.main,
    },
    breadcrumbActive: {
        fontSize: '.875rem',
        color: theme.palette.gray[300],
        fontWeight: 600,
    },
    textWhite: {
        color: theme.palette.white.main,
    },
    customTooltip: {
        background: 'rgba(246, 249, 252, 0.6)',
        borderRadius: 25,
        color: theme.navyBlue,
        fontWeight: 700,
        padding: 10,
        width: 200,
    },
    chartDescription: {
        margin: '0 0 20px 50px',
        fontSize: 14,
    },
    customDescriptionTooltip: {
        fontFamily: 'Nunito',
        padding: '0 10',
        textAlign: 'left',
    },
    chartTitle: {
        fontFamily: 'Nunito',
        margin: '0 0 10px 50px',
        fontSize: 20,
    },

    chart: {
        '& .recharts-label': {
            fontFamily: 'Nunito',
            fontSize: 14,
            fill: theme.navyBlue,
        },
        '& .recharts-cartesian-axis-ticks': {
            fontFamily: 'Nunito',
            fontSize: 14,
            fill: theme.navyBlue,
        },
        '& .recharts-default-legend': {
            textAlign: 'left !important',
            marginLeft: '50px !important',
        },
    },
    cardContentRoot: {
        fontFamily: 'Nunito',
    }
});

export default componentStyles;
