import React, {useState, useEffect, useRef} from 'react';
import Select from 'react-select';
import { Typography, Divider, makeStyles } from '@material-ui/core';
import getDataProviderApiUrl from "../utils/getDataProviderApiUrl";
import {canSwitchFirms} from "../utils";
import tokenManager from "../tokenManager";

const useStyles = makeStyles((theme) => ({
    text: {
        textAlign: 'center',
    },
    divider: {
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    control: {
        maxWidth: '95%',
        width: '95%',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: theme.palette.blue.navyBlue,
        marginBottom: '1rem',
        paddingBottom: '0.25rem',
        fontSize: '0.9rem',
        letterSpacing: '0.1em',
    },
    container: {
        width: 220,
    },
}));

const NetworkSidebar = (props) => {
    const classes = useStyles();
    const token = localStorage.getItem('token');
    const [isPropsLoaded, setIsPropsLoaded] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null); // For single-select, use `useState(null)`, for multi-select, use `useState([])`
    const sortedOptions = (props.identity?.networkFirms || []).sort((a, b) =>
        a.label.localeCompare(b.label)
    );

    useEffect(() => {
        if (props.identity && tokenManager.getDecodedToken().firm_id) {
            const defaultSelectedOption = { value: props.identity.id, label: props.identity.name };
            setSelectedOption(defaultSelectedOption);
        }
        setIsPropsLoaded(true);
    }, [props.identity]);

    const setToken = (token) => {
        localStorage.setItem('token', token)
        return true;
    };


    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        const request = new Request(getDataProviderApiUrl('auth/user/switch'), {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify({
                token: token,
                firm: selectedOption ? selectedOption.value : null,
                group: null,
            }),
        });

        return fetch(request)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                setToken(response.token);
                canSwitchFirms(window.location.pathname) ? window.location.reload() : window.location.href = '/';
            });
    };
    return (
        <div className={classes.container}>
            <Typography className={classes.text} variant="h4">
                Select Firm
            </Typography>

            <Select
                value={selectedOption}
                onChange={handleSelectChange}
                options={sortedOptions}
                className={classes.control}
                isClearable
            />

            <Divider className={classes.divider} variant="middle"/>
        </div>
    );
};
export default NetworkSidebar;
