import React from 'react';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import _ from 'lodash';
import {makeStyles} from "@material-ui/styles";
// import Avatar from '@mui/material/Avatar';


export const ArgonAdviser = ({source, record}) => {
    const classes = useStyles();
    const name = _.get(record, `${source}`, '');
    const avatar = _.get(record, `${source === 'full_name' ?
            'avatar_thumbnail' :
            (
                source === 'professional_full_name' ?
                    'professional_avatar' :
                    'professional.avatar'
            )}`,
        '');

    return (
        <Box alignItems="center" display="flex" className={classes.root}>
            <Box component={Avatar} marginRight="1rem" alt="..." src={avatar}/>
            <Box display="flex" alignItems="flex-start">
                <Box fontSize=".875rem" component="span">
                    {name}
                </Box>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles(() => ({
    'root': {
        '& .MuiAvatar-circular': {
            width: '32px',
            height: '32px',
        },
        '& .MuiAvatar-circular > img': {
            width: '32px',
            height: '32px',
        }
    }
}))
