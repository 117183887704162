import React from 'react';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GetAppIcon from '@material-ui/icons/GetApp';
import FormButtonBase from './FormButtonBase';
import { useDataProvider } from 'react-admin';
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

export const REVIEW_INVITE_TYPE_PROSPECT = 'first_impression';
export const REVIEW_INVITE_TYPE_CLIENT = 'review';
export const REVIEW_INVITE_TYPE_TRANSFERRED_CLIENTS = 'transferred_client_review';
export const REVIEW_INVITE_TYPE_FOLLOW_UP_REVIEWS = 'invite_follow_up_reviews';

export const CLIENT_TYPE_BUTTONS = [
    {
        icon: <PersonAddIcon/>,
        title: 'Invite Prospects',
        type: REVIEW_INVITE_TYPE_PROSPECT,
        description: 'These reviewers are not yet clients',
    },
    {
        icon: <PersonAddIcon/>,
        title: 'Invite Clients',
        type: REVIEW_INVITE_TYPE_CLIENT,
        description: 'These reviewers are clients or have been clients in the last 5 years',
    },
];
const INVITE_TRANSFERRED_CLIENT_TYPE_BUTTON = [
    {
        icon: <PersonAddIcon/>,
        title: 'Invite Transferred Clients',
        type: REVIEW_INVITE_TYPE_TRANSFERRED_CLIENTS,
        description: 'These reviewers are clients who have been recently acquired by your firm or have recently moved adviser',
    },
];
const FOLLOW_UP_FEEDBACK_BUTTON = [
    {
        icon: <PersonAddIcon/>,
        title: 'Invite client follow-up feedback',
        type: REVIEW_INVITE_TYPE_FOLLOW_UP_REVIEWS,
        description: 'These financial advice clients left feedback between 3 to 9 months ago',
    },
];
export const getClientTypeButtons = (showTransferredClientInvitation, showFollowUpFeedback) => {
    let buttons = CLIENT_TYPE_BUTTONS;
    if (showTransferredClientInvitation) {
        buttons = buttons.concat(INVITE_TRANSFERRED_CLIENT_TYPE_BUTTON)
    }
    if (showFollowUpFeedback) {
        buttons = buttons.concat(FOLLOW_UP_FEEDBACK_BUTTON);
    }
    return buttons;
}
const exporter = reviews => {
    const advisersForExport = reviews.map(review => {
        const reviewForExport = {
            'adviser id': review.id,
            'name' : review.full_name
        };

        return reviewForExport;
    });
    jsonExport(advisersForExport, {
        headers: ['adviser id','name'],
    }, (err, csv) => {
        downloadCSV(csv, 'firm-reviews');
    });
};
export const DownloadButton = () => {
    const dataProvider = useDataProvider();

    const handleClick = async () => {
        try {
            const res = await dataProvider.getList(
                'professionals',
                {
                    sort: {
                        field: 'id',
                        order: 'ASC',
                    },
                    pagination: {
                        page: 1,
                        perPage: 1000,
                    },
                },
            );

            if(res) {
                exporter(res.data);
            }

        } catch (e) {
            console.log('Error ', e);
        }

    };

    return (
        <FormButtonBase
            title="Download"
            icon={<GetAppIcon/>}
            buttonValue={'download'}
            value={'download'}
            onClick={handleClick}
        />
    );
};
