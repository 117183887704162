import React from 'react';
import PageTabs from '../../AppLayout/PageTabs';
import { tabs } from "../../utils/tabs";
import { usePermissions } from "react-admin";

const FirmDetailsTabbedWrapper = props => {
    const { children } = props;

    const { permissions } = usePermissions()

    return (
        <>
            {permissions && permissions.includes('ROLE_EDIT_FIRM') &&
            <PageTabs routes={tabs(permissions)}/>}
            {children}
        </>
    );
};

export default FirmDetailsTabbedWrapper;
