import React from 'react';
import MenuItemLinkText from '../../components/MenuItemLinkText';
import { MenuItemLink } from 'ra-ui-materialui';
import { Typography, Divider, makeStyles } from '@material-ui/core';
import { isNetworkDashboard } from '../../helpers/safeguard';
import { hasApplicationFeature } from '../../utils';

const useStyles = makeStyles((theme) => ({
    divider: {
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    text: {
        marginLeft: theme.spacing(2),
        color: theme.palette.blue.navyBlue,
        marginBottom: '1rem',
        paddingTop: '0.25rem',
        paddingBottom: '0.25rem',
        fontSize: '0.9rem',
        textTransform: 'uppercase',
        letterSpacing: '0.1em',
    },
}));

const SafeGuardSidebar = (props) => {
    const classes = useStyles();

    return (
        <>
            <Divider className={classes.divider} variant="middle"/>
            <Typography className={classes.text} variant="h2">
                Elevation
            </Typography>

            <MenuItemLink
                to="/elevation/firm"
                primaryText={(isNetworkDashboard() ?
                    <MenuItemLinkText text="Network Dashboard"/> :
                    <MenuItemLinkText text="Firm Dashboard"/>
                )}
                selected={window.location.pathname === '/elevation/firm' || window.location.pathname.includes('/adviser-dashboard')}
                {...props}
            />
            {props.canViewInsights && isNetworkDashboard() &&
                <>
                    <MenuItemLink
                        to="/firm-overview"
                        primaryText={<MenuItemLinkText text="Firm Overview"/>}
                        selected={window.location.pathname === '/firm-overview'}
                        {...props}
                    />
                    <MenuItemLink
                        to="/firm-detailed"
                        primaryText={<MenuItemLinkText text="Firm Detailed"/>}
                        selected={window.location.pathname === '/firm-detailed'}
                        {...props}
                    />
                </>
            }
            <MenuItemLink
                to="/elevation-analytics"
                primaryText={<MenuItemLinkText text="Compare"/>}
                selected={window.location.pathname === '/elevation-analytics'}
                {...props}
            />
            <MenuItemLink
                to="/client-answers"
                primaryText={<MenuItemLinkText text="Client Answers" />}
                selected={window.location.pathname === '/client-answers'}
                {...props}
            />
            {hasApplicationFeature('reports') && <MenuItemLink
                to="/report/risk"
                primaryText={<MenuItemLinkText text="Potential Risks" />}
                selected={window.location.pathname === '/report/risk'}
                {...props}
            /> }
            {hasApplicationFeature('reports') && <MenuItemLink
                to="/report/opportunity"
                primaryText={<MenuItemLinkText text="Opportunities" />}
                selected={window.location.pathname === '/report/opportunity'}
                {...props}
            /> }
            {/* {!props.network &&
                <MenuItemLink
                    to="/consumer-duty-report"
                    primaryText={<MenuItemLinkText text="Consumer Duty Report" />}
                    selected={window.location.pathname === '/consumer-duty-report'}
                    {...props}
                />
            } */}
            {props.canViewInsights &&
                <MenuItemLink
                    to="/insights"
                    primaryText={<MenuItemLinkText text="Insights"/>}
                    selected={window.location.pathname === '/insights'}
                    {...props}
                />
            }
        </>
    );
};
export default SafeGuardSidebar;
