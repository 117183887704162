import React from 'react';
import UserHeader from '../components/Headers/UserHeader';
import ReportPage from './components/ReportPage';

const ReportRisk = () => {
    return (
        <>
            <UserHeader title={'Report: Potential Risks'}/>
            <ReportPage type={'risk'}/>
        </>
    );
};

export default ReportRisk;