const componentStyles = (theme) => ({
  header: {
    background: theme.palette.blue.main,
    flexGrow: 1

  },
  logoWrapper: {
    height: theme.spacing(3),
    width: theme.spacing(22),
    position: "absolute",
    top: "5%",
    left: "2%",
  }
});

export default componentStyles;
