import React from 'react';
import { Typography, useTheme } from '@material-ui/core';

const SectionTitle = ({ title, header, size, color, icon, variant, ...rest }) => {
    const theme = useTheme();

    return (
        <>
            <Typography
                variant={variant ? variant : "h1"}
                style={{
                    color: color
                        ? color
                        : header
                        ? theme.palette.white.main
                        : theme.palette.error.main,
                    ...(size && { fontSize: size }),
                }}
                {...rest}
            >
                {icon}
                {title}
            </Typography>
        </>
    );
};

export default SectionTitle;
