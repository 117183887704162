import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

const Spinner = () => {
  return (
    <Box sx={{ display: 'flex', height: 300, 'justify-content': 'center', 'align-items': 'center' }}>
      <CircularProgress color="secondary" />
    </Box>
  );
}

export default Spinner;