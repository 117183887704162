import React, { useEffect, useState } from 'react';
import StackedBarChart from './components/NewComponents/StackedBarChart';
import dataProvider from '../dataProvider';
import { formatRange } from '../utils';
import Loading from './components/NewComponents/Loading';
import Empty from './components/NewComponents/Empty'
const month = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
}
const quarters = {
    1: [1,2,3],
    2: [4,5,6],
    3: [7,8,9],
    4: [10,11,12],
}

const getQuarterByMonth = month => {
    return Object.keys(quarters).find(index => quarters[index].includes(month))
}

const PerformanceOverTime = (props) => {
    const { questionId,
        answers,
        setAnswers,
        getAnswersFromData,
        range,
        formatDataByPercentage,
        dataValueType,
        groupDataBy,
        serviceType,
        adviserId,
        firmId
    } = props
    const [formattedData, setFormattedData] = useState({
        count: {},
        percentage: {},
    })
    const [loading, setLoading] = useState(true)
    const [empty, setEmpty] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        setLoading(true)
        setError(false)
        fetchData().then((res) => setLoading(false)).catch(err =>setLoading(false))
    }, [range.to, range.from, serviceType, questionId, adviserId, firmId]);

    const formatDataToMonthly = (dataToFormat) => {
        let monthlyData = [];

        dataToFormat.map(item => {
            let monthlyDataItemIndex = monthlyData.findIndex(dataForMonth => dataForMonth.date === `${month[item.month]} ${item.year}`);
            if (monthlyDataItemIndex !== -1 && monthlyData[monthlyDataItemIndex][item.question_choice_gka] === undefined) {
                monthlyData[monthlyDataItemIndex] = {
                    ...monthlyData[monthlyDataItemIndex],
                    [item.question_choice_gka]: item.answer_frequency,
                    total: monthlyData[monthlyDataItemIndex].total + item.answer_frequency
                };
            } else if (monthlyDataItemIndex === -1) {
                monthlyData = [
                    ...monthlyData,
                    {
                        date: `${month[item.month]} ${item.year}`,
                        [item.question_choice_gka]: item.answer_frequency,
                        total: item.answer_frequency
                    }];
            }
            return true
        })
        return monthlyData.sort((a, b) => {
            const c = new Date(a.date);
            const d = new Date(b.date);
            return c - d;
        });
    }

    const formatDataToQuarterly = (dataToFormat) => {
        let quarterlyData = [];

        dataToFormat.map(item => {
            const quarter = getQuarterByMonth(item.month);
            if (quarter) {
                let quarterlyDataItemIndex = quarterlyData.findIndex(dataForQuarter => dataForQuarter.date === `Q${quarter} ${item.year}`);
                if (quarterlyDataItemIndex !== -1 && quarterlyData[quarterlyDataItemIndex][item.question_choice_gka] === undefined) {
                    quarterlyData[quarterlyDataItemIndex] = {
                        ...quarterlyData[quarterlyDataItemIndex],
                        [item.question_choice_gka]: item.answer_frequency,
                        total: quarterlyData[quarterlyDataItemIndex].total + item.answer_frequency
                    };
                } else if (quarterlyDataItemIndex !== -1 && quarterlyData[quarterlyDataItemIndex][item.question_choice_gka] !== undefined) {
                    quarterlyData[quarterlyDataItemIndex] = {
                        ...quarterlyData[quarterlyDataItemIndex],
                        [item.question_choice_gka]: quarterlyData[quarterlyDataItemIndex][item.question_choice_gka] + item.answer_frequency,
                        total: quarterlyData[quarterlyDataItemIndex].total + item.answer_frequency
                    };
                } else if (quarterlyDataItemIndex === -1) {
                    quarterlyData = [
                        ...quarterlyData,
                        {
                            date: `Q${quarter} ${item.year}`,
                            [item.question_choice_gka]: item.answer_frequency,
                            total: item.answer_frequency
                        },
                    ];
                }
            }
            return true
        })
        return quarterlyData;
    }

    const formatDataToYearly = (dataToFormat) => {
        let yearlyData = [];
        dataToFormat.map(item => {
            let yearlyDataItemIndex = yearlyData.findIndex(dataForYear => dataForYear.date === item.year);
            if (yearlyDataItemIndex !== -1 && yearlyData[yearlyDataItemIndex][item.question_choice_gka] === undefined) {
                yearlyData[yearlyDataItemIndex] = {
                    ...yearlyData[yearlyDataItemIndex],
                    [item.question_choice_gka]: item.answer_frequency,
                    total: yearlyData[yearlyDataItemIndex].total + item.answer_frequency
                };
            } else if (yearlyDataItemIndex !== -1 && yearlyData[yearlyDataItemIndex][item.question_choice_gka] !== undefined) {
                yearlyData[yearlyDataItemIndex] = {
                    ...yearlyData[yearlyDataItemIndex],
                    [item.question_choice_gka]: yearlyData[yearlyDataItemIndex][item.question_choice_gka] + item.answer_frequency,
                    total: yearlyData[yearlyDataItemIndex].total + item.answer_frequency
                };
            } else if (yearlyDataItemIndex === -1) {
                yearlyData = [
                    ...yearlyData,
                    {
                        date: item.year,
                        [item.question_choice_gka]: item.answer_frequency,
                        total: item.answer_frequency,
                    }];
            }

            return true
        })
        return yearlyData;
    }

    const fetchData =  async () => {
        if (!questionId) return Promise.reject()

        try {
            const { data } = await dataProvider.getNone("performance/performance-over-time",
                {
                    questionId,
                    ...(serviceType && { serviceTypeId: serviceType.type }),
                    ...(range.to && range.from && { ...formatRange(range) }),
                    ...(adviserId && { professionalId: adviserId }),
                    ...(firmId && { firmId }),
                }
            )
            if (!data || !data.length) {
                setEmpty(true)
                return Promise.reject()
            }

            setEmpty(false)
            const formattedAnswers = getAnswersFromData(data);
            setAnswers([...formattedAnswers])

            const formattedDataByFrequency = {
                monthly: formatDataToMonthly(data),
                quarterly: formatDataToQuarterly(data),
                yearly: formatDataToYearly(data),
            }

            const formattedDataByPercentage = {
                monthly: formatDataByPercentage([...formattedDataByFrequency.monthly], formattedAnswers),
                quarterly: formatDataByPercentage([...formattedDataByFrequency.quarterly], formattedAnswers),
                yearly: formatDataByPercentage([...formattedDataByFrequency.yearly], formattedAnswers),
            }
            setFormattedData({
                count: { ...formattedDataByFrequency },
                percentage: { ...formattedDataByPercentage },
            })

            return Promise.resolve()
        } catch (err) {
            setError(true)
            return Promise.reject()
        }
    }

    if (!questionId) return null
    if (loading) return <Loading />
    if (empty) return <Empty icon={"chart"} text={"There are no results for the selected filters"} />
    if (error) return <Empty icon={"warning"} text={"An unexpected error occurred"} />

    return (
        <StackedBarChart dataKeyX={"date"} chartType={dataValueType} data={formattedData[dataValueType][groupDataBy]} bars={answers} id={questionId} />
    );
};

export default PerformanceOverTime;

