import ReviewList from './ReviewList';
import ReviewEdit from './ReviewEdit';
import ReviewShow from './ReviewShow';

const reviews = {
    show: ReviewShow,
    list: ReviewList,
    edit: ReviewEdit,
    options: { label: 'Reviews' },
};

export default reviews;
