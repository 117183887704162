import React from 'react';
import StarWidgetList from './Tools/StarWidgetList';
import { Card, CardContent, Grid, makeStyles } from '@material-ui/core';
import CarouselWidgetList from './Tools/CarouselWidgetList';
import RatingWidgetList from './Tools/RatingWidgetList';
import { Title } from 'react-admin';
import PageSectionTitle from './Tools/components/PageSectionTitle';
import UserHeader from '../components/Headers/UserHeader';

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(4),
        '@media (min-width: 1820px)': {
            width: '80%',
        },
    },
    reviewLinkStyle: {
        textDecoration: 'none',
    },
}));
const ReputationTools = () => {
    const classes = useStyles();
    const reviewLink =
        "<a style='text-decoration: none; color: #106582' href='/reviews'> reviews tab.</a>";
    return (
        <>
            <UserHeader
                title="Reputation Tools"
                description="Strengthen your VouchedFor reputation with some of our marketing tools."
            />
            <Card>
                <Title title="Reputation Tools" />
                <CardContent className={classes.content}>
                    <Card>
                        <CardContent>
                            <PageSectionTitle
                                title="Rating Widget"
                                description="Add a dynamic widget to your website to showcase your firm’s VouchedFor rating. When visitors to your site click on the the widget, they will be taken to your firm profile on VouchedFor."/>
                            <Grid container spacing={2}>
                                <StarWidgetList />
                                <RatingWidgetList />
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardContent>
                            <PageSectionTitle
                                title="Latest Reviews Widget"
                                description={
                                    'Add a carousel widget which displays the latest 5 reviews across all the verified advisers linked to your firm, or select the ‘featured’ reviews that appear in the carousel in the ' +
                                    `${reviewLink}`
                                }/>
                            <Grid container spacing={2}>
                                <CarouselWidgetList />
                            </Grid>
                        </CardContent>
                    </Card>
                </CardContent>
            </Card>
        </>
    );
};

export default ReputationTools;
