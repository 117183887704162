import getDataProviderApiUrl from "./utils/getDataProviderApiUrl";
import authProvider from "./authProvider";
import decodeJwt from "jwt-decode";
import { getTokenRefreshTimeout } from "utils";

const tokenManager = () => {
    let refreshEndpoint = getDataProviderApiUrl('auth/token/refresh', null);

    const setRefreshTokenEndpoint = endpoint => refreshEndpoint = endpoint;

    const refreshToken = () => {
        const timeout = parseInt(getTokenRefreshTimeout());
        if (!timeout) {
            authProvider.logout();
            return;
        }
        // Wait before refreshing the token
        window.setTimeout(
            getRefreshedToken,
            timeout
        );
    };

    const getRefreshedToken = () => {
        const token = localStorage.getItem('token')
        const refresh_token = localStorage.getItem('refresh_token')

        let lastActiveTime = localStorage.getItem('last_api_call')

        let d = new Date();
        let timeoutQueryTime = d.getTime();

        // Check for activity in the last 5 minutes
        if ((timeoutQueryTime - lastActiveTime) / 1000 > (5 * 60)) {
            authProvider.logout()
        }

        const request = new Request(refreshEndpoint, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify({token: token, refresh_token: refresh_token}),
        });
        return fetch(request)
            .then((response) => {
                if (response.status !== 200) {
                    authProvider.logout();
                }
                return response.json();
            })
            .then((response) => {
                if (response.data) {
                    setToken(response.data.token);
                    return true;
                }

                return false;
            });
    };

    const setToken = (token) => {
        localStorage.setItem('token', token)
        const decodedToken = getDecodedToken()
        localStorage.setItem('roles', JSON.stringify(decodedToken.roles));
        refreshToken();
        return true;
    };

    const setTokenAndRefreshToken = (token, refresh_token) => {
        setToken(token)
        localStorage.setItem('refresh_token', refresh_token)
        refreshToken();
        return true;
    };

    const getDecodedToken = () => {
        const token = localStorage.getItem('token');
        if (token) {
            if (token.substring(0,1) != "{") {
                const decodedToken = decodeJwt(token);
                return decodedToken;
            } else {
                return JSON.parse(token)
            }   
        }
        return {}
    }

    const removeTokensFromStorage = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('roles');
        localStorage.removeItem('last_api_call');
        localStorage.removeItem('allowedFirms');
        localStorage.removeItem('firm_id');
    };

    return {
        setRefreshTokenEndpoint,
        setToken,
        removeTokensFromStorage,
        setTokenAndRefreshToken,
        getDecodedToken
    }
};

export default tokenManager();
