import { hasApplicationFeature } from '../utils';
import tokenManager from '../tokenManager';
import mixpanel from 'mixpanel-browser/src/loaders/loader-module';
import API_KEYS from '../constants/api-keys';

const MIXPANEL_TRACK_PAGES = 'mixpanel-track-pages';

const initialiseMixpanel = () => {
    try {
        if (hasApplicationFeature(MIXPANEL_TRACK_PAGES)) {
            mixpanel.init(API_KEYS.mix_panel_project_token, { track_pageview: 'url-with-path' });

            if (tokenManager.getDecodedToken()?.id) {
                mixpanel.identify(tokenManager.getDecodedToken().id);
            }
        }
    } catch (e) {
        console.log(e);
    }
};

export const checkCookiesAndInitMixpanel = () => {
    if (!hasSelectedPerformanceCookies()) {
        return;
    }
    initialiseMixpanel();
};

const hasSelectedPerformanceCookies = () => {
    const cookieState = window.CookieScript.instance.currentState();
    if (cookieState?.action !== 'accept') {
        return false;
    }

    return cookieState['categories'].includes('performance');
};
window.addEventListener('CookieScriptCategory-performance', function() {
    initialiseMixpanel();
});
