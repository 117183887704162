import { required, email } from 'react-admin';

export const validateEmail = [required(), email()];

export const pastDateValidation = (value, allValues) => {
    if (new Date(value) > Date.now()) {
        return 'Date should be in the past';
    }
};

export const validateMinMaxFields = (values) => {
    const errors = {};

    if (!values) return {};

    const { minimumAmount, maximumAmount } = values;

    if (maximumAmount === 0) {
        errors.maximumAmount = [`Max value cannot be 0`];
    }

    if (minimumAmount && maximumAmount) {
        if (minimumAmount >= maximumAmount) {
            errors.maximumAmount = [`Must be greater than ${minimumAmount}`];
        }
    }

    return errors;
};

export const fileSizeValidation = (maxSize, message = 'Please select a smaller file') => value => {
    return value && value.rawFile.size > maxSize ? message : undefined;
};

export const futureDateValidation = (value, allValues) => {
    if (!value) return undefined;

    let currentDate = new Date(value);

    if (typeof value === 'string') {
        if (value.includes('/') && value.length === 10) {
            const valueArr = value.split('/').reverse();

            currentDate = new Date(...valueArr);
        }
    }

    if (currentDate < Date.now()) {
        return 'Date should be in the future';
    }
};
