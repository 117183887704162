import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import { getHistory } from '../../helpers/history';

const propTypes = {
    label: PropTypes.string,
};

const defaultProps = {
    label: 'Go Back',
};

class BackButton extends Component {
    getHref = () => {
        const history = getHistory(2);
        return history ? history : this.props.href;
    };

    render() {
        return (
            <Button color="primary" href={this.getHref()}>
                <ListIcon />
                {this.props.label}
            </Button>
        );
    }
}

BackButton.propTypes = propTypes;
BackButton.defaultProps = defaultProps;

export default BackButton;
