import * as React from 'react';
import LoginPageWrapper from "./components/wrappers/LoginPageWrapper";
import LoginForm from './components/login/LoginForm';
import AuthPageContent from './components/wrappers/AuthPageContent';

const MyLoginPage = () => {
    return (
        <LoginPageWrapper>
            <AuthPageContent>
                <LoginForm />
            </AuthPageContent>
        </LoginPageWrapper>
    );
};

export default MyLoginPage;
