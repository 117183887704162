import * as React from 'react';
import { Show, SimpleShowLayout } from 'react-admin';
import QuestionList from './components/QuestionList';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 !important',
        '& .MuiPaper-root': {
            boxShadow: 'none'
        }
    },
}));

const ReviewShow = (props) => {
    const classes = useStyles()
    return (
        <Show {...props}>
            <SimpleShowLayout className={classes.root}>
                <QuestionList label="" source="questions" />
            </SimpleShowLayout>
        </Show>
)};

export default ReviewShow;
