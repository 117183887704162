import React from 'react';
import { CardContent, Box, CardHeader } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const StepContentWrapper = (props) => {
    const { children, title } = props;

    return (
        <>
            <CardHeader
                subheader={
                    <Grid
                        container
                        component={Box}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid item xs="auto">
                            <Box component={Typography} variant="h3" marginBottom="0!important">
                                <Typography>{title}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                }
            ></CardHeader>
            <CardContent>
                <Box>{children}</Box>
            </CardContent>
        </>
    );
};

export default StepContentWrapper;
