import React, { useState, useEffect }  from 'react';
import { Admin, Resource } from 'react-admin';
import MyTheme from './MyTheme';
import dataProvider from './dataProvider';
import { createBrowserHistory as createHistory } from 'history';
import authProvider from './authProvider';
import Dashboard from './Dashboard/Dashboard';
import MyLayout from './MyLayout';
import MyLoginPage from './Auth/MyLoginPage';
import { PersonOutlined } from '@material-ui/icons';

import customRoutes from './customRoutes';

import reviews from './Reviews';
import AdviserList from './Advisers/AdviserList';
import AdviserEdit from './Advisers/AdviserEdit';
import qualifications from './Qualifications';
import Fees from './Fees';
import FeeLevels from './FeeLevels';
import firmDetails from './FirmDetails';
import awards from './Awards';
import accreditations from './Accreditations';
import ResourceWithPermission from './acl/acl';
import InviteReviews from './InviteReviews';
import first_impressions from './Reviews/FirstImpressions';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppHelmet from 'AppHelmet';
import customReducers from './Store/index';
import review_invitations from 'ReviewInvitations';
import review_invitation_requests from 'ReviewInvitationRequests';
import * as analytics from './analytics';
import * as Sentry from "@sentry/react";
import { INTEGRATION_KEYS } from './constants/api-keys'
import { checkCookiesAndInitMixpanel } from './utils/mixpanel';
import AdviserPerformanceList from './AdvisersPerformance/AdviserPerformanceList';
import AdviserSettingsList from './AdvisersSettings/AdviserSettingsList';
import AdviserSettingsEdit from './AdvisersSettings/AdviserSettingsEdit';
import review_data from './ReviewData';

checkCookiesAndInitMixpanel();
const history = createHistory();

export const historyList = sessionStorage.getItem('historyList')
    ? JSON.parse(sessionStorage.getItem('historyList'))
    : [];


Sentry.init({
    dsn: INTEGRATION_KEYS.sentry_dsn,
    environment: INTEGRATION_KEYS.sentry_env,
    tracesSampleRate: INTEGRATION_KEYS.sentry_env.includes('prod') ? 0.05 : 1.0,
    attachStacktrace: true,
    replaysSessionSampleRate: INTEGRATION_KEYS.sentry_env.includes('prod') ? 0 : 0.1,  
    replaysOnErrorSampleRate: INTEGRATION_KEYS.sentry_env.includes('prod') ? 0 : 0.1,
    integrations: [new Sentry.Replay()],
});

function App() {

    const [analyticsActivated, setAnalyticsaAtivated] = useState(false)
    
    useEffect(() => {
        if (!analyticsActivated) {
            analytics.activate()
            setAnalyticsaAtivated(true)
        }
    }, []);

    return (
        <Admin
            title="Firm"
            layout={MyLayout}
            dashboard={Dashboard}
            loginPage={MyLoginPage}
            dataProvider={dataProvider}
            authProvider={authProvider}
            theme={MyTheme}
            customRoutes={customRoutes}
            history={history}
            customReducers={customReducers}
        >
            <AppHelmet />
            <CssBaseline />
            <ResourceWithPermission name="reviews" {...reviews} />
            <ResourceWithPermission name="review-data" {...review_data} />
            <ResourceWithPermission name="first-impressions" {...first_impressions} />
            <ResourceWithPermission name="review-invitations" {...review_invitations} />
            <ResourceWithPermission name="review-invitation-requests" {...review_invitation_requests} />
            <ResourceWithPermission name="professionals/professionals-for-filter" />
            <ResourceWithPermission
                name="professionals/settings"
                list={AdviserSettingsList}
                edit={AdviserSettingsEdit}
                icon={PersonOutlined}
                options={{ label: 'Advisers Settings' }}
            />
            <ResourceWithPermission
                name="professionals/performance"
                list={AdviserPerformanceList}
                icon={PersonOutlined}
                options={{ label: 'Advisers Performance' }}
            />
            <ResourceWithPermission
                name="professionals"
                list={AdviserList}
                edit={AdviserEdit}
                icon={PersonOutlined}
                options={{ label: 'Advisers' }}
            />
            <ResourceWithPermission name="firm-awards" {...awards} />
            <ResourceWithPermission name="firm-accreditations" {...accreditations} />
            <ResourceWithPermission
                name="fees"
                list={Fees.list}
                create={Fees.create}
                options={{ label: 'Fees' }}
                edit={Fees.edit}
            />
            <ResourceWithPermission
                name="fee-level"
                create={FeeLevels.create}
                edit={FeeLevels.edit}
            />
            <ResourceWithPermission
                name="bulk-invites"
                create={InviteReviews.create}
                edit={InviteReviews.edit}
            />
            <ResourceWithPermission name="bulk-invite-records" />
            <ResourceWithPermission name="template" />
            <ResourceWithPermission name="firm" {...firmDetails} />
            <ResourceWithPermission name="qualifications" {...qualifications} />
            <Resource name="certificates" />
            <Resource name="elevation/drivers" />
            <Resource name="elevation/answers" />
            <Resource name="elevation/dashboard/advisers/1" />
            <Resource name="elevation/dashboard/advisers/2" />
            <Resource name="elevation/dashboard/advisers/3" />
            <Resource name="action" />
            <Resource name="report" />
            <Resource name="report/risks" />
            <Resource name="report/risk-choices" />
            <Resource name="report/opportunity-choices" />
            <Resource name="report/opportunities" />
            <Resource name="performance/performance-over-time" />
            <Resource name="performance/performance-by-adviser-firm" />
            <Resource name="questions" />
            <Resource name="answer-choices" />
        </Admin>
    );
}

export default App;
