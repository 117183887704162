import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import EditImageButton from './Inputs/EditImageButton';

const useStyles = makeStyles(() => ({
    imageWrapper: {
        height: 188,
        width: 188,
        position: 'relative',
        marginLeft: 10,
        marginBottom: 24,
        '& > img': {
            display: 'block',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '50%',
        },
    },
}));

const FirmLogo = (props) => {
    const { enableEdit, src, onClick = () => {} } = props;
    const classes = useStyles();

    if (!src) {
        return (
            <Typography variant="body1">
                <b>Photo: </b> No photo uploaded
            </Typography>
        );
    }

    return (
        <div>
            <div className={classes.imageWrapper}>
                <img src={src} alt="Firm Logo" />
                {enableEdit && <EditImageButton onClick={onClick} />}
            </div>
        </div>
    );
};

export default FirmLogo;
