import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    button: {
        borderRadius: 4,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        minWidth: 310,
        maxWidth: 340,
        height: 150,
        padding: '.625rem 1.25rem',
        boxShadow: 'none',
        border: '1px solid',

        [theme.breakpoints.down('sm')]: {
            width: '80%',
            minWidth: '80%',
            maxWidth: '80%',
            '& .MuiTypography-root': {
                fontSize: '0.7rem',
            },
        },
    },
    buttonContent: {
        display: 'flex',
        padding: '10px',
    },
    buttonIcon: {
        display: 'flex',
    },
    title: {
        height: '100px',
    },
    titleText: {
        paddingLeft: '10px',
        fontSize: '24px',
    },
    url: {
        textAlign: 'right',
    },
    anchor: {
        color: theme.palette.secondary.dark,
    },
}));

const ReportButton = ({ title, icon, url }) => {
    const classes = useStyles();
    return (
        <Box className={classes.button}>
            <Box className={classes.title}>
                {icon}
                <span className={classes.titleText}>
                    {title}
                </span>
            </Box>
            <Box className={classes.url}>
                <a
                    className={classes.anchor}
                    href={url}
                >
                    View Report
                </a>
            </Box>
        </Box>
    );
};

export default ReportButton;