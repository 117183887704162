import * as React from 'react';
import {
    Datagrid,
    TextField,
    DateField,
    SimpleList,
    EditButton,
} from 'react-admin';
import { DateFieldWithDefaultValue, YesNoTextField } from '../components/FieldComponents';
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { useMediaQuery } from '@material-ui/core';
import { formatSimpleListDate } from '../utils';
import { useACL } from '../acl/acl';
import { ArgonAdviser } from '../components/Argon/ArgonTableComponents/ArgonAdviser';
import { Box } from '@material-ui/core';
import componentStyles from '../assets/theme/components/cards/cards/card-button';
import { makeStyles } from '@material-ui/core/styles';
import ListWrapper from '../components/List/ListWrapper';
import NumberRating from '../components/NumberRating';
import UserHeader from '../components/Headers/UserHeader';
import ListActions from "../components/ListActions";

const exporter = (advisers) => {
    const advisersForExport = advisers.map((adviser) => {

        const adviserForExport = {};

        adviserForExport.id = adviser.id;
        adviserForExport.full_name = adviser.full_name;
        adviserForExport.review_count = adviser.review_analytics.review_count || '0';
        adviserForExport.first_impression_count = adviser.review_analytics.first_impression_count || '0';
        adviserForExport.overall_rating = adviser.review_analytics.overall_rating;
        adviserForExport.response_rate = adviser.review_analytics.response_rate || '0' + ' %';
        adviserForExport.last_review_or_fi_date = adviser.reputation_tool_usage ? formatSimpleListDate(
            adviser.reputation_tool_usage.last_review_or_fi_date
        ) : '';
        adviserForExport.last_login = formatSimpleListDate(
            adviser.last_login
        );
        adviserForExport.last_review_invite = adviser.reputation_tool_usage ? formatSimpleListDate(
            adviser.reputation_tool_usage.date_last_review_invite
        ) : '';

        return adviserForExport;
    });
    jsonExport(
        advisersForExport,
        {
            headers: [
                'id',
                'full_name',
                'review_count',
                'first_impression_count',
                'overall_rating',
                'response_rate',
                'last_review_or_fi_date',
                'last_login',
                'last_review_invite',
                'contactable',
            ], // order fields in the export
            rename: [
                'ID',
                'Adviser name',
                'Review count',
                'First impression count',
                'Overall rating',
                'Response Rate',
                'Last review of first impression date',
                'Last login',
                'Last review invite date',
                'Contactable',
            ], // rename fields in the export
        },
        (err, csv) => {
            downloadCSV(csv, 'adviser-export'); // download as 'adviser-export.csv` file
        }
    );
};

const useStyles = makeStyles(componentStyles);

const AdviserList = ({ permissions, ...props }) => {
    const { export: canExportAdvisers } = useACL('professionals');
    const { edit: canEditAdvisers } = useACL('professionals');
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const isMedium = useMediaQuery('(max-width:1231px)');
    const isLarge = useMediaQuery('(max-width:1439px)');
    
    const classes = useStyles();
    return (
        <>
            <UserHeader
                title="Advisers"
            />
            <ListWrapper
                title="Advisers linked to firm"
                exporter={canExportAdvisers ? exporter : false}
                bulkActionButtons={false}
                actions={canExportAdvisers ? <ListActions resource={"professionals"} /> : false}
                {...props}
                sort={{ field: 'reputation_tool_usage.last_review_or_fi_date', order: 'DESC' }}
            >
                {isSmall ? (
                    <SimpleList
                        primaryText={(record) => `Adviser name: ${record.full_name}`}
                        secondaryText={(record) =>
                            `Overall rating: ${
                                record.review_analytics && record.review_analytics.overall_rating
                            }`
                        }
                        tertiaryText={(record) =>
                            `${
                                record.c_is_contactable === true ? 'Contactable' : 'Not contactable'
                            }`
                        }
                    />
                ) : isMedium ? (
                    <Box className={classes.cardRoot}>
                        <Datagrid rowClick="edit">
                            <ArgonAdviser source="full_name" label="Adviser name" />
                            <NumberRating
                                source="review_analytics.overall_rating"
                                label="Overall rating"
                            />
                            <NumberRating
                                source = "review_analytics.response_rate"
                                label="Response Rate"
                            />
                            <YesNoTextField source="c_is_contactable" label="Contactable" />
                            {canEditAdvisers && <EditButton label={''} fontSize="3rem" />}
                        </Datagrid>
                    </Box>
                ) : isLarge ? (
                    <Box className={classes.cardRoot}>
                    <Datagrid rowClick="edit">
                        <ArgonAdviser source="full_name" label="Adviser name" />
                        <TextField source="review_analytics.review_count" label="Reviews" />
                        <TextField
                            source="review_analytics.first_impression_count"
                            label="First Impressions"
                        />
                        <NumberRating
                            source="review_analytics.overall_rating"
                            label="Overall rating"
                        />
                        <NumberRating
                            source = "review_analytics.response_rate"
                            label="Response Rate"
                        />
                        <YesNoTextField source="c_is_contactable" label="Contactable" />
                        {canEditAdvisers && <EditButton label={''} fontSize="3rem" />}
                    </Datagrid>
                </Box>
                ) : (
                    <Box className={classes.cardRoot}>
                        <Datagrid rowClick="edit">
                            <ArgonAdviser source="full_name" label="Adviser name" />
                            <TextField source="review_analytics.review_count" label="Reviews" />
                            <TextField
                                source="review_analytics.first_impression_count"
                                label="First Impressions"
                            />
                            <NumberRating
                                source="review_analytics.overall_rating"
                                label="Overall rating"
                            />
                            <NumberRating
                                source = "review_analytics.response_rate"
                                label="Response Rate"
                            />
                            <DateField
                                source="reputation_tool_usage.last_review_or_fi_date"
                                label="Last review date"
                            />
                            <DateFieldWithDefaultValue
                                source="reputation_tool_usage.date_last_review_invite"
                                label="Last review invite date"
                            />
                            <DateFieldWithDefaultValue source="last_login" label="Last login" />

                            {canEditAdvisers && <EditButton label={''} fontSize="3rem" />}
                        </Datagrid>
                    </Box>
                )}
            </ListWrapper>
        </>
    );
};

export default AdviserList;
