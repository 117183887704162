import { List, ListItem, Typography, ListItemText } from "@material-ui/core"
import dataProvider from "dataProvider";
import React, { useEffect, useState } from "react";
import StepContentWrapper from "./StepContentWrapper";
import { makeStyles } from "@material-ui/core/styles";
import { BooleanInput } from "react-admin";
import { REVIEW_INVITE_TYPE_FOLLOW_UP_REVIEWS } from '../Content/FormButtons';

const useStyles = makeStyles((theme) => ({
  listItemMain: {
    display: 'list-item',
    listStyleType: "disc"
  },
  title: {
    marginBottom: "24px",
  },
  number: {
    color: theme.palette.primary.main,
    fontWeight: "900"
  },
  list: {
    marginLeft: "16px"
  },
  confirm: {
    marginTop: "40px",
    '& .MuiTypography-root': {
      fontSize: "14px",
      color: "#adb5bd",
    }
  }
}))

const validateConfirm = (value) => {
  if (!value) return "Please confirm"
}

const Step6 = ({ record }) => {

  const classes = useStyles()
  const [data, setData] = useState({
    advisers: { nr_advisers: 0, nr_invites: 0 }
  })
  useEffect(() => {
    async function fetchData() {
      const data = await dataProvider.getNone(`${record.id}/get-records`)
      setData(data.data)
    }
    fetchData()
  }, []);
  return (


    <StepContentWrapper title={'Step 6) Confirmation of emails to be sent'}>
     <Typography component="div" className={classes.title}>
        <span className={classes.number}>{data.advisers.nr_invites}</span> review invite(s) will be sent for <span className={classes.number}>{data.advisers.nr_advisers}</span> adviser(s)
      </Typography>

      <Typography component="p">For advisers with public profiles, answers to the following questions will be public if a review is left from an invite:</Typography>

      {record.invite_type == REVIEW_INVITE_TYPE_FOLLOW_UP_REVIEWS ? (
          <List className={classes.list} dense={true}>
            <ListItem className={classes.listItemMain}><ListItemText primary="How has your adviser helped you since your last review?" /></ListItem>
            <ListItem className={classes.listItemMain}><ListItemText primary="Star ratings out of 5 for advice, service, value and overall." /></ListItem>
          </List>
      ) : (
          <List className={classes.list} dense={true}>
            <ListItem className={classes.listItemMain}><ListItemText primary="What were the circumstances that caused you to look for an adviser?" /></ListItem>
            <ListItem className={classes.listItemMain}><ListItemText primary="How has your adviser helped you?" /></ListItem>
            <ListItem className={classes.listItemMain}><ListItemText primary="Have you seen the outcome you were hoping for?" /></ListItem>
            <ListItem className={classes.listItemMain}><ListItemText primary="What could they have done better?" /></ListItem>
            <ListItem className={classes.listItemMain}><ListItemText primary="Star ratings out of 5 for advice, service, value and overall." /></ListItem>
          </List>
      )}
          <Typography className={classes.title} component="p">All other answers will be private.</Typography>
          <Typography component="p">For advisers with private profiles (i.e. Elevation only) all answers will remain private until / unless public profiles are created for those advisers</Typography>

      <BooleanInput
        validate={validateConfirm}
        className={classes.confirm}
        label="Please confirm you have read the above and are happy for the review invites to be sent" source="is_confirmed"
        data-test={"checkbox-confirm"}
      />

    </StepContentWrapper>
  )
}

export default Step6;