import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { ConfirmPasswordField, LoginPasswordField } from "../../components/Inputs/LoginFields";
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';

const styles = theme => ({
    gridItem: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    listItem: {
        display: 'flex',
        paddingBottom: 0,
        paddingTop: 0,
        '& > .MuiListItemIcon-root': {
            minWidth: '25px'
        }
    },
    passText: {
        '& > span': {
            fontSize: '11px'
        }
    },
    svg_icons: {
        transform: 'scale(0.6)'
    }
});

const PasswordConfirm = props => {
    const { classes } = props;


    return (
        <>
            <LoginPasswordField />
            <ConfirmPasswordField valid={false} />
            <Box sx={{ flexGrow: 1, width: "100%"}}>
                <Grid className={classes.gridItem} item xs={12} dense={true}>
                    <List>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.svg_icons}>
                            <CheckCircle />
                        </ListItemIcon>
                        <ListItemText
                            className={classes.passText} 
                            primary="Your password must be at least 10 characters"
                        />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.svg_icons}>
                            <CheckCircle />
                        </ListItemIcon>
                        <ListItemText
                            className={classes.passText} 
                            primary="Your password must contain a combination of letters and numbers"
                        />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.svg_icons}>
                            <CheckCircle />
                        </ListItemIcon>
                        <ListItemText
                            className={classes.passText} 
                            primary="Your password must contain a combination of lower and upper case"
                        />
                    </ListItem>

                </List>
                </Grid>
            </Box>
        </>
    );
};

export default withStyles(styles)(PasswordConfirm);
