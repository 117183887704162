import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

const propTypes = {
    /**
     * The content of the button
     */
    children: PropTypes.any.isRequired,
};

const styles = theme => ({
    root: {
        color: theme.palette.error.main,

        '&:hover': {
            backgroundColor: 'rgba(211, 91, 131, 0.08)',
        },
    },
});

const ErrorButton = ({ classes, className, children, ...props }) => (
    <Button className={cn(classes.root, className)} {...props}>
        {children}
    </Button>
);

ErrorButton.propTypes = propTypes;

export default withStyles(styles)(ErrorButton);
