import React, { useState } from 'react';
import ListWrapper from '../../components/List/ListWrapper';
import { Datagrid, TextField, useNotify } from 'react-admin';
import { formatRange } from '../../utils';
import ReportFilter from './ReportFilter';
import { makeStyles } from '@material-ui/styles';
import { Box, CircularProgress } from '@material-ui/core';
import { DownloadCsvFile } from '../../helpers/fileDownload';
import Tooltip from "@material-ui/core/Tooltip";
import Tab from "@material-ui/core/Tab";
import ContentTooltip from "../../Profile/components/ContentTooltip";
import {useACL} from "../../acl/acl";

const ReportTable = (props) => {
    const classes = useStyles();
    const {
        type,
        handleFirmChange,
        handleAdviserChange,
        handleDateChange,
        dateRange,
        adviserFromUrl,
        firmFromUrl,
    } = props;

    const [isExporting, setIsExporting] = useState(false);
    const notify = useNotify();

    const { export: canExport } = useACL('report');

    const showErrorMessage = (message) => {
        notify(message, 'warning')
    }

    const exportComplete = () => {
        setIsExporting(false)
    };

    const startExporting = () => {
        setIsExporting(true);
    }

    const getFileName = (type) => {
        let fileName = '';
        if (type === 'risk') {
            fileName =  'report_potential_risks';
        } else {
            fileName = 'report_opportunities'
        }

        if(firmFromUrl) {
            return `${fileName} (${firmFromUrl})`;
        }

        return fileName
    }

    const getFilters = () => {
        return <ReportFilter
            onAdviserChange={handleAdviserChange}
            onDateChange={handleDateChange}
            onFirmChange={handleFirmChange}
            adviser={adviserFromUrl}
            dateRange={dateRange}
            firm={firmFromUrl}
        />;
    }

    const getActions = () => {
        return (canExport ? (isExporting ?
                (<Box marginRight="2rem">
                    <CircularProgress/>
                </Box>)
                :
                <Box marginRight="1rem">
                    <DownloadCsvFile
                        resource={'report' + '/csv'}
                        startDownloadEvent={startExporting}
                        finishDownloadEvent={exportComplete}
                        filename={getFileName(type)}
                        showErrorMessage={showErrorMessage}
                    />
                </Box>
            ) : false
        );
    }

    return (
        <div className={classes.root}>
            <ListWrapper
                basePath={'/report'}
                resource={'report'}
                bulkActionButtons={false}
                perPage={0}
                showPagination={false}
                filters={getFilters()}
                filter={{
                    ...({ from: formatRange(dateRange).from }),
                    ...({ to: formatRange(dateRange).to }),
                    ...({ type: type }),
                }}
                sort={{ field: 'identified', order: 'DESC' }}
                actions={getActions()}
                {...props}
            >
                <Datagrid>
                    <TextField label={''} source={'name'} sortable={false}/>

                        <TextField label={'Identified'} source={'identified'} sortable={true}/>

                    <TextField label={
                        <Tooltip title="Actions viewed by an adviser by logging into their account, and reading the review." arrow placement="top">
                            <div>
                                Viewed action
                            </div>
                        </Tooltip>
                    } source={'viewed_action'} sortable={true}/>
                    <TextField label={
                        <Tooltip title="Actions which the adviser has marked as complete in their account." arrow placement="top">
                            <div>
                                Actioned by adviser
                            </div>
                        </Tooltip>
                    } source={'actioned_by_adviser'} sortable={true}/>
                    <TextField label={
                        <Tooltip title="Actions marked as complete by a user at the firm on behalf of the adviser." arrow placement="top">
                            <div>
                                Actioned by firm
                            </div>
                        </Tooltip>
                    } source={'actioned_by_firm'} sortable={true}/>
                </Datagrid>
            </ListWrapper>
        </div>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        '& tr:first-child td': {
            backgroundColor: '#daedf3',
        },
        '& .MuiTableRow-root': {
            height: '61px',
        },
        '& .MuiTableCell-head': {
            lineHeight: '1.4',
        },
    },
}));

export default ReportTable;
