import * as React from 'react';
import {SimpleForm, TopToolbar, Edit} from 'react-admin';
import BackButton from '../components/Buttons/BackButton';
import MortgageFeeLevelForm from './Pages/CreateEdit/MortgageFeeLevelForm';
import {validateMinMaxFields} from "../validation";
import InitialAndOngoingFeeLevelForm from "./Pages/CreateEdit/InitialAndOngoingFeeLevelForm";


const transformData = (data) => {
    switch (data.levelType) {
        case 'up_to':
            data.minimumAmount = null;
            break
        case 'over':
            data.maximumAmount = null;
            break
    }
    return data;
}

const FeeLevelEdit = ({ dispatch, recordValues, ...props }) => {
    const { save, record } = props;

    return (
        <Edit
            actions={
                <TopToolbar>
                    <BackButton href="/fees" />
                </TopToolbar>
            }
            transform={transformData}
            title={null}
            undoable={false}
            {...props}
        >
            <SimpleForm
                redirect='/fees'
                save={save}
                record={record}
                validate={validateMinMaxFields}
            >
                <FeeLevelForm {...props} />
            </SimpleForm>
        </Edit>
    );
};

const FeeLevelForm = (props) => {
    const { record } = props;
    const type = record && record.entityType;

    switch (type) {
        case 'mortgage':
            return <MortgageFeeLevelForm type={type} {...props} />;
        case 'initial-and-ongoing':
            return <InitialAndOngoingFeeLevelForm type={type} {...props} />;
        case 'fixed-fees-only':
            return <InitialAndOngoingFeeLevelForm type={type} {...props} />;
        default:
            return null;
    }
}

export default FeeLevelEdit;
