import {useDelete} from "react-admin";
import React from "react";
import ErrorButton from "../../components/Buttons/ErrorButton";

const FeesDeleteButton = ({record}) => {
    const [deleteOne, {loading, loaded}] = useDelete('fees', record.id);

    if (loaded) {
        window.location.reload()
    }

    return <ErrorButton disabled={loading} onClick={deleteOne}>Remove This Fee Type</ErrorButton>;
};

export default FeesDeleteButton;
