import React from 'react';
import _ from 'lodash';
import {SimpleForm} from 'react-admin'
import {FEE_SUB_TYPE} from "../../../constants/professional";
import Typography from '@material-ui/core/Typography';
import AddFeeLevelButton from "../../components/AddFeeLevelButton";
import InputPrefix from "../../../components/Inputs/InputPrefix";
import InitialAndOngoingFeeLevelDataGrid from "./InitialAndOngoingFeeLevelDataGrid";
import FeesDeleteButtonWithConfirmation from "../../../Fees/components/FeesDeleteButtonWithConfirmation";
import FeesDeleteButton from "../../../Fees/components/FeesDeleteButton";
import FormNumberInput from "../../../components/Inputs/Form/FormNumberInput";
import PageSectionTitle from "../../../ReputationTools/Tools/components/PageSectionTitle";
const { HOURLY_CHARGE } = FEE_SUB_TYPE;

const InitialAndOngoingFeeLevelList = ({ data, title, feeName, investmentId, financialPlanningId, otherFees }) => {
    const levels = data && data.chargeLevels;
    const type = data && data.type;
    const subType = data && data.subType;

    return (
        <>
            <PageSectionTitle title={`${title} Fees`} />
            {subType === HOURLY_CHARGE ? (
                <SimpleForm record={data} redirect={false} toolbar={null} >
                    <FormNumberInput
                        disabled
                        source="amount"
                        label="Your average hourly fee amount"
                        InputProps={{ startAdornment: <InputPrefix label="£" /> }}
                    />
                </SimpleForm>
            ) : (
                <>
                    {_.isEmpty(levels) ? (
                        <Typography variant="body1">
                            You haven't added any {`${feeName}`} fee levels
                        </Typography>
                    ) : (
                        <InitialAndOngoingFeeLevelDataGrid subType={subType} data={levels} />
                    )}

                    <AddFeeLevelButton
                        to={{
                            pathname: '/fee-level/create',
                            state: {
                                feeType: type,
                                subType: subType
                            },
                        }}
                    />
                </>
            )}

            {investmentId && financialPlanningId ? <FeesDeleteButtonWithConfirmation otherFees={otherFees} investmentId={investmentId} financialPlanningId={financialPlanningId} record={data} /> : <FeesDeleteButton  record={data} />}
        </>
    );
};

export default InitialAndOngoingFeeLevelList;
