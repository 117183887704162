import * as React from 'react';
import { useState } from 'react';
import { Responsive, useNotify } from 'react-admin';
import { useACL } from '../acl/acl';
import TableForListComponentWrapper from '../components/List/TableForListComponentWrapper';
import { LargeDataGrid, MediumDataGrid, SmallDataGrid, XSmallDataGrid } from './components/ReviewDataGrids';
import ReviewDataFilters from './components/ReviewDataFilters';
import dataProvider from '../dataProvider';
import _ from 'lodash';
import { formatRange } from '../utils';
import { Box, Card, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DownloadCsvFile } from '../helpers/fileDownload';
import TableForListComponent from '../components/List/TableForListComponent';

const QUESTIONS_WITH_OPTIONS = [
    'multi_choice_single_response',
    'multi_choice_multi_response_with_text',
    'star_rating',
    'numeric_range',
];

export const ReviewDataList = (props) => {
    const classes = useStyles();
    const [showSelectInputAnswerFilter, setShowSelectInputAnswerFilter] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [range, setRange] = useState({ from: null, to: null });
    const [isExporting, setIsExporting] = useState(false);
    const { export: canExportReviews } = useACL('review-data');

    const notify = useNotify();

    const showErrorMessage = (message) => {
        notify(message, 'warning');
    };

    const exportComplete = () => {
        setIsExporting(false);
    };

    const startExporting = () => {
        setIsExporting(true);
    };

    const getFilters = () => (
        <ReviewDataFilters
            handleFilterChange={handleFilterChange}
            showSelectInputAnswerFilter={showSelectInputAnswerFilter}
            selectedQuestion={selectedQuestion}
            range={range}
            setRange={setRange}
        />
    );

    const MyExportButton = () => {
        const classes = useStyles();
        return (
            canExportReviews
                ? (
                    isExporting
                        ?
                        <Box className={classes.exportButton}>
                            <CircularProgress alwaysOn/>
                        </Box>
                        :
                        <Box className={classes.exportButton}>
                            <DownloadCsvFile
                                alwaysOn
                                resource="review-data/csv"
                                startDownloadEvent={startExporting}
                                finishDownloadEvent={exportComplete}
                                filename="review-data"
                                showErrorMessage={showErrorMessage}
                            />
                        </Box>
                )
                : null
        );
    };

    const { show: canShow } = useACL('review-data');
    const handleFilterChange = async (param, value) => {
        if (param === 'question') {
            if (value) {
                const response = await dataProvider.getMany('questions', {
                    ids: [value],
                });

                const question = response.data[0];
                if (_.isEmpty(question)) {
                    setShowSelectInputAnswerFilter(false);
                }

                if (QUESTIONS_WITH_OPTIONS.includes(question?.discr)) {
                    setShowSelectInputAnswerFilter(true);
                } else {
                    setShowSelectInputAnswerFilter(false);
                }
                setSelectedQuestion(question);
            } else {
                setSelectedQuestion(null);
                setShowSelectInputAnswerFilter(false);
            }
        }
    };

    return (
        <TableForListComponentWrapper
            title={'Review Data'}
            color={'white'}
            className={classes.root}
        >
            <Card className={classes.root}>
                <TableForListComponent
                    empty={false}
                    resource={'review-data'}
                    basePath={'/review-data'}
                    bulkActionButtons={false}
                    sort={{ field: 'submitted_at', order: 'DESC' }}
                    filter={{
                        ...(range.to && range.from && { ...formatRange(range) }),
                    }}
                    filters={getFilters()}
                    exporter={false}
                    actions={<MyExportButton/>}
                    {...props}
                >
                    <Responsive
                        xsmall={<XSmallDataGrid/>}
                        small={<SmallDataGrid/>}
                        medium={<MediumDataGrid canShow={canShow} showAnswerColumn={!_.isEmpty(selectedQuestion)}/>}
                        large={<LargeDataGrid canShow={canShow} showAnswerColumn={!_.isEmpty(selectedQuestion)}/>}
                    />
                </TableForListComponent>
            </Card>
        </TableForListComponentWrapper>
    )
        ;
};


const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 1000,
        marginTop: 50,
        padding: 20,
    },
    exportButton: {
        marginLeft: 'auto',
    },
}));

export default ReviewDataList;