import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from '../Links';

const FootNote = ({
    content,
    linkContent,
    linkHref,
    isSearchCardContent,
    hidden,
    ...props
}) => {
    if (hidden) {
        return null;
    }

    return (
        <Typography {...props}  variant="caption">
            *Note:&nbsp;
            {content}
            {linkContent && <Link href={linkHref}>{linkContent}</Link>}
            {isSearchCardContent && (
                <Typography component="span" style={{ display: 'inline' }}>
                    or{' '}
                    <Link href="/invite-reviews">
                        {' '}
                        invite more reviews{' '}
                    </Link>
                    to improve this!
                </Typography>
            )}
        </Typography>
    );
};

export default FootNote;
