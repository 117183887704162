import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PageTabWithTooltip from './PageTabWithTooltip';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Box from '@material-ui/core/Box'
import Chat from "@material-ui/icons/Chat";
import RateReview from "@material-ui/icons/RateReview";

const styles = theme => ({
    root: {
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            marginBottom: 0,
            '& .MuiTabs-flexContainer': {
                flexDirection: 'column',
                '& > .MuiButtonBase-root': {
                    marginRight: 0,
                    marginBottom: theme.spacing(1),
                }
            }
        }
    },
    topMargin: {
        marginTop: theme.spacing(5),
    },
    disabled: {
        opacity: 0.4,
        cursor: 'default',
    },
    boxRoot: {
        width: "800px",
        [theme.breakpoints.down('lg')]: {
            width: 'auto',
        },
    }
});

class PageTabs extends Component {
    state = {
        activeTab: false,
        nextRouteProps: null,
    };

    componentDidMount() {
        this.setActiveTab();
    }

    setActiveTab = () => {
        const { activeTab } = this.state;
        const { routes } = this.props;

        if (!activeTab) {
            const currentRoute = routes.filter(route => route.link === window.location.pathname)[0];

            if (currentRoute) {
                this.setState({ activeTab: currentRoute.label });
            }
        }
    };

    onTabClick = tab => () => {
        const { link, label, parentSidebarItem, onTabClick } = tab;
        const url = window.location.href;

        if (onTabClick) {
            return onTabClick();
        }

        if (url.includes(link)) {
            window.location.reload();
        }

        this.setState(
            {
                activeTab: label,
                nextRouteProps: {
                    pathname: link,
                    state: {
                        parentSidebarItem: !link || url.includes(link) ? null : parentSidebarItem,
                        activeTab: label,
                    },
                },
            },
            () => this.setState({ nextRouteProps: null })
        );
    };

    renderTabs = tab => {
        const { width, classes } = this.props;
        const props = {
            className: tab.disabled ? classes.disabled : null,
            value: tab.label,
            key: tab.label,
            label: tab.label,
            onClick: tab.disabled ? () => {} : this.onTabClick(tab),
        };

        if (isWidthDown('sm', width) && tab.disabled) {
            return null;
        };

        if (tab.tooltipTitle) {
            return <PageTabWithTooltip title={tab.tooltipTitle} {...props} />;
        };

        const iconToShow = () => {
            switch (true) {
                case tab.label === 'Reviews':
                    return <Box component={RateReview} marginRight=".5rem" />;
                case tab.label === 'First Impressions':
                    return <Box component={Chat} marginRight=".5rem" />;
                default:
                    return '';
            }
        };

        return <Tab
            {...props}
            icon={iconToShow()}
        />;
    };

    render() {
        const { nextRouteProps } = this.state;
        const { routes, classes } = this.props;

        if (nextRouteProps) {
            return <Redirect to={nextRouteProps} />;
        }

        return (
            <Box className={classes.boxRoot}>
                <Tabs
                    className={this.props.topMargin ? classes.topMargin : classes.root}
                    value={this.state.activeTab}
                    indicatorColor="primary"
                    scrollButtons="auto"
                    variant="scrollable"

                >
                    {routes.map(tab => this.renderTabs(tab))}
                </Tabs>
            </Box>
        );
    }
}

export default withStyles(styles)(withWidth()(PageTabs));
