import React from 'react';
import SelectFocus from './SelectFocus';
import { Box } from '@material-ui/core';
import { FOCUS_SELECT_NETWORK, FOCUS_SELECT, QUERY_ID } from '../../constants/safeguard';
import {
    ArgonSelectInput,
} from '../../components/Argon/ArgonSelectInput';
import { CustomChart } from '../../SafeGuard/Charts/CustomChart';
import { CustomBarChart } from '../../SafeGuard/Charts/BarChart';
import { CHART_LABELS } from '../../constants/safeguard';
import Spinner from '../../SafeGuard/Charts/Spinner';
import { firmCanSeeFilter, getServiceTypeFilter, isNetworkDashboard } from '../../helpers/safeguard';
import { connect } from 'react-redux';
import ServiceTypeFilter from "./ServiceTypeFilter";

const SafeGuardAnalyticsContent = props => {
    const { classes, state, onChange, advisers, drivers, setState, handleServiceTypeChange, firmIdentity } = props;

    const network = isNetworkDashboard();

    const handleFocusChange = e => {
      
        if (e.target.value.id === 2) {
            let query = [{ id: e.target.value.query.id, filter: { goal_id: state.goal.id } }];
            query = getServiceTypeFilter(query, state.serviceType);
            setState({
                ...state,
                focus: e.target.value,
                currentDashboardId: e.target.value.id,
                query,
                graphs: [],
            });
        } else if (e.target.value.id === 4) {
            setState({
                ...state,
                focus: e.target.value,
                graphs: [],
            });
        } else {
            let query = [
                { id: QUERY_ID.FIRM_AND_INDUSTRY_AVERAGE, filter: { goal_id: state.goal.id } },
                { id: QUERY_ID.GOAL_BY_ADVISER, filter: { goal_id: state.goal.id } },
            ];
            query = getServiceTypeFilter(query, state.serviceType);
            setState({
                ...state,
                focus: null,
                currentDashboardId: 1,
                query,
                graphs: [],
            });
        }
    };

    return (
        <div className={classes.goalWrapper}>
            <Box
                className={classes.dropdownWrapper}
                style={{ rowGap: 20, justifyContent: 'space-between' }}
            >
                <ArgonSelectInput
                    onChange={handleFocusChange}
                    name="focus"
                    className={classes.dropdown}
                    value={state.focus ? state.focus : '0'}
                    data={network ? FOCUS_SELECT_NETWORK : FOCUS_SELECT}
                    dataLabelName="name"
                    dataKeyName="all"
                    defaultItem="vs industry"
                    enableDefaultItem={true}
                    selectElementOnly={true}
                    noForm={true}
                    data-test={"compare-select-focus"}
                />
                {firmCanSeeFilter(firmIdentity?.type) &&
                    <Box>
                        <ServiceTypeFilter
                            serviceType={state.serviceType}
                            handleServiceTypeChange={handleServiceTypeChange}
                            classes={classes}
                        />
                    </Box>
                }
            </Box>
            {state.focus ? (
                <SelectFocus
                    classes={classes}
                    state={state}
                    focusType={state.focus}
                    onChange={onChange}
                    graphs={state.graphs}
                    advisers={advisers}
                    drivers={drivers}
                />
            ) : (
                <>
                    <Box className={classes.dropdownWrapper} />
                    <Box className={classes.chartWrapper}>
                        {state.graphs.length === 2 ? (
                            <>
                                <CustomChart
                                    data={
                                        state.graphs.find(
                                            item => item.id === QUERY_ID.FIRM_AND_INDUSTRY_AVERAGE
                                        ).data
                                    }
                                    displaySettings={
                                        CHART_LABELS.firmAndIndustryAverage[`goal${state.goal.id}`]
                                    }
                                />
                                <CustomBarChart
                                    data={
                                        state.graphs.find(
                                            item => item.id === QUERY_ID.GOAL_BY_ADVISER
                                        ).data
                                    }
                                    displaySettings={
                                        CHART_LABELS.goalByAdviser[`goal${state.goal.id}`]
                                    }
                                />
                            </>
                        ) : (
                            <Spinner />
                        )}
                    </Box>
                </>
            )}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        firmIdentity: state.identity,
    };
};
export default connect(mapStateToProps)(SafeGuardAnalyticsContent);
