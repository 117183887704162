import React from 'react';
import PropTypes from 'prop-types';
import {PageTitle} from '../components/Typography/Headlines';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const propTypes = {
    title: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
    container: {
        paddingLeft: theme.spacing(1),
    },
}));

const PageTopToolbar = props => {
    const classes = useStyles();

    return (
        <Grid
            container
            spacing={1}
            alignItems="center"
            className={classes.container}
        >
            <PageTitle title={props.title} {...props} />
            {props.children}
        </Grid>
    );
};

PageTopToolbar.propTypes = propTypes;

export default PageTopToolbar;
