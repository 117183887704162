import * as React from 'react'
import { useQueryWithStore } from 'react-admin'
import { Card, CardContent } from "@material-ui/core";
import FieldWithLabel from "../components/FieldWithLabel";
import DetailsTabbedPageWrapper from "../FirmDetails/components/FirmDetailsTabbedWrapper";

const ProfileShow = () => {
    const { data } = useQueryWithStore({
        type: 'getOne',
        resource: 'firm',
        payload: { id: 'no-id' }
    });

    if (!data) {
        return null;
    }

    return (
        <DetailsTabbedPageWrapper>
            <Card>
                <CardContent>
                    <FieldWithLabel label="Firm Name" content={data.name} />
                    <FieldWithLabel label="Firm FCA ID" content={data.fca_number} />
                    <FieldWithLabel label="About" content={data.about} variant="textarea" />
                    {data.logo ?
                        <>
                            <FieldWithLabel label="Logo" />
                            <img src={data.logo.urls.standard_retina} alt="Firm logo" />
                        </>
                        : null}
                    <br/>
                </CardContent>
            </Card>
        </DetailsTabbedPageWrapper>
    )
}

export default ProfileShow;
