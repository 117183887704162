import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles, IconButton } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    button: {
        position: 'absolute',
        bottom: 8,
        right: 16,
        padding: 8,
        backgroundColor: theme.palette.secondary.main,

        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
    icon: {
        color: theme.palette.primary.contrastText,
    },
}));

const EditImageButton = ({ onClick }) => {
    const classes = useStyles();

    return (
        <IconButton onClick={onClick} aria-label="Edit" className={classes.button}>
            <EditIcon className={classes.icon} />
        </IconButton>
    );
};

export default EditImageButton;
