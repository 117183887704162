import * as React from "react";
import {Card, CardContent, Grid} from "@material-ui/core";
import FormDateInput from "../components/Inputs/FormDateInput";

const ApprovedOnDate = ({ record }) => {
    if (!record || !record.firm_config) return null;

    if (!record.firm_config.should_show_approved_field) return null;

    return (
        <Grid item md={12} l={12}>
            <Grid>
                <Card>
                    <CardContent>
                        <FormDateInput label="SJP Approved" source="firm_config.approved_on_date" />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default ApprovedOnDate;
