import React from 'react';
import { SelectInput } from 'react-admin';
import useOutlinedStyle from './useOutlinedStyle';

const FormSelectInput = ({ className, ...rest }) => {
    return (
        <SelectInput
            {...rest}
            className={useOutlinedStyle(className)}
            variant="outlined"
            margin="none"
        />
    );
};

export default FormSelectInput;
