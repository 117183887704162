import * as React from 'react';
import { Edit, SimpleForm, Toolbar, SaveButton } from 'react-admin';
import QuestionList from './components/QuestionList';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > .MuiCardContent-root': {
            padding: '0 !important',
        },
        '& .MuiPaper-root': {
            boxShadow: 'none'
        }
    },
}));

const EditTitle = ({ record }) => {
    const fullName = (title) => {
        return record.customer ? `${title}: ${record.customer.name}` : '';
    };

    return <span>{fullName('View Review')}</span>;
};

const ReviewEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const ReviewEdit = (props) => {
    const classes = useStyles()
    return (
        <Edit undoable={false} title={<EditTitle />} {...props}>
            <SimpleForm className={classes.root} toolbar={<ReviewEditToolbar />}>
                <QuestionList label="Hello" source="questions" />
            </SimpleForm>
        </Edit>
)};

export default ReviewEdit;
