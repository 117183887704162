import React from 'react';
import { useQueryWithStore, required } from 'react-admin';
import { ArgonSelectInput } from '../../components/Argon/ArgonSelectInput';

const AccreditationSelectField = () => {
    const { data } = useQueryWithStore({
        type: 'getNone',
        resource: 'firm-accreditations/accreditation-list',
    });

    if (!data) return null;

    return <ArgonSelectInput validate={[required()]} source="accreditationId" data={data} />;
};

export default AccreditationSelectField;
