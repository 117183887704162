import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {useDelete, showNotification, refreshView, useNotify} from 'react-admin';
import ErrorButton from '../../components/Buttons/ErrorButton';
import { Typography } from '@material-ui/core';
import DialogBase from "../../components/DialogBase";

const FeesDeleteButtonWithConfirmation = ({ investmentId, financialPlanningId, otherFees }) => {
    const notify = useNotify();

    const [deleteInvestment] = useDelete('fees', investmentId, null, {
        onSuccess: () => {
            deleteFinancialPlanning();
        },
    });

    const [deleteFinancialPlanning] = useDelete('fees', financialPlanningId, null, {
        onSuccess: () => {
            dispatch(refreshView());
            dispatch(notify('Fee levels deleted'));
        },
    });

    const [showDialog, setShowDialog] = useState(false);
    const dispatch = useDispatch();

    const handleSave = async () => {
        try {
            deleteInvestment();
        } catch {
            dispatch(showNotification('Failed to delete fees', 'warning'));
        }

        handleClose();
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    return (
        <>
            <ErrorButton
                onClick={() => setShowDialog(true)}
                color="secondary"
                disabled={!financialPlanningId}
            >
                Remove this fee type
            </ErrorButton>
            <DialogBase
                open={showDialog}
                onClose={handleClose}
                ErrorButtonProps={{
                    label: 'Cancel',
                    onClick: handleClose,
                }}
                SaveButtonProps={{
                    label: 'OK, remove these fees',
                    onClick: handleSave,
                }}
                title="Confirm"
                message={
                    <Typography>
                        As you added your <b>Implementation & Ongoing Service Fees</b> fees in
                        combination with <b>Financial Planning</b> fees, your {otherFees} fees
                        will also be removed.
                    </Typography>
                }
            />
        </>
    );
};

export default FeesDeleteButtonWithConfirmation;
