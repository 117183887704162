import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { FormDataConsumer, required} from 'react-admin';
import InputPrefix from '../../../components/Inputs/InputPrefix';
import FormTextInput from '../../../components/Inputs/Form/FormTextInput';
import FormSelectInput from '../../../components/Inputs/Form/FormSelectInput';
import FormNumberInput from '../../../components/Inputs/Form/FormNumberInput';
import PageSectionTitle from "../../../ReputationTools/Tools/components/PageSectionTitle";

const MortgageFeeLevelForm = (props) => {
    const [redirect] = useState(false);
    const isCreateForm = window.location.href.includes('create');
    const type = props.type

    if (redirect) {
        return <Redirect to="/fees" />;
    }

    return (
        <>
            <PageSectionTitle title={`${isCreateForm ? 'Add' : 'Edit'} fee level: Mortgage`} />
            <FormSelectInput
                label="For mortgage value"
                source="levelType"
                validate={[required()]}
                choices={[
                    { id: 'up_to', name: 'Up to' },
                    { id: 'between', name: 'Between' },
                    { id: 'over', name: 'Over' },
                ]}
            />
            <FormTextInput
                style={{ display: 'none' }}
                source="type"
                defaultValue={type}
            />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return (
                        <FormNumberInput
                            source={
                                formData.levelType === 'up_to' ? 'maximumAmount' : 'minimumAmount'
                            }
                            {...rest}
                            label="Value"
                            InputProps={{
                                startAdornment: <InputPrefix label="£" />,
                            }}
                        />
                    );
                }}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    formData.levelType === 'between' && (
                        <FormNumberInput
                            source="maximumAmount"
                            label="Max Value"
                            {...rest}
                            InputProps={{
                                startAdornment: <InputPrefix label="£" />,
                            }}
                        />
                    )
                }
            </FormDataConsumer>
            <FormNumberInput
                source="paymentFee"
                label="Fee"
                validate={[required()]}
                InputProps={{
                    startAdornment: <InputPrefix label="£" />,
                }}
            />
            <FormSelectInput
                label="On"
                source="paymentTime"
                defaultValue={'completion'}
                validate={[required()]}
                choices={[
                    { id: 'offer', name: 'Offer' },
                    { id: 'completion', name: 'Completion' },
                    { id: 'application', name: 'Application' },
                ]}
            />
        </>
    );
};

export default MortgageFeeLevelForm;
