import * as React from 'react';
import { Edit, SimpleForm, Toolbar, SaveButton } from 'react-admin';
import AddButton from '../components/Buttons/AddButton';
import QualificationGrid from '../Qualifications/components/QualificationGrid';
import AdviserInfo from './AdviserInfo';
import UserHeader from '../components/Headers/UserHeader';

const AddQualificationButton = (props) => {
    const { record, basePath } = props;

    return (
        <AddButton
            resource="qualifications"
            label="Add qualification"
            to={{
                pathname: '/qualifications/create',
                search: `?filter=${JSON.stringify({ adviser_id: record.id })}`,
                state: { qualificationsUrl: `${basePath}/${record.id}` },
            }}
        />
    );
};

const AdviserEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const AdviserEdit = (props) => (
    <>
        <UserHeader title="Edit adviser details" description='' />
        <Edit undoable={false} {...props}>
            <SimpleForm toolbar={<AdviserEditToolbar />}>
                <AdviserInfo {...props} />
                <QualificationGrid />
                <AddQualificationButton />
            </SimpleForm>
        </Edit>
    </>
);

export default AdviserEdit;
