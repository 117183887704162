export const defaultColours = {
    navyBlue: '#022D4F',
    mintGreen: '#4BC1CA',
    brightBlue: '#4282F3',
    grassGreen: '#1FAF83',
    cherryRed: '#E43B58',
    darkWarmGrey: '#474444',
    paleMintGreen: '#DBF3F5',
    paleBlue: '#DDE9FF',
    paleGrassGreen: '#E2F5EF',
    palePink: '#F3D3C7',
    midWarmGrey: '#C1B8B3',
    paleWarmGrey: '#EEEBE9',
    white: '#fff',
    black: 'rgba(0, 0, 0, 0.87)',
};

const themeColors = {
  white: {
    main: "#FFFFFF",
    secondary: "#EEEFF7"
  },
  black: {
    light: "#022D4F",
    main: "#000000",
  },
  green: {
    main: "#1FAF83",
  },
  blue: {
    main: '#022D4F',
    brightBlue: '#4282F3',
    navyBlue: '#022D4F',
  },
  transparent: {
    main: "transparent",
  },
  gray: {
    100: "#f6f9fc",
    200: "#EEEBE9",
    300: "#EEEBE9",
    400: "#EEEBE9",
    500: "#C1B8B3",
    600: "#C1B8B3",
    700: "#474444",
    800: "#474444",
    900: "#212529",
  },
  primary: {
    main: defaultColours.grassGreen,
    dark: defaultColours.darkWarmGrey,
    contrastText: defaultColours.white,
    textPrimary: defaultColours.black,
    snackbar: "#4282F3",
    badgeBg: defaultColours.paleBlue,
    badgeBgHover: defaultColours.brightBlue,
    badge: defaultColours.brightBlue,
    inputCredit: defaultColours.brightBlue,
  },
  secondary: {
    main: defaultColours.mintGreen,
    dark: defaultColours.navyBlue,
    light: defaultColours.mintGreen,
    snackbar: defaultColours.paleWarmGrey,
    badgeBgHover: defaultColours.paleBlue,
    btnOutline: defaultColours.mintGreen,
    btnActive: defaultColours.paleMintGreen,
  },
  tertiary: {
    light: defaultColours.paleBlue,
    main: defaultColours.brightBlue,
    contrastText: defaultColours.paleWarmGrey,
    dark: defaultColours.midWarmGrey,
  },
  warning: {
    tableLight: defaultColours.palePink,
    tableLightHover: defaultColours.palePink,
    light: "#ffd600",
    main: "#fb6340",
    snackbar: "#fc7c5f",
    badgeBg: "#fee6e0",
    badgeBgHover: defaultColours.cherryRed,
    badge: defaultColours.cherryRed,
  },
  error: {
    main: defaultColours.cherryRed,
    light: defaultColours.cherryRed,
    snackbar: "#f75676",
    badgeBg: "#fdd1da",
    badgeBgHover: defaultColours.cherryRed,
    badge: defaultColours.cherryRed,
    dialogNotification: "#f56036",
  },
  info: {
    main: defaultColours.brightBlue,
    snackbar: defaultColours.mintGreen,
    badgeBg: defaultColours.paleMintGreen,
    badgeBgHover: defaultColours.brightBlue,
    badge: defaultColours.brightBlue,
  },
  background: {
    default: "#f8f9fe",
  },
  text: {
    primary: defaultColours.navyBlue,
  },
  dark: {
    tableBorder: defaultColours.navyBlue,
    tableHeadColor: defaultColours.brightBlue,
    tableHeadBgColor: defaultColours.navyBlue,
    main: defaultColours.navyBlue,
    dark: "#0b1526",
    snackbar: defaultColours.darkWarmGrey,
    badgeBg: defaultColours.brightBlue,
    badgeBgHover: "#09111e",
  },
  success: {
    tableLightHover: defaultColours.paleGrassGreen,
    tableLight: defaultColours.paleGrassGreen,
    main: defaultColours.grassGreen,
    snackbar: defaultColours.grassGreen,
    badgeBg: defaultColours.paleGrassGreen,
    badgeBgHover: defaultColours.grassGreen,
    badge: defaultColours.grassGreen,
  },
  facebook: {
    main: "#3b5999",
    dark: "#2d4474",
  },
  twitter: {
    main: "#1da1f2",
    dark: "#0c85d0",
  },
  google: {
    main: "#dd4b39",
    dark: "#c23321",
  },
  instagram: {
    main: "#e4405f",
    dark: "#d31e40",
  },
  pinterest: {
    main: "#bd081c",
    dark: "#8c0615",
  },
  youtube: {
    main: "#cd201f",
    dark: "#a11918",
  },
  vimeo: {
    main: "#04A0F0",
    dark: "#037fbe",
  },
  slack: {
    main: "#3aaf85",
    dark: "#2d8968",
  },
  dribbble: {
    main: "#ea4c89",
    dark: "#e51e6b",
  },
  github: {
    main: "#222222",
    dark: "#090909",
  },
};

export default themeColors;
