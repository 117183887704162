import React from 'react';
import _ from 'lodash';
import {FormDataConsumer, required, useQuery} from 'react-admin';
import FootNote from '../../../components/Typography/FootNote';
import FeesTieredButtonsField from '../../components/FeesTieredButtonsField';
import {FEE_TYPE} from '../../../constants/professional';
import {FEE_SUB_TYPE_CHOICES} from '../../../constants/lists';

import {Typography} from '@material-ui/core';

import FormSelectInput from "../../../components/Inputs/Form/FormSelectInput";
import TrueFalseInput from "../../../components/Inputs/TrueFalseInput";

const {INVESTMENT_AND_PENSION_WITH_PLANNING, INVESTMENT_AND_PENSION} = FEE_TYPE;

const FeeCreateFormContent = () => {
        const { data, loading } = useQuery({
                type: 'getNone',
                resource: 'fees/choices',
        });

       if (loading) {
           return null;
       }

    return (
        <>
            <FormSelectInput label="Choose type of fee" source="type" choices={data} fullWidth />
            <FormDataConsumer>
                {({formData, ...rest}) => formData.type === 'investment_and_pension_with_financial_planning' &&
                    <FormSelectInput
                        {...rest}
                        validate={formData.type === 'mortgage' ? [] : [required()]}
                        label="Please choose the model you'd like to use for Implementation & Ongoing Service fees"
                        source='fp_subType'
                        fullWidth
                        choices={FEE_SUB_TYPE_CHOICES}
                    />
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({
                      formData,
                      ...rest
                  }) => formData.fp_subType === 'initial-and-ongoing' &&
                    <FeesTieredButtonsField
                        label='Please choose which way you tier your charges for Financial Planning fees'
                        source='fp_isWealthBased'
                    />
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({formData, ...rest}) => {
                    if (formData.type === 'mortgage') {
                        delete formData.subType
                        delete formData.isWealthBased
                        delete formData.fp_subType
                        delete formData.fp_isWealthBased
                        delete formData.fp_offsetAgainstInitialFee
                    }

                    if (formData.type === 'investment_and_pension') {
                        delete formData.fp_subType
                        delete formData.fp_isWealthBased
                        delete formData.fp_offsetAgainstInitialFee
                    }

                    return (
                        <>
                            {(formData.type === 'investment_and_pension' || formData.type === 'investment_and_pension_with_financial_planning') &&
                            <FormSelectInput
                                {...rest}
                                validate={[required()]}
                                label="Please choose the model you'd like to use for Financial Planning fees"
                                source='subType'
                                fullWidth
                                choices={FEE_SUB_TYPE_CHOICES}
                            />}
                        </>
                    )
                }}
            </FormDataConsumer>
            <FormDataConsumer>
                {({
                      formData,
                      ...rest
                  }) => formData.subType === 'initial-and-ongoing' &&
                    <FeesTieredButtonsField
                        label='Please choose which way you tier your charges for Implementation & Ongoing Service fees'
                        source='isWealthBased'
                    />
                }
            </FormDataConsumer>


            <FormDataConsumer>
                {({formData, ...rest}) => formData.type === 'investment_and_pension_with_financial_planning' &&
                    <>
                        <TrueFalseInput
                            label="Financial planning fee can be offset against initial fee for implementation"
                            source="fp_offsetAgainstInitialFee"
                            defaultValue={false}
                        />
                        {formData.fp_offsetAgainstInitialFee === true && (
                            <Typography variant="body1" color="error">
                                As your fees can be offset, your fee report and insights won’t
                                quite work yet. You can still add your fees to your profile and
                                display them to prospective clients!
                            </Typography>
                        )}
                    </>
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({formData}) => {
                    const type = _.get(formData, 'type', null);

                    if (!type) return;

                    if (type === INVESTMENT_AND_PENSION_WITH_PLANNING) {
                        return (
                            <FootNote
                                content={
                                    <span>
                                        On the next step, you’ll be asked to add <b>fee levels</b>{' '}
                                        to detail your fees at different asset levels.
                                        <br/>
                                        The Financial Planning fees you add will <b>added</b> to
                                        your Implementation & Ongoing fees to get your total cost.
                                    </span>
                                }
                            />
                        );
                    }

                    if (type === INVESTMENT_AND_PENSION) {
                        return (
                            <FootNote
                                content={
                                    <span>
                                        On the next step, you’ll be asked to add <b>fee levels</b>{' '}
                                        to detail your fees at different asset levels.
                                    </span>
                                }
                            />
                        );
                    }

                    return (
                        <FootNote
                            content={
                                <span>
                                    On the next step you can add fees for different levels of mortgage values.
                                </span>
                            }
                        />
                    );
                }}
            </FormDataConsumer>
        </>
    );
}

export default FeeCreateFormContent;
