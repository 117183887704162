import React from 'react';
import { FileField, required, FileInput, FormDataConsumer } from 'react-admin';
import StepContentWrapper from './StepContentWrapper';
import { Typography } from '@material-ui/core';
import { CUSTOM_BULK_INVITE } from '../../constants';

const Step3 = () => {
    return (
        <StepContentWrapper title={'Step 3) Upload the completed template'}>
            <FileInput
                source="file"
                label=""
                placeholder="Drop a .csv file to upload, or click to select it."
                validate={[required()]}
                accept="text/csv, .csv"
            >
                <FileField source="src" title="title"/>
            </FileInput>
            <FormDataConsumer>
                {({formData}) => {
                    formData.allow_config_override = sessionStorage.getItem(CUSTOM_BULK_INVITE)
                }}
            </FormDataConsumer>
            <Typography variant="string" color="error" className="">Max 2000 rows in a file.</Typography>
        </StepContentWrapper>
    );
};

export default Step3;
