import React, {useState} from 'react';
import Switch from "@material-ui/core/Switch";
import {CircularProgress, makeStyles} from "@material-ui/core";
import dataProvider from "../dataProvider";

const useStyles = makeStyles(() => ({
    wrapper: {
        display: 'inline-flex',
        minWidth: '80px',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '45px',
    },
}));

const CustomBooleanInput = ({ record, source, label }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [switchValue, setSwitchValue] = useState(record[source]);

    const handleToggle = () => {
        setLoading(true);

        const updatedRecord = { ...record, [source]: !switchValue };

        dataProvider.updateReviewInviteSendReminder({
            data: updatedRecord,
            id: record.id
        }).then((res) => {
            if (res && res.data && res.data.hasOwnProperty('send_reminder')) {
                setSwitchValue(res.data.send_reminder);
                setLoading(false);
            }
        });
    };

    return (
        <div className={classes.wrapper}>
            {loading ?
                <CircularProgress size={16}/>
                :
                <Switch checked={switchValue} onChange={handleToggle} color="primary" label={label}/>
            }
        </div>
    );
};

export default CustomBooleanInput;