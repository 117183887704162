export const DASHBOARD_TYPES = {
    GOALS: 'goals',
    DRIVERS: 'drivers',
    FOCUS: 'focus',
    ADVISERS: 'advisers',
    PROFESSIONAL_TYPE: 'professionalType'
};

export const FOCUS_TYPES = {
    ADVISER: 'adviser_performance',
    FIRM: 'firm_performance',
};

export const FOCUS_SELECT = [
    {
        id: 2,
        name: 'Firm performance',
        type: 'firm_performance',
        query: { id: 49 },
    },
    {
        id: 4,
        name: 'Adviser performance',
        type: 'adviser_performance',
    },
];

export const FOCUS_SELECT_NETWORK = [
    {
        id: 2,
        name: 'Network performance',
        type: 'network_performance',
        query: { id: 49 },
    },
    {
        id: 4,
        name: 'Adviser performance',
        type: 'adviser_performance',
    },
];

export const CHART_LABELS = {
    firmPerformance: {
        goal1: {
            yLabel: null,
            bar1: 'Firm',
            bar2: 'Industry',
            bar1Label: 'Firm',
            bar2Label: 'Industry',
            xAxis: 'Driver',
            tooltip: 'of prospects intend to become a client. This is based on ',
            title: 'Prospect conversion drivers',
            reviews: 'Reviews',
            description:
                'Comparison against the industry average for all drivers associated with the propect conversion goal.',
        },
        goal2: {
            yLabel: null,
            bar1: 'Firm',
            bar2: 'Industry',
            bar1Label: 'Firm',
            bar2Label: 'Industry',
            xAxis: 'Driver',
            tooltip: 'of clients would be a passionate advocate. This is based on ',
            title: 'Client advocacy drivers',
            reviews: 'Reviews',
            description:
                'Comparison against the industry average for all drivers associated with the client advocacy goal.',
        },
        goal3: {
            yLabel: null,
            bar1: 'Firm',
            bar2: 'Industry',
            bar1Label: 'Firm',
            bar2Label: 'Industry',
            xAxis: 'Driver',
            tooltip: 'of reviews don’t exceed our risk threshold. This is based on ',
            title: 'Risk score drivers',
            reviews: 'Reviews',
            description:
                'Comparison against the industry average for all drivers associated with the risk score goal.',
        },
    },
    firmAndIndustryAverage: {
        goal1: {
            xAxis: 'Date',
            description: '% of prospects that intend to become a client',
            yLabelLeft: 'Reviews',
            yLabelRight: null,
            tooltip: 'of prospects intend to become a client',
            line1: 'Industry Average',
            line2: 'Firm Average',
            bar: 'Firm Reviews',
            barLabel: 'Firm Reviews',
            title: 'Prospect conversion',
        },
        goal2: {
            xAxis: 'Date',
            description: '% of clients that would be a passionate advocate for their adviser',
            yLabelLeft: 'Reviews',
            yLabelRight: null,
            tooltip: 'of clients that would be a passionate advocate for their adviser',
            line1: 'Industry Average',
            line2: 'Firm Average',
            bar: 'Firm Reviews',
            barLabel: 'Firm Reviews',
            title: 'Client advocacy',
        },
        goal3: {
            xAxis: 'Date',
            description: '% of reviews that don’t exceed our risk threshold',
            yLabelLeft: 'Reviews',
            yLabelRight: null,
            tooltip: 'of reviews that don’t exceed our risk threshold',
            line1: 'Industry Average',
            line2: 'Firm Average',
            bar: 'Firm Reviews',
            barLabel: 'Firm Reviews',
            title: 'Risk score',
        },
    },
    goalByAdviser: {
        goal1: {
            xAxis: 'Adviser Name',
            bar1: 'Average',
            bar1Label: 'Adviser average',
            yLabel: '',
            tooltip: 'of prospects intend to become a client. This is based on ',
            title: 'Prospect conversion by adviser',
            reviews: 'Reviews',
            description:
                '% of prospects that intend to become a client. Adviser requires a minimum of 5 reviews to appear in the chart.',
        },
        goal2: {
            xAxis: 'Adviser Name',
            bar1: 'Average',
            bar1Label: 'Adviser average',
            yLabel: '',
            tooltip: 'of clients would be a passionate advocate. This is based on ',
            title: 'Client advocacy by adviser',
            reviews: 'Reviews',
            description:
                '% of clients that would be a passionate advocate for their adviser. Adviser requires a minimum of 5 reviews to appear in the chart.',
        },
        goal3: {
            xAxis: 'Adviser Name',
            bar1: 'Average',
            bar1Label: 'Adviser average',
            yLabel: null,
            tooltip: 'of reviews don’t exceed our risk threshold. This is based on ',
            title: 'Risk score by adviser',
            reviews: 'Reviews',
            description:
                '% of reviews that don’t exceed our risk threshold. Adviser requires a minimum of 5 reviews to appear in the chart.',
        },
    },
    driverVsIndustryAverage: {
        xAxis: 'Date',
        line1: 'Industry Average',
        line2: 'Firm',
        bar: 'Firm Reviews',
        barLabel: 'Firm Reviews',
        yLabelLeft: 'Reviews',
        yLabelRight: null,
        title: 'Average of {{driver}} by month',
        noIndustryAverageTitle: 'Driver average',
        description: '',
        tooltip: 'Firm average of ',
        tooltipType: 'driverIndustryAverage',
    },
    driverForAllAdvisers: {
        xAxis: 'Adviser Name',
        bar1: 'avg',
        bar1Label: 'Adviser average',
        yLabel: '',
        tooltip: 'Adviser average of ',
        tooltipType: 'driverAllAdvisers',
        reviews: 'Reviews',
        title: 'Driver across all advisers at firm',
        description: 'Adviser requires a minimum of 5 reviews to appear in the chart',
    },
    goalKeyDriverByAdviser: {
        goal1: {
            yLabel: '',
            bar1: 'Adviser',
            bar2: 'Firm',
            bar3: 'Industry',
            bar1Label: 'Adviser',
            bar2Label: 'Firm',
            bar3Label: 'Industry',
            tooltip: 'Reviews',
            xAxis: 'Driver',
            title: '\'s prospect conversion drivers',
            reviews: 'Reviews',
            description: '',
        },
        goal2: {
            yLabel: '',
            bar1: 'Adviser',
            bar2: 'Firm',
            bar3: 'Industry',
            bar1Label: 'Adviser',
            bar2Label: 'Firm',
            bar3Label: 'Industry',
            tooltip: 'Reviews',
            xAxis: 'Driver',
            title: '\'s client advocacy drivers',
            reviews: 'Reviews',
            description: '',
        },
        goal3: {
            yLabel: '',
            bar1: 'Adviser',
            bar2: 'Firm',
            bar3: 'Industry',
            bar1Label: 'Adviser',
            bar2Label: 'Firm',
            bar3Label: 'Industry',
            tooltip: 'Reviews',
            xAxis: 'Driver',
            title: '\'s risk score drivers',
            reviews: 'Reviews',
            description: '',
        },
    },
};

export const QUERY_ID = {
    'GOAL_BY_ADVISER': 48,
    'FIRM_PERFORMANCE': 49,
    'DRIVER_FIRM_AVERAGE': 51,
    'FIRM_AND_INDUSTRY_AVERAGE': 52,
    'GOAL_KEY_DRIVER_BY_ADVISER': 53,
    'DRIVER_ACROSS_ALL_ADVISERS_AT_FIRM': 54,
};


export const DRIVER_STRENGTHS = [
    {
        driver_id: 1,
        description: 'The description of the driver',
        name: 'A driver',
        industry_avg: 44,
        firmAvg: 80,
    },
    {

        driver_id: 2,
        description: 'The description of the driver',
        name: 'A driver',
        industry_avg: 65,
        firmAvg: 75,
    },
    {
        driver_id: 3,
        description: 'The description of the driver',
        name: 'A driver',
        industry_avg: 70,
        firmAvg: 80,
    },
];
export const DRIVER_AREAS_FOR_IMPROVEMENT = [
    {
        driver_id: 4,
        description: 'The description of the driver',
        name: 'A driver',
        industry_avg: 80,
        firmAvg: 45,
    },
    {
        driver_id: 5,
        description: 'The description of the driver',
        name: 'A driver',
        industry_avg: 68,
        firmAvg: 62,
    },
    {
        driver_id: 10,
        description: 'The description of the driver',
        name: 'A driver',
        industry_avg: 75,
        firmAvg: 65,
    },
];

export const SERVICE_TYPE_FILTER_FA = {
    name: 'Financial advice',
    type: 1,
};
export const SERVICE_TYPE_FILTER_MA = {
    name: 'Mortgage advice',
    type: 2,
};
export const SERVICE_TYPE_FILTER_LEGAL = {
    name: 'Legal advice',
    type: 4,
};
export const SERVICE_TYPE_FILTER_PROTECTION = {
    name: 'Protection advice',
    type: 3,
};

export const SERVICE_TYPE_FILTER_TRANSFERRED_CLIENTS = {
    name: 'Transferred clients',
    type: 5,
};

export const SERVICE_TYPE_FILTER_EQUITY_RELEASE = {
    name: 'Equity release',
    type: 6,
};

export const SERVICE_TYPE_FILTERS = [
    SERVICE_TYPE_FILTER_FA,
    SERVICE_TYPE_FILTER_MA,
    SERVICE_TYPE_FILTER_LEGAL,
    SERVICE_TYPE_FILTER_TRANSFERRED_CLIENTS,
    SERVICE_TYPE_FILTER_PROTECTION,
    SERVICE_TYPE_FILTER_EQUITY_RELEASE,
]

export const SERVICE_TYPE_FILTERS_EXCEPT_LEGAL_AND_TRANSFERRED_CLIENTS = [
    SERVICE_TYPE_FILTER_FA,
    SERVICE_TYPE_FILTER_MA,
    SERVICE_TYPE_FILTER_PROTECTION,
    SERVICE_TYPE_FILTER_EQUITY_RELEASE,
]

export const DRIVER_TYPES = {
    FA: 1,
    MRG: 2,
    PROTECTION: 3,
    LEGAL:4,
}

export const GOALS = {
    1: "Prospect Conversion",
    2: "Client Advocacy",
    3: "Risk Mitigation"
}

export const FIRM_TYPES_TO_SHOW_CLIENT_TYPE_FILTER = ['adviser', 'coach', 'network']