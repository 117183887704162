import React, { useState } from 'react';
import CopyTextButton from '../../components/Buttons/CopyTextButton';
import {
    REP_TOOL_FIRM_REVIEWS_DARK,
    REP_TOOL_FIRM_REVIEWS_DARK_VF_2023,
    REP_TOOL_FIRM_REVIEWS_LIGHT, REP_TOOL_FIRM_REVIEWS_LIGHT_VF_2023
} from '../../constants/assets';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {useMediaQuery} from "@material-ui/core";
import ReputationToolItem from "./components/ReputationToolItem";

const CarouselWidget = ({ widget }) => {
    const [ showModal, setShowModal ] = useState(false);

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const handlePreview = (widget) => {
        if (!widget) return;

        setShowModal(true);
    };

    const closePreview = () => {
        setShowModal(false);
    };

    const image = widget.id === 'dark' ?
        (widget.content.includes('vf2023') ? REP_TOOL_FIRM_REVIEWS_DARK_VF_2023 : REP_TOOL_FIRM_REVIEWS_DARK)
        :
        (widget.content.includes('vf2023') ? REP_TOOL_FIRM_REVIEWS_LIGHT_VF_2023 : REP_TOOL_FIRM_REVIEWS_LIGHT);

    return (
        <>
            <ReputationToolItem
                CardProps={{
                    style: {
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                    },
                }}
                img={image}
                actions={
                    <>
                        <CopyTextButton
                            clipboardContent={widget.content}
                            style={{ margin: 4 }}
                            variant="contained"
                        >
                            Copy carousel code
                        </CopyTextButton>
                        <Button
                            style={{ margin: 4 }}
                            color="secondary"
                            variant="contained"
                            onClick={handlePreview}
                        >
                            Preview
                        </Button>
                    </>
                }
            />
            {Boolean(widget.content) && (
                <Dialog
                    open={showModal}
                    maxWidth={false}
                    onClose={closePreview}
                >
                    <DialogContent>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: isSmall
                                    ? widget.content.replace('width="900"', 'width=100%')
                                    : widget.content,
                            }}
                        />
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
}

export default CarouselWidget;
