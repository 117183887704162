import React, { useEffect, useState } from 'react';
import { AutocompleteInput, Filter, ReferenceInput, TextInput } from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import DateFilter from '../../SafeGuard/components/NewComponents/DateFilter';
import { formatRange } from '../../utils';
import TextField from '@material-ui/core/TextField';

const reviewTypes = [
    { 'id': 'review', 'name': 'Review' },
    { 'id': 'first_impression', 'name': 'First Impression' },
];

const serviceTypes = [
    { 'id': '1', 'name': 'Financial advice' },
    { 'id': '2', 'name': 'Mortgage advice' },
    { 'id': '3', 'name': 'Protection advice' },
    { 'id': '4', 'name': 'Legal advice' },
    { 'id': '6', 'name': 'Equity release advice' },
];

export default (props) => {
    const {
        handleFilterChange,
        showSelectInputAnswerFilter,
        selectedQuestion,
        range,
        setRange,
        displayedFilters,
        setFilters,
        filterValues,
    } = props;
    const classes = useStyles();
    const [filtersForQuestionFilter, setFiltersForQuestionFilter] = useState({});
    const handleQuestionChange = (value) => {
        handleFilterChange('question', value);
        setFilters({ ...filterValues, question_id: value, answer_choice: null });
    };

    useEffect(() => {
        (() => {
            if (filterValues) {
                if (!('question_id' in filterValues)) {
                    handleFilterChange('question', null);
                }
            }
        })();
    }, [displayedFilters]);

    useEffect(() => {
        const { question_id, answer_choice, ...rest } = filterValues;
        setFiltersForQuestionFilter(rest);
    }, [filterValues]);

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <label className={classes.label}> Filter reviews by</label>
            </div>

            <div className={classes.filters}>
                <div className={classes.dateFilter}>
                    <div className={classes.label}>
                        <label>Date </label>
                    </div>
                    <Filter {...props} className={classes.upwardShiftedFilter}>
                        <DateFilter
                            alwaysOn
                            range={range}
                            setRange={setRange}
                            shortcutsToShow={['lastWeek', 'lastMonth', 'last3Months', 'lastYear']}/>
                    </Filter>
                </div>
                <div className={classes.filter}>
                    <div className={classes.label}>
                        <label>Adviser Name</label>
                    </div>
                    <Filter {...props}>
                        <ReferenceInput
                            filter={{ ...filterValues }}
                            label={''}
                            source={'professional_id'}
                            reference={'professionals/professionals-for-filter'}
                            alwaysOn
                        >
                            <AutoCompleteInputWithoutLoadingSpinner optionText={'name_only'}/>
                        </ReferenceInput>
                    </Filter>
                </div>
                <div className={classes.filter}>
                    <div className={classes.label}>
                        <label>Client First Name</label>
                    </div>
                    <Filter {...props}>
                        <TextInput label={''} source={'customer_first_name'} resettable={true} alwaysOn/>
                    </Filter>
                </div>
                <div className={classes.filter}>
                    <div className={classes.label}>
                        <label>Client Email</label>
                    </div>
                    <Filter {...props}>
                        <TextInput label={''} source={'customer_email'} resettable={true} alwaysOn/>
                    </Filter>
                </div>
                <div className={classes.questionFilter}>
                    <div className={classes.label}>
                        <label>Question</label>
                    </div>
                    <Filter {...props} className={classes.upwardShiftedFilter}>
                        <ReferenceInput
                            filter={{
                                ...(range.to && range.from && { ...formatRange(range) }),
                                ...filtersForQuestionFilter,
                            }}
                            label={''}
                            source={'question_id'}
                            reference={'questions'}
                            sort={{ field: 'label_template', order: 'ASC' }}
                            onChange={handleQuestionChange}
                            perPage={1000}
                            enableGetChoices={({ q }) => q && q.length >= 2}
                            alwaysOn
                        >
                            <AutoCompleteInputWithoutLoadingSpinner optionText={'label_template'} />
                        </ReferenceInput>
                    </Filter>
                </div>
                <div>
                    {
                        selectedQuestion ?
                            <div className={classes.label}>
                                <label>Answer</label>
                            </div>
                            : <></>
                    }
                    <Filter {...props} className={classes.upwardShiftedFilter}>
                        {
                            selectedQuestion && showSelectInputAnswerFilter &&
                            <ReferenceInput
                                className={classes.filter}
                                filter={{ question_id: selectedQuestion.id, question_discr: selectedQuestion.discr }}
                                label={''}
                                source={'answer_choice'}
                                reference={'answer-choices'}
                                alwaysOn
                            >
                                <AutocompleteInput optionText={'text'}/>
                            </ReferenceInput>
                        }

                        {
                            selectedQuestion && !showSelectInputAnswerFilter &&
                            <TextInput
                                className={classes.filter}
                                label={false}
                                source={'answer_choice'}
                                resettable={true}
                                alwaysOn
                            />
                        }
                    </Filter>
                </div>
                <div className={classes.filter}>
                    <div className={classes.label}>
                        <label>Review Type</label>
                    </div>
                    <Filter {...props}>
                        <AutocompleteInput
                            allowEmpty
                            label={false}
                            source={'type'} choices={reviewTypes}
                            alwaysOn
                        />
                    </Filter>
                </div>
                <div className={classes.filter}>
                    <div className={classes.label}>
                        <label>Service Type</label>
                    </div>
                    <Filter {...props}>
                        <AutocompleteInput
                            allowEmpty
                            label={false}
                            source={'service_type_id'} choices={serviceTypes}
                            alwaysOn
                        />
                    </Filter>
                </div>
            </div>
        </div>
    )
        ;
}

const AutoCompleteInputWithoutLoadingSpinner = ({optionText, ...props}) => {
    return (
        <AutocompleteInput
            {...props}
            loading={false}
            optionValue="id"
            optionText={optionText}
            resettable={false}
            inputProps={{ placeholder: 'Test' }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label="test"
                    placeholder="Favorites"
                />
            )}
        />
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiFilledInput-inputMarginDense': {
            paddingTop: '6px',
        },
    },
    questionFilter: {
        minWidth: 200,
        maxWidth: 400,
    },
    title: {
        marginBottom: 20,
    },
    label: {
        fontWeight: 'bold',
        fontFamily: 'Nunito',
        marginBottom: 10,
    },
    dateFilter: {
        minWidth: 200,
    },
    filters: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    filter: {
        minWidth: 100,
        maxWidth: 180,
    },
    upwardShiftedFilter: {
        position: 'relative',
        top: '-5px',
    },
}));
