import React from 'react';
import { OutlinedInput, FormLabel, FormGroup, CardContent } from '@material-ui/core';
import { useInput } from 'react-admin';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import componentStyles from '../../assets/theme/components/cards/cards/card-button';
import FormHelperText from '@material-ui/core/FormHelperText';


const useStyles = makeStyles(componentStyles);

const getErrorMessage = (error) =>{
    if (error === 'ra.validation.required') {
        return 'This field is required.'
    }
    return error;
};

const ArgonTextInput = (props) => {
    const {
        input: { name, onChange, value },
        meta: { touched, error },
        isRequired,
    } = useInput(props);

    const classes = useStyles();

    const multiline = _.get(props, 'multiline', false);
    const disabled = _.get(props, 'disabled', false);
    const placeholder = _.get(props, 'placeholder', '')
    const minRows = multiline ? 12 : false;

    const { label, ...rest } = props

    return (
        <CardContent className={classes.cardRoot}>
            <FormGroup>
                <FormLabel>{props.label}</FormLabel>
                <OutlinedInput
                    name={name}
                    onChange={onChange}
                    error={!!(touched && error)}
                    helperText={touched && error}
                    {...rest}
                    required={isRequired}
                    multiline={multiline}
                    minRows={minRows}
                    disabled={disabled}
                    placeholder={placeholder}
                    value={value}
                />
                {
                    props.helperText && (
                        <FormHelperText>
                            {props.helperText}
                        </FormHelperText>
                    )
                }
                {(touched && error) &&
                    <FormHelperText className={classes.errorMessage} data-test={'error-' + name}>
                        {getErrorMessage(error)}
                    </FormHelperText>
                }
            </FormGroup>
        </CardContent>
    );
};

export default ArgonTextInput;
