import React, {cloneElement} from 'react';
import _ from 'lodash';
import {Datagrid, EditButton, ListContextProvider} from 'react-admin';
import {
    PrefixTextField,
    UnderscoreConvertTextField,
} from '../../../components/ResourcePageComponents';
import {FEE_SUB_TYPE} from "../../../constants/professional";
import DeleteFeeLevelButton from "../../components/DeleteFeeLevelButton";

const MyList = ({children, data}) => {
    return (
        <ListContextProvider
            value={{
                data: _.keyBy(data, 'id'),
                ids: data.map(({id}) => id),
                currentSort: {field: 'id', order: 'DESC'},
                basePath: '/fees',
                resource: 'fees',
            }}
        >
            {cloneElement(children)}
        </ListContextProvider>
    );
};

const InitialAndOngoingFeeLevelDataGrid = (props) => {
    const inputPrefix = props.subType === FEE_SUB_TYPE.FIXED_FEE_ONLY ? '£' : '%';

    return (
        <MyList {...props}>
            <Datagrid>
                <UnderscoreConvertTextField label="For levels" source="levelType" />
                <PrefixTextField label="Minimum value" source="minimumAmount" prefix="£" />
                <PrefixTextField label="Maximum value" source="maximumAmount" prefix="£" />
                <PrefixTextField key="initialCharge" label="Initial" source="initialCharge" prefix={inputPrefix} />,
                <PrefixTextField key="ongoingCharge" label="Ongoing" source="ongoingCharge" prefix={inputPrefix} />,
                <EditButton basePath="fee-level" />
                <DeleteFeeLevelButton/>

            </Datagrid>
        </MyList>
    );
};

export default InitialAndOngoingFeeLevelDataGrid;
