import React from 'react';
import { copyToClipboard } from '../../utils';

import Button from './CustomButton';
import { useNotify } from 'react-admin';

const CopyTextButton = props => {
    const notify = useNotify();

    const {
        clipboardContent,
        onClick,
        children,
        notificationMessage,
        ...rest
    } = props;

    const handleClick = e => {
        if (!clipboardContent) return;

        copyToClipboard(clipboardContent);

        notify('Code copied to clipboard');
    };

    return (
        <Button onClick={handleClick} color="tertiary" variant="outlined" {...rest}>
            {children}
        </Button>
    );
};

export default CopyTextButton;

