import React, { useEffect, useState } from 'react';
import {
    Box, makeStyles, Button, DialogContent, DialogActions,
    Dialog
} from '@material-ui/core';
import SectionTitle from './SectionTitle';
import DateRangeCustom from './DateRange';
import { formatRange } from 'utils';
import { SERVICE_TYPE_FILTER_LEGAL } from '../../constants/safeguard';
import { firmCanSeeFilter } from '../../helpers/safeguard';
import ServiceTypeFilter from "./ServiceTypeFilter";
import { connect } from 'react-redux';
import DateRangeDialogFilter from './DateRangeDialogFilter';

const useStyles = makeStyles(theme => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        width: 'auto',
        flexDirection: 'column',
        marginBottom: '16px',
        paddingLeft: "55px",
        paddingRight: "55px",
        [theme.breakpoints.up("sm")]: {
            paddingLeft: "70px",
            paddingRight: "70px",
            flexDirection: 'row',

        },
        backgroundColor: theme.navyBlue,
        marginLeft: "-40px",
        marginRight: "-40px",
    },
    button: {
        background: "#fff",
        color: theme.palette.blue.navyBlue,
        '&:hover': {
            background: "#fff",
            color: theme.palette.blue.navyBlue,
        },
        marginBottom: "1rem",
        maxWidth: "100%",
        borderRadius: "2rem",
        textAlign: "left",
        fontWeight: 300,
        padding: "0.625rem .75rem",
        width: 250,
        textTransform: 'none',
        fontSize: '0.875rem',
        lineHeight: '1.5',
        letterSpacing: '0',
    },
    dropdown: {
        maxWidth: "100%",
        width: 250,
        borderRadius: "2rem",
    },
}));

const DashboardHeader = ({ range, setRange, serviceType, setServiceType, adviser, firmIdentity }) => {
    const classes = useStyles();
    const [showDialog, setShowDialog] = useState(false)
    const [tempDateRange, setTempDateRange] = useState({from:null, to:null})

    useEffect(() => {
        if(firmIdentity?.type) {
            if(!firmCanSeeFilter(firmIdentity?.type)) {
                setServiceType(SERVICE_TYPE_FILTER_LEGAL);
            }
        }
    },[firmIdentity?.type]);

    const handleServiceTypeChange = (e) => {
        setServiceType(e.target.value)
    }
        
    return (
        <Box className={classes.header}>
            <Box>
                <SectionTitle title={adviser?.name ? `Client experience for ${adviser?.full_name}`: "Client experience"} header size="2.75rem" />
                <Button
                    className={classes.button}
                    variant="contained"
                    onClick={() => setShowDialog(true)}
                >
                    {range.to && range.from ?
                        formatRange(range).from + " - " + formatRange(range).to
                        :
                        "Filter dashboard by date"
                    }

                </Button>
                <Box>
                    <ServiceTypeFilter
                        serviceType={serviceType}
                        handleServiceTypeChange={handleServiceTypeChange}
                        classes={classes}
                    />
                </Box>
            </Box>

            <DateRangeDialogFilter
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                setRange={setRange}
                setTempDateRange={setTempDateRange}
                tempDateRange={tempDateRange}
            />
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        firmIdentity: state.identity,
    };
};
export default connect(mapStateToProps)(DashboardHeader);
