import React from "react";

// Core Components
import Footer from '../../../AppLayout/Footer';
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import { CONTACT } from '../../../constants';
import { Notification  } from "react-admin";
import AuthContentWrapper from "./AuthContentWrapper";

const useStyles = makeStyles(
    (theme) => ({
        mainContent: {
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh"
        },
        header: {
            flex: "1 0 auto",
            display: 'flex'
        },
        footer: {
            flexShrink: 0
        },
        formContainer: {
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
                marginTop: theme.spacing(3),
            }
        },
        secondSection: {
            width: theme.spacing(75),
            margin: `${theme.spacing(1)}px auto`,
            paddingBottom: theme.spacing(8),
            textAlign: 'center',
            color: "#fff",

            [theme.breakpoints.down('sm')]: {
                width: '100%',
                margin: 0,
                marginBottom: theme.spacing(3),
            },
        },
        font: {
            fontSize: '16px',
        }
    }));

const AuthPageContent = (props) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.mainContent}>
                <div className={classes.header}>
                    <AuthContentWrapper>
                        <Grid className={classes.formContainer}>
                            <Grid
                                container
                                item
                                sm={12}
                                md={6}
                                justify='center'
                            >
                                {props.children}
                            </Grid>
                        </Grid>
                        <div className={classes.secondSection}>
                            <Typography variant="body1">
                                <b>Need help?</b> Call us on {CONTACT.PHONE_NUMBER}
                            </Typography>
                        </div>
                        <Notification />
                    </AuthContentWrapper>
                </div>
                <div className={classes.footer}>
                    <Footer />
                </div>
            </div>
        </>
    )

}

export default AuthPageContent;