import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const propTypes = {
    title: PropTypes.string.isRequired,
    examples: PropTypes.array.isRequired,
};

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    cardWrapper: {
        borderRadius: 4,
        border: '1px solid',
        borderColor: theme.palette.primary.contrastText,
        cursor: 'pointer',
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        marginRight: theme.spacing(1),
        minWidth: theme.spacing(30),

        [theme.breakpoints.down('xs')]: {
            minWidth: 'auto',
            width: '100%',
            marginRight: 0,
        },
    },
    active: {
        background: theme.palette.secondary.faint,
        borderColor: theme.palette.secondary.main,
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    examples: {
        padding: 0,
        marginLeft: theme.spacing(1),
    },
});

const FeesTieredButtonBase = props => {
    const { title, examples, classes, onClick, value, isWealthBased } = props;
    const isActive = value === isWealthBased;

    return (
        <div className={classes.root}>
            <Radio checked={isActive} onChange={onClick} />
            <div
                className={
                    isActive
                        ? cn(classes.cardWrapper, classes.active)
                        : classes.cardWrapper
                }
                onClick={onClick}
            >
                <Typography className={classes.title} variant="body1">
                    <b>{title}, e.g:</b>
                </Typography>
                <ul className={classes.examples}>
                    {examples.map(example => (
                        <Typography
                            component="li"
                            key={example}
                            variant="body1"
                        >
                            {`- ${example}`}
                        </Typography>
                    ))}
                </ul>
            </div>
        </div>
    );
};

FeesTieredButtonBase.propTypes = propTypes;

export default withStyles(styles)(FeesTieredButtonBase);
