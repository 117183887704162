import React from 'react';
import { ExportButton, useListContext } from 'react-admin';
import { Box } from '@material-ui/core';

// limit is set in the backend. To change the limit change it in the backend.
const CSV_DOWNLOAD_LIMIT = 999999;

const ListActions = (props) => {
    const { exportLoading, exporter, resource } = props;
    const { currentSort, filterValues, total } = useListContext();


    return (
        <Box marginRight="1rem">
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={CSV_DOWNLOAD_LIMIT}
                exporter={exporter}
                onClick={exportLoading}
            />
        </Box>
    );
};

export default ListActions;
