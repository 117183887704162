import * as React from 'react'
import {SimpleForm, Edit} from 'react-admin'
import FeeEditFormContent from "./Pages/Edit/FeeEditFormContent";

const FeeEdit = props => (
        <Edit undoable={false} basePath="/fees" resource="fees" id='no-id' {...props}>
            <SimpleForm redirect="/fees">
                <FeeEditFormContent />
            </SimpleForm>
        </Edit>
    );

export default FeeEdit;
