import getDataProviderApiUrl from "./utils/getDataProviderApiUrl";
import tokenManager from "./tokenManager";
import mixpanel from 'mixpanel-browser/src/loaders/loader-module';
import { checkCookiesAndInitMixpanel } from './utils/mixpanel';

const authProvider = {
    login: ({username, password}) => {
        const url = getDataProviderApiUrl('login', null);
        const request = new Request(url, {
            method: 'POST',
            body: JSON.stringify({username, password}),
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
        });
        return fetch(request)
            .then((response) => {
                if (response.status === 401) {
                    return response.json();
                }

                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((response) => {
                if (response.message) {
                    throw new Error(response.message);
                }
                tokenManager.setTokenAndRefreshToken(response.token, response.refresh_token)

                checkCookiesAndInitMixpanel();
            })
            .catch((error) => {
                throw new Error(error);
            });
    },

    logout: () => {
        tokenManager.removeTokensFromStorage();
        window.location.href = '/login';

        try{
            //disable tracking
            mixpanel.disable();
            //reset the user after user logs out
            mixpanel.reset();
        } catch (e) {
            console.log(e);
        }
        return Promise.resolve();
    },

    getIdentity: () => {
        let url = getDataProviderApiUrl('profile', null);

        const newRequest = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'vf-token' : localStorage.getItem('token')
            }),
        });

        return fetch(newRequest)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    const pageUrl = encodeURIComponent(window.location.pathname + window.location.search)
                    window.location.href = '/login?redirect='+JSON.stringify(pageUrl);
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((response) => {
                let profile = {};
                if (response.data.network) {
                    profile = response.data.network;
                    // todo: get this info from api when we merge ticket VFX-228
                    profile.type = 'network';
                }
                if (response.data.currentFirm) {
                    profile.about = response.data.currentFirm.about;
                    profile.disclaimer = response.data.currentFirm.disclaimer;
                    profile.fca_number = response.data.currentFirm.fca_number;
                    profile.firm_config = response.data.currentFirm.firm_config;
                    profile.hasTransferredClients = response.data.currentFirm.hasTransferredClients;
                    profile.id = response.data.currentFirm.id ;
                    profile.is_active = response.data.currentFirm.is_active;
                    profile.logo = response.data.currentFirm.logo;
                    profile.name = response.data.currentFirm.name;
                    profile.type = response.data.currentFirm.type;
                }

                profile.fullName = profile.name;
                profile.avatar = profile.logo ? profile.logo.urls.small : '';
                profile.allowedFirms = response.data.allowedFirms;
                profile.showFirmAvgOnAdviserElevationDashboard = response.data.showFirmAvgOnAdviserElevationDashboard;
                profile.hideIndividualDashboardLink = response.data.hideIndividualDashboardLink;
                profile.crmLookup = response.data.crmLookup;
                profile.showTransferredClientInvitation = response.data.showTransferredClientInvitation;
                profile.firmHasIntegration = response.data.firmHasIntegration;
                profile.showFollowUpFeedback = response.data.showFollowUpFeedback;
                profile.firm_subgroups = response.data.firmSubgroups;
                profile.showVerifiedSidebar = response.data.showVerifiedSidebar;
                profile.networkFirms = response.data.networkFirms;
                profile.allowCustomBulkInviteForNetwork = response.data.allowCustomBulkInviteForNetwork;

                return Promise.resolve(profile)
            })
            .catch(() => {
                throw new Error('Network error');
            });
    },

    checkAuth: () => {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },

    checkError: (error) => {
        const status = error.status;

        if (status === 401 || status === 403) {
            tokenManager.removeTokensFromStorage();
            window.location.href = '/login?'+window.location;
            return Promise.reject();
        }

        return Promise.resolve();
    },
    getPermissions: () => {
        let roles = localStorage.getItem('roles');

        return roles ? Promise.resolve(roles) : Promise.reject();
    },
};

export default authProvider;
