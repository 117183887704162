import React from 'react';
import EditButton from '../../../components/Buttons/EditButton';
import AddButton from '../../../components/Buttons/AddButton';
import {PageTitle} from '../../../components/Typography/Headlines';

import Typography from '@material-ui/core/Typography';

const FEES_COUNT_LIMIT = 3;

const FeeListActions = ({ feesCount }) => {
    return (
        <>
            {feesCount > 0 && (
                <>
                    <PageTitle title="Notes on your fees" />
                    <Typography>
                        You can add notes to explain more about how your fees
                        work by clicking the EDIT button below.
                        <br />
                        If your fees don't fit the models we have available, you
                        can enter notes detailing how they work.
                    </Typography>
                    <EditButton
                        href="/fees-edit"
                        label="Edit fee notes and types"
                    />
                </>
            )}
            {feesCount < FEES_COUNT_LIMIT && (
                <AddButton
                    resource="fees"
                    label={
                        feesCount > 0
                            ? 'Add another fee'
                            : 'Add fees for all advisers'
                    }
                    to="/fees/create"
                />
            )}
        </>
    );
};

export default FeeListActions;
