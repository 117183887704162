import * as React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { fade } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles(theme => ({
    textTertiary: {
        color: theme.palette.tertiary.main,
        "&:hover": {
            backgroundColor: fade(
                theme.palette.tertiary.main,
                theme.palette.action.hoverOpacity
            ),
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
                backgroundColor: "transparent"
            }
        }
    },
    outlinedTertiary: {
        color: theme.palette.tertiary.main,
        backgroundColor: "#fff",
        border: `1px solid ${fade(theme.palette.tertiary.main, 0.5)}`,
        "&:hover": {
            border: `1px solid ${theme.palette.tertiary.main}`,
            backgroundColor: fade(
                theme.palette.tertiary.main,
                1
            ),
            color: '#fff',
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
                backgroundColor: "transparent"
            }
        }
    },
    containedTertiary: {
        color: theme.palette.tertiary.contrastText,
        backgroundColor: theme.palette.tertiary.main,
        "&:hover": {
            backgroundColor: theme.palette.tertiary.dark,
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
                backgroundColor: theme.palette.tertiary.main
            }
        }
    }
}));

const CustomButton = React.forwardRef(function CustomButton(
    { variant = "text", color, className, ...other },
    ref
) {
    const classes = useStyles();
    return (
        <Button
            {...other}
            variant={variant}
            color={color === "tertiary" ? "primary" : color}
            className={clsx(className, {
                [classes[`${variant}Tertiary`]]: color === "tertiary"
            })}
            ref={ref}
        />
    );
});
export default CustomButton;