import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import SectionTitle from './components/SectionTitle';
import {Box} from '@material-ui/core';
import firmDetailed from '../assets/img/firm_detailed.png';

const useStyles = makeStyles(theme => ({
    wrapper: {
        marginTop: '4rem',
        width: '100%',
        height: 'calc(100vh - 15rem - 56px)',
        position: 'relative',
    },
    box: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    root: {
        marginTop: '8rem',
    },
}));

const FirmDetailed = () => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <SectionTitle title="Firm Detailed" header size="2.75rem"/>
            <Box className={classes.wrapper}>
                <Box className={classes.box}>
                    <img style={{maxHeight: '100%', margin: "auto", maxWidth: "100%"}} src={firmDetailed}/>
                </Box>
            </Box>
        </Box>
    );
};

export default FirmDetailed;
