import React from 'react';
import {
    COMPANY_URLS,
    FIND_ADVISER_URLS,
    LEGAL_URLS,
    SOCIAL_URLS,
    ADVISER_URLS,
} from '../constants';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const DATE = new Date();

const styles = theme => ({
    container: {
        backgroundColor: theme.palette.secondary.main,
        padding: `0 ${theme.spacing(4)}px ${theme.spacing(5)}px`,

        [theme.breakpoints.down('xs')]: {
            padding: `0 ${theme.spacing(1)}px ${theme.spacing(5)}px`,
        },
    },
    links: {
        width: '100vw',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    anchor: {
        color: theme.palette.primary.contrastText,
        textDecoration: 'none',
        fontSize: theme.spacing(2) - 2,
        '&:hover': {
            color: theme.palette.secondary.light,
        },
    },
    list: {
        paddingLeft: theme.spacing(1),
        listStyle: 'none',
        color: theme.palette.primary.contrastText,
    },
    listItem: {
        margin: 0,
    },
    listItemTitle: {
        color: theme.palette.primary.contrastText,
        fontSize: theme.spacing(2) - 2,
        marginBottom: theme.spacing(1),
    },
    copyrightSection: {
        marginTop: theme.spacing(5),
        marginLeft: theme.spacing(26),
        color: theme.palette.primary.contrastText,
    },
    white: {
        color: theme.palette.primary.contrastText,
    },
});

const FooterColumn = props => {
    const {classes, title, list} = props;

    return (
        <ul className={classes.list}>
            <Typography
                component="li"
                variant="body1"
                className={classes.listItemTitle}
            >
                <b>{title}</b>
            </Typography>
            {list.map(item => (
                <Typography
                    component="li"
                    variant="body1"
                    key={item.url}
                    className={classes.listItem}
                >
                    <a
                        className={classes.anchor}
                        href={item.url}
                        rel="noopener noreferrer"
                    >
                        {item.text}
                    </a>
                </Typography>
            ))}
        </ul>
    );
};

const FooterColumnWithStyles = withStyles(styles)(FooterColumn);

const Footer = ({classes}) => {
    return (
        <div className={classes.container}>
            <Grid className={classes.links}>
                <Grid container item md={4} xs={12}>
                    <Grid item xs={6}>
                        <FooterColumnWithStyles
                            title="Find An Adviser"
                            list={FIND_ADVISER_URLS}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FooterColumnWithStyles
                            title="Company"
                            list={COMPANY_URLS}
                        />
                    </Grid>
                </Grid>
                <Grid container item md={4} xs={12}>
                    <Grid item xs={6}>
                        <FooterColumnWithStyles
                            title="Social"
                            list={SOCIAL_URLS}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FooterColumnWithStyles
                            title="Advisers"
                            list={ADVISER_URLS}
                        />
                    </Grid>
                </Grid>
                <Grid container item md={2} xs={12}>
                    <Grid item xs={6}>
                        <FooterColumnWithStyles
                            title="Legal & Cookies"
                            list={LEGAL_URLS}
                        />
                    </Grid>
                </Grid>
                <Grid container item md={10} xs={12}>
                    <Grid item xs={8}>
                        <Typography variant="body1" className={classes.listItemTitle}>
                            <b>&copy; VouchedFor {DATE.getFullYear()}</b>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default withStyles(styles)(Footer);
