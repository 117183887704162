import dataProvider from 'dataProvider';
import React, { useState, useEffect } from 'react';
import GoalSelect from './components/GoalSelect';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { ArgonSelectInput } from '../components/Argon/ArgonSelectInput';
import GoalAnswersTable from './components/GoalAnswersTable';
import SectionTitle from './components/SectionTitle';
import { useHistory } from 'react-router-dom';
import { firmCanSeeFilter, getServiceTypeAsString, getFilteredDrivers } from 'helpers/safeguard';
import {SERVICE_TYPE_FILTER_FA, SERVICE_TYPE_FILTER_LEGAL, SERVICE_TYPE_FILTERS} from '../constants/safeguard';
import ServiceTypeFilter from "./components/ServiceTypeFilter";
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    dropdown: {
        width: '100%',
    },
    wrapper: {
        display: 'flex',
        width: 'auto',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        paddingTop: '0.5rem',
        '& .MuiFormGroup-root': {
            marginBottom: '0.5rem',
        },
        background: theme.palette.blue.navyBlue,
        marginLeft: '-40px',
        marginRight: '-40px',
        paddingLeft: '40px',
        paddingRight: '40px',
        paddingBottom: '1rem',
    },
    filterWrapper: {
        width: '100%',
        maxWidth: 500,
    },
}));

const ClientAnswers = props => {
    const classes = useStyles();

    const [goals, setGoals] = useState({});
    const [selectedGoal, setSelectedGoal] = useState(0);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [drivers, setDrivers] = useState([]);
    const [advisers, setAdvisers] = useState([]);
    const [selectedAdviser, setSelectedAdviser] = useState(null);
    const [driverFromUrl, setDriverFromUrl] = useState(null);
    const [adviserFromUrl, setAdviserFromUrl] = useState(null);
    const [serviceType, setServiceType] = useState(SERVICE_TYPE_FILTER_FA);

    const { firmIdentity } = props;

    const history = useHistory();
    const search = history.location.search;
    const params = new URLSearchParams(search);

    useEffect(() => {
        (async () => {
            const search = history.location.search;
            const params = new URLSearchParams(search);
            const goalIdFromUrl = params.get('goal');
            const driverIdFromUrl = params.get('driver');
            const serviceTypeFromUrl = params.get('service_type');
            const adviserIdFromUrl = params.get('adviser');
            if (driverIdFromUrl) setDriverFromUrl(driverIdFromUrl);
            if (adviserIdFromUrl) setAdviserFromUrl(adviserIdFromUrl);
            if (serviceTypeFromUrl) setServiceType(SERVICE_TYPE_FILTERS.find(filter => serviceTypeFromUrl == filter.type));

            const data = await dataProvider.getNone('elevation/goals');
            setGoals(data.data);
            if (goalIdFromUrl) {
                const selectedG = data.data.find(
                    goal => parseInt(goal.id) === parseInt(goalIdFromUrl)
                );
                if (selectedG) setSelectedGoal(selectedG);
            }
        })();
    }, []);

    useEffect(() => {
        if (firmIdentity?.type) {
            if (!firmCanSeeFilter(firmIdentity?.type)) {
                setServiceType(SERVICE_TYPE_FILTER_LEGAL);
            }
        }
    }, [firmIdentity?.type]);

    useEffect(() => {
        fetchDrivers();
        fetchAdvisers();
    }, [selectedGoal]);

    useEffect(() => {
        if (adviserFromUrl && advisers.length) {
            const selectedA = advisers.find(
                adviser => parseInt(adviser.id) === parseInt(adviserFromUrl)
            );
            if (selectedA) {
                setSelectedAdviser(selectedA);
            }
        }
    }, [advisers]);

    useEffect(() => {
        if (driverFromUrl && drivers.length) {
            const selectedD = drivers.find(
                driver => parseInt(driver.id) === parseInt(driverFromUrl)
            );
            if (selectedD) {
                setSelectedDriver(selectedD);
                setDriverFromUrl(null);
            }
        }
    }, [drivers]);

    useEffect(() => {
        if (selectedDriver) {
            fetchAdvisers();
        } else {
            setAdvisers([]);
        }
    }, [selectedDriver]);

    const fetchDrivers = async () => {
        if (selectedGoal) {
            const driverData = await dataProvider.getNone('elevation/drivers', {
                goal: selectedGoal.id,
            });
            setDrivers(driverData.data);
        }
    };

    const fetchAdvisers = async () => {
        const adviserData = await dataProvider.getNone('elevation/drivers/advisers', {
            goal_id: selectedGoal?.id,
            driver_id: selectedDriver?.id,
            service_type: getServiceTypeAsString(serviceType),
            minimal_fields: true,
        });
        setAdvisers(adviserData.data);
    };

    const handleSelectChange = e => {
        setSelectedGoal(e.target.value);
        setSelectedDriver(null);
        setSelectedAdviser(null);
        params.set('goal', e.target.value.id);
        params.delete('driver');
        params.delete('adviser');
        history.push({ search: params.toString() });
    };

    const handleDriverChange = e => {
        setSelectedDriver(e.target.value);
        setSelectedAdviser(null);
        setAdviserFromUrl(null);
        params.set('driver', e.target.value.id);
        params.delete('adviser');
        history.push({ search: params.toString() });
    };

    const handleAdviserChange = e => {
        setSelectedAdviser(e.target.value && e.target.value != 0 ? e.target.value : null);
        params.set('adviser', e.target.value.id);
        if (!e.target.value || e.target.value == 0) {
            params.delete('adviser')
        }
        history.push({ search: params.toString() });
    };

    const handleServiceTypeChange = e => {
        setServiceType(e.target.value);
        setSelectedDriver(null);
        setSelectedAdviser(null);
        setAdviserFromUrl(null);
        params.set('service_type', e.target.value.type);
        params.delete('driver');
        params.delete('adviser');
        history.push({ search: params.toString() });
    };
    return (
        <>
            <SectionTitle title="Client Answers" header size="2.75rem" />
            <Box className={classes.wrapper}>
                <Box className={classes.filterWrapper}>
                    <ServiceTypeFilter
                        serviceType={serviceType}
                        handleServiceTypeChange={handleServiceTypeChange}
                        classes={classes}
                    />
                    <GoalSelect
                        title="Choose a goal"
                        type="goals"
                        onChange={handleSelectChange}
                        classes={classes}
                        goals={goals}
                        selectedGoal={selectedGoal}
                    />

                    <ArgonSelectInput
                        onChange={handleDriverChange}
                        className={classes.dropdown}
                        defaultValue={drivers[0]}
                        value={selectedDriver ? selectedDriver : 0}
                        data={getFilteredDrivers(drivers, selectedGoal.id, serviceType)}
                        dataLabelName="name"
                        dataKeyName="all"
                        defaultItem="Select a driver"
                        selectElementOnly={true}
                        noForm={true}
                        data-test={"client-answers-select-driver"}
                    />

                    <ArgonSelectInput
                        onChange={handleAdviserChange}
                        className={classes.dropdown}
                        value={selectedAdviser ? selectedAdviser : 0}
                        data={advisers}
                        dataLabelName="full_name"
                        dataKeyName="all"
                        defaultItem="All advisers"
                        selectElementOnly={true}
                        noForm={true}
                        enableDefaultItem={true}
                        data-test={"client-answers-select-adviser"}
                    />
                </Box>
            </Box>
            {(selectedGoal || selectedDriver) && (
                <GoalAnswersTable
                    safeguard={true}
                    serviceType={serviceType}
                    showProfessional={true}
                    key={selectedGoal.id + (selectedDriver ? selectedDriver.id : '')}
                    professional={
                        selectedAdviser ? { filter: { professional_id: selectedAdviser.id } } : null
                    }
                    driver={selectedDriver}
                    goal={selectedGoal}
                />
            )}
        </>
    );
};

const mapStateToProps = state => {
    return {
        firmIdentity: state.identity,
    };
};

export default connect(mapStateToProps)(ClientAnswers);
