import * as React from 'react';
import { DatagridBody } from 'react-admin';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const CustomDatagridRow = ({ record, resource, id, children, basePath }) => (
    <TableRow key={id}>
        {React.Children.map(children, (field) => (
            <TableCell style={{ width: field.props.width }} key={`${id}-${field.props.source}`}>
                {React.cloneElement(field, {
                    record,
                    basePath,
                    resource,
                })}
            </TableCell>
        ))}
    </TableRow>
);

const AwardAndAccreditationGridBody = (props) => (
    <DatagridBody {...props} row={<CustomDatagridRow />} />
);

export default AwardAndAccreditationGridBody;
