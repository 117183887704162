import React, { Component } from 'react';
import _ from 'lodash';
import { ImageInput } from 'react-admin';
import AdviserLogo from './AdviserLogo';
import { MAX_FILE_SIZE_TWO_MB } from '../constants/values';
import { withStyles } from '@material-ui/core';
import ImagePreviewCrop from './Inputs/ImagePreviewCrop';

const styles = () => ({
    imageInput: {
        maxWidth: 180,
    },
});

class AdviserLogoImage extends Component {
    state = {
        showUploadBar: false,
        adviserImage: {},
    };

    componentDidMount() {
        const adviserImage = _.get(this.props, ['record', 'avatar'], {});

        this.setState({
            adviserImage,
            showUploadBar: _.isEmpty(adviserImage),
        });
    }

    toggleUploadBar = () => {
        this.setState({
            showUploadBar: !this.state.showUploadBar,
        });
    };

    renderAdviserLogo = () => {
        if (this.props.disabled && !this.state.adviserImage) {
            return <span className={this.props.classes.inputLabel}>Adviser logo</span>;
        }

        return null;
    };

    render() {
        const { showUploadBar } = this.state;
        const adviserImage = _.get(this.props, ['record', 'avatar'], {});
        const { isAdmin, classes } = this.props;

        return (
            <div>
                {!_.isEmpty(adviserImage) && (
                    <AdviserLogo
                        enableEdit={isAdmin}
                        src={adviserImage}
                        onClick={this.toggleUploadBar}
                    />
                )}
                {showUploadBar ? (
                    <div>
                        <ImageInput
                            source="file"
                            label="Adviser logo"
                            accept="image/*"
                            maxSize={MAX_FILE_SIZE_TWO_MB}
                            className={classes.imageInput}
                            options={{
                                disabled: !isAdmin,
                            }}
                        >
                            <ImagePreviewCrop source="src" />
                        </ImageInput>
                    </div>
                ) : (
                    this.renderAdviserLogo()
                )}
            </div>
        );
    }
}

export default withStyles(styles)(AdviserLogoImage);
