import React from 'react';
import cn from 'classnames';
import _ from 'lodash';

import Radio from '@material-ui/core/Radio';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

import StarBorder from '@material-ui/icons/StarBorder';
import Star from '@material-ui/icons/Star';
import {TextField} from "react-admin";

const styles = (theme) => ({
    RatingFontSize:{
        fontSize: '1rem',
    }
});

const NumberRating = (props) => {
    const { source, type } = props;
    const isTransferredClientReview = _.get(props?.record, 'is_transferred_client_review', false)

    let rating;

    if (type === 'survey') {
        rating = _.get(props, 'rating', 0);
    } else {
        rating = _.get(props.record, `${source}`, 0);
    }

    if (rating == 5.0) rating = 5;
    const { classes, centered } = props;

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                { isTransferredClientReview ? <p>N.A.</p> : <span className={classes.RatingFontSize}>{rating}</span>}
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(NumberRating);
