import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const propTypes = {
    defaultValue: PropTypes.bool,
};

const defaultProps = {
    defaultValue: false,
    input: {
        onChange: () => {},
    }
};

const CustomFormCheckboxInput = props => {
    const inputValue = _.get(props, ['input', 'value'], '');

    const [checked, setChecked] = useState(
        props.defaultValue ? props.defaultValue : inputValue
    );

    const handleChange = name => event => {
        props.input.onChange(event.target.checked);
        setChecked(event.target.checked);
    };

    return (
        <div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={handleChange('checked')}
                        value="checked"
                        disabled={props.disabled}
                    />
                }
                label={props.label}
            />
        </div>
    );
};

CustomFormCheckboxInput.propTypes = propTypes;
CustomFormCheckboxInput.defaultProps = defaultProps;

export default CustomFormCheckboxInput;
