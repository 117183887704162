import * as React from 'react';
import { Grid } from '@material-ui/core';
import ArgonTextInput from '../components/Argon/ArgonTextInput';
import _ from 'lodash';
import { DISCLAIMER_PLACEHOLDER } from '../constants/constantStrings';

const DisclaimerEdit = (props) => {
    const record = _.get(props, 'record', {});

    return (
        <Grid item md={12} l={12}>
            <ArgonTextInput
                multiline
                source="disclaimer"
                label="Add a disclaimer to your advisers’ profiles."
                placeholder={
                    'NOTE - the text you add here will automatically apply to all advisers linked to your firm.\n' +
                    'Alternatively, advisers can individually edit this themselves in their account.\n'
                }
                defaultValue={record.disclaimer === '' ? DISCLAIMER_PLACEHOLDER : record.disclaimer}
            />
        </Grid>
    );
};

export default DisclaimerEdit;
