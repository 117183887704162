import React from 'react';
import cn from 'classnames';
import _ from 'lodash';

import Radio from '@material-ui/core/Radio';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

import StarBorder from './vectors/VfStarOutlined';
import Star from './vectors/VfStar';

const styles = (theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
    },
    rootIE11: {
        alignItems: 'center',
        display: 'block',
    },
    justifyCenter: {
        justifyContent: 'center',
    },
    starsWrap: {
        display: 'flex',
    },
    radioStars: {
        padding: 0,
        display: 'flex',

        [theme.breakpoints.only('xs')]: {
            margin: '0 2px',
        },
    },
    radioStarsHover: {
        color: theme.palette.primary.main,
    },
    readOnly: {
        cursor: 'default',
    },
    starFontSize: {
        fontSize: 30,
    },
    starFontSizeSmall: {
        fontSize: 25,
    },
    starFontSizeXSmall: {
        fontSize: 20,
    },
    starFontSizeXXSmall: {
        fontSize: 16,
    },
    error: {
        color: theme.palette.error.light,
    },
    hoverError: {
        color: theme.palette.primary.main,
    },
    starToolTip: {
        padding: 1,
        maxWidth: '540px',
    },
    starPopper: {
        opacity: 1,
    },
    starPaper: {
        padding: '10px 15px',
    },
    tooltipList: {
        paddingLeft: '40px',
    },
});

const StarRating = (props) => {
    const { source, type } = props;
    const isTransferredClientReview = _.get(props?.record, 'is_transferred_client_review', false)

    let rating;

    if (type === 'survey') {
        rating = _.get(props, 'rating', 0);
    } else {
        rating = _.get(props.record, `${source}`, 0);
    }

    const renderStars = (value, index) => {
        return renderRadio(value);
    };

    const isChecked = (value, rating) => {
        if (rating >= value) {
            return true
        }

        if (value - rating <= 0.5) {
            return true
        }

        return false
    }

    const renderRadio = (value) => {
        const { classes, showError, xxsmall, xsmall, small, color, nativeColor } = props;
        const normal = !(xxsmall || xsmall || small);

        return (
            <Radio
                checked={isChecked(value, rating)}
                onMou
                value={value}
                name="rating"
                color={color || 'primary'}
                key={value}
                icon={
                    <StarBorder
                        className={cn({
                            [classes.starFontSizeXXSmall]: xxsmall,
                            [classes.starFontSizeXSmall]: xsmall,
                            [classes.starFontSizeSmall]: small,
                            [classes.starFontSize]: normal,
                        })}
                        fill={nativeColor}
                    />
                }
                checkedIcon={
                    <Star
                        className={cn({
                            [classes.starFontSizeXXSmall]: xxsmall,
                            [classes.starFontSizeXSmall]: xsmall,
                            [classes.starFontSizeSmall]: small,
                            [classes.starFontSize]: normal,
                        })}
                        fill={nativeColor}
                    />
                }
                classes={{
                    root: cn({
                        [classes.radioStars]: true,
                        [classes.error]: showError,
                        [classes.readOnly]: true,
                    }),
                }}
                className={cn({
                    star: true,
                    'selected-star': rating >= value,
                })}
                disableRipple={true}
            />
        );
    };

    const { classes, centered } = props;

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                { isTransferredClientReview ?
                   <p
                       style={{ 'textAlign' : 'center' }}
                   >n/a</p> :
                <span
                    className={cn({
                        [classes.starsWrap]: true,
                        [classes.justifyCenter]: centered,
                    })}
                >
                    {[1, 2, 3, 4, 5].map((v, index) => renderStars(v.toString(), index))}
                </span>
                }
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(StarRating);
