import React from 'react';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Typography, makeStyles, useTheme } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';

const Range = createSliderWithTooltip(Slider.Range);

const useStyles = makeStyles(theme => ({

    tooltip: {
        '& .rc-slider-tooltip-inner': {
            backgroundColor: theme.palette.secondary.main,
            width: 100,
            height: 100,
            display: 'flex',
            alignItems: 'center',
        },
    },
}));


const handleStyles = {
    person: {
        marginTop: -20,
        width: 0,
        height: 0,
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        borderTop: "20px solid #1FAF83",
        background: 'transparent',
        borderRadius: 0,
        borderBottom: "transparent",
    },
    avg: {
        marginTop: 3,
        width: 0,
        height: 0,
        background: 'transparent',
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        borderBottom: "20px solid #022D4F",
        borderTop: "transparent",
        borderRadius: 0
    },
    extraFirm: {
        marginTop: 3,
        width: 0,
        height: 0,
        background: 'transparent',
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        borderBottom: "20px solid #046484",
        borderTop: "transparent",
        borderRadius: 0
    },
    end: {
        display: 'none',
    },
};

const CustomSlider = ({ avg, currentAvg, extraFirmAvg }) => {
    const classes = useStyles();
    const theme = useTheme();

    const customToolTip = value => {
        switch (true) {
            case value === (avg + 0.01):
                return (
                    <Typography>{`Industry average: ${Math.floor(value)}%`}</Typography>
                );
            case value === (extraFirmAvg + 0.02):
                return (
                    <Typography>{`Firm average: ${Math.floor(value)}%`}</Typography>
                );
            default:
                return <Typography>{`Your average: ${value}%`}</Typography>;
        }
    };

    return (
        <Range
            value={[0, (avg + 0.01), currentAvg, ...(extraFirmAvg !== false ? [extraFirmAvg+ 0.02] : []), 100]}
            min={0}
            max={100}
            trackStyle={[
                { backgroundColor: 'transparent' },
                { backgroundColor: 'transparent' },
                { backgroundColor: 'transparent' },
            ]}
            handleStyle={[
                        handleStyles.end,
                        handleStyles.avg,
                        handleStyles.person,
                        ...(extraFirmAvg !== false ? [handleStyles.extraFirm] : []),
                        handleStyles.end,
                    ]}
            railStyle={{ backgroundColor: theme.palette.tertiary.dark, height: 10, borderRadius: '3rem' }}
            tipFormatter={customToolTip}
            tipProps={{ overlayClassName: classes.tooltip }}
            dots={false}
            step={0.01}
            marks={{
                [0]: {
                    style: { color: theme.palette.secondary.main, fontSize: '1rem', top: '-25px', left: '-18px' },
                    label: `0%`,
                },
                [100]: {
                    style: { color: theme.palette.secondary.main, fontSize: '1rem', top: '-25px', left: '102%', right: '18px' },
                    label: `100%`,
                }
            }}
        />
    );
};
export default CustomSlider;
