import React, { cloneElement } from 'react';
import { AutocompleteInput, Filter, ReferenceInput, TextField, useGetList } from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        margin: '20px',
        gap: '10px',
        '& .MuiFilledInput-inputMarginDense': {
            paddingTop: '6px',
        },
        '& .MuiInputLabel-root': {
            fontWeight: 'normal',
            color: theme.palette.secondary.dark,
        },
    },
    label: {
        paddingTop: '10px',
        fontWeight: 'bold',
    },
    filter: {
        width: '690px',
    },
}));

export default (props) => {
    const {
        onOptionChange,
        choice,
        type,
        from,
        to,
        professional,
        firm
    } = props;

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <label className={classes.label}>{`Select ${type === 'risk' ? 'a risk' : 'an opportunity'}`}</label>
            <Filter {...props}>
                <ReferenceInput
                    className={classes.filter}
                    label={`Select ${type === 'risk' ? 'a risk' : 'an opportunity'}`}
                    source={type}
                    reference={'report/' + type + '-choices'}
                    filter={{from, to , professional, firm}}
                    /**This is needed because we use the risk_id in url and share it in emails.
                    If the risk id is not in the choices(default limit is 25, but we have 27 risks), it won't work when the link is pasted.
                    limit=50 is fine for now because there are 27 risks in total.
                    **/
                    perPage={50}
                    alwaysOn
                    onChange={onOptionChange}
                    defaultValues={choice}
                >
                    <AutocompleteInputCustom choice={choice}/>
                </ReferenceInput>
            </Filter>
        </div>
    );
}

const AutocompleteInputCustom = (props) => {
    const { choice } = props;
    const val = choice ? props.choices.find(ch => {
        return ch.id === parseInt(choice);
    }) : null;

    return <AutocompleteInput
        {...props}
        optionText={'name'}
        optionValue={'id'}
        resettable={false}
        defaultValue={val?.id}
        inputProps={{ placeholder: 'Test' }}
        renderInput={(params) => (
            <TextField
                {...params}
                variant="standard"
                label="test"
                placeholder="Favorites"
            />
        )}
    />;
};
