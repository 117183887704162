import * as React from "react";
import StatsHeader from "../components/VF/StatsHeader";
import { useQuery } from "react-admin";
// core components
import StatsContent from "./components/StatsContent"
import SafeguardStatsContent from "./components/SafeguardStatsContent";
import dataProvider from '../dataProvider';
import { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {isNetworkView} from "../helpers/safeguard";
import tokenManager from "../tokenManager";

export default () => {

    const [stats, setStats] = useState(null);
    const [statsFetched, setStatsFetched] = useState(false);

    const network = isNetworkView();

    useEffect(() => {
        fetchSatistics();
    }, []);

    const { data } = useQuery({
        type: 'getNone',
        resource: 'statistics',
    });

    const fetchSatistics = () => {
        dataProvider
            .getNone('elevation/dashboard/goals')
            .then(res => {
                setStats(res.data);
                setStatsFetched(true);
            })
            .catch(() => setStatsFetched(true));
    }
    if (!data) return null

    return (
        <>
            <StatsHeader data={data} />
            { statsFetched &&  <SafeguardStatsContent data={stats} />}
            {!network  && <StatsContent data={data.statistics} />}

        </>
    )
};