import _ from 'lodash';
import { DRIVER_STRENGTHS, DRIVER_AREAS_FOR_IMPROVEMENT } from 'constants/safeguard';
import API_KEYS from "./constants/api-keys";

export function formatSimpleListDate(date) {
    if (!date) {
        return '';
    }
    return new Date(date).toLocaleDateString("en-GB");
}

export function copyToClipboard(string) {
    if (!string) return;

    const textContainer = document.createElement('textarea');

    textContainer.innerText = string;
    document.body.appendChild(textContainer);
    textContainer.select();
    document.execCommand('copy');
    textContainer.remove();
}

export function createReplaceVariable(variableName) {
    if (process.env.NODE_ENV === 'production') {
        if (variableName.includes('REPLACEME')) {
            throw new Error(variableName + ' missing from deploy env.');
        }
        return variableName;
    } else {
        return process.env[variableName];
    }
}
export function getApplicationEnvironment() {
    return createReplaceVariable('prod');
}

function getApplicationFeatureFlags() {
    return createReplaceVariable('reports,mixpanel-track-pages');
}
export function isProductionEnv() {
    return getApplicationEnvironment() === 'prod';
}

export function getTokenRefreshTimeout() {
    return createReplaceVariable('16800000')
}

export const formatPlural = (count, singular, plural) => {
    const number = Number(count);

    return number === 1 ? singular : plural;
};

export const featureToggle = name => {
    return localStorage.getItem(name) !== null;
};

export const hasNumber = myString => {
    return /\d/.test(myString);
};

export const canSwitchFirms = pathname => {
    return hasNumber(pathname) ? false : true;
};

export function formatCurrency(number) {
    return `£ ${Number(parseInt(number).toFixed(2)).toLocaleString('en-US')}`;
}

export function getSafeguardDashboardDummyData(driverStatistics) {
    return {
        strengths: _.isEmpty(driverStatistics.strenghts)
            ? DRIVER_STRENGTHS
            : driverStatistics.strengths,
        areas_for_improvement: _.isEmpty(driverStatistics.areas_for_improvement)
            ? DRIVER_AREAS_FOR_IMPROVEMENT
            : driverStatistics.areas_for_improvement,
    };
}

export function insufficientDriverData(num) {
    if (num?.nr_of_missing_drivers > 0) {
        return `You're missing data for ${num.nr_of_missing_drivers} ${num.nr_of_missing_drivers == 1 ? 'driver' : 'drivers'}, gather more reviews now to compare your performance across more drivers`;
    } else if (num?.min_reviews_to_show_data > 0) {
        return `Not enough review data, you need ${num.min_reviews_to_show_data} more review(s)`
    }
}

export const formatRange = (range) => {
    return  { to: range.to?.toLocaleDateString("en-GB").slice(0, 10), from: range.from?.toLocaleDateString("en-GB").slice(0, 10) }
}

export const formatUKDateStringToUS = (dateString) => {
    const dateParts = dateString.split("/");
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
}

export const showWidget = () => {
    switch (true) {
        case (!API_KEYS.vf_2023_widget_launch_date):
            return true;
        case (new Date().toJSON().slice(0, 10) >= API_KEYS.vf_2023_widget_launch_date):
            return false;
        default:
            return true;
    }
};

export const hasApplicationFeature = (featureFlag) => {
    const applicationFeatureFlags = getApplicationFeatureFlags().split(",");
    let hasFeature = false;

    applicationFeatureFlags.forEach(flag => {
        if (flag === featureFlag) {
            hasFeature = true;
        }
    })

    return hasFeature;
}
