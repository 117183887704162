import React, { useState } from 'react';
import { useForm, FormSpy } from 'react-final-form';
import { useDispatch } from 'react-redux';
import {
    SimpleForm,
    useTranslate,
    useNotify,
    useMutation,
    refreshView,
} from 'react-admin';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions, Typography, Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconSave from '@material-ui/icons/Save';
import Button from './CustomButton';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        paddingTop: theme.spacing(4),
        paddingBottom: 0,
        '& > h2': {
            color: theme.palette.secondary.main,
        },
    },
    dialogContent: {
        paddingTop: theme.spacing(4),
    },
}));

const FormSubmitButton = ({
    record = {},
    type = 'update',
    resource = '',
    label = 'Save',
    startIcon = <IconSave/>,
    mutatePayload = null,
    refresh = true,
    invalidForm = false,
    handleSuccess = () => {},
    handleFailure = () => {},
    handleFinish = () => {},
}) => {
    const [loading, setLoading] = useState(false);
    const [mutate] = useMutation();
    const notify = useNotify();
    const dispatch = useDispatch();
    const form = useForm();

    const handleSubmit = () => {
        if (!resource) {
            return;
        }

        setLoading(true);

        mutate({
            type,
            resource,
            payload: getQueryPayload(),
        }, {
            onSuccess: () => {
                handleSuccess();

                form.reset();

                onRequestEnd();
            },
            onFailure: (error) => {
                handleFailure(error);

                notify(error.message, 'error');

                onRequestEnd();
            },
        });
    };

    const onRequestEnd = () => {
        setLoading(false);

        if (refresh) {
            dispatch(refreshView());
        }

        handleFinish();
    };

    const getQueryPayload = () => {
        const formValues = form.getState().values;
        const formAndRecordValues = {...record, ...formValues};
        const payload = {};

        if (record.id) {
            payload.id = record.id;
        }

        if (typeof mutatePayload === 'function') {
            payload.data = mutatePayload(formAndRecordValues);
        } else {
            payload.data = formAndRecordValues;
        }

        return payload;
    };

    return (
        <Button
            disabled={invalidForm || loading}
            onClick={handleSubmit}
            color="tertiary"
            variant="contained">
            {label}
            <Box
                component={IconSave}
                marginLeft=".5em"
                top="3px"
                position="relative"
            />
        </Button>
    );
};

const DialogButtonContent = ({
     form,
     resource,
     type,
     record,
     popupButtonSaveLabel,
     popupButtonSaveIcon,
     mutatePayload,
     refresh = true,
     disableSave = false,
     setShowDialog = () => {},
     handleSuccess = () => {},
     handleFailure = () => {},
     handleFinish = () => {},
}) => {
    const translate = useTranslate();
    const classes = useStyles();
    const [invalidForm, setInvalidForm] = useState(false);

    return (
        <>
            <FormSpy
                subscription={{ invalid: true }}
                onChange={({ invalid }) => setInvalidForm(invalid)}
            />
            <DialogContent className={classes.dialogContent}>
                {form}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowDialog(false)}>
                    {translate('ra.action.cancel')}
                </Button>
                <FormSubmitButton
                    type={type}
                    resource={resource}
                    startIcon={popupButtonSaveIcon}
                    mutatePayload={mutatePayload}
                    record={record}
                    handleSuccess={() => {
                        setShowDialog(false);

                        handleSuccess();
                    }}
                    handleFailure={handleFailure}
                    handleFinish={handleFinish}
                    refresh={refresh}
                    label={popupButtonSaveLabel}
                    invalidForm={disableSave || invalidForm}
                />
            </DialogActions>
        </>
    );
};

const DialogButton = (props) => {
    const [showDialog, setShowDialog] = useState(false);
    const {
        name,
        title,
        icon,
        buttonColour = 'tertiary',
        useSimpleForm = false,
        shouldShowButton = true,
        buttonVariant = 'outlined',
    } = props;

    const classes = useStyles();
    const contentProps = {
        ...props,
        setShowDialog,
    };

    if (!shouldShowButton) {
        return null;
    }

    return (
        <>
            <Button
                color={buttonColour}
                onClick={() => setShowDialog(true)}
                startIcon={icon}
                size="medium"
                variant={buttonVariant}
                style={{ margin: 8, marginLeft: 0 }}
            >
                {name}
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={() => setShowDialog(false)}
                aria-label={name}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <Typography variant='h2'>{title}</Typography>
                </DialogTitle>
                {
                    useSimpleForm ?
                        <SimpleForm toolbar={null}>
                            <div style={{ width: 'auto' }}>
                                <DialogButtonContent
                                   {...contentProps}
                                />
                            </div>
                        </SimpleForm>
                        : <DialogButtonContent {...contentProps} />
                }
            </Dialog>
        </>
    );
};

export default DialogButton;
