import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import componentStyles from '../../assets/theme/components/headers/user-header.js';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles(componentStyles);

const UserHeader = (props) => {
    const classes = useStyles();
    const { title, description } = props;
    const theme = useTheme();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const isMedium = useMediaQuery((theme) => theme.breakpoints.down('lg'));

    let height = 220
    if (!description) {
        height = 140
    }

    return (
        <Box display="flex" className={classes.wrapperBox} position="relative">
            <Container
                display="flex"
                alignItems="center"
                maxWidth={false}
                height={height}
                overflow="hidden"
                component={Box}
                classes={{ root: classes.containerRoot }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h1" classes={{ root: classes.typographyRootH1 }}>
                            {title}
                        </Typography>
                        {description && (
                            <Box
                                component="p"
                                color={theme.palette.white.main}
                                lineHeight={isSmall ? 'auto' : '1.7'}
                                fontSize={isSmall ? '0.8rem' : '1rem'}
                                fontFamily="Nunito"
                            >
                                {description}
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default UserHeader;
