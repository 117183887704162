import goalsReducer from 'Store/goalsReducer';
import serviceTypeReducer from 'Store/serviceTypeReducer';
import identityReducer from './identityReducer';

const customReducers = {
    goal: goalsReducer,
    serviceType: serviceTypeReducer,
    identity: identityReducer
};

export default customReducers;
