import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import DisplayDriverAverages from './components/DisplayDriverAverages';
import { insufficientDriverData } from '../utils';
import _ from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import { DRIVER_STRENGTHS, DRIVER_AREAS_FOR_IMPROVEMENT, GOALS } from '../constants/safeguard';



const DriverStatistics = ({ driverStatistics, compareToIndustry, goal, forAdviser, serviceType }) => {
    const [statistics, setStatistics] = useState(null);
    const [disabled, setDisabled] = useState(null);
    const [missingNumbers, setMissingNumbers] = useState(null);

    const theme = useTheme();

    useEffect(() => {
        if (driverStatistics?.extra_info) {
            setMissingNumbers(driverStatistics.extra_info);
        }
        setStatistics({
            strengths: _.isEmpty(driverStatistics.strengths) || driverStatistics.strengths.length === 0 ? DRIVER_STRENGTHS : driverStatistics.strengths,
            areas_for_improvement: _.isEmpty(driverStatistics.areas_for_improvement) || driverStatistics.areas_for_improvement.length === 0 ? DRIVER_AREAS_FOR_IMPROVEMENT : driverStatistics.areas_for_improvement,
        }
        );
        setDisabled({
            strengths: _.isEmpty(driverStatistics.strengths),
            areas_for_improvement: _.isEmpty(driverStatistics.areas_for_improvement),
        });
    }, [driverStatistics]);

    const renderTitle = (text, color, marginTop) => {
        return (
            <Typography
                variant="h1"
                style={{ color: color, marginTop:  marginTop}}>
                {text}
            </Typography>
        );
    };


    return (
        <>
            {(missingNumbers?.nr_of_missing_drivers > 0 || missingNumbers?.min_reviews_to_show_data > 0) && (
                <Typography style={{ color: theme.palette.error.main, fontWeight: 'bold' }}>
                    {insufficientDriverData(missingNumbers)}
                </Typography>
            )}
            {statistics && (
                <>
                    {renderTitle(forAdviser? `Things ${forAdviser.name} is best at` : "Things you’re best at", theme.palette.primary.main, 0)}
                    <DisplayDriverAverages
                        type="strengths"
                        data={statistics.strengths}
                        disabled={disabled.strengths}
                        compareToIndustry={compareToIndustry}
                        reviewsNeeded={missingNumbers?.min_reviews_to_show_data}
                        goal={goal}
                        forAdviser={forAdviser}
                        serviceType={serviceType}
                    />
                    {renderTitle(forAdviser ? `Opportunities for ${forAdviser.name} to improve ${GOALS[goal].toLowerCase()}` : `Opportunities to improve ${GOALS[goal].toLowerCase()}`, theme.palette.primary.main, '1rem')}
                    <DisplayDriverAverages
                        data={statistics.areas_for_improvement}
                        compareToIndustry={compareToIndustry}
                        disabled={disabled.areas_for_improvement}
                        reviewsNeeded={missingNumbers?.min_reviews_to_show_data}
                        goal={goal}
                        forAdviser={forAdviser}
                        serviceType={serviceType}
                    />
                </>
            )}
        </>
    );
};

export default DriverStatistics;
