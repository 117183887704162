import React, { useState, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { DateRange } from 'react-date-range';


const useStyles = makeStyles(theme => ({
    picker: {
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            '& .rdrMonth': {
                width: '100%'
            },
            '& .rdrMonthAndYearWrapper': {
                width: '100%'
            }
        },

    }
}));

const DateRangeCustom = props => {
    const { range, setRange } = props;
    const [ranges, setRanges] = useState([{
        startDate: range.from ? range.from : new Date(),
        endDate: range.to,
        key: 'selection',
    }]);

    useEffect(() => {
        setRange({
            from: ranges[0].startDate,
            to: ranges[0].endDate
        });
    }, [ranges])

    const customClasses = useStyles();

    const onChange = (date) => {
        setRanges([date.selection])
    };

    return (
        <Box>
            <DateRange
                className={customClasses.picker}
                ranges={ranges}
                startDatePlaceholder="From"
                endDatePlaceholder="To"
                maxDate={new Date()}
                onChange={onChange}
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                showSelectionPreview={true}
                retainEndDateOnFirstSelection={range.to ? true : false}

            />
        </Box>
    );
};
export default DateRangeCustom;
