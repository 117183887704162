import { Typography } from '@material-ui/core';
import dataProvider from '../../dataProvider';
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import StepContentWrapper from './StepContentWrapper';
import React from 'react';
import { connect } from 'react-redux';
import Button from '../../components/Buttons/CustomButton';
import { makeStyles } from '@material-ui/core/styles';
import { REVIEW_INVITE_TYPE_FOLLOW_UP_REVIEWS } from '../Content/FormButtons';

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: '1.5rem',
    },
}));

const Step2 = props => {
    const classes = useStyles();

    const downloadHandle = async () => {

        if (props.record.invite_type === REVIEW_INVITE_TYPE_FOLLOW_UP_REVIEWS) {
            const { data } = await dataProvider.getNone('follow-up');
            const headers = ['Adviser ID', 'Adviser name', 'Client email', 'Client first name', 'Customer Master ID'];
            let advisersForExport = []

            data.map(reviewer => {
                const { adviser_id, adviser_name, client_first_name, client_email, client_master_id} = reviewer; // omit backlinks and author

                const toExport = {};

                toExport['Adviser ID'] = adviser_id;
                toExport['Adviser name'] = adviser_name;
                toExport['Client first name'] = client_first_name;
                toExport['Client email'] = client_email;
                toExport['Customer Master ID'] = client_master_id;

                if (Object.keys(toExport).length !== 0) {
                    advisersForExport = [...advisersForExport, toExport]
                }
                return true;
            });
            jsonExport(
                advisersForExport,
                {
                    headers: headers, // order fields in the export
                },
                (err, csv) => {
                    downloadCSV(csv, 'eligible-client-follow-up-feedback'); // download as 'linked.advisers.csv` file
                }
            );

        } else {
            const { data } = await dataProvider.getNone('professionals');
            const headers = props.crmLookup
                ? ['CRM Owner ID', 'Client email', 'Client first name']
                : ['Adviser ID', 'Adviser name', 'Client email', 'Client first name'];
            let advisersForExport = []

            data.map(adviser => {
                const { full_name, id, crm_lookup } = adviser; // omit backlinks and author

                const toExport = {};
                if (props.crmLookup) {
                    if (crm_lookup?.crm_owner_id) {
                        toExport['CRM Owner ID'] = crm_lookup?.crm_owner_id;
                        toExport['Client email'] = '';
                        toExport['Client first name'] = '';
                    }
                } else {
                    toExport['Adviser ID'] = id;
                    toExport['Adviser name'] = full_name;
                    toExport['Client email'] = '';
                    toExport['Client first name'] = '';
                    toExport['Client ID'] = '';
                }

                if (Object.keys(toExport).length !== 0) {
                    advisersForExport = [...advisersForExport, toExport]
                }
                return true;
            });
            jsonExport(
                advisersForExport,
                {
                    headers: headers, // order fields in the export
                },
                (err, csv) => {
                    downloadCSV(csv, 'client-details-template'); // download as 'linked.advisers.csv` file
                }
            );
        }
    };

    const renderStep2FieldList = () => (
        <>
            {props.record.invite_type === REVIEW_INVITE_TYPE_FOLLOW_UP_REVIEWS ? (
                <>
                    <Typography component="div">
                        Download the spreadsheet below which contains 5 columns:
                    </Typography>
                    <ul>
                        <li>Adviser name</li>
                        <li>Adviser ID</li>
                        <li>Client first name</li>
                        <li>Client email</li>
                        <li>Customer ID (this is used to link the original feedback with the follow-up feedback)</li>
                    </ul>
                </>
            ) : (props.crmLookup ? (
                <>
                    <Typography component="div">
                        Download the spreadsheet below which contains 4 columns:
                    </Typography>
                    <ul>
                        <li>CRM Owner ID</li>
                        <li>Client first name</li>
                        <li>Client email</li>
                    </ul>
                </>
            ) : (
                <>
                    <Typography component="div">
                        Download the spreadsheet below which contains 5 columns:
                    </Typography>
                    <ul>
                        <li>Adviser name</li>
                        <li>Adviser ID</li>
                        <li>Client first name</li>
                        <li>Client email</li>
                        <li>Client ID (optional - if you provide a Client ID then we'll store this against the invite and review if they complete one)</li>
                    </ul>
                </>
            ))}
        </>
    );

    return (
        <StepContentWrapper title={'Step 2) Download our template and complete the client details'}>
            {renderStep2FieldList()}


            {props.record.invite_type == "invite_follow_up_reviews" ? (
                <Typography component="div">
                    All eligible clients (financial advice clients that left feedback between 3 to 9 months ago)
                    that can be sent follow-up feedback will appear in the download. If you want to remove a client
                    from the invite simply delete the row. Alternatively, if you want to send the feedback invite
                    to all those that are eligible then simply re-upload the file.
                </Typography>
            ) : (
                <Typography component="div">
                    Complete this spreadsheet with the client names and emails you wish to invite for
                    each adviser.
                </Typography>
            )}

            <Button
                className={classes.button}
                variant="outlined"
                color="tertiary"
                onClick={downloadHandle}
            >
                Download
            </Button>
        </StepContentWrapper>
    );
};

const mapStateToProps = state => {
    return {
        crmLookup: state.identity?.crmLookup,
    };
};

export default connect(mapStateToProps)(Step2);
