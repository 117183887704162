import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles ({
    firmAbout: {
        whiteSpace: 'pre-line',
    },
});

const FieldWithLabel = ({label, content, variant}) => {
    const classes = useStyles();

    return variant === 'textarea' ?
        (
            <>
                <Typography><b>{label}:</b></Typography>
                <Typography variant="body1" paragraph className={classes.firmAbout}>{content}</Typography>
                <br/>
            </>
        ) : (
            <>
                <Typography><b>{label}:</b></Typography>
                <Typography>{content}</Typography>
                <br/>
            </>
        )
}

export default FieldWithLabel;
