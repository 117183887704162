import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PageTopToolbar from '../../AppLayout/PageTopToolbar';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '8px',
    },
    pageActionTitle: {
        fontSize: '35px',
        paddingBottom: theme.spacing(4),
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

const TableForListComponentWrapper = ({title, color, ...props}) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <PageTopToolbar
                title={title}
                className={classes.pageActionTitle}
                color={color}
            />
            <Box>
                <Box className={classes.container}>
                    {props.children}
                </Box>
            </Box>
        </Box>
    );
};

export default TableForListComponentWrapper;