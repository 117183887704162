import * as React from 'react';
import { useQuery } from 'react-admin';
import LoginPageWrapper from '../Auth/components/wrappers/LoginPageWrapper';
import { Redirect, useLocation } from 'react-router-dom';
import CompleteRegistrationForm from './components/complete-registration/CompleteRegistrationForm';
import AuthPageContent from './components/wrappers/AuthPageContent';


const RegistrationCompletion = () => {
    const location = useLocation()
    const token = new URLSearchParams(location.search).get("token")
    
    const { loading, error } = useQuery({
        type: 'getOne',
        resource: `get-user-by-token`,
        payload: { id: token }
    });

    if (!token) return <Redirect to="/login" />

    if (loading) return null

    return (
        <LoginPageWrapper>
            <AuthPageContent>
                <CompleteRegistrationForm  error={error} token={token}/> 
            </AuthPageContent>
        </LoginPageWrapper>
    );
};

export default RegistrationCompletion;
