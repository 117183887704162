import React from 'react';
import { OutlinedInput, FormLabel, FormGroup } from '@material-ui/core';
import { useInput } from 'react-admin';
import _ from 'lodash';

const ArgonTextInputNoCard = (props) => {
    const {
        input: { name, onChange, ...rest },
        meta: { touched, error },
        isRequired,
    } = useInput(props);

    const multiline = _.get(props, 'multiline', false);
    const disabled = _.get(props, 'disabled', false);
    const placeholder = _.get(props, 'placeholder', '')
    const classes = _.get(props, 'className', '')
    const minRows = multiline ? 12 : false;

    return (
        <FormGroup className={classes}>
            <FormLabel>{props.label}</FormLabel>
            <OutlinedInput
                name={name}
                onChange={onChange}
                error={!!(touched && error)}
                helperText={touched && error}
                {...rest}
                required={isRequired}
                multiline={multiline}
                minRows={minRows}
                disabled={disabled}
                placeholder={placeholder}
            />
        </FormGroup>
    );
};

export default ArgonTextInputNoCard;
