import {MenuItemLink} from "react-admin";
import * as React from "react";
import getDataProviderApiUrl from "./utils/getDataProviderApiUrl";
import {canSwitchFirms} from "./utils";

const FirmChangeMenuItem = ({firm, group, type}) => {
    const token = localStorage.getItem('token');

    const setToken = (token) => {
        localStorage.setItem('token', token)
        return true;
    };

    const onClick = (event) => {
        const request = new Request(getDataProviderApiUrl('auth/user/switch'), {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify({
                token: token, 
                firm: type == "firm" ? event.target.id : firm.id,
                group: type == "group" ? event.target.id : (group ? group.id : null),
            }),
        });

        return fetch(request)
            .then((response) => {

                return response.json();
            })
            .then((response) => {
                setToken(response.token);
                canSwitchFirms(window.location.pathname) ? window.location.reload() : window.location.href = '/';
            });
    }

    return (
        <MenuItemLink
            to={window.location.pathname}
            primaryText={type == "group" ? group.name : firm.name }
            onClick={onClick}
            sidebarIsOpen
            id={type == "group" ? group.id : firm.id}
            data-test={"firm-" + (type == "group" ? group.name : firm.name)}
        />
    )
}

export default FirmChangeMenuItem;
