import API_KEYS from './constants/api-keys';
import { isProductionEnv } from './utils';


//currently we're only using linkedin pixel, I'm commenting out gTag and facebook pixel intergrations, but leave the code in case we'll need it in the future

// const gTag = key => {
//     return {
//         track: function (action, options) {
//             const { category, label, value } = options;

//             if (!isProductionEnv()) {
//                 console.log(
//                     `Gtag activated\n` +
//                     (category ? `Category: ${category}\n` : '') +
//                     (action ? `Action: ${action}\n` : '') +
//                     (label ? `Label: ${label}\n` : '') +
//                     (value ? `Value: ${JSON.stringify(value)}\n` : '')
//                 );
//             }

//             window.gtag('event', action, {
//                 'event_category': category,
//                 'event_label': label,
//                 'value': value,
//             });
//         },
//         pageView: function(pathname) {
//             if (pathname) {
//                 return window.gtag('config', key, { page_path: pathname });
//             }
//         },
//     };
// }

// const facebookPixel = key => {
//     return {
//         activate: function() {
//             (function(f, b, e, v, n, t, s) {
//                 if (f.fbq) {
//                     return;
//                 }
//                 n = f.fbq = function() {
//                     n.callMethod
//                         ? n.callMethod.apply(n, arguments)
//                         : n.queue.push(arguments);
//                 };
//                 if (!f._fbq) {
//                     f._fbq = n;
//                 }
//                 n.push = n;
//                 n.loaded = !0;
//                 n.version = '2.0';
//                 n.queue = [];
//                 t = b.createElement(e);
//                 t.setAttribute('type', 'text/plain')
//                 t.setAttribute('data-cookiescript', 'accepted')
//                 t.async = !0;
//                 t.src = v;
//                 s = b.getElementsByTagName(e)[0];
//                 s.parentNode.insertBefore(t, s);
//             })(
//                 window,
//                 document,
//                 'script',
//                 '//connect.facebook.net/en_US/fbevents.js'
//             );

//             window.fbq('init', key);
//         },
//         track: function(action, opts) {
//             if (isProductionEnv()) {
//                 window.fbq('trackCustom', action, opts);
//             }
//         },
//         pageView: function(pathname) {
//             if (isProductionEnv()) {
//                 window.fbq('track', 'PageView');
//             } else {
//                 console.log(`FacebookPixel URL visited: ${pathname}`);
//             }
//         },
//     };
// };

const linkedinPixel = key => {
    return {
        activate: function() {
            window._linkedin_data_partner_ids =
                window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(key);
            (function() {
                var s = document.getElementsByTagName('script')[0];
                var b = document.createElement('script');
                b.type = 'text/plain';
                b.setAttribute('data-cookiescript', 'accepted');
                b.setAttribute('data-cookiecategory', 'targeting');
                b.async = true;
                b.src =
                    'https://snap.licdn.com/li.lms-analytics/insight.min.js';
                s.parentNode.insertBefore(b, s);
            })();
        },
        track: function() {},
        pageView: function() {},
    };
};

const integrations = [
    linkedinPixel(API_KEYS.linkedin_pixel_key),
];


const integrationsToActivate = [
    linkedinPixel(API_KEYS.linkedin_pixel_key),
];

export function activate() {
    integrationsToActivate.forEach(integration => integration.activate());
}

export function track(action, options) {
    integrations.forEach(integration => integration.track(action, options));
}

export function pageView(pathname) {
    integrations.forEach(integration => integration.pageView(pathname));
}
