import React, { useEffect } from 'react';
import { SimpleForm, Create, RadioButtonGroupInput } from 'react-admin';
import FormButtonFields from './Content/FormButtonFields';
import { BulkInviteToolbar } from '../components/FormToolbar';
import { CLIENT_TYPE_BUTTONS } from './Content/FormButtons';
import StepContentWrapper from './components/StepContentWrapper';
import UserHeader from '../components/Headers/UserHeader';
import _ from "lodash";
import { useSelector } from 'react-redux';
import { CUSTOM_BULK_INVITE } from '../constants';

const BulkInviteCreate = (props) => {
    const allowCustomBulkInviteForNetwork = useSelector(
        ({identity}) => identity?.allowCustomBulkInviteForNetwork
    );

    const validateInviteType = (values) => {
        const errors = {};

        if (_.isEmpty(values.invite_type)) {
            errors.invite_type = 'Please select who to invite';
        }

        if (allowCustomBulkInviteForNetwork && _.isUndefined(values.allow_config_override) ){
            errors.allow_config_override = 'Please select if you would like to send Standard or Custom emails';
        }

        return errors
    };

    useEffect(() => {
        sessionStorage.setItem(CUSTOM_BULK_INVITE, String(false));
    }, []);

    const handleInputChange = (value) => {
        sessionStorage.setItem(CUSTOM_BULK_INVITE, String(value))
    }

    return (
        <>
            <UserHeader
                title="Invite Tool"
                description="Send review invites to prospects or clients on behalf of your advisers."
            />
            <Create undoable={false} {...props}>
                <SimpleForm toolbar={<BulkInviteToolbar />} validate={validateInviteType}>
                    <StepContentWrapper
                        title={'Step 1) Choose whether to invite prospects or clients'}
                    >
                        <FormButtonFields
                            source="invite_type"
                            buttonsToRender={CLIENT_TYPE_BUTTONS}
                        />

                        {
                         allowCustomBulkInviteForNetwork &&
                        <RadioButtonGroupInput
                            source={'allow_config_override'}
                            label={'Select Bulk invite type'}
                            choices={[
                                { id: false, name: 'Standard' },
                                { id: true, name: 'Openwork Client Contact' },
                            ]}
                            onChange={handleInputChange}
                            data-test={'custom-bulk-invite-radio-button'}
                        />
                        }
                    </StepContentWrapper>

                </SimpleForm>
            </Create>
        </>
    );
};

export default BulkInviteCreate;
