import React from 'react';
import { Datagrid, TextField, DateField, DeleteButton, ReferenceManyField, Responsive, SimpleList } from 'react-admin';
import Chip from '@material-ui/core/Chip';
import { PageviewOutlined } from '@material-ui/icons';
import { CardContent, Typography } from '@material-ui/core';

const ChipUrl = ({ label = '', record = {} }) => {
    return (
        <Chip
            label={<PageviewOutlined />}
            onClick={() => {
                window.open(record.url);
            }}
        />
    );
};

const QualificationGrid = (props) => {
    return (
        <CardContent>
            <Typography>Awards</Typography>
            <ReferenceManyField
                {...props}
                label={null}
                reference="qualifications"
                target="adviser_id"
            >
                <Responsive
                    medium={
                        <SimpleList
                            primaryText={(record) => record.qualification.name}
                            secondaryText={(record) =>
                                `Expiry: ${record.expiry ? record.expiry : "Not provided"}`
                            }
                        />
                    }
                    large={
                        <Datagrid>
                            <TextField label="Name" source="qualification.name" />
                            <DateField label="Expiry" source="expiry" emptyText="Not provided" />
                            <ChipUrl />
                            <DeleteButton redirect={false} label="" />
                        </Datagrid>
                    }
                />
            </ReferenceManyField>
        </CardContent>
    );
};

export default QualificationGrid;
