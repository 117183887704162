import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ContentTooltip from "Dashboard/components/ContentTooltip.js";
import { ExternalLink } from "components/Links.js";

// core components
import componentStyles from "../../../assets/theme/components/card-stats.js";
import boxShadows from "../../../assets/theme/box-shadow.js";

const useStyles = makeStyles(componentStyles);
const useStylesCustom = makeStyles((theme) => ({
  titleSameHeigh: {
    minHeight: "3em",
    lineHeight: 1.5,
    overflow: "auto",
  },
  active: {
    backgroundColor: theme.palette.blue.navyBlue,
    boxShadow: "0 0 2rem 0 rgba(225, 225, 225, .15) !important"
  }
}));

const getToolTipText = (description, description_hyperlink) => {
  return (
      <>
          {description} {description_hyperlink &&
          <ExternalLink href={description_hyperlink} target={'_blank'}>
              Learn more.
          </ExternalLink>}
      </>
  );
};

function CardStats({ subtitle, title, footer, icon, color, statColor, titleSameHeigh, onClick, className, isActive, description, hyperlink }) {
  const classes = { ...useStyles(), ...useStylesCustom() };
  const theme = useTheme();
  return (
    <>
      <Card classes={{ root: className ? `${classes.cardRoot} ${className} ${isActive && classes.active}` : `${classes.cardRoot} ${isActive && classes.active}` }} elevation={0} onClick={onClick}>
        <CardContent classes={{ root: classes.cardContentRoot }}>          
            <Grid container component={Box} justifyContent="space-between">
            <Grid item xs="auto">
              <Box
                component={Typography}
                variant="h5"
                color={isActive ? theme.palette.white.main + " !important" : theme.palette.secondary.dark + " !important"}
                marginBottom="0!important"
                marginTop="0!important"
                className={`${classes.textUppercase} ${titleSameHeigh ? classes.titleSameHeigh : ''}`}
              >
                {subtitle}
                { className &&
                  <ContentTooltip 
                        placement="right"
                        title={getToolTipText(description, hyperlink)}
                        disableFocusListener
                        disableTouchListener
                        interactive
                        leaveDelay={200}
                        isGoalTooltip={true}
                      >
                    </ContentTooltip> 
                }  
              </Box>
              <Box
                component={Typography}
                variant="h2"
                color={isActive ? theme.palette.white.main + " !important" : statColor ? statColor + "!important" : theme.palette.blue.navyBlue + " !important"}
                fontSize="40px!important"
                fontWeight="900!important"
                marginBottom="4px!important"
                marginTop="4px!important"
              >
                {title}
              </Box>
            </Grid>
            {icon ?
              <Grid item xs={"auto"}>
                <Box
                  width="3rem"
                  height="3rem"
                  padding="12px"
                  textAlign="center"
                  display="inline-flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="50%"
                  boxShadow={boxShadows.boxShadow}
                  color={theme.palette.white.main}
                  className={classes[color]}
                >
                  {icon && typeof icon === "object" ? (
                    <Box
                      component={icon}
                      width="1.5rem!important"
                      height="1.5rem!important"
                    />
                  ) : null}
                  {icon && typeof icon === "string" ? (
                    <Box component="i" fontSize="1.25rem" className={icon} />
                  ) : null}
                </Box>
              </Grid>
              : null}
          </Grid>
          {footer ? (
            <Box
              component="p"
              fontSize=".875rem"
              marginTop="0"
              marginBottom="0"
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              color={isActive ? theme.palette.white.main : theme.palette.blue.navyBlue}
            >
              {footer}
            </Box>
          ) : null}
        </CardContent>
      </Card>
    </>
  );
}

CardStats.defaultProps = {
  color: "bgPrimaryLight",
};

CardStats.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.object,
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.oneOfType([
    // i.e. an icon name from Nucleo Icons - e.g. ni ni-atom
    // // or an icon name from Font Awesome - e.g. fa fa-heart
    PropTypes.string,
    // i.e. a component from @material-ui/icons
    PropTypes.object,
  ]),
  color: PropTypes.oneOf([
    "bgPrimary",
    "bgPrimaryLight",
    "bgError",
    "bgErrorLight",
    "bgWarning",
    "bgWarningLight",
    "bgInfo",
    "bgInfoLight",
    "bgSuccess",
    "bgSuccessLight",
  ]),
};

export default CardStats;
