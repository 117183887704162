import React, { useState, useEffect } from 'react';
import { Typography, Checkbox, makeStyles, Card, CardContent, CardActions, FormControlLabel } from '@material-ui/core';
import SectionTitle from '../../SafeGuard/components/SectionTitle';
import ErrorIcon from '@material-ui/icons/Error';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import dataProvider from '../../dataProvider';
import {ExternalLink} from "../../components/Links";
import { usePermissions } from "react-admin";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
    risk: {
        border: `1px solid ${theme.palette.error.main}`,
        backgroundColor: 'rgba(228, 59, 88, 0.02)'

    },
    opportunity: {
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: 'rgba(31, 175, 131, 0.02)'
    },
    riskText: {
        color: `${theme.palette.error.main} !important`
    },
    opportunityText: {
        color: `${theme.palette.primary.main} !important`
    },
    icon: {
        marginRight: '15px',
        verticalAlign: 'top',
        fontSize: '28px'
    },
    header: {
        marginTop: '1.3rem !important',
        marginBottom: '1.3rem !important',
    },
    cardRoot: {
        width: '100%',
        maxWidth: 900,
        padding: '8px 24px',
        borderRadius: '12px',
        '& .MuiTypography-root': {
            lineHeight: 1.3,
            fontSize: '0.875rem',
            marginBottom: 0,
            marginTop: 0,
        },      
        '& .MuiTypography-body2': {
            fontSize: '0.7rem',
            fontStyle: 'italic',
        },
        margin: '1.3rem 0',
        '& .MuiCardContent-root': {
            padding: '0 !important'
        },
        '& .MuiCardActions-root': {
            padding: '8px 0 !important'
        },
        '& .MuiTypography-h6': {
            padding: '0 !important',
            fontSize: '1rem',
            fontWeight: 500
        },
        '& .MuiFormControlLabel-labelPlacementStart': {
            marginLeft: 0
        }, 
        '& .MuiFormControlLabel-label': {
            fontSize: '1rem',
        }, 
        '& .MuiIconButton-root': {
            paddingTop: 0,
            paddingBottom: 0
        }, 
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
            color: theme.palette.secondary.dark
        },
        [theme.breakpoints.down('sm')]: {
            padding: '24px',
            maxWidth: '300px',
        },
    },
    wrapper: {
        marginBottom: '0.5rem',
        marginTop: '0.5rem !important',
        flexBasis: 0,
        [theme.breakpoints.up('md')]: {
            alignSelf: 'center',
        }
    },
    wrapperTextMain: {
        flexGrow: 5,
        [theme.breakpoints.down('md')]: {
            flexGrow: 3,
        }
    },
    wrapperTextSecondary: {
        flexGrow: 1,
        marginBottom: 0,
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end !important',
        },
    },
    cardContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        }
    },
    cardWrapper: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        flexDirection: 'column',
        flexWrap: 'wrap',
        flexGrow: 0,   
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
        }
    },
    predictedValue: {
        fontSize: '0.7rem'
    },
    title: {
        color: `${theme.palette.secondary.dark} !important`,
        '&.MuiTypography-h4': {
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: 1.235
        },
    },
    externalLink: {
        paddingTop: '10px'
    }
}));

const RisksAndOpportunities = (props) => {

    const [data, setData] = useState([])
    const { isOpportunity } = props;
    const classes = useStyles()
    const { permissions } = usePermissions()

    useEffect(() => {
        setData(props.data)
    }, [])

    if (!data || data.length === 0) return null

    const handleActionChange = async (event, item, key) => {
        if (event.target.checked) {
            data[key].action.completed_at = new Date()
        } else {
            data[key].action.completed_at = null
        }
        setData([...data])
        const payload = {
            id: item.action.id,
            data: {
                value: event.target.checked,
            },
        };
        await dataProvider.update('action', payload);
    }

    const getHelperText = () => {
        if (data.length === 0) return ''
        
        if (isOpportunity) {
            return `${data.length} ${data.length === 1 ? 'Opportunity' : 'Opportunities'}`
        }

        return `${data.length} Potential ${data.length === 1 ? 'Risk' : 'Risks'}`
    }
    
    return (
        <>
            <div className={classes.header}>
                    <SectionTitle title={getHelperText()} className={classes.title} variant={"h4"} 
                    icon={isOpportunity ? <TrendingUpIcon className={`${classes.icon} ${classes.opportunityText}`} /> : <ErrorIcon className={`${classes.icon} ${classes.riskText}`} />}/>
            </div>
            <div className={classes.cardWrapper}>
                {data.map((item, key) => (
                    <Card className={`${classes.cardRoot} ${isOpportunity ? classes.opportunity : classes.risk}`} variant="outlined">
                        <CardContent className={`${classes.cardContent}`}>
                            <Typography variant="body1" className={`${isOpportunity ? classes.opportunityText : classes.riskText} ${classes.wrapper} ${classes.wrapperTextMain}`} variant={"h6"} >{item.description}</Typography>                            
                            {isOpportunity && item.value!==0 && (
                                <div className={`${classes.wrapper} ${classes.wrapperTextSecondary}`}>
                                    <div>
                                        <Typography variant="body1"><span className={classes.predictedValue}>Predicted value: </span>&pound;{item.value}</Typography> 
                                    </div>
                                </div>
                            )}
                        </CardContent>
                        <CardContent className={classes.cardContent}>
                            <div className={`${classes.wrapper} ${classes.wrapperTextMain}`}>
                                <div>
                                <Typography variant="body1">{isOpportunity ? item.opportunity_text : item.risk_text}</Typography>
                                <Typography variant="body1" className={classes.externalLink}>
                                    <ExternalLink href={item.external_link?.url}>
                                        {item?.external_link?.label}
                                    </ExternalLink>
                                </Typography>
                                </div>
                            </div>
                            { item.action && (<div className={`${classes.wrapper} ${classes.wrapperTextSecondary}`}>
                                {permissions && permissions.includes('ROLE_EDIT_FIRM') 
                                    ?   <FormControlLabel
                                            value="start"
                                            control={<Checkbox
                                                label="Actioned?"
                                                name="actioned"
                                                color="primary"
                                                checked={item.action.completed_at ? true : false}
                                                onChange={(event) => handleActionChange(event, item, key)}
                                            /> }
                                            label="Actioned?"
                                            labelPlacement="start"
                                        />
                                    :  <Tooltip title="You do not have permission to edit this action" arrow placement="top">
                                            <FormControlLabel
                                                value="start"
                                                control={<Checkbox
                                                    label="Actioned?"
                                                    name="actioned"
                                                    color="primary"
                                                    disabled
                                                    checked={item.action.completed_at ? true : false}
                                                /> }
                                                label="Actioned?"
                                                labelPlacement="start"
                                            />
                                        </Tooltip>
                                }
                            </div>) }
                        </CardContent>
                    </Card>
                ))}
            </div>
        </>
    )
}

export default RisksAndOpportunities;