import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import DriverSlider from './DriverSlider';

const useStyles = makeStyles(theme => ({
    driverStatistics: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    disabled: {
        filter: 'grayscale(1)',
        opacity: 0.4,
    },
    showMoreText: {
        color: theme.palette.secondary.main,
        textAlign: 'right',
        fontWeight: 600,
        cursor: "pointer",
        marginBottom: '16px'
    }
}));

const DisplayDriverAverages = props => {
    const { data, type, disabled, forAdviser, goal, serviceType } = props;

    const classes = useStyles();

    return (
        <Box className={`${classes.driverStatistics} ${disabled && classes.disabled}`}>
            {data.map(driver => {
                return (
                    <DriverSlider
                        key={driver.driver_id + Math.random()}
                        averages={driver}
                        type={type}
                        forAdviser={forAdviser}
                        goal={goal}
                        serviceType={serviceType}
                    />
                );
            })}
        </Box>
    );
};

export default DisplayDriverAverages;
