import * as React from 'react';
import {Create, SimpleForm, TopToolbar} from 'react-admin';
import BackButton from '../components/Buttons/BackButton';
import MortgageFeeLevelForm from './Pages/CreateEdit/MortgageFeeLevelForm';
import InitialAndOngoingFeeLevelForm from "./Pages/CreateEdit/InitialAndOngoingFeeLevelForm";
import {validateMinMaxFields} from "../validation";

const FeeLevelCreate = ({ dispatch, recordValues, ...props }) => {
    const { save, record } = props;
    const type = props.location.state.feeType
    const subType = props.location.state.subType

    return (
        <Create
            actions={
                <TopToolbar>
                    <BackButton href="/fees" />
                </TopToolbar>
            }
            title={null}
            {...props}
        >
            <SimpleForm
                redirect='/fees'
                save={save}
                record={record}
                validate={validateMinMaxFields}
            >
                {type === 'mortgage' && <MortgageFeeLevelForm type={subType} {...props} />}
                {type === 'investment_and_pension' && <InitialAndOngoingFeeLevelForm type={type} subType={subType} {...props} />}
                {type === 'investment_and_pension_with_financial_planning' && <InitialAndOngoingFeeLevelForm type={type} subType={subType} {...props} />}
            </SimpleForm>
        </Create>
    );
};

export default FeeLevelCreate;
