import React from 'react';
import { required, useQueryWithStore } from 'react-admin';
import { ArgonSelectInputWithAutoComplete } from '../../components/Argon/ArgonSelectInput';

const QualificationAutocompleteField = () => {
    const { data } = useQueryWithStore({
        type: 'getNone',
        resource: 'qualifications/certificates',
        payload: { perPage: 1000 },
    });

    if (!data) return null;

    return (
        <ArgonSelectInputWithAutoComplete
            validate={[required()]}
            source="qualification"
            data={data}
            label="Qualification"
        />
    );
};

export default QualificationAutocompleteField;
