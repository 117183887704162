import * as React from 'react';
import ProfileShow from "./ProfileShow";
import ProfileEdit from "./ProfileEdit";
import {useACL} from "../acl/acl";

const ProfilePage = () => {
    const { edit: canEdit } = useACL('firm');


    return (
        canEdit ? <ProfileEdit/> : <ProfileShow/>
    );
}

export default ProfilePage;
