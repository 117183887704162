import * as React from 'react';
import {Datagrid, DateField, downloadCSV, Responsive, TextField,} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import {formatSimpleListDate} from '../utils';
import {useACL} from '../acl/acl';
import ListActions from "../components/ListActions";
import TableForListComponent from '../components/List/TableForListComponent';
import TableForListComponentWrapper from '../components/List/TableForListComponentWrapper';
import {OverallRatingField} from "../components/OverallRatingField";

const exporter = (advisersPerformance) => {
    const advisersForExport = advisersPerformance.map((adviser) => {

        const adviserForExport = {};

        adviserForExport.id = adviser.id;
        adviserForExport.full_name = adviser.full_name;
        adviserForExport.review_count = adviser.review_analytics.review_count || '0';
        adviserForExport.first_impression_count = adviser.review_analytics.first_impression_count || '0';
        adviserForExport.review_invite_count = adviser.reputation_tool_usage ? adviser.reputation_tool_usage.review_invite_count || '0' : '0';
        adviserForExport.overall_rating = adviser.review_analytics.overall_rating;
        adviserForExport.response_rate = adviser.review_analytics.response_rate || '0' + ' %';
        adviserForExport.last_review_or_fi_date = adviser.reputation_tool_usage ? formatSimpleListDate(
            adviser.reputation_tool_usage.last_review_or_fi_date
        ) : '';
        adviserForExport.last_review_invite = adviser.reputation_tool_usage ? formatSimpleListDate(
            adviser.reputation_tool_usage.date_last_review_invite
        ) : '';

        return adviserForExport;
    });
    jsonExport(
        advisersForExport,
        {
            headers: [
                'id',
                'full_name',
                'review_count',
                'first_impression_count',
                'review_invite_count',
                'overall_rating',
                'response_rate',
                'last_review_or_fi_date',
                'last_review_invite',
            ], // order fields in the export
            rename: [
                'ID',
                'Adviser name',
                'Review count',
                'First impression count',
                'Review invitation count',
                'Overall rating',
                'Response Rate',
                'Last review of first impression date',
                'Last review invite date',
            ], // rename fields in the export
        },
        (err, csv) => {
            downloadCSV(csv, 'adviser-performance-export'); // download as 'adviser-performance-export.csv` file
        }
    );
};

const CustomMediumDataGrid = props => {
    return (
        <Datagrid>
            <TextField source="full_name" label="Adviser Name" sortBy="professional.first_name"/>
            <TextField source="review_analytics.review_count" label="Reviews"/>
            <TextField
                source="review_analytics.first_impression_count"
                label="First Impressions"
            />
            <TextField
                source="reputation_tool_usage.review_invite_count"
                label="Review Invitations"
            />
            <OverallRatingField
                source="review_analytics.overall_rating"
                label="Overall Rating"
            />
            <OverallRatingField
                source="review_analytics.response_rate"
                label="Response Rate"
            />
            <DateField
                source="reputation_tool_usage.last_review_or_fi_date"
                label="Last Review Date"
            />
            <DateField
                source="reputation_tool_usage.date_last_review_invite"
                label="Last Invite Date"
            />
        </Datagrid>
    );
};

const AdviserPerformanceList = ({permissions, ...props}) => {
    const {export: canExportAdvisers} = useACL('professionals/performance');

    return (
        <TableForListComponentWrapper
            title={`Adviser Performance`}
            color={'white'}
        >
            <TableForListComponent
                basePath="/professionals/performance"
                exporter={canExportAdvisers ? exporter : false}
                bulkActionButtons={false}
                actions={canExportAdvisers ? <ListActions resource={"professionals/performance"}/> : false}
                sort={{field: 'reputation_tool_usage.last_review_or_fi_date', order: 'DESC'}}
            >
                <Responsive small={<CustomMediumDataGrid/>} medium={<CustomMediumDataGrid/>}/>
            </TableForListComponent>
        </TableForListComponentWrapper>
    );
};

export default AdviserPerformanceList;
