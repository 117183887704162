import React, {useEffect} from 'react';
import { Filter, AutocompleteInput, ReferenceInput, TextInput } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {useSelector} from "react-redux";
import {isFirmViewOnNetworkPortal, isNetworkView} from "../helpers/safeguard";

const useStyles = makeStyles(() => ({
    xSmallView: {
        marginTop: '20px',
        marginBottom: '10px',
        '& .MuiFilledInput-inputMarginDense': {
            paddingTop: '6px',
        },
    },
    largeView: {
        marginTop: '20px',
        marginLeft: '20px',
        marginBottom: '20px',
        display: 'flex',
        '& .MuiFilledInput-inputMarginDense': {
            paddingTop: '6px',
        },
    },
    label: {
        fontWeight: 'bold',
        fontFamily: 'Nunito',
    },
}));

const AutocompleteInputCustom = props => {
    const { adviser } = props;
    const val = adviser
        ? props.choices.find(ch => {
              return ch.id == parseInt(adviser);
          })
        : null;

    return (
        <AutocompleteInput
            {...props}
            optionValue="id"
            resettable={false}
            defaultValue={val && val.id}
            inputProps={{ placeholder: 'Test' }}
            renderInput={params => (
                <TextField {...params} variant="standard" label="test" placeholder="Favorites" />
            )}
        />
    );
};

const ReviewInvitationFilter = ({ isXSmall, adviser, firm, onFilterChange, onFirmFilterChange }, props) => {
    const classes = useStyles();
    const networkFirms = useSelector(({ identity }) => identity.networkFirms);
    const sortedFirms = (networkFirms || []).sort((a, b) =>
        a.label.localeCompare(b.label)
    );
    const network = isNetworkView();
    const firmNetworkView = isFirmViewOnNetworkPortal();

    return (
        <div>
            <div className={isXSmall ? classes.xSmallView : classes.largeView}>
                <label className={classes.label}> Filter invites by</label>
            </div>
            <div className={isXSmall ? classes.xSmallView : classes.largeView}>
                <div>
                    <label className={classes.label}>Adviser</label>
                    <Filter {...props}>
                        <ReferenceInput
                            filter={{ minimal_fields: true, review_invitation_filter: true}}
                            label={false}
                            source="professional"
                            reference="professionals"
                            alwaysOn
                            onChange = {onFilterChange}
                            defaultValues = {adviser}
                        >
                            <AutocompleteInputCustom adviser={adviser} optionText="name_only" />
                        </ReferenceInput>
                    </Filter>
                </div>
                {network && !firmNetworkView && (
                    <div className={classes.filter}>
                        <label className={classes.label}> Firms</label>
                        <Filter {...props}>
                            <AutocompleteInputCustomFirm
                                alwaysOn
                                label={false}
                                source={'firm'}
                                suggestionLimit={50}
                                choices={sortedFirms}
                                onChange={onFirmFilterChange}
                                firm={firm}
                            />
                        </Filter>
                    </div>
                )}
                <div>
                    <label className={classes.label}>Type of invite</label>
                    <Filter {...props}>
                        <AutocompleteInputCustom
                            allowEmpty
                            label={false}
                            alwaysOn
                            optionText="name"
                            source="type"
                            choices={[
                                { id: 'review', name: 'Review' },
                                { id: 'first_impression', name: 'First impression' },
                            ]}
                        />
                    </Filter>
                </div>
                <div>
                    <label className={classes.label}>Client email</label>
                    <Filter {...props}>
                        <TextInput label={false} alwaysOn source="client_email" />
                    </Filter>
                </div>
                <div>
                    <label className={classes.label}>Client name</label>
                    <Filter {...props}>
                        <TextInput label={false} alwaysOn source="client_first_name" />
                    </Filter>
                </div>
            </div>
        </div>
    );
};

const AutocompleteInputCustomFirm = (props) => {
    let { firm } = props;

    const val = firm ? props.choices.find(ch => {
        return ch.value === parseInt(firm);
    }) : null;

    return (
        <AutocompleteInput
            {...props}
            optionText={'label'}
            optionValue={'value'}
            resettable={false}
            defaultValue={val && val.value}
            inputProps={{ placeholder: 'Test' }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label="test"
                    placeholder="Favorites"
                />
            )}
        />
    );
};

export default ReviewInvitationFilter;
