import API_KEYS from './api-keys';

export const ROOT_DOMAIN = API_KEYS.consumer_url;
export const ACCOUNTS_DOMAIN = API_KEYS.accounts_url;
export const SUPPORT_DOMAIN = 'https://pro-support.vouchedfor.co.uk';

const ABOUT_DOMAIN = 'https://about.vouchedfor.co.uk';

export const COMPANY_URL = {
    ABOUT: `${ABOUT_DOMAIN}/about/what-we-do`,
    BLOG: `${ROOT_DOMAIN}/blog`,
    EMAIL_SUPPORT: 'mailto:consumer_support@vouchedfor.co.uk',
};

export const FIND_ADVISER_URL = {
    FINANCIAL_ADVISER: `${ROOT_DOMAIN}/financial-advisor-ifa`,
    MORTGAGE_ADVISER: `${ROOT_DOMAIN}/mortgage-advisor`,
    ACCOUNTANT: `${ROOT_DOMAIN}/accountant`,
    LEGAL_ADVISER: `${ROOT_DOMAIN}/solicitor`,
};

export const ADVISER_URL = {
    ADVISER_LOGIN: `${ACCOUNTS_DOMAIN}/login`,
    ADVISER_SIGNUP: `${ACCOUNTS_DOMAIN}/register`,
};

export const TERMS_AND_CONDITIONS = `${ROOT_DOMAIN}/terms-and-conditions`;

export const PRIVACY_POLICY = `${ROOT_DOMAIN}/privacy-policy`;

export const SOCIAL_URL = {
    FACEBOOK: 'https://www.facebook.com/VouchedFor',
    TWITTER: 'https://twitter.com/VouchedFor',
    LINKEDIN: 'https://www.linkedin.com/company/vouchedfor/',
};

export const SUPPORT_URL = {
    FEES: {
        ADD_YOUR_FEES: `${SUPPORT_DOMAIN}/article/99-adding-your-fees-to-vouchedfor`,
    },
};