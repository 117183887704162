import React from 'react';
import { Typography } from '@material-ui/core';
import MyTheme from '../../MyTheme';

export const PageTitle = ({ title, color, ...props }) => {
    const { basePath, ...rest } = props;
    const { palette } = MyTheme;

    switch (color) {
        case 'white':
            color = palette.primary.contrastText;
            break;
        case 'textPrimary':
            color = palette.primary.textPrimary;
            break;
        default:
            color = palette.secondary.main;
    }

    return (
        <div>
            <Typography style={{ color }} variant="h5" {...rest}>
                {title}
            </Typography>
        </div>
    );
};
