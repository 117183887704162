import React from 'react';
import { SimpleForm, required, TopToolbar, Edit } from 'react-admin';
import { pastDateValidation } from '../validation';
import { PageTitle } from '../components/Typography/Headlines';
import BackButton from '../components/Buttons/BackButton';
import ArgonTextInput from '../components/Argon/ArgonTextInput';
import ArgonDatePicker from '../components/Argon/ArgonDatePicker';

const AccreditationEdit = (props) => {
    const profileUrl = '/profile';

    return (
        <Edit
            undoable={true}
            {...props}
            actions={
                <TopToolbar>
                    <BackButton href={profileUrl} />
                </TopToolbar>
            }
        >
            <SimpleForm redirect={profileUrl}>
                <PageTitle title="Edit accreditation" />
                <ArgonTextInput source="accreditation.title" label="Accreditation" disabled />
                <ArgonDatePicker
                    label="Date Received"
                    source="date"
                    validate={[required(), pastDateValidation]}
                />
            </SimpleForm>
        </Edit>
    );
};

export default AccreditationEdit;
