import React from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import DateRangeCustom from '../../SafeGuard/components/DateRange';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paperWidthSm': {
            width: 'auto',
        },
    },
    actions: {
        display: "flex",
        justifyContent: "space-between"
    },
}));

export default ({ showDialog, setShowDialog, setRange, setTempDateRange, tempDateRange }) => {
    const classes = useStyles();

    const handleCloseClick = () => setShowDialog(false);
    const handleOkClick = () => {
        setRange({ to: tempDateRange.to, from: tempDateRange.from });
        setShowDialog(false);
    };
    const handleClearClick = () => {
        setRange({ to: null, from: null });
        setTempDateRange({ to: null, from: null });
        setShowDialog(false);
    };

    return (
        <Dialog
            className={classes.dialog}
            open={showDialog}
            onClose={handleCloseClick}
        >
            <DialogContent>
                <DateRangeCustom range={tempDateRange} setRange={setTempDateRange}/>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button
                    label="ra.action.cancel"
                    onClick={handleClearClick}
                >
                    Clear interval
                </Button>
                <Button
                    color="secondary"
                    label="ra.action.cancel"
                    onClick={handleOkClick}
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}