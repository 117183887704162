import React from 'react'
import { Divider, makeStyles, Typography } from '@material-ui/core';
import MenuItemLinkText from '../../components/MenuItemLinkText';
import { MenuItemLink } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  text: {
    marginLeft: theme.spacing(2),
    color: theme.palette.blue.navyBlue,
    marginBottom: '1rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    fontSize: '0.9rem',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
  },
}));

export default (props) => {
  const classes = useStyles();
  const {onMenuClick, open} = props;
  return (
    <div>
      <MenuItemLink
          to="/tools"
          primaryText={<MenuItemLinkText text="Reputation Tools" />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          selected={window.location.pathname === '/tools'}
      />
    </div>
  )
}