import React from 'react'
import { useQuery } from "react-admin";
import RatingWidget from "./RatingWidget";
import {Grid} from "@material-ui/core";

const RatingWidgetList = () => {

    const { data } = useQuery({
        type: 'getNone',
        resource: 'widget',
    });

    if (!data) {
        return null;
    }
    
    return (
        <Grid item xl={4} lg={6} sm={12} style={{minWidth: '350px'}} >
        <RatingWidget widget={data}/>
        </Grid>
    )
}

export default RatingWidgetList;