import React, { useState, useEffect, useRef } from 'react';
import {Box, makeStyles, useTheme} from '@material-ui/core';
import SafeguardStatsContent from '../Dashboard/components/SafeguardStatsContent';
import DriverStatistics from './DriverStatistics';
import DashboardHeader from './components/DashboardHeader';
import AdviserTable from './components/AdviserTable';
import dataProvider from 'dataProvider';
import Spinner from '../components/Spinner';
import { formatRange } from '../utils';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import { getServiceTypeAsString } from '../helpers/safeguard';
import SectionTitle from './components/SectionTitle';
import setGoalAction from 'Store/goalsAction';
import { connect } from 'react-redux';
import setServiceTypeAction from 'Store/serviceTypeAction';
import {SERVICE_TYPE_FILTERS} from "../constants/safeguard";

const useStyles = makeStyles(theme => ({
    wrapper: {
        marginTop: theme.spacing(2),
    },
    containerRoot: {
        marginBottom: 0,
        [theme.breakpoints.up('md')]: {
            paddingLeft: '30px',
            paddingRight: '30px',
        },
    },
    gridItem: {
        display: 'flex',
        justifyContent: 'center',
        '&:nth-of-type(1)': {
            paddingBottom: 0,
        },
    },
    statisticsWrapper: {
        flexBasis: '90%',
    },
    title: {
        marginTop: '4rem',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '30px',
            paddingRight: '30px',
        },
    },
}));

const SafeguardDashboard = props => {
    const theme = useTheme();
    const { goal, setGoal} = props;
    const classes = useStyles();
    const [range, setRange] = useState({ from: null, to: null });
    const [stats, setStats] = useState(null);
    const [statsFetched, setStatsFetched] = useState(false);
    const [initialFetch, setInitialFetch] = useState(true);
    const [serviceType, setServiceType] = useState(null);
    const [driverStatisticsFetched, setDriverStatisticsFetched] = useState(false);
    const [driverStatistics, setDriverStatistics] = useState({
        strengths: [],
        areas_for_improvement: [],
    });
    const history = useHistory();
    const driverRef = useRef();
    const goalRef = useRef();

    useEffect(() => {
        const search = history.location.search;
        const params = new URLSearchParams(search);
        const goalIdFromUrl = params.get('goal');
        if (goalIdFromUrl) {
            setGoal(goalIdFromUrl);
        }
        fetchStatistics();
        setInitialFetch(false);
    }, []);

    useEffect(() => {
        setDriverStatisticsFetched(false);
        fetchStatistics();
    }, [range]);

    useEffect(() => {
        if (!initialFetch) {
            setDriverStatisticsFetched(false);
            fetchStatistics();
        }
    }, [serviceType]);

    useEffect(() => {
        if (!initialFetch) {
            setDriverStatisticsFetched(false);
            fetchStatistics();
        }
    }, [goal]);

    const handleCardClick = goal => {
        setGoal(goal);
    };

    const fetchStatistics = () => {
        if (driverRef.current) driverRef.current.abort();
        if (goalRef.current) goalRef.current.abort();

        const controllerD = new AbortController();
        driverRef.current = controllerD;

        const controllerG = new AbortController();
        goalRef.current = controllerG;

        let params = formatRange(range);
        if (!serviceType) {
            dataProvider
                .getNone( 'elevation/dashboard/highest-statistics')
                .then(res => {
                    const type = SERVICE_TYPE_FILTERS.find(x => x.type === res.data['serviceType']);
                    setServiceType(type);
                    params['service_type'] = getServiceTypeAsString(serviceType);
                })
                .catch(() => {});

        } else {
            params['service_type'] = getServiceTypeAsString(serviceType);
        }

        dataProvider
            .getNone('elevation/dashboard/goals', params, { signal: goalRef.current?.signal })
            .then(res => {
                setStats(res.data);
                setStatsFetched(true);
                goalRef.current = null;
            })
            .catch(() => setStatsFetched(true));

        dataProvider
            .getNone(`elevation/dashboard/drivers/${goal}`, params, {
                signal: driverRef.current?.signal,
            })
            .then(res => {
                setDriverStatistics({
                    strengths: [...res.data.strengths],
                    areas_for_improvement: [...res.data.areas_for_improvement],
                    extra_info: { ...res.data.extra_info },
                });
                setDriverStatisticsFetched(true);
                driverRef.current = null;
            })
            .catch(() => {});
    };

    return (
        <>
            <DashboardHeader
                range={range}
                setRange={setRange}
                serviceType={serviceType}
                setServiceType={setServiceType}
            />
            <Box className={classes.wrapper}>
                <Box className={classes.title}>
                    <SectionTitle title="Choose Elevation Goal:" color={theme.palette.blue.navyBlue} />
                </Box>

                {statsFetched ? (
                    <SafeguardStatsContent
                        fullScreen={true}
                        data={stats}
                        handleClick={handleCardClick}
                        goal={goal}
                        hideTitle={true}
                        serviceType={serviceType}
                    />
                ) : (
                    <Spinner />
                )}
                <Container
                    maxWidth={false}
                    component={Box}
                    className={classes.containerRoot}
                    marginBottom="1rem"
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12} className={classes.gridItem}>
                            {driverStatisticsFetched ? (
                                <Box className={classes.statisticsWrapper}>
                                    <DriverStatistics
                                        serviceType={serviceType}
                                        driverStatistics={driverStatistics}
                                        goal={goal}
                                    />
                                </Box>
                            ) : (
                                <Spinner />
                            )}
                        </Grid>
                        <Grid item xs={12} className={classes.gridItem}>
                            <Box className={classes.statisticsWrapper}>
                                <AdviserTable goal={goal} range={range} serviceType={serviceType} />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

const mapStateToProps = state => {
    return {
        goal: state.goal,
        serviceType: state.serviceType,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setGoal: goal => dispatch(setGoalAction(goal)),
        setServiceType: serviceType => dispatch(setServiceTypeAction(serviceType)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SafeguardDashboard);
