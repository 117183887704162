import React from 'react';
import { ArgonSelectInput } from '../../components/Argon/ArgonSelectInput';
import {
    SERVICE_TYPE_FILTER_TRANSFERRED_CLIENTS,
    SERVICE_TYPE_FILTERS_EXCEPT_LEGAL_AND_TRANSFERRED_CLIENTS
} from '../../constants/safeguard';
import { firmCanSeeFilter } from '../../helpers/safeguard';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';

const ServiceTypeFilter = props => {
    const { serviceType, handleServiceTypeChange, classes, firmIdentity, defaultItem = "Filter dashboard by type of service" } = props;
    const serviceTypeFilters = SERVICE_TYPE_FILTERS_EXCEPT_LEGAL_AND_TRANSFERRED_CLIENTS
        .concat(firmIdentity?.hasTransferredClients ? SERVICE_TYPE_FILTER_TRANSFERRED_CLIENTS : []);

    return (
        <>
            {firmCanSeeFilter(firmIdentity?.type) ? (
                <ArgonSelectInput
                    onChange={e => handleServiceTypeChange(e)}
                    className={classes.dropdown}
                    value={serviceType ? serviceType : 0}
                    data={serviceTypeFilters}
                    dataLabelName="name"
                    dataKeyName="all"
                    selectElementOnly={true}
                    noForm={true}
                    defaultItem={defaultItem}
                />
            ) : (
                <Box style={{ height: '60px' }}></Box>
            )}
        </>
    );
};

const mapStateToProps = state => {
    return {
        firmIdentity: state.identity,
    };
};
export default connect(mapStateToProps)(ServiceTypeFilter);
