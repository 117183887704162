import React, { useCallback } from 'react';
import { useInput } from 'ra-core';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
    FormLabel,
    FormGroup,
    CardContent,
    Typography,
    Box,
    makeStyles,
    useTheme,
} from '@material-ui/core';
import moment from 'moment';
import componentStyles from '../../assets/theme/components/cards/cards/card-button';
import defaultComponentStyles from '../../assets/theme/components/inputs/dates';

const useStyles = makeStyles(componentStyles);
const useDefaultStyles = makeStyles(defaultComponentStyles);

const useCustomStyles = makeStyles(theme => ({
    root: {
        flexBasis: "75%",
        backgroundColor: "#ffffff",
        '& .MuiInputBase-root': {
            paddingRight: 0,
        },
        marginTop: '4px',
        marginBottom: '4px',
    },
}));

const ArgonDatePickerDefault = props => {
    const { onChange, value, type, classes } = props;

    const defaultClasses = useDefaultStyles();
    const customClasses = useCustomStyles();
    const theme = useTheme();

    return (
        <Box className={`${defaultClasses.wrapper} ${classes}`} maxWidth={350}>
            <Typography style={{ margin: 0, marginRight: '16px', alignSelf: 'center', color: theme.palette.white.main }}>
                {type === 'from' ? 'From:' : 'To:'}
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    className={customClasses.root}
                    variant="inline"
                    format="dd/MM/yyyy"
                    placeholder="dd/mm/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={value}
                    onChange={date => onChange(date, type)}
                    disableFuture
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />

            </MuiPickersUtilsProvider>
        </Box>
    );
};

const ArgonDatePickerCustom = props => {
    const { label, source, resource, isRequired, validate, onChange = () => { }, ...rest } = props;
    const { input, meta } = useInput({ source, validate });
    const { touched, error } = meta;

    const handleChange = useCallback(
        value => {
            onChange(value);

            Date.parse(value) ? input.onChange(moment(value).format()) : input.onChange(null);
        },
        [input, onChange]
    );

    return (
        <FormGroup>
            <FormLabel>{label}</FormLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    error={Boolean(touched && error)}
                    helperText={touched && error}
                    format="dd/MM/yyyy"
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    placeholder="dd/mm/yyyy"
                    value={input.value ? new Date(input.value) : null}
                    onChange={date => handleChange(date)}
                />
            </MuiPickersUtilsProvider>
        </FormGroup>
    );
};

const ArgonSelectInput = props => {
    const classes = useStyles();
    let { noForm } = props;
    if (noForm) {
        return <ArgonDatePickerDefault {...props} />;
    } else {
        return (
            <CardContent className={classes.cardRoot}>
                <ArgonDatePickerCustom {...props} />
            </CardContent>
        );
    }
};

export default ArgonSelectInput;
