import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { useNotify, useSafeSetState, SimpleForm } from 'react-admin';
import getDataProviderApiUrl from "../../../utils/getDataProviderApiUrl";
import withStyles from '@material-ui/core/styles/withStyles';
import { LoginEmailField } from "../../../components/Inputs/LoginFields";
import FormToolbar from "../../../components/FormToolbar";
import AuthCardComponent from '../AuthCardComponent';

const styles = theme => ({
    card: {
        margin: `${theme.spacing(5)}px 0`,
        width: 464,
        padding: 0,
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: `0 ${theme.spacing(2)}px`,
        },
    },
    cardContent: {
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(3),
        },

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            paddingTop: 0,
        },
    },
    title: {

        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(2),
        },
    },
    form: {
        '& > div': {
            padding: 0,
        },

        '& > .MuiToolbar-root': {
            position: 'relative',
            padding: 0,
            '& > .MuiButton-contained': {
                width: '100%',
                backgroundColor: theme.palette.tertiary.main,
                '&:hover': {
                    backgroundColor: theme.palette.tertiary.dark,
                },

                '& > .MuiButton-label': {
                    ' & > .MuiSvgIcon-root': {
                        display: 'none',
                    }
                },
                '&.Mui-disabled': {
                    opacity: 0.85,
                    color: 'white',
                },
            },
        },
    },
    anchor: {
        textDecoration: 'none',
        color: theme.palette.secondary.dark,
    },
    formToolbar: {
        marginTop: 0,
        backgroundColor: 'inherit'
    },
    loginButton: {
        marginTop: theme.spacing(2),
        padding: "15px",
        backgroundColor: theme.palette.secondary.dark,
        color: "#fff",
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
            color: "#fff"
        }
    }
});

const CompleteRegistrationForm = props => {
    const { classes } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const notify = useNotify();
    const [success, setSuccess] = useState(false)

    const submit = values => {
        setLoading(true);
        const request = new Request(getDataProviderApiUrl('reset-password'), {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify({ email: values.username }),
        });

        return fetch(request)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                setLoading(false);
                if (response.error) {
                    notify(response.error, 'error')
                } else {
                    setSuccess(true)
                }
            });

    };

    return (
        <AuthCardComponent
            title="Please enter your email address below, and we will send you a link to reset your password"
        >
            {success ?
                <Typography color="primary" variant="body1">Thank you! We've sent you an email with the reset password link</Typography>
                :
                <SimpleForm
                    className={classes.form}
                    save={submit}
                    saving={loading}
                    toolbar={<FormToolbar label="Send Email" className={classes.formToolbar} />}
                >
                    <LoginEmailField />
                </SimpleForm>
            }
        </AuthCardComponent>

    );
};

export default withStyles(styles)(CompleteRegistrationForm);
