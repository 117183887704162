import * as React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import componentStyles from '../../../components/Argon/styles';
import _ from 'lodash';

const useStyles = makeStyles(componentStyles);

export const ProspectIntention = ({ record }) => {
    const classes = useStyles();

    const answer = _.get(record, 'future_use', 'No data');

    const color = () => {
        switch (true) {
            case answer === 'Yes':
                return 'bgSuccess';
            case answer === 'Maybe':
                return 'bgWarning';
            default:
                return 'bgError';
        }
    };

    return (
        <Box paddingTop=".35rem" paddingBottom=".35rem">
            <Box
                marginRight="10px"
                component="i"
                width=".375rem"
                height=".375rem"
                borderRadius="50%"
                display="inline-block"
                className={classes.verticalAlignMiddle + ' ' + classes[color()]}
            ></Box>
            {answer}
        </Box>
    );
};
