import React from 'react';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import ContentTooltip from "../Profile/components/ContentTooltip";

const propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

const PageTabWithTooltip = ({ title, ...rest }) => {
    return (
        <ContentTooltip placement="top" title={title}>
            <Tab {...rest} />
        </ContentTooltip>
    );
};

PageTabWithTooltip.propTypes = propTypes;

export default PageTabWithTooltip;
