import React from 'react';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import _ from 'lodash';

export const ArgonAdviser = ({ source, record }) => {

    const name = _.get(record, `${source}`, '');
    const avatar = _.get(record, `${source === 'full_name' ?
        'avatar_thumbnail' : 
            (
                source === 'professional_full_name' ?
                    'professional_avatar' :
                    'professional.avatar'
            )}`,
        '');

    return (
        <Box alignItems="center" display="flex">
            <Box component={Avatar} marginRight="1rem" alt="..." src={avatar} />
            <Box display="flex" alignItems="flex-start">
                <Box fontSize=".875rem" component="span">
                    {name}
                </Box>
            </Box>
        </Box>
    );
};
