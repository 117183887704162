import React, {cloneElement} from 'react';
import {Datagrid, EditButton, ListContextProvider, TextField } from 'react-admin';
import {
    PrefixTextField,
    UnderscoreConvertTextField,
} from '../../../components/ResourcePageComponents';
import _ from 'lodash';
import DeleteFeeLevelButton from "../../components/DeleteFeeLevelButton";

const MyList = ({children, data, ...props}) => {
    return (
        <ListContextProvider
            value={{
                data: _.keyBy(data, 'id'),
                ids: data.map(({id}) => id),
                currentSort: {field: 'id', order: 'DESC'},
                basePath: '/fees',
                resource: 'fees',
            }}
        >
            {cloneElement(children)}
        </ListContextProvider>
    );
};

const MortgageFeeLevelDataGrid = props => {

    return (
        <MyList {...props}>
            <Datagrid>
                <UnderscoreConvertTextField label="For levels" source="levelType" />
                <PrefixTextField label="Minimum value" source="minimumAmount" prefix="£" />
                <PrefixTextField label="Maximum value" source="maximumAmount" prefix="£" />
                <PrefixTextField label="Fee" source="paymentFee" prefix="£" />
                <TextField label="On" source="paymentTime" />
                <EditButton basePath="fee-level" />

                <DeleteFeeLevelButton/>
            </Datagrid>
        </MyList>
    );
};

export default MortgageFeeLevelDataGrid;
