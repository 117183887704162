import React, { useState, useEffect } from 'react';
import { Typography, makeStyles, Box } from '@material-ui/core';
import 'rc-slider/assets/index.css';
import CustomSlider from './CustomSlider';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';

import ContentTooltip from '../../Dashboard/components/ContentTooltip';
import { ExternalLink } from '../../components/Links';

const useStyles = makeStyles(theme => ({
    sliderWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 120,
        background: '#fff',
        marginBottom: "1rem",
        padding: "1rem 3rem 1rem 1rem",
        borderRadius: '16px',
        [theme.breakpoints.down('lg')]: {
            padding: "1rem"
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            textAlign: 'center',
            flexDirection: 'column',
            padding: "1rem"
        },
        boxShadow: "rgba(17, 17, 26, 0.05) 0px 2px 4px, rgba(17, 17, 26, 0.05) 0px 4px 8px"
    },
    driverTitle: {
        flexBasis: "20%",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
    },
    driverTooltipIcon: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        [theme.breakpoints.up('md')]: {
            minHeight: 100,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    slider: {
        flexBasis: "75%",
        padding: '1rem',
        display: "flex",
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            flexBasis: "70%",
        },
        [theme.breakpoints.down('sm')]: {
            flexBasis: "100%",
            flexDirection: "column",
            width: "80%",
            marginTop: "1rem",
        },
        '& .rc-slider-step': {
            '& .rc-slider-dot-active': {
                display: 'none',
                opacity: 0,
                visibility: 'hidden',
            },
        },
        '& .rc-slider-rail': {
            height: "3px !important"
        },
        '& .rc-slider': {
            padding: "3px 0",
            height: "4px",
        },
    },
    driverName: {
        color: theme.palette.secondary.main,
        marginBottom: 0,
    },
    anchor: {
        textDecoration: 'none',
        color: theme.palette.secondary.main,
    },
    sliderInnerWrapper: {
        flexGrow: 0.8,
        alignSelf: 'center',
        [theme.breakpoints.down('md')]: {
            flexGrow: 0.7,
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            marginBottom: "2rem"
        },
    },
    chipContainer: {
        '& .MuiButtonBase-root': {
           padding: "1.1rem 0.3rem",
           borderRadius: "2rem"
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: "1rem"
        },
        textTransform: 'uppercase',
        fontWeight: '700',
        letterSpacing: '1px',
        '& .MuiChip-root': {
            backgroundColor: theme.palette.tertiary.main,
            color: theme.palette.primary.contrastText
        },
        '& .MuiChip-clickable': {
            '&:hover': {
                backgroundColor: theme.palette.tertiary.dark,
                color: theme.palette.white.main
            }
        },
    }

}));

const getToolTipText = (description, description_hyperlink) => {
    return (
        <>
            {description} {description_hyperlink &&
            <ExternalLink href={description_hyperlink} target={'_blank'}>
                Learn more.
            </ExternalLink>}
        </>
    );
};

const DriverSlider = props => {
    const { averages, type, forAdviser, goal, serviceType } = props;

    const [url, setUrl] = useState('/client-answers')
    const classes = useStyles();
    const strength = type === 'strengths';

    useEffect(() => {
        const goalUrl = `?goal=${goal}`;
        const driverUrl = averages?.driver_id ? `&driver=${averages.driver_id}` : '';
        const adviserUrl = forAdviser ? `&adviser=${forAdviser.id}` : '';
        const serviceTypeUrl = serviceType ? `&service_type=${serviceType.type}` : '';
        setUrl(`/client-answers${goalUrl}${driverUrl}${adviserUrl}${serviceTypeUrl}`)
    }, [props])

    const extra_firm_avg = averages.firm_avg !== undefined ? Math.round(parseFloat(averages.firm_avg)) : false
    const avg = Math.round(parseFloat(averages.industry_avg)),
        currentAvg = averages.firmAvg ?
            Math.round(parseFloat(averages.firmAvg)) : Math.round(parseFloat(averages.professionalAvg));

    return (
        <Box className={classes.sliderWrapper}>
            <Box className={classes.driverTooltipIcon}>
                <ContentTooltip
                    placement="left"
                    title={getToolTipText(averages.description, averages.descriptionHyperlink)}
                    disableFocusListener
                    disableTouchListener
                    interactive
                    leaveDelay={200}
                    isDriverTooltip={true}
                />
            </Box>
            <Box className={classes.driverTitle}>
                <Typography className={classes.driverName}>
                    {averages.name}
                </Typography>
            </Box>
            <Box className={classes.slider}>
                <Box className={classes.sliderInnerWrapper}>
                    <CustomSlider
                        extraFirmAvg={extra_firm_avg}
                        avg={avg}
                        currentAvg={currentAvg}
                        strength={strength} />
                </Box>
                <Box component="div" className={classes.chipContainer}>
                    <Link to={url} className={classes.anchor}>
                        <Chip
                            color="secondary"
                            label={`${averages.review_count ? `See ${averages.review_count} reviews` : "See reviews"}`}
                            clickable={true}
                        />
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

export default DriverSlider;
