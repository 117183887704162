import { Datagrid, DateField, Loading, SimpleList, TextField, useListContext } from 'react-admin';
import * as React from 'react';
import { ShowReviewDataButton } from './ShowReviewDataButton';
import { OverallRatingField } from '../../components/OverallRatingField';
import EmailWithTooltip from '../../components/EmailWithTooltip';

export const XSmallDataGrid = props => (
    <SimpleList
        linkType="show"
        style={{ 'word-break': 'break-all' }}
        primaryText={(record) => `Client first name: ${record.customer_first_name}`}
        secondaryText={(record) => `Client email: ${record.customer_email}`}
    />
);

export const SmallDataGrid = props => (
    <SimpleList
        linkType="show"
        primaryText={(record) => `Client name: ${record.customer_name}`}
        secondaryText={(record) =>
            (<>
                    Adviser name: {record.professional_full_name}
                    <br/>
                    Client email: {record.customer_email}
                </>
            )}
    />
);
export const MediumDataGrid = props => {
    const { canShow, showAnswerColumn } = props;

    return (
        <Datagrid {...props}>
            <DateField label="Date Submitted" source="submitted_at"/>
            <TextField label="Adviser Name" source="professional_full_name"/>
            <TextField label="Client First Name" source="customer_first_name"/>
            <EmailWithTooltip label="Client Email" source="customer_email"/>
            {showAnswerColumn &&
                <TextField label="Answer" source="answer" sortable={false}/>
            }
            <ShowReviewDataButton canShow={canShow}/>
        </Datagrid>
    );
};
export const LargeDataGrid = props => {
    const { canShow, showAnswerColumn } = props;
    const { loading } = useListContext();

    if (loading) {
        return <Loading loadingSecondary={''}/>;
    }

    return (
        <Datagrid {...props} >
            <DateField label="Date Submitted" source="submitted_at"/>
            {
                !showAnswerColumn &&
                <TextField label="Review Type" source="type"/>
            }
            <TextField label="Adviser Name" source="professional_full_name"/>
            <TextField label="Client First Name" source="customer_first_name"/>
            <EmailWithTooltip label="Client Email" source="customer_email"/>
            <OverallRatingField source="overall_rating" label="Overall Rating"/>
            {showAnswerColumn &&
                <TextField label="Answer" source="answer" sortable={false}/>
            }

            <ShowReviewDataButton canShow={canShow}/>
        </Datagrid>
    );
};
