export const FEE_TYPE = {
    INVESTMENT_AND_PENSION: 'investment_and_pension',
    INVESTMENT_AND_PENSION_WITH_PLANNING:
        'investment_and_pension_with_financial_planning',
    MORTGAGE: 'mortgage',
};

export const FEE_SUB_TYPE = {
    FIXED_FEE_ONLY: 'fixed-fees-only',
    INITIAL_AND_ONGOING: 'initial-and-ongoing',
    HOURLY_CHARGE: 'hourly-charge',
};
