export const CONTACT = {
    EMAIL: 'customer_service@vouchedfor.co.uk',
    PHONE_NUMBER: '0203 111 0580',
    ACCOUNTS_TEAM_NUMBER: '0203 111 0583',
};

export const MAX_FILE_UPLOAD_SIZE_TWO_MB = 2000000;

export const SURVEY_TYPE = {
    REVIEW: 'review',
    FIRST_IMPRESSION: 'first_impression',
};

export const CUSTOM_BULK_INVITE = 'custom-bulk-invite';