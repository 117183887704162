import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, Typography } from '@material-ui/core';

const propTypes = {
    label: PropTypes.string.isRequired,
};

const InputPrefix = ({ label }) => (
    <InputAdornment position="start">
        <Typography>{label}</Typography>
    </InputAdornment>
);

InputPrefix.propTypes = propTypes;

export default InputPrefix;
