
import React, { useEffect, useState } from 'react';

import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '100%',
        overflowX: 'hidden',
        height: 400,
        display: 'flex',
        alignItems: 'center'
    },
    scrollableContainer: {
        overflowX: 'scroll',
        height: '100%',
        width: '100%',
        position: 'relative',
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
    },
    responsiveContainer: {
        position: 'unset',
        fontFamily: 'Nunito',
        '& .recharts-legend-wrapper': {
            position: 'sticky !important',
        },
        '& .recharts-cartesian-axis-tick': {
            color: theme.palette.secondary.dark,
            fontSize: "0.7rem"
        }
    },
}))
const colors = ["#022D4F", "#4BC1CA", "#1FAF83", "#4282F3", "#474444", "#E43B58", "#DBF3F5", "#DDE9FF", "#E2F5EF", "#F3D3C7", "#C1B8B3", "#EEEBE9"];


const StackedBarChart = props => {
    const classes = useStyles()
    const { data, bars, id, chartType, dataKeyX } = props
    const [ wrapperWidth, setWrapperWidth ] = useState(0)

    useEffect(() => {
        // eslint-disable-next-line no-restricted-globals
        const screenWidth = screen.width
        if ( screenWidth < (1200 + 80 + 64)) {
            setWrapperWidth((screenWidth - 80 - 32))
        } else {
            setWrapperWidth(1200)
        }
    }, []);

    const calculateBarchartWidth = (numberOfItem = 12) => {
        if (numberOfItem > 24)
            return numberOfItem * 50;
        return '100%'; };
    return (
        wrapperWidth ?
            <Box className={classes.root} style={{width: wrapperWidth}}>
                <Box className={classes.scrollableContainer} >
                    <ResponsiveContainer className={classes.responsiveContainer} height={300} width={calculateBarchartWidth(data.length)}>
                        <BarChart data={data}>
                            <CartesianGrid vertical={false} horizontal={false} stroke="#f5f5f5" />
                            <XAxis tickLine={false}  dataKey={dataKeyX} />
                            { chartType === 'percentage'
                                ? <YAxis minTickGap={1} tickLine={false}  axisLine={false} allowDecimals={false} domain={[0, 100]} tickFormatter={(value) => `${Math.round(value)}%`} />
                                : <YAxis minTickGap={1} tickLine={false} axisLine={false} allowDecimals={false} />
                            }
                            <Tooltip formatter={(value, name, props) => {
                                if (chartType === 'percentage') {
                                    return `${value}%`
                                }

                                return value;
                            }} />
                            <Legend layout="horizontal" verticalAlign="top" align="center" width={wrapperWidth} />
                            { bars.map((bar, key) =>  <Bar maxBarSize={45} name={bar.text} dataKey={bar.gka} stackId={id} fill={colors[key]} />)}
                        </BarChart>
                    </ResponsiveContainer>
                </Box>
            </Box>
            : null
    )
}

export default StackedBarChart
