import * as React from 'react';
import LoginPageWrapper from './components/wrappers/LoginPageWrapper';
import ForgotPasswordForm from './components/reset-password/ForgotPasswordForm';
import AuthPageContent from './components/wrappers/AuthPageContent';

const ForgotPassword = () => {
    return (
        <LoginPageWrapper>
            <AuthPageContent>
                <ForgotPasswordForm />
            </AuthPageContent>
        </LoginPageWrapper>
    );
};

export default ForgotPassword;
