import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import {DashboardMenuItem, MenuItemLink} from 'react-admin';
import { useACL } from './acl/acl';
import MenuItemLinkText from './components/MenuItemLinkText';
import SafeGuardSidebar from './SafeGuard/components/SafeGuardSidebar';
import NetworkSidebar from './components/NetworkSidebar';
import VerifiedSideBar from './SafeGuard/components/VerifiedSideBar';
import {isNetworkView, isFirmViewOnNetworkPortal} from "./helpers/safeguard";

const useStyles = makeStyles(theme => ({
    root:{
        "& .MuiListItem-root.Mui-selected": {
            backgroundColor: 'transparent',
        },
    },
}));

const Menu = ({ onMenuClick, logout, showVerifiedSidebar, networkFirms }) => {
    const classes = useStyles();
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    const identity = useSelector(({identity}) => identity)
    const { list: canListReviews } = useACL('reviews');
    const { list: canListAdvisers } = useACL('professionals');
    const { create: canCreateBulkInvitations } = useACL('bulk-invites');
    const { enabled: canViewSafeguard } = useACL('elevation');
    const { list: canListPreviousInvites } = useACL('review-invitations');
    const { enabled: canViewInsights } = useACL('insights');
    const canViewInviteRequests = identity?.['firmHasIntegration'];

    const network = isNetworkView();
    const firmViewOnNetworkPortal = isFirmViewOnNetworkPortal();

    return (
        <div className={classes.root}>
            {network && (
                <NetworkSidebar identity={identity} networkFirms={networkFirms}/>)
            }
            <DashboardMenuItem
                onClick={onMenuClick}
                sidebarIsOpen={open}
                primaryText={<MenuItemLinkText text="Home" />}
                leftIcon={false}
                selected={window.location.pathname === '/'}
            />
            {canListAdvisers && (
                <MenuItemLink
                    to="/professionals"
                    primaryText={<MenuItemLinkText text="Advisers" />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    selected={window.location.pathname === '/professionals'}
                />
            )}
            {canListReviews && (
                <MenuItemLink
                    to="/reviews"
                    primaryText={<MenuItemLinkText text="Reviews" />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    selected={window.location.pathname === '/reviews'}
                />
            )}
            {canListReviews && (
                <MenuItemLink
                    to="/first-impressions"
                    primaryText={<MenuItemLinkText text="First Impressions" />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    selected={window.location.pathname === '/first-impressions'}

                />
            )}
            {!firmViewOnNetworkPortal && canCreateBulkInvitations && (
                <MenuItemLink
                    to="/bulk-invites/create"
                    primaryText={<MenuItemLinkText text="Invite Tool" />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    selected={window.location.pathname.includes('/bulk-invites')}
                />
            )}
            {canListPreviousInvites && canCreateBulkInvitations && (
                <MenuItemLink
                    to="/invite-history"
                    primaryText={<MenuItemLinkText text="Invite History" />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    selected={window.location.pathname === '/invite-history'}

                />
            )}
            {!network
                && canViewInviteRequests
                && (
                <MenuItemLink
                    to="/invite-requests"
                    primaryText={<MenuItemLinkText text="Invite Requests" />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    selected={window.location.pathname === '/invite-requests'}

                />
            )}
            {
                !network && (
                    <>
                        <MenuItemLink
                            to="/profile"
                            primaryText={<MenuItemLinkText text="Firm Profile Settings" />}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            selected={window.location.pathname === '/profile'}
                        />
                        {showVerifiedSidebar && (
                            <VerifiedSideBar  onClick={onMenuClick} sidebarIsOpen={open}/>
                        )}
                    </>
                )
            }
            {canViewSafeguard && (
                <SafeGuardSidebar network={network} onClick={onMenuClick} sidebarIsOpen={open} canViewInsights={canViewInsights}/>
            )}
            {isXSmall && logout}
        </div>
    );
};

const mapStateToProps = ({identity}) => {
    return {
        networkFirms: identity?.networkFirms,
        showVerifiedSidebar: identity?.showVerifiedSidebar
    }
}

export default connect(mapStateToProps)(Menu);
