import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import FeesTieredButtonBase from './FeesTieredButtonBase';
import CustomFormInput from '../../components/Forms/CustomFormInput';

import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    row: {
        display: 'flex',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5),

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
});

const FeesTieredButtons = props => {
    const inputValue = _.get(props, ['input', 'value'], '');
    const [isWealthBased, setIsWealthBased] = useState(
        typeof inputValue !== 'boolean' ? true : inputValue
    );

    useEffect(() => {
        props.input.onChange(isWealthBased);
    }, [props.input, isWealthBased]);

    return (
        <div className={props.classes.row}>
            <FeesTieredButtonBase
                title="Wealth based"
                value={true}
                onClick={e => setIsWealthBased(true)}
                isWealthBased={isWealthBased}
                examples={[
                    'For 0-50k 1%',
                    'For 50-100k, 0.75%',
                    'For 100k+, 0.5%',
                ]}
            />
            <FeesTieredButtonBase
                title="Tiered"
                onClick={e => setIsWealthBased(false)}
                value={false}
                isWealthBased={isWealthBased}
                examples={[
                    'For the first 0-50k 1%',
                    'For the next 50-100k, 0.75%',
                    'For the next 100k+, 0.5%',
                ]}
            />
        </div>
    );
};

const FeesTieredButtonsWithStyles = withStyles(styles)(FeesTieredButtons);

const FeesTieredButtonsField = ({ label, source }) => {
    return (
        <CustomFormInput
            label={label}
            source={source}
            component={FeesTieredButtonsWithStyles}
        />
    );
};

export default FeesTieredButtonsField;
