import Grid from '@material-ui/core/Grid';
import CardStatsGradient from '../../../components/Cards/Widgets/CardStatsGradient';
import React from 'react';
import { formatSimpleListDate } from '../../../utils';
import { BooleanInput } from 'react-admin';
import { useACL } from '../../../acl/acl';
import _ from 'lodash';
import { useTheme } from '@material-ui/core/styles';

const SurveyInfo = ({ record }) => {
    const theme = useTheme();

    const Title = ({ record }) => {
        return <span style={{color: theme.palette.secondary.dark}}>for {record.professional ? record.professional.full_name : ''}</span>;
    };

    const Subtitle = ({ record }) => {
        let date = formatSimpleListDate(record.submitted_at);
        const emailString = record.customer?.email ? `(${record.customer.email})` : record.customer_email ? record.customer_email : '';

        return <span style={{color: theme.palette.secondary.dark}}>Review by {record.customer ? `${record.customer.name} ${emailString} on ${date}` : record.customer_name ? `${record.customer_name} ${emailString} on ${date}` : ''}</span>;
    };

    const { edit: canEdit } = useACL('reviews');

    const showFeaturedToggle = () => {
        const path = window.location.pathname;
        return ( canEdit && !path.includes('show') );
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <CardStatsGradient
                    subtitle={<Subtitle record={record} />}
                    title={<Title record={record} />}
                    color="white"
                />
                {record.reviewer_type === 'review' && showFeaturedToggle() && (
                    <BooleanInput label="Use this as featured review in the carousel widget" source="featured" />
                )}
            </Grid>
        </Grid>
    );
};

export default SurveyInfo;
