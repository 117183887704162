import React from 'react';
import { Create, FileField, required, SimpleForm, FileInput, TopToolbar } from 'react-admin';
import { futureDateValidation } from '../validation';
import { fileSizeValidation } from '../validation';
import BackButton from '../components/Buttons/BackButton';
import { MAX_FILE_UPLOAD_SIZE_TWO_MB } from '../constants';
import { PageTitle } from '../components/Typography/Headlines';
import QualificationAutocompleteField from './components/QualificationAutocompleteField';
import { transformParams } from '../helpers/fileUploads';
import ArgonDatePicker from '../components/Argon/ArgonDatePicker';

const QualificationCreate = props => {

    const { resource, location, ...rest } = props;

    if (!location.search || !location.search.includes("adviser_id")) {
        return null
    }

    const transform = (data) => {
        return transformParams(data).then(newdata => { return newdata })
    }

    return (
        <Create
            title={null}
            {...rest}
            transform={transform}
            resource={resource + location.search}
            actions={
                <TopToolbar>
                    <BackButton
                        href={location.state && location.state.qualificationsUrl ? location.state.qualificationsUrl : '/professionals'}/>
                </TopToolbar>
            }>
            <SimpleForm
                redirect={location.state && location.state.qualificationsUrl ? location.state.qualificationsUrl : '/professionals'}>
                <PageTitle title="Add qualification"/>
                <QualificationAutocompleteField/>

                <FileInput
                    source="file"
                    label="Upload Certificate (max 2mb)"
                    validate={[required(), fileSizeValidation(MAX_FILE_UPLOAD_SIZE_TWO_MB)]}>
                    <FileField source="src" title="title" target="_blank"/>
                </FileInput>
                <ArgonDatePicker
                    label="Expiry date"
                    validate={[futureDateValidation]}
                    source="expiry"/>
            </SimpleForm>
        </Create>
    );
};

export default QualificationCreate;
