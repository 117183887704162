import boxShadows from "../../../box-shadow.js";
import hexToRgb from "../../../hex-to-rgb";
import themeColors from "../../../colors";

const componentStyles = (theme) => ({
  cardRoot: {
    backgroundColor: theme.palette.white.main,
    boxShadow: boxShadows.boxShadow + "!important",
    border: "0!important",
    borderBottom:
        "1px solid rgba(" + hexToRgb(themeColors.black.main) + ",.05)",
    "&:first-child": {
      borderRadius: "calc(.375rem - 1px) calc(.375rem - 1px) 0 0",
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
  }
});

export default componentStyles;
