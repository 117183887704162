import React from 'react';
import cn from 'classnames';
import { TextInput } from 'react-admin';
import useOutlinedStyle from './useOutlinedStyle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    inline: {
        width: 'auto !important',
        minWidth: 360,
        marginRight: theme.spacing(2),

        [theme.breakpoints.down('xs')]: {
            minWidth: 'unset',
            marginRight: 0,
        },
    },
}));

const FormTextInput = ({ className, inline = false, ...rest }) => {
    const classes = useStyles();
    const outlinedClassName = useOutlinedStyle(className);

    return (
        <TextInput
            {...rest}
            className={cn({
                [outlinedClassName]: true,
                [classes.inline]: inline,
            })}
            variant="outlined"
            margin="none"
        />
    );
};

export default FormTextInput;
