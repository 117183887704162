import { REVIEW_INVITE_TYPE_FOLLOW_UP_REVIEWS } from '../InviteReviews/Content/FormButtons';

export const showClientIdField = (inviteType, crmLookup) => {
    if (inviteType === REVIEW_INVITE_TYPE_FOLLOW_UP_REVIEWS || crmLookup === true) {
        return false;
    }
    return true;
};

export const getErrorMessage = (resource) => {
    switch (resource) {
        case 'review-invitations':
            return 'Error getting Review Invitations'
        case 'reviews':
            return 'Error getting Reviews'
        case 'first-impressions':
            return 'Error getting First Impressions'
        case 'bulk-invites':
            return 'Error sending invites'
        case 'bulk-invite-records':
            return 'Error getting bulk invite records'
        default:
            console.log('Something went wrong ', resource)
            return 'Processing.'
    }
}