import React from 'react';
import { Layout } from 'react-admin';
import Menu from './Menu';
import MyAppBar from './AppBar/MyAppBar';

import { makeStyles } from '@material-ui/core/styles';
import MySidebar from './MySidebar';

const useStyles = makeStyles(theme => ({
    noTopMargin: {
        '& > div:nth-of-type(1)': {
            marginTop: 0,
        },
        '& #main-content': {
            padding: 0,
        },
    },
    container: {
        marginTop: '-16rem',
        marginLeft: '40px',
        marginRight: '40px',
    },
}));

const MyLayout = props => {
    const classes = useStyles();
    return (
        <Layout
            sidebar={MySidebar}
            className={classes.noTopMargin}
            {...props}
            appBar={() => <div></div>}
            menu={Menu}
        >
            <MyAppBar />
            <div className={classes.container}>{props.children}</div>
        </Layout>
    );
};

export default MyLayout;
