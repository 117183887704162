import * as React from 'react';
import { AppBar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames'
import { useLocation } from 'react-router-dom';
import CustomUserMenu from './CustomUserMenu';
import { LOGO_WHITE } from "../constants";

const useStyles = makeStyles((theme) => ({
    spacer: {
        flex: 1,
    },
    logo: {
        height: '1.3rem',
        width: '8rem',
        margin: '1rem',
    },
    header: {
        backgroundColor: theme.palette.secondary.dark,
        paddingBottom: '18rem',
        "& > header": {
            boxShadow: 'none',
        },
    },
    headerSmall: {
        paddingBottom: '8rem',
    },
    appbar: {
        position: 'relative',
        backgroundColor: theme.palette.secondary.dark,
        [theme.breakpoints.down('md')]: {
            '& > div:nth-of-type(1) > .MuiButtonBase-root:nth-of-type(1)': {
                display: 'block'
            }
        }
    },
}));

const MyAppBar = (props) => {
    const classes = useStyles();
    const route = useLocation();

    const renderHeaderSize = (path) => {
        switch (path) {
            case '/':
            case '/elevation':
            case '/insights':
            case '/firm-overview':
            case '/firm-detailed':
                return cn(classes.header, classes.headerSmall);
            default:
                return classes.header;
        }
    };

    return (
        <div className={renderHeaderSize(route.pathname)}>
            <AppBar
                {...props}
                className={classes.appbar}
                elevation={1}
                userMenu={<CustomUserMenu />}>
                <img src={LOGO_WHITE} alt="VouchedFor Logo" className={classes.logo} />
                <span className={classes.spacer} />
            </AppBar>
        </div>
    );
};

export default MyAppBar;


