import React from 'react';
import Helmet from 'react-helmet';
import API_KEYS from './constants/api-keys';

const AppHelmet = () => {
    const getMicrosoftClarityCode = () => {
        return `
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);
            t.setAttribute('type', 'text/plain');t.setAttribute('data-cookiescript', 'accepted');
            t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${API_KEYS.microsoft_clarity}");`;
    };

    return (
        <Helmet
            script={[
                {
                    type: 'text/javascript',
                    innerHTML: getMicrosoftClarityCode(),
                },
            ]}
        />
    );
};

export default AppHelmet;
