import _ from 'lodash';

export const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        if (file.rawFile.type === 'image/jpeg') {
            const image = new Image();

            image.onload = () => {
                let drawImageParams = [];

                // convert jpg to png and return base64 encoded string
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = image.width;
                canvas.height = image.height;

                drawImageParams = [image, 0, 0, image.width, image.height];

                ctx.drawImage(...drawImageParams);

                resolve(canvas.toDataURL('image/png'));
            };

            image.src = file.src;
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.rawFile);

            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        }
    });
};

export const createFileParamsForUpload = (params) => {
    const paramsCopy = _.cloneDeep(params);

    if (!_.has(paramsCopy, 'file')) {
        return paramsCopy;
    }


    paramsCopy.file.title = _.get(paramsCopy, 'file.rawFile.name');
    paramsCopy.file.rawFile = {
        preview: _.get(paramsCopy, 'file.rawFile.preview'),
    };

    return paramsCopy;
}

export const transformParams = (params) => {
    if (_.has(params, 'file')) {
        // if file is already base64 encoded
        if (_.has(params, 'file.dataURL')) {
            const newParams = createFileParamsForUpload(params);
            return new Promise((resolve) => {
                resolve(newParams);
            });
        }

        return convertFileToBase64(params.file).then(file => {
            params.file.dataURL = file;
            const newParams = createFileParamsForUpload(params);
            return newParams;
        });
    }

    return new Promise((resolve) => {
        resolve(params);
    });
};
