import { fetchUtils, HttpError } from 'react-admin';
import {stringify} from 'query-string';
import getDataProviderApiUrl from './utils/getDataProviderApiUrl';
import { getErrorMessage } from './helpers/helperFunctions';

const getLexikToken = () => {
    const lexikToken = localStorage.getItem('token');

    return lexikToken ? lexikToken : null;
}

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    localStorage.setItem('last_api_call', String(new Date().getTime()))
    options.headers.set('vf-token', getLexikToken());

    return fetchUtils
        .fetchJson(url, options)
        .catch(e => {
            const errorMessage = getErrorMessage(options.resource);
            return Promise.reject(
                new HttpError(
                    errorMessage,
                    e.status,
                    e.body,
                ),
            );
        });
}

const dataProvider = {
    getList: (resource, params) => {
        const {page, perPage} = params.pagination;

        let field, order;
        field = params.sort ? params.sort.field : null;
        order = params.sort ? params.sort.order : null;

        let queryString = [];

        queryString.push('limit=' + perPage);
        queryString.push('offset=' + (page - 1) * perPage);
        queryString.push(encodeURI(`order_by[${field}]=${order}`));

        if (params.filter) {
            for (let key in params.filter) {
                queryString.push(
                    'filters%5B' + key + '%5D=' + params.filter[key]
                );
            }
        }

        const url = `${getDataProviderApiUrl(resource, params)}?${queryString.join('&')}`;
        return httpClient(url, {resource}).then(({json}) => ({
            data: json.data,
            total: json.aggregate_data ? json.aggregate_data.total : json.data.length,
        }));
    },

    getNone: (resource, params, options) => {

        const query = params ? `?${stringify(params)}` : ''
        const url = `${getDataProviderApiUrl(resource, params)}${query}`

        return httpClient(url, options).then(({json}) => ({
            data: json.data,
        }))
    },

    getOne: (resource, params) => {
        let queryString = []

        if (params.filters) {
            for (let key in params.filters) {
                queryString.push(
                    key + '=' + params.filters[key]
                );
            }
        }

        const url = `${getDataProviderApiUrl(resource, params)}/${params.id}${queryString.length > 0 ? `?${queryString.join('&')}` : ''}`

        return httpClient(url,{resource}).then(({json}) => ({
            data: json.data,
        }))
    },

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({id: params.ids}),
        };
        const url = `${getDataProviderApiUrl(resource, params)}?${stringify(query)}`;
        return httpClient(url,{resource}).then(({json}) => ({data: json.data}));
    },

    getManyReference: (resource, params) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${getDataProviderApiUrl(resource, params)}?${stringify(query)}`;

        return httpClient(url, { resource }).then(({headers, json}) => ({
            data: json.data,
            total: json.aggregate_data.total,
        }));
    },

    update: (resource, params) => {
        return httpClient(`${getDataProviderApiUrl(resource, params)}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
            resource,
        }).then(({json}) => ({data: json.data}))
    },

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({id: params.ids}),
        };
        return httpClient(`${getDataProviderApiUrl(resource, params)}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({data: json.data}));
    },

    create: (resource, params) =>
        httpClient(`${getDataProviderApiUrl(resource, params)}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
            resource,
        }).then(({json}) => ({data: json.data})),

    delete: (resource, params) => {
        return httpClient(`${getDataProviderApiUrl(resource, params)}/${params.id}`, {
            method: 'DELETE',
        }).then(({json}) => ({data: json}));
    },

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({id: params.ids}),
        };
        return httpClient(`${getDataProviderApiUrl(resource, params)}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({data: json}));
    },

    updateReviewInviteSendReminder: (params) => {
        return httpClient(`${getDataProviderApiUrl('review-invitations/toggle-send-reminder', params)}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
            resource: 'review-invitations/toggle-send-reminder',
        }).then(({json}) => ({data: json.data}))
    },
};

export default dataProvider;
