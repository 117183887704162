import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { useACL } from '../../acl/acl';

// core components
import CardStats from "../../components/Cards/Dashboard/CardStats.js";

import componentStyles from "../../assets/theme/components/headers/stats-header.js";
import themeColors from "assets/theme/colors";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(componentStyles);

const useStylesCustom = makeStyles((theme) => ({
    title: {
        color: theme.palette.secondary.dark,
        marginBottom: 0
    },
    industryDiff: {
        fontWeight: 900,
        fontSize: "1rem",
    },
    industryDiffColor: {
        color: theme.palette.blue.navyBlue,
    },
    activeTextColor: {
        color: theme.palette.white.main,
    },
    link: {
        cursor: "pointer"
    }
}));

const contentData = {
    prospect_intentions: {
        title: 'Prospect Conversion',
        text: 'of prospects intend to become a client',
        color: themeColors.primary.main,
        reverseColor: themeColors.error.main,
        goal: 1
    },
    client_advocacy: {
        title: 'Client Advocacy',
        text: 'of clients are passionate advocates',
        color: themeColors.primary.main,
        reverseColor: themeColors.error.main,
        goal: 2
    },
    safeguarding_clients: {
        title: 'Mitigating Risk',
        text: 'of reviews don’t exceed our risk threshold',
        color: themeColors.primary.main,
        reverseColor: themeColors.error.main,
        goal: 3
    },
}

const SafeguardStatsContent = (props) => {

    const classes = { ...useStyles(), ...useStylesCustom() };

    const { data, adviserStat } = props
    const { enabled: canViewSafeguard } = useACL('elevation');
    const history = useHistory();

    if (!data) return null
    if (!canViewSafeguard) return null

    const handleClick = (goal) => {
        if (props.handleClick) {
            props.handleClick(goal)
        } else {
            history.push(`/elevation/firm?goal=${goal}`)
        }
    }

    return (
        <Container
            maxWidth={false}
            component={Box}
            classes={{ root: classes.containerRoot }}
            marginBottom="1rem"
        >
            <Grid container spacing={3}>
                {props.hideTitle ? null : <Grid item xs={12}>
                    <Typography variant="h1" component="h1" className={classes.title}>
                        See your performance against the Elevation goals
                    </Typography>
                </Grid>}
                {Object.keys(contentData).map((key) => {
                    const diff = adviserStat ? data[key].professionalAvg - data[key].overallAvg : data[key].firmAvg - data[key].overallAvg
                    const avgToCompare = adviserStat ? data[key].professionalAvg : data[key].firmAvg
                    const reviewCount = adviserStat ? data[key].professionalReviewCount : data[key].firmReviewCount
                    return (
                        <Grid key={key + Math.random()} item xl={props.fullScreen ? 4 : 3} lg={4} md={6} xs={12}
                        data-test={"goal-stats-"+key}
                        >
                            <CardStats
                                isActive={props.goal && parseInt(props.goal) === contentData[key].goal}
                                onClick={() => props.handleClick ? props.handleClick(contentData[key].goal) : null}
                                className={props.handleClick ? classes.link : null}
                                subtitle={contentData[key].title}
                                titleSameHeigh={true}
                                description={data[key].description}
                                hyperlink={data[key].descriptionHyperlink}
                                title={<span className={classes.link} onClick={() => handleClick(contentData[key].goal)}>{reviewCount >= 5 ? avgToCompare ? `${Math.round(avgToCompare)}%` : "0%" : "-"}</span>}
                                icon={null}
                                color="bgSuccess"
                                footer={
                                    <>
                                        <div>
                                            <Box component="div" fontFamily="Nunito">
                                                {contentData[key].text}
                                            </Box>
                                            <Box component="div" marginTop="1rem">
                                                {reviewCount >= 5 ?
                                                    <>
                                                        {
                                                            diff >= 0
                                                                ? <Box color={contentData[key].color} component={ArrowUpward} />
                                                                : <Box color={contentData[key].reverseColor} component={ArrowDownward} />
                                                        }
                                                        <Box component="span"
                                                            className={`${classes.industryDiff} ${props.goal && parseInt(props.goal) === contentData[key].goal ? classes.activeTextColor : classes.industryDiffColor}`} >
                                                            <span className={classes.link} onClick={() => handleClick(contentData[key].goal)}>{Math.round(Math.abs(diff))}% vs industry</span></Box>
                                                    </>
                                                    :
                                                    <Box component="span" fontFamily="Nunito"
                                                        className={`${classes.industryDiff} ${props.goal && parseInt(props.goal) === contentData[key].goal ? classes.activeTextColor : classes.industryDiffColor}`}
                                                    >
                                                        <span className={classes.link} onClick={() => handleClick(contentData[key].goal)}>You need at least 5 reviews for this goal to see data</span>
                                                    </Box>
                                                }
                                            </Box>
                                        </div>
                                    </>
                                }
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </Container>
    )
}

export default SafeguardStatsContent;

