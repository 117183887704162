import { Typography } from "@material-ui/core"
import StepContentWrapper from "./StepContentWrapper";
import { useEffect } from 'react';
import { CUSTOM_BULK_INVITE } from '../../constants';

const Step7 = () => {
    useEffect(() => {
        sessionStorage.removeItem(CUSTOM_BULK_INVITE);
    }, []);

    return (
        <StepContentWrapper title={<Typography variant="h1" component="h1" color="primary">Thank you!</Typography>}>

          <Typography component="div" data-test={"step7-message"}>Your invitations will start to send now!</Typography>

        </StepContentWrapper>
    )
}

export default Step7;