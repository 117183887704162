import dataProvider from 'dataProvider';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import DownloadCDRReports from './components/DownloadCDRReports';

const useStyles = makeStyles(theme => ({}));

const ConsumerDutyReport = props => {
    const classes = useStyles();
    const { firmIdentity } = props;
    return (
        <>
            <DownloadCDRReports firmId={firmIdentity.id} />{' '}
        </>
    );
};

const mapStateToProps = state => {
    return {
        firmIdentity: state.identity,
    };
};

export default connect(mapStateToProps)(ConsumerDutyReport);
