import * as React from 'react';
import LoginPageWrapper from './components/wrappers/LoginPageWrapper';
import ResetPasswordForm from './components/reset-password/ResetPasswordForm';
import AuthPageContent from './components/wrappers/AuthPageContent';

const ResetPassword = () => {    
    return (
        <LoginPageWrapper>
            <AuthPageContent>
                <ResetPasswordForm />
            </AuthPageContent>
        </LoginPageWrapper>
    );
};

export default ResetPassword;
