import UserDropdown from '../components/Dropdowns/UserDropdown';
import { connect } from 'react-redux';
import * as React from 'react';
import { useGetIdentity } from 'react-admin';
import setIdentityAction from '../Store/indentityAction';
import { useEffect } from 'react';


const CustomUserMenu = (props) => {
    const { identity } = useGetIdentity();

    useEffect(() => {
        props.setIdentity(identity);
    }, [identity]);

    const getAllowedFirms = (identity) => {
        return identity ? identity.allowedFirms : null;
    };

    const getCurrentFirmId = (identity) => {
        return identity ? identity.id : null;
    };

    const allowedFirms = getAllowedFirms(identity);
    const currentFirmId = getCurrentFirmId(identity);

    return (
        <UserDropdown allowedFirms={allowedFirms} currentFirmId={currentFirmId} identity={identity} />
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setIdentity: identity => dispatch(setIdentityAction(identity)),
    };
};

export default connect(null, mapDispatchToProps)(CustomUserMenu);
