import React, { useCallback } from 'react';
import { FieldTitle, useInput } from 'ra-core';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';


const FormDateInput = (props) => {
    const {
        label,
        source,
        resource,
        className,
        isRequired,
        validate,
        onChange = () => {},
        ...rest
    } = props;
    const { input, meta } = useInput({ source, validate });
    const { touched, error } = meta;

    const handleChange = useCallback(value => {
        onChange(value);

        Date.parse(value) ? input.onChange(moment(value).format()) : input.onChange(null);
    }, [input, onChange]);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                disableToolbar
                label={<FieldTitle
                    label={label}
                    source={source}
                    resource={resource}
                    isRequired={isRequired}
                />}
                className={className}
                margin="none"
                error={Boolean(touched && error)}
                helperText={touched && error}
                format="dd/MM/yyyy"
                inputVariant="outlined"
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                value={input.value ? new Date(input.value) : null}
                onChange={date => handleChange(date)}
                {...rest}
            />
        </MuiPickersUtilsProvider>
    );
};

export default FormDateInput;
