import React, {cloneElement} from 'react';
import {
    ListBase,
    useListContext
} from 'react-admin'
import {Card, makeStyles, Typography} from "@material-ui/core";

import FeeListActions from "./Pages/List/FeeListActions";
import MortgageFeeLevelList from "../FeeLevels/Pages/List/MortgageFeeLevelList";
import InitialAndOngoingFeeLevelList from "../FeeLevels/Pages/List/InitialAndOngoingFeeLevelList";
import {SUPPORT_URL} from "../constants";
import {ExternalLink} from "../components/Links";
import PageSectionTitle from "../ReputationTools/Tools/components/PageSectionTitle";

const useStyles = makeStyles(theme => ({
    warning: {
        color: theme.palette.error.main,
    },
}));

const FeeList = props => (
    <MyList {...props}>
        <FeesGrid />
    </MyList>
);

const FeesGrid = () => {
    const { ids, data } = useListContext();
    const feesCount = ids.length;

    let mortgageFeesData = false;
    let investmentFeesData = false;
    let financialPlanningFeesData = false;
    let investmentId = null;
    let financialPlanningId = null;

    ids.map(id => {
            switch (data[id].type) {
                case 'mortgage':
                    mortgageFeesData = data[id];
                    break;
                case 'investment_and_pension':
                    investmentFeesData = data[id];
                    investmentId = investmentFeesData.id
                    break;
                case 'investment_and_pension_with_financial_planning':
                    financialPlanningFeesData = data[id];
                    financialPlanningId = financialPlanningFeesData.id;
                    break;
            }

            return id;
        }
    );

    return (
        <>
            {mortgageFeesData && <MortgageFeeLevelList data={mortgageFeesData}/>}
            {investmentFeesData &&
            <InitialAndOngoingFeeLevelList
                data={investmentFeesData}
                feeName="investment"
                financialPlanningId = {financialPlanningId}
                investmentId = {investmentId}
                otherFees = "Financial Planning"
                title={financialPlanningFeesData
                    ? 'Implementation & Ongoing Service'
                    : 'Implementation & Ongoing Service (without Financial Planning)'}
            />
            }
            {financialPlanningFeesData &&
            <InitialAndOngoingFeeLevelList
                data={financialPlanningFeesData}
                title="Financial Planning"
                feeName="financial planning"
                financialPlanningId = {financialPlanningId}
                investmentId = {investmentId}
                otherFees = "Implementation & Ongoing Service"
            />
            }
            <FeeListActions feesCount={feesCount}/>
        </>
    );
};

const MyList = ({children, ...props}) => {
    const classes = useStyles()

    return (
        <ListBase {...props}>
            <Card>
                <PageSectionTitle title="Your Fee Data"/>
                <Typography variant="body1">
                    <p>Add your fees to your advisers’ profiles and we’ll make it easy for prospective clients to view examples of what you would charge at different wealth levels.<ExternalLink href={SUPPORT_URL.FEES.ADD_YOUR_FEES}> Read more.</ExternalLink></p>
                    <p className={classes.warning}><b>Please note - the fees you add here will automatically apply to all advisers linked to your firm.</b></p>
                    <p>Alternatively, advisers can individually edit this themselves in their account.</p>
                </Typography>
                {cloneElement(children)}
            </Card>
        </ListBase>
    );
}

export default FeeList;
