import React from 'react';
import UserHeader from '../components/Headers/UserHeader';
import ReportPage from './components/ReportPage';

const ReportOpportunity = () => {
    return (
        <>
            <UserHeader title={'Report: Opportunities'}/>
            <ReportPage type={'opportunity'}/>
        </>
    );
};

export default ReportOpportunity;