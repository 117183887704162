import React from 'react'
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';


const EmailWithTooltip = ({ source, record }) => {
    const customerEmail = _.get(record, `${source}`, '');

    let truncatedEmail = customerEmail;
    if (customerEmail.length > 12) {
        truncatedEmail = truncatedEmail.substring(0, 12) + '...';
    }

    return (
        <Tooltip title={customerEmail} placement={'top'} >
            <Typography>
                {truncatedEmail}
            </Typography>
        </Tooltip>
    );
};

export default EmailWithTooltip;