import React from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

import componentStyles from "../../assets/theme/components/cards/cards/card-pricing-cards.js";

const useStyles = makeStyles(componentStyles);

const AuthCardComponent = props => {
    const classes = useStyles();

    return (
        <>
            <Card className={classes.cardRoot} elevation={0}>
                <CardHeader
                    className={classes.cardHeaderRoot}
                    title={props.title}
                    titleTypographyProps={{
                        className: classes.titleInfo,
                        component: "h4",
                        variant: "h4",
                    }}
                ></CardHeader>
                <CardContent>
                    {props.children}
                </CardContent>
            </Card>
        </>
    );

};

export default AuthCardComponent;

