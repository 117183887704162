import React from 'react';
import { Filter, TextInput } from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    xSmallView: {
        marginTop: '20px',
        marginBottom: '10px',
        '& .MuiFilledInput-inputMarginDense': {
            paddingTop: '6px',
        },
    },
    largeView: {
        marginTop: '20px',
        marginLeft: '20px',
        marginBottom: '20px',
        display: 'flex',
        '& .MuiFilledInput-inputMarginDense': {
            paddingTop: '6px',
        },
    },
    label: {
        fontWeight: 'bold',
        fontFamily: 'Nunito',
    },
}));

const ReviewInvitationRequestFilter = ({ isXSmall, adviser, onFilterChange }, props) => {
    const classes = useStyles();
    return (
        <div>
            <div className={isXSmall ? classes.xSmallView : classes.largeView}>
                <label className={classes.label}> Filter invite requests by</label>
            </div>
            <div className={isXSmall ? classes.xSmallView : classes.largeView}>
                <div>
                    <label className={classes.label}>Client email</label>
                    <Filter {...props}>
                        <TextInput label={false} alwaysOn source="client_email" />
                    </Filter>
                </div>
                <div>
                    <label className={classes.label}>Client name</label>
                    <Filter {...props}>
                        <TextInput label={false} alwaysOn source="client_first_name" />
                    </Filter>
                </div>
            </div>
        </div>
    );
};

export default ReviewInvitationRequestFilter;
