import * as React from 'react';
import {
    Datagrid,
    DateField,
    downloadCSV,
    SimpleList,
    TextField,
} from 'react-admin';
import UserHeader from '../components/Headers/UserHeader';
import { Box, CircularProgress, useMediaQuery } from '@material-ui/core';
import ListWrapper from '../components/List/ListWrapper';
import { ArgonAdviser } from '../components/Argon/ArgonTableComponents/ArgonAdviser';
import ReviewInvitationRequestFilter from './ReviewInvitationRequestFilter';
import { useACL } from '../acl/acl';
import ListActions from '../components/ListActions';
import jsonExport from 'jsonexport/dist';
import { formatSimpleListDate } from '../utils';
import {useHistory, useLocation} from "react-router-dom";
import {useState} from "react";
import {parse, stringify} from "query-string";
import _ from "lodash";

const ReviewInvitationHeaders = {
    id : 'Invite Id',
    client_crm_sid: 'Client CRM Id',
    client_first_name : 'Client First Name',
    client_email : 'Client Email',
    adviser_name : 'Adviser Name',
    review_type : 'Review Type',
    send_at: 'Send At Date',
    created_at: 'Created At Date'
};

export const ReviewInvitationRequestList = props => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isMedium = useMediaQuery('(max-width:1231px)');
    const { export: canExportInviteRequests } = useACL('invite-requests');

    const history = useHistory()
    const location = useLocation();
    const [filter, setFilter] = useState(parse(location.search));

    const [isExporting, setIsExporting] = useState(false);
    const search = history.location.search;
    const params = new URLSearchParams(search);
    const adviserFromUrl = params.get('adviser');

    const exportLoading = () => {
        setIsExporting(true);
    };

    const exporter = reviewInvitations => {
        const reviewInvitationsForExport = reviewInvitations.map(previousInvite => {
            const previousInviteForExport = {
                id : previousInvite.id,
                client_crm_sid: previousInvite.client_crm_sid,
                client_first_name : previousInvite.client_first_name,
                client_email : previousInvite.client_email,
                adviser_name : previousInvite.professional.full_name,
                review_type : (previousInvite.review_type.charAt(0).toUpperCase() + previousInvite.review_type.slice(1)).replace('_', ' '),
                send_at: formatSimpleListDate(previousInvite.send_at),
                created_at: formatSimpleListDate(previousInvite.created_at)
            };

            return previousInviteForExport;
        });

        const buildHeaders = () => {
            let headers = [];
            let rename = [];
            Object.keys(ReviewInvitationHeaders).map(key => {
                headers = [...headers, key];
                rename = [...rename, ReviewInvitationHeaders[key]];
            });
            return { headers, rename };
        };
        jsonExport(reviewInvitationsForExport, buildHeaders(), (err, csv) => {
            downloadCSV(csv, 'review-invitation-requests');
        });
        setIsExporting(false);
    };

    const handleFilterChange = (value) => {
        setFilter({ ...filter, adviser: value });
        history.push({
            pathname: location.pathname,
            search: stringify({ ...filter, adviser: value }),
        });
    };

    const renderXSmallList = () => {
        return (
            <SimpleList
                linkType={false}
                style={{ 'word-break': 'break-all' }}
                primaryText={record => `Client name: ${record.client_first_name}`}
                secondaryText={record => `Client email: ${record.client_email}`}
            />
        );
    };

    const renderSmallList = () => {
        return (
            <SimpleList
                linkType={false}
                primaryText={(record) => `Client name: ${record.client_first_name}`}
                secondaryText={(record) =>
                    (<>
                            Adviser name: {record.professional.full_name}
                            <br/>
                            Client email: {record.client_email}
                        </>
                    )}
            />
        );
    };

    const renderMediumList = () => {
        return (
            <Datagrid>
                <ArgonAdviser label="Adviser name" source="professional.full_name" sortable={false}/>
                <TextField label="Client email" source="client_email" />
                <DateField label="Date email will be sent" source="send_at" showTime={true}/>
            </Datagrid>
        );
    };

    const renderLargeList = () => {
        return (
            <Datagrid>
                <ArgonAdviser label="Adviser name" source="professional.full_name" sortable={false}/>
                <TextField label="Client name" source="client_first_name" />
                <TextField label="Client email" source="client_email" />
                <TextField label="Client ID" source="client_crm_sid" />
                <TextField label="Review Type" source="review_type" />
                <DateField label="Date invite added to queue" source="created_at" showTime={true}/>
                <DateField label="Date email will be sent" source="send_at" showTime={true}/>
            </Datagrid>
        );
    };

    return (
        <>
            <UserHeader
                title="Invite Requests"
                description={``}
            />
            <ListWrapper
            basePath="/invite-requests"
            resource="review-invitation-requests"
            {...props}
            title="Invitation Requests"
            bulkActionButtons={false}
            actions={ canExportInviteRequests ?
                (isExporting ?
                    ( <Box marginRight="1rem">
                        <CircularProgress />
                    </Box>)
                    : <ListActions resource={"review-invitation-requests/csv"} exporter={exporter} exportLoading={exportLoading} />)
                : false
            }
            filters={<ReviewInvitationRequestFilter isXSmall={isXSmall}  onFilterChange={handleFilterChange} adviser={adviserFromUrl}/>}
            sort={{ field: 'send_at', order: 'DESC' }}
            >
            {isXSmall
                ? renderXSmallList()
                : isSmall
                ? renderSmallList()
                : isMedium
                ? renderMediumList()
                : renderLargeList()
            }
            </ListWrapper>
        </>
    );
};

export default ReviewInvitationRequestList;
