import React, {forwardRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import _ from 'lodash';

import componentStyles from '../../assets/theme/components/dropdowns/user-dropdown.js';
import { useLogout } from 'react-admin';
import FirmChangeMenuItem from '../../FirmChangeMenuItem';
import tokenManager from 'tokenManager.js';
import {isNetworkView} from "../../helpers/safeguard";

const useStyles = makeStyles(componentStyles);
const useCustomStyles = makeStyles(theme => ({
    text: {
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
        color: theme.palette.gray['600'],
        paddingTop: '0.25rem',
        paddingBottom: '0.25rem',
        fontSize: '0.9rem',
        textTransform: 'uppercase',
        letterSpacing: '0.1em',
        marginTop: "1rem",
        '&:nth-of-type(1)': {
            marginTop: "0"
        }
    },
    logoutText: {
        color: theme.palette.blue.navyBlue,
    }
    
}));

const LogoutButton = forwardRef((props, ref) => {
    const logout = useLogout();
    const classes = useCustomStyles();
    const handleClick = () => logout();
    return (
        <Box
            display="flex!important"
            alignItems="center!important"
            component={MenuItem}
            onClick={handleClick}
        >
            <Box
                component={DirectionsRun}
                width="1.25rem!important"
                height="1.25rem!important"
                marginRight="0.5rem"
                className={classes.logoutText}
            />
            <Typography className={classes.logoutText} variant="subtitle2">Logout</Typography>
        </Box>
    );
});

const MenuItems = forwardRef(({ firms, firmId, groups, groupId }, ref) => {
    const classes = useCustomStyles();

    const network = isNetworkView();

    return (
        <>
        {!network  && (
            <>
                {!_.isEmpty(firms) && (
                    <>
                        <Typography className={classes.text} variant="h2">
                            Change firm
                        </Typography>
                        {firms.map(firm => {
                            if (firm.id !== firmId) {
                                return (
                                    <FirmChangeMenuItem
                                        key={`firm-${firm.id}`}
                                        firm={firm}
                                        group={groups.find(group => groupId == group.id)}
                                        type="firm"
                                    />
                                );
                            }
                        })}
                    </>
                )}

                {(!_.isEmpty(groups) && groups.length > 1) && (
                    <>
                        <Typography className={classes.text} variant="h2">
                            Change firm group
                        </Typography>
                        {groups.map(group => {
                            if (group.id !== groupId) {
                                return (
                                    <FirmChangeMenuItem
                                        key={`group-${group.id}`}
                                        group={group}
                                        firm={firms.find(firm => firmId == firm.id)}
                                        type="group"
                                    />
                                );
                            }
                        })}
                    </>
                )}
            </>
            )}
        </>
    );
});

const UserDropdown = props => {
    const { allowedFirms, currentFirmId, identity } = props;
    const token = tokenManager.getDecodedToken();
    const selectedGroup = token.selected_subgroup;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const network = isNetworkView();

    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const menuId = 'dropdowns-user-dropdown-id';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItems
                firms={allowedFirms}
                firmId={currentFirmId}
                groups={identity?.firm_subgroups}
                groupId={selectedGroup}
                networkFirms={identity?.networkFirms}
            />

            {!network  && (
                <Divider component="div" classes={{ root: classes.dividerRoot }} />
            )}
            <LogoutButton />
        </Menu>
    );

    return (
        <>
            <Button
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                classes={{
                    label: classes.buttonLabel,
                    root: classes.buttonRoot,
                }}
                data-test={"firm-select-user-menu"}
            >
                <Avatar
                    alt="..."
                    src={identity ? identity.avatar : ''}
                    classes={{
                        root: classes.avatarRoot,
                    }}
                />
                <Hidden mdDown>{identity ? identity.fullName : ''}</Hidden>
            </Button>
            {renderMenu}
        </>
    );
};

export default UserDropdown;
