import React from 'react';
import UserHeader from '../components/Headers/UserHeader';
import { Card, CardContent } from '@material-ui/core';
import ReportButton from './components/ReportButton';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles } from '@material-ui/styles';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

const useStyles = makeStyles(theme => ({
    root: {
        height: '500px',
        display: 'flex',
        columnGap: '20px',
        flexDirection:'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    errorIcon: {
        color: theme.palette.error.main,
    },
    trendingUpIcon: {
        color: theme.palette.primary.main,
    },
}));

const ReportSelectPage = () => {
    const classes = useStyles();
    return (
        <>
            <UserHeader title={'Reports'}/>
            <Card>
                <CardContent className={classes.root}>
                    <ReportButton
                        title={'Potential Risks'}
                        icon={<ErrorIcon className={classes.errorIcon}/>}
                        url={'/report/risk'}
                    />
                    <ReportButton
                        title={'Opportunities'}
                        icon={<TrendingUpIcon className={classes.trendingUpIcon}/>}
                        url={'/report/opportunity'}
                    />
                </CardContent>
            </Card>
        </>
    );
};

export default ReportSelectPage;