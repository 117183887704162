import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import SectionTitle from './components/SectionTitle';
import {Box} from '@material-ui/core';
import firmOverview from '../assets/img/firm_overview.png';

const useStyles = makeStyles(theme => ({
    wrapper: {
        marginTop: '5rem',
        width: '100%',
        height: 'calc(100vh - 15rem - 56px)',
        position: 'relative',
    },
    box: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    root: {
        marginTop: '8rem',
    },
}));

const FirmOverview = () => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <SectionTitle title="Firm Overview" header size="2.75rem"/>
            <Box className={classes.wrapper}>
                <Box className={classes.box}>
                    <img style={{maxHeight: '100%', margin: "auto", maxWidth: "100%"}} src={firmOverview}/>
                </Box>
            </Box>
        </Box>
    );
};

export default FirmOverview;
