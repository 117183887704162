import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import dataProvider from '../dataProvider';
import GoalSelect from './components/GoalSelect';
import {
    DASHBOARD_TYPES,
    FOCUS_TYPES,
    QUERY_ID, SERVICE_TYPE_FILTER_LEGAL, SERVICE_TYPE_FILTER_FA,
} from '../constants/safeguard';
import { useQuery } from 'react-admin';
import SafeguardAnalyticsContent from './components/SafeGuardAnalyticsContent';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { getServiceTypeFilter, getServiceTypeAsString, firmCanSeeFilter } from '../helpers/safeguard';
import SectionTitle from './components/SectionTitle';
import setGoalAction from 'Store/goalsAction';
import { connect } from 'react-redux';
import setServiceTypeAction from 'Store/serviceTypeAction';

const useStyles = makeStyles(theme => ({
    dashboardWrapper: {
        marginTop: 0,
    },
    chartWrapper: {
        marginTop: theme.spacing(4),
    },
    dropdownTitle: {
        marginRight: theme.spacing(2),
    },
    goalWrapper: {
        marginTop: 0,
        minHeight: 400,
    },
    goalDropdown: {
        width: 300,
    },
    dropdown: {
        minWidth: 450,
        borderRadius: '2rem',
    },
    dropdownWrapper: {
        display: 'flex',
        minWidth: 450,
        minHeight: 50,
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        paddingTop: '0.5rem',
        position: 'relative',
        flexWrap: 'wrap',
        '& .MuiFormGroup-root': {
            marginBottom: 0,
        },
    },
    noData: {
        textAlign: 'center',
        padding: '2rem',
        color: theme.palette.error.main,
        background: '#fff',
        borderRadius: '1rem',
    },
}));

const initialState = {
    goal: {},
    currentDashboardId: null,
    query: [],
    focus: null,
    graphs: [],
    serviceType: SERVICE_TYPE_FILTER_FA,
};

const SafeguardAnalytics = props => {
    const { firmIdentity } = props;
    const [goals, setGoals] = useState({});
    const [state, setState] = useState(initialState);
    const [advisers, setAdvisers] = useState([]);
    const history = useHistory();
    const { goal, serviceType, setGoal, setServiceType } = props;

    const { data } = useQuery({
        type: 'getNone',
        resource: 'elevation/drivers',
    });

    const classes = useStyles();

    const handleSelectChange = e => {
        if (e.target.name === DASHBOARD_TYPES.GOALS) {
            let query = [
                { id: QUERY_ID.FIRM_AND_INDUSTRY_AVERAGE, filter: { goal_id: e.target.value.id } },
                { id: QUERY_ID.GOAL_BY_ADVISER, filter: { goal_id: e.target.value.id } },
            ];
            query = getServiceTypeFilter(query, state.serviceType);
            setState({
                ...state,
                focus: null,
                goal: e.target.value,
                graphs: [],
                currentDashboardId: 1,
                query,
            });
            setGoal(e.target.value.id);
        } else if (e.target.name === DASHBOARD_TYPES.FOCUS) {
            setState({
                ...state,
                focus: e.target.value,
                currentDashboardId: e.target.value.id,
            });
        } else if (e.target.name === FOCUS_TYPES.FIRM) {
            let query = [
                { id: QUERY_ID.DRIVER_FIRM_AVERAGE, filter: e.target.value.filter },
                { id: QUERY_ID.DRIVER_ACROSS_ALL_ADVISERS_AT_FIRM, filter: e.target.value.filter },
            ];
            query = getServiceTypeFilter(query, state.serviceType);
            setState({
                ...state,
                currentDashboardId: e.target.value.id,
                graphs: [],
                query,
            });
        } else if (e.target.name === FOCUS_TYPES.ADVISER) {
            let query = [
                { id: QUERY_ID.GOAL_KEY_DRIVER_BY_ADVISER, filter: e.target.value.filter },
            ];
            query = getServiceTypeFilter(query, state.serviceType);
            setState({
                ...state,
                currentDashboardId: e.target.value.id,
                graphs: [],
                query,
            });
        } else {
            setState({ ...state, currentDashboardId: e.target.value.id, query: e.target.value });
        }
    };

    useEffect(() => {
        (async () => {
            if (!firmIdentity?.type) return;
            const cType =
                firmIdentity?.type && !firmCanSeeFilter(firmIdentity.type)
                    ? SERVICE_TYPE_FILTER_LEGAL
                    : serviceType;

            const data = await dataProvider.getNone('elevation/goals');
            let goals = data.data;

            const search = history.location.search;
            const params = new URLSearchParams(search);
            const goalIdFromUrl = params.get('goal');
            if (goalIdFromUrl) {
                setGoal(goalIdFromUrl);
            }
            const selectedGoal = goals.find(goalItem => goalItem.id === parseInt(goal));
            setGoals(goals);
            setState({
                ...state,
                goal: selectedGoal,
                query: [
                    {
                        id: QUERY_ID.FIRM_AND_INDUSTRY_AVERAGE,
                        service_type: cType,
                        filter: {
                            goal_id: selectedGoal.id,
                            service_type: getServiceTypeAsString(cType),
                        },
                    },
                    {
                        id: QUERY_ID.GOAL_BY_ADVISER,
                        service_type: cType,
                        filter: {
                            goal_id: selectedGoal.id,
                            service_type: getServiceTypeAsString(cType),
                        },
                    },
                ],
                currentDashboardId: 1,
                focus: null,
                serviceType: cType,
            });
        })();
    }, [firmIdentity?.type]);

    useEffect(() => {
        if (!state.query.length || state.currentDashboardId == null) return;
        const fetchQuestion = async () => {
            let data = [];
            await Promise.all(
                state.query.map(async (q, i) => {
                    const response = await dataProvider.getOne('elevation/analytics', {
                        id: q.id,
                        filters: q.filter,
                    });
                    data.push({
                        id: q.id,
                        data: response.data,
                    });
                })
            );

            await setState({ ...state, graphs: data });
        };

        fetchQuestion();
    }, [state.query, state.currentDashboardId, state.serviceType, state.goal]);

    useEffect(() => {
        if (_.isEmpty(state.goal)) return;
        const fetchAdvisers = async () => {
            let params = {
                goal_id: state.goal.id,
                minimal_fields: true,
            };
            if (state.serviceType) {
                params['service_type'] = getServiceTypeAsString(state.serviceType);
            }

            const { data } = await dataProvider.getNone('elevation/drivers/advisers', params);
            await setAdvisers(data);
        };
        fetchAdvisers();
    }, [state.goal, state.serviceType]);

    const handleServiceTypeChange = e => {
        const val = e.target.value
        if (
            state.focus &&
            state.focus.type === FOCUS_TYPES.ADVISER &&
            state.query[0].id !== QUERY_ID.GOAL_KEY_DRIVER_BY_ADVISER
        ) {
            return;
        }

        let query = getServiceTypeFilter(state.query, val);
        setState({
            ...state,
            query,
            serviceType: val,
        });
        setServiceType(val);
    };

    return (
        <Box>
            <SectionTitle title={'Compare'} header size="2.75rem" />
            <Box className={classes.dashboardWrapper}>
                <GoalSelect
                    title="Choose a goal"
                    type="goals"
                    onChange={handleSelectChange}
                    classes={classes}
                    goals={goals}
                    selectedGoal={state.goal}
                />
                <SafeguardAnalyticsContent
                    onChange={handleSelectChange}
                    classes={classes}
                    state={state}
                    setState={setState}
                    drivers={data}
                    advisers={advisers}
                    handleServiceTypeChange={handleServiceTypeChange}
                />
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        goal: state.goal,
        serviceType: state.serviceType,
        firmIdentity: state.identity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setGoal: goal => dispatch(setGoalAction(goal)),
        setServiceType: serviceType => dispatch(setServiceTypeAction(serviceType)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SafeguardAnalytics);
