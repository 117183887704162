import React from 'react';
import { required } from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import { validateEmail } from '../../validation';
import ArgonTextInputNoCard from '../../components/Argon/ArgonTextInputNoCard';


const useStyles = makeStyles(
    theme => ({
        formInput: {
            '& > .MuiFormLabel-root': {
                textAlign: 'left',
                textTransform: 'uppercase',
                letterSpacing: '1px',
            },
            width: '100%',
            '& > .MuiOutlinedInput-root': {
                borderRadius: '2rem',
                "&:hover": {
                    border: `1px solid ${theme.palette.secondary.main}`,

                    // Reset on touch devices, it doesn't add specificity
                    "@media (hover: none)": {
                        backgroundColor: "transparent"
                    }
                }
            },
        },

    }));

export const LoginPasswordField = props => {
    const classes = useStyles();

    return (
        <ArgonTextInputNoCard
            {...props}
            source="password"
            label="Password"
            type="password"
            validate={[required()]}
            fullWidth
            className={classes.formInput}
        />
    );
};

export const ConfirmPasswordField = props => {
    const classes = useStyles();

    return (
        <ArgonTextInputNoCard
            {...props}
            source="confirmPassword"
            label="Confirm Password"
            type="password"
            validate={[required()]}
            fullWidth
            className={classes.formInput}
        />
    );
};

export const LoginEmailField = props => {
    const classes = useStyles();

    return (
        <ArgonTextInputNoCard
            {...props}
            source="username"
            type="username"
            label="Email"
            validate={validateEmail}
            fullWidth
            className={classes.formInput}
        />
    );
};