import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

// core components
import componentStyles from "../../../assets/theme/components/headers/auth-header.js";
import { LOGO_WHITE } from "../../../constants";

const useStyles = makeStyles(componentStyles);

const AuthContentWrapper = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <Box
        className={classes.header}
        position="relative"
        paddingTop="5rem"
      >
        <Container maxWidth="xl">
          <img className={classes.logoWrapper} src={LOGO_WHITE} alt="VouchedFor" />
          {children}
        </Container>
        <Box
          position="absolute"
          zIndex="100"
          height="70px"
          top="auto"
          bottom="0"
          pointerEvents="none"
          left="0"
          right="0"
          width="100%"
          overflow="hidden"
          transform="translateZ(0)"
        >
        </Box>
        <Box
          position="absolute"
          zIndex="100"
          height="70px"
          top="auto"
          bottom="0"
          pointerEvents="none"
          left="0"
          right="0"
          width="100%"
          overflow="hidden"
          transform="translateZ(0)"
        >
          <Box
            bottom="0"
            position="absolute"
            pointerEvents="none"
            component="svg"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <Box
              component="polygon"
              fill="#4BC1CA"
              points="2560 0 2560 100 0 100"
            />
          </Box>
        </Box>

      </Box>
    </>
  );
};

AuthContentWrapper.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default AuthContentWrapper;
