import React, { useState } from 'react';
import ListWrapper from '../../components/List/ListWrapper';
import {Datagrid, DateField, TextField, useNotify} from 'react-admin';
import RiskAndOpportunityFilter from './RiskAndOpportunityFilter';
import { formatRange } from '../../utils';
import { Box, CircularProgress } from '@material-ui/core';
import { DownloadCsvFile } from '../../helpers/fileDownload';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import {useACL} from "../../acl/acl";


const getPlural = (type) => {
    if (type === 'risk') {
        return 'risks';
    }
    return 'opportunities';
};

const RiskAndOpportunityTable = (props) => {
    const {
        type,
        handleOptionChange,
        choiceFromUrl,
        dateRange,
        adviserFromUrl,
        firmFromUrl,
    } = props;

    const classes = useStyles();

    const [isExporting, setIsExporting] = useState(false);
    const notify = useNotify();
    const { export: canExport } = useACL('report/' + getPlural(type));

    const showErrorMessage = (message) => {
        notify(message, 'warning')
    }

    const exportComplete = () => {
        setIsExporting(false)
    };

    const startExporting = () => {
        setIsExporting(true);
    }

    const getFileName = (type) => {
        let fileName = '';
        if (type === 'risk') {
            fileName =  'potential_risks';
        } else {
            fileName = 'opportunities'
        }

        if(firmFromUrl) {
            return `${fileName} (${firmFromUrl})`;
        }

        return fileName
    }

    const ShowReviewButton = ({ record }) => {
        const {survey_id, survey_type} = record;
        const type = survey_type === 'review' ? 'reviews' : 'first-impressions';

        return (
            (<Button
                href={`/${type}/${survey_id}/show`}
                color="secondary"
            >
                Read Review
            </Button>)
        );
    };

    const getFilters = () => {
        return <RiskAndOpportunityFilter
            onOptionChange={handleOptionChange}
            choice={choiceFromUrl}
            type={type}
            from={formatRange(dateRange).from}
            to={formatRange(dateRange).to}
            professional={adviserFromUrl}
            firm={firmFromUrl}
        />;
    };

    const getActions = () => {
        return (canExport ? (isExporting ?
                (<Box marginRight="2rem">
                    <CircularProgress/>
                </Box>)
                :
                <Box marginRight="1rem">
                    <DownloadCsvFile
                        resource={'report/' + getPlural(type) + '/csv'}
                        startDownloadEvent={startExporting}
                        finishDownloadEvent={exportComplete}
                        filename={getFileName(type)}
                        showErrorMessage={showErrorMessage}
                    />
                </Box>
            ): false
        )
    }
    return (
        <div className={classes.root}>
            <ListWrapper
                basePath={'/report/' + getPlural(type)}
                resource={'report/' + getPlural(type)}
                bulkActionButtons={false}
                filters={getFilters()}
                filter={{
                    ...({ from: formatRange(dateRange).from }),
                    ...({ to: formatRange(dateRange).to }),
                    ...({ professional: adviserFromUrl }),
                    ...({ firm: firmFromUrl }),
                }}
                actions={getActions()}
                sort={{ field: 'id', order: 'DESC' }}
                {...props}
            >
                <Datagrid>
                    <TextField label={'Adviser'} source={'adviser_name'}/>
                    <TextField label={'Client'} source={'client_name'}/>
                    <TextField label={'Firm'} source={'firm_name'}/>
                    <TextField label={type + ' name'} source={'name'}/>
                    <DateField label={'Date viewed action'} source={'date_viewed_action'}/>
                    <DateField label={'Date Actioned'} source={'date_actioned'}/>
                    <DateField label={'Review Date'} source={'review_date'}/>
                    <ShowReviewButton />
                </Datagrid>
            </ListWrapper>
        </div>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiTableRow-root': {
            height: '61px',
        },
        '& .MuiTableCell-head': {
            lineHeight: '1.4',
        },
    },
}));

export default RiskAndOpportunityTable;
