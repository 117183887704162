import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import _ from 'lodash';
import StarRating from '../../components/StarRating';
import { palette } from '../../assets/theme/theme';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
        margin: 0
    },
    rating: {
        display: 'flex',
        gap: '1.5rem',
        alignItems: 'center',
        padding: `${theme.spacing(1)}px 0`,
    },
    starsWrapper: {
        width: 'auto',
    },
    titleWrapper: {
        width: '50%',
        '& .MuiTypography-root': {
            marginBottom: '2px',
            fontSize: '1.1rem'
        }
    },
}));

const StarRatings = ({ record = {} }) => {
    const averageScore = _.get(record, 'average_score', 0);
    const qualityOfAdvice = _.get(record, 'review_scores.financial_adviser_quality_of_advice', 0);
    const qualityOfService = _.get(record, 'review_scores.financial_adviser_quality_of_service', 0);
    const valueForMoney = _.get(record, 'review_scores.financial_adviser_value_for_money', 0);

    const allRatings = [
        {
            value: averageScore,
            title: <strong>Overall</strong>,
            colour: palette.secondary.dark,
        },
        {
            value: qualityOfAdvice,
            title: 'Advice',
            colour: palette.secondary.light,
        },
        {
            value: qualityOfService,
            title: 'Service',
            colour: palette.secondary.light,
        },
        {
            value: valueForMoney,
            title: 'Value',
            colour: palette.secondary.light,
        },
    ];

    const classes = useStyles();

    return (
        <Box>
            <Card>
                <CardContent className={classes.root}>
                    {record.reviewer_type === 'review' ? (
                        <Box width="100%">
                            {allRatings.map((rating) => {
                                return (
                                    <Box className={classes.rating}>
                                        <Box className={classes.starsWrapper}>
                                            <StarRating
                                                type="survey"
                                                rating={rating.value}
                                                nativeColor={rating.colour}/>
                                        </Box>
                                        <Box className={classes.titleWrapper}>
                                            <Typography variant="body2">{rating.title}</Typography>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    ) : (
                        <Box className={classes.rating}>
                            <Box className={classes.starsWrapper}>
                                <StarRating
                                    type="survey"
                                    rating={averageScore}
                                    nativeColor={palette.secondary.dark}/>
                            </Box>
                            <Typography variant="body2">
                                <strong>Overall</strong>
                            </Typography>
                        </Box>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};

export default StarRatings;
