import { PageTitle } from '../../components/Typography/Headlines';
import { Grid } from '@material-ui/core';
import ArgonTextInput from '../../components/Argon/ArgonTextInput';
import { required } from 'react-admin';
import ArgonDatePicker from '../../components/Argon/ArgonDatePicker';
import { pastDateValidation } from '../../validation';
import React from 'react';

const AwardCreateLayout = () => {
    return (
        <Grid container spacing={2}>
            <Grid item>
                <PageTitle title="Add award" />
            </Grid>
            <Grid item xs={12} lg={12}>
                <ArgonTextInput source="title" label="Award" validate={required()} />
            </Grid>
            <Grid item xs={12} lg={12}>
                <ArgonDatePicker
                    label="Date Received"
                    source="date"
                    validate={[required(), pastDateValidation]}
                />
            </Grid>
        </Grid>
    );
};

export default AwardCreateLayout;
