import FeeLevelCreate from './FeeLevelCreate';
import FeeLevelEdit from './FeeLevelEdit';

const feeLevels = {
    create: FeeLevelCreate,
    edit: FeeLevelEdit,

};

export default feeLevels;
