import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames'
import {Box} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { REVIEW_INVITE_TYPE_TRANSFERRED_CLIENTS } from './FormButtons';
import Button from '../../components/Buttons/CustomButton';

const propTypes = {
    title: PropTypes.string.isRequired,
    examples: PropTypes.array.isRequired,
};

const styles = myTheme => ({
    cardWrapper: {
        borderRadius: 4,
        cursor: 'pointer',
        marginRight: myTheme.spacing(2),
        marginBottom: myTheme.spacing(2),
        minWidth: 250,
        maxWidth: 320,
        padding: '.625rem 1.25rem',
        boxShadow: 'none',
        border: '1px solid',

        [myTheme.breakpoints.down('sm')]: {
            width: '100%',
            minWidth: '100%',
            maxWidth: '100%',
            '& .MuiTypography-root': {
                fontSize: '0.7rem'
            }
        },

        '&:hover': {
            boxShadow: 'none',
            transform: 'none',
            backgroundColor: myTheme.palette.secondary.main
        },
    },
    clientButton: {
        textAlign: 'left',
        '& .MuiSvgIcon-root': {
            fontSize: 40,
        },
    },
    title: {
        color: myTheme.palette.primary.textPrimary,
        marginTop: myTheme.spacing(1),
        marginBottom: myTheme.spacing(1),
        textTransform: 'none',
        letterSpacing: '0.035rem',
    },
    active: {
        marginTop: myTheme.spacing(1),
        marginBottom: myTheme.spacing(1),
        textTransform: 'none',
        letterSpacing: '0.035rem',
    },
    examples: {
        padding: 0,
        marginLeft: myTheme.spacing(1),
    },
    buttonContent: {
        display: 'flex',
        height: 145,
    },
    buttonIcon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: myTheme.spacing(1),
    },
});

const FormButtonBase = props => {
    const { title, classes, onClick, value, buttonValue, description = null } = props;
    const isActive = value === buttonValue;

    return (
            <Button
                className={
                    buttonValue === 'review'
                    || buttonValue === 'first_impression'
                    || buttonValue === REVIEW_INVITE_TYPE_TRANSFERRED_CLIENTS
                        ? cn(classes.cardWrapper, classes.clientButton)
                        : classes.cardWrapper
                }
                variant={isActive ? 'contained' : 'outlined'}
                color={isActive ? 'secondary' : 'default'}
                onClick={onClick}
                data-test={"invite-button-" + value}
            >
                <Box className={classes.buttonContent}>
                    <Box className={classes.buttonIcon}>
                        <PersonAddIcon/>
                    </Box>
                    <Box >
                        <Typography
                            className={
                                isActive
                                    ? classes.active
                                    : classes.title
                            }
                            variant="body1">
                            <b>{title}</b>
                        </Typography>
                        <Typography
                            variant="caption"
                            className={
                                isActive
                                    ? classes.active
                                    : classes.title
                            }
                        >{description}</Typography>
                    </Box>
                </Box>
            </Button>
    );
};

FormButtonBase.propTypes = propTypes;

export default withStyles(styles)(FormButtonBase);
