import * as React from 'react'
import {Route} from 'react-router-dom'
import ReputationTools from './ReputationTools/ReputationTools'
import SafeguardAnalytics from "./SafeGuard/SafeguardAnalytics"
import ConsumerDutyReport from "./SafeGuard/ConsumerDutyReport"
import SafeguardDashboard from "./SafeGuard/SafeguardDashboard"
import FeeEdit from "./Fees/FeeEdit";
import AwardAndAccreditationsShow from "./Awards/AwardAndAccreditationsShow";
import DisclaimerEdit from "./FirmDetails/DisclaimerEdit";
import ProfilePage from "./Profile/ProfilePage";
import { RouteWithFeatureFlag, RouteWithPermission } from './acl/acl';
import ReviewList from './Reviews/ReviewList';
import FirstImpressionList from './Reviews/FirstImpressions/FirstImpressionList';
import RegistrationCompletionPage from './Auth/RegistrationCompletionPage';
import ForgotPasswordPage from './Auth/ForgotPasswordPage';
import ResetPasswordPage from './Auth/ResetPasswordPage';
import AdviserDashboard from './Advisers/Dashboard/AdviserDashboard'
import ClientAnswers from './SafeGuard/ClientAnswers'
import ReviewInvitationList from './ReviewInvitations/ReviewInvitationList';
import Insights from "./SafeGuard/Insights";
import ReviewInvitationRequestList from "./ReviewInvitationRequests/ReviewInvitationRequestList";
import ReportSelectPage from './Reports/ReportSelectPage';
import ReportRisk from './Reports/ReportRisk';
import ReportOpportunity from './Reports/ReportOpportunity';
import FirmOverview from "./SafeGuard/FirmOverview";
import FirmDetailed from "./SafeGuard/FirmDetailed";
import ReviewDataList from './ReviewData/ReviewDataList';
import Performance from './SafeGuard/Performance';

const generateRoutes = () => {
    let routes = [
        <RouteWithPermission exact path="/tools" component={ReputationTools} resource='tools' permission='download'/>,
        <Route exact path="/profile" component={ProfilePage}/>,
        <Route exact path="/registration-completion" component={RegistrationCompletionPage} noLayout/>,
        <Route exact path="/forgot-password" component={ForgotPasswordPage} noLayout/>,
        <Route exact path="/reset-password" component={ResetPasswordPage} noLayout/>,
        <Route exact path="/reset-password/:token" component={ResetPasswordPage} noLayout/>,
        <RouteWithPermission exact path="/fees-edit" component={FeeEdit} resource='fees' permission='edit'/>,
        <RouteWithPermission exact path="/awards" component={AwardAndAccreditationsShow} resource='firm-awards' permission='edit'/>,
        <RouteWithPermission exact path="/disclaimer" component={DisclaimerEdit} resource='firm' permission='edit'/>,
        <RouteWithPermission exact path="/reviews" component={ReviewList} resource="reviews" permission='list' />,
        <RouteWithPermission exact path="/review-data" component={ReviewDataList} resource="review-data" permission='list' />,
        <RouteWithPermission exact path="/first-impressions" component={FirstImpressionList} resource="first-impressions" permission='list' />,
        <RouteWithPermission exact path="/invite-history" component={ReviewInvitationList} resource="review-invitations" permission='list' />,
        <RouteWithPermission exact path="/elevation-analytics" component={SafeguardAnalytics} resource="elevation" permission='enabled' />,
        <RouteWithPermission exact path="/performance-over-time" component={Performance} resource="elevation" permission='enabled' />,
        <RouteWithPermission exact path="/performance-adviser-over-time" component={Performance} resource="elevation" permission='enabled' />,
        <RouteWithPermission exact path="/performance-firm-over-time" component={Performance} resource="elevation" permission='enabled' />,
        <RouteWithPermission exact path="/performance-adviser" component={Performance} resource="elevation" permission='enabled' />,
        <RouteWithPermission exact path="/performance-firm" component={Performance} resource="elevation" permission='enabled' />,

        <RouteWithPermission exact path="/elevation/firm" component={SafeguardDashboard} resource="elevation" permission='enabled' />,
        <RouteWithPermission exact path="/adviser-dashboard/:professional" component={AdviserDashboard} resource="elevation" permission='enabled' />,
        <RouteWithPermission exact path="/client-answers" component={ClientAnswers} resource="elevation" permission='enabled' />,
        <RouteWithPermission exact path="/insights" component={Insights} resource="insights" permission='enabled' />,
        <RouteWithPermission exact path="/firm-overview" component={FirmOverview} resource="insights" permission='enabled' />,
        <RouteWithPermission exact path="/firm-detailed" component={FirmDetailed} resource="insights" permission='enabled' />,
        <RouteWithPermission exact path="/invite-requests" component={ReviewInvitationRequestList} resource="invite-requests" permission='list' />,
        // <RouteWithPermission exact path="/consumer-duty-report" component={ConsumerDutyReport} resource="elevation" permission='enabled' />,
        <RouteWithFeatureFlag exact path="/report" component={ReportSelectPage} resource="elevation" permission='enabled' featureFlag={'reports'}/>,
        <RouteWithFeatureFlag exact path="/report/risk" component={ReportRisk} resource="elevation" permission='enabled' featureFlag={'reports'}/>,
        <RouteWithFeatureFlag exact path="/report/opportunity" component={ReportOpportunity} resource="elevation" permission='enabled' featureFlag={'reports'}/>,
    ]

    return routes
};

const customRoutes = generateRoutes();

export default customRoutes;
