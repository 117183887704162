import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import API_KEYS from '../../constants/api-keys';
import SectionTitle from './SectionTitle';

const FREE_FA_CDR_REPORT = 'free_fa';
const FREE_MA_CDR_REPORT = 'free_ma';

const DownloadCDRReports = ({ firmId }) => {
    const classes = useStyles();

    const getPdfLink = (type) => {
        const token = localStorage.getItem('token');
        return `${API_KEYS.cdr_url}/report/pdf/${type}/${firmId}?lexiktoken=${token}`;
    };

    return (
        <>
            <div className={classes.container}>
                <SectionTitle title="Consumer Duty Reports" header size="1.75rem" />
                <Typography className={classes.subtitle} variant="body2">Powered by your Elevation data. This has been designed to help spot the gaps in your Consumer Duty plan, benchmark your firm vs. the industry and evidence your compliance to the regulator.</Typography>
                <div className={classes.buttonGroup}>

                    <div>
                        <Button
                            variant='contained'
                            color='secondary'
                            href={getPdfLink(FREE_FA_CDR_REPORT)}
                            target="_blank"
                            className={classes.button}
                        >
                            <span className={classes.buttonText}>Financial Advice Report </span>
                        </Button>
                    </div>
                    <div>
                        <Button
                            variant='contained'
                            color='secondary'
                            href={getPdfLink(FREE_MA_CDR_REPORT)}
                            target="_blank"
                            className={classes.button}
                        >
                            <span className={classes.buttonText}>Mortgage Advice Report</span>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
        ;
};

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'block',
        padding: '20px',
        marginBottom: '20px',
        paddingLeft: '0px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: -40,
            marginRight: -40,
            paddingLeft: 40,
            paddingRight: 40,
            background: theme.palette.secondary.dark
        },
    },
    buttonGroup: {
      marginTop: '20px',
    },
    button: {
        margin: '5px',
        marginLeft: '0px',
        width: '300px',
    },
    buttonText: {
        fontWeight: 'normal',
    },
    subtitle: {
        color: theme.palette.white.main
    }
}));

export default DownloadCDRReports;
