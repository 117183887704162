import React, {
    createElement,
    useRef,
    useEffect,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useCheckAuth } from 'ra-core';
import MyTheme from '../../../MyTheme';

const useStyles = makeStyles(
    () => ({
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
        },
    }),
    { name: 'RaLogin' }
);

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider.login()` method. Redirects to the root page (/)
 * upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */
const LoginPageWrapper = props => {
    const {
        theme,
        title,
        classes: classesOverride,
        className,
        children,
        notification,
        staticContext,
        ...rest
    } = props;
    const containerRef = useRef();
    const classes = useStyles(props);
    const muiTheme = useMemo(() => createMuiTheme(MyTheme), [theme]);
    const checkAuth = useCheckAuth();
    const history = useHistory();
    useEffect(() => {
        checkAuth({}, false)
            .then(() => {
                // already authenticated, redirect to the home page
                history.push('/');
            })
            .catch(() => {
                // not authenticated, stay on the login page
            });
    }, [checkAuth, history]);

    return (
        <ThemeProvider theme={muiTheme}>
            <div
                className={classnames(classes.main, className)}
                {...rest}
                ref={containerRef}
            >
                {children}
                {notification ? createElement(notification) : null}
            </div>
        </ThemeProvider>
    );
};

LoginPageWrapper.propTypes = {
    backgroundImage: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    theme: PropTypes.object,
    staticContext: PropTypes.object,
};

export default LoginPageWrapper;
