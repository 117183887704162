import React from 'react';
import { NumberInput } from 'react-admin';
import useOutlinedStyle from './useOutlinedStyle';

const FormNumberInput = ({ className, ...rest }) => {
    return (
        <NumberInput
            {...rest}
            className={useOutlinedStyle(className)}
            variant="outlined"
            margin="none"
        />
    );
};

export default FormNumberInput;
