import {
    Datagrid,
    TextField,
    ReferenceManyField,
    Pagination,
    DeleteButton,
    BooleanInput,
} from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
import {
    Button, DialogContent, DialogActions,
    Dialog, Typography,
} from '@material-ui/core';
import BulkInviteRecordEdit from '../BulkInviteRecordEdit';
import React, { useState } from 'react';
import IconCancel from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import { Alert } from '@material-ui/lab';
import StepContentWrapper from "./StepContentWrapper";
import { connect } from 'react-redux';
import { showClientIdField } from '../../helpers/helperFunctions';

const useStyles = makeStyles((theme) => ({
    invalid: {
        color: theme.palette.error.main,
    },
    editButton: {
        display: 'flex',
        clear: 'both',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
        width: 'fit-content',
    },
    titleFont: {
        fontSize: '20px',
    },
}));


export const WarningMessage = ({ warnings }) => {
    const items = warnings.split('","')

    return (
    <Alert icon={<></>} style={{whiteSpace:'pre-wrap'}} severity="error">
        {items.map(w => (
            <div dangerouslySetInnerHTML={{__html:  w.replaceAll('"', '')}}
            data-test={'error-message'}>
            </div>
        ))}
    </Alert>
    )
};

const WarningField = ({record}) => {
    if(!record.warning) {
        return null;
    }
    const warnings = record.warning.split(".");
    return (
        warnings.map(warning => (
            <Typography variant="body2"> {warning}</Typography>
        ))
    )
}

const AdviserField = (props) => {
    const classes = useStyles();
    return <TextField label="Adviser" source="professional.full_name" className={props.record.status === 'invalid' && classes.invalid} />
}

const ClientField = (props) => {
    const classes = useStyles();
    return <TextField label="Client first name" source="client_name" className={props.record.status === 'invalid' && classes.invalid} />
}

const EmailField = (props) => {
    const classes = useStyles();
    return <TextField label="Client email" source="client_email" className={props.record.status === 'invalid' && classes.invalid} />
}

const EditBulkInviteRecordButton = (props) => {
    const classes = useStyles();

    const [showDialog, setShowDialog] = useState(false)
    if (!showDialog) {
        return (
            <Button
                color="primary"
                className={classes.editButton}
                onClick={() => setShowDialog(true)}
            >
                <EditIcon style={{ fontSize: 20 }} />
            </Button>
        )
    }

    const handleCloseClick = () => setShowDialog(false)

    return (
        <Dialog
            fullWidth
            open={showDialog}
            onBackdropClick={handleCloseClick}
        >
            <DialogActions>
                <Button
                    label="ra.action.cancel"
                    onClick={handleCloseClick}
                >
                    <IconCancel />
                </Button>
            </DialogActions>
            <DialogContent>
                <BulkInviteRecordEdit {...props} />
            </DialogContent>
        </Dialog>
    )
}

const Step4 = (props) => {
    const inviteType = props.record?.invite_type;
    const crmLookup = props.crmLookup;

    const validWarning = (value) => {
        if (!value) {
            return 'Please accept the warning or change the file.'
        }
        return undefined;
    }

    return (
            <StepContentWrapper title={'Step 4) Confirm the details are correct'}>
            {props.record?.error_message_html && props.record?.next_step === 4 && <WarningMessage warnings = {props.record.error_message_html} /> }
            {props.record.invalid_emails ? <Alert severity="error">You have invalid fields in your list. Please correct them before continuing to the next step</Alert> : null}
            {
                props.record?.invite_records_count ? (
                <>
                    <ReferenceManyField
                        perPage={50}
                        pagination={<Pagination rowsPerPageOptions={[10, 25, 50]}/>}
                        label={null} reference="bulk-invite-records" target="bulkInvite" {...props}>
                        <>
                            <Datagrid>
                                <AdviserField label="Adviser"/>
                                <ClientField label="Client first name"/>
                                <EmailField label="Client email"/>
                                {
                                    showClientIdField(inviteType, props.crmLookup) &&
                                    <TextField label="Client ID" source="crm_customer_code"/>
                                }
                                <WarningField label="Warnings" data-test={"warning"}/>
                                <EditBulkInviteRecordButton
                                    showDialog={true}
                                    bulkInvite={props.record.id}
                                    inviteType={inviteType}
                                    crmLookup={crmLookup}
                                />
                                <DeleteButton
                                    redirect={false} label={''}/>
                            </Datagrid>
                        </>
                    </ReferenceManyField>
                    {props.record?.error_message_html &&
                    < BooleanInput
                        label = {<span>I understand there are warnings. I would like to proceed. </span>}
                        defaultValue={false}
                        source = "accepted_warnings"
                        validate ={validWarning}
                        data-test={"checkbox-accepted_warnings"}
                    />
                    }
                </>
                ) : (<Typography variant="body1"> No invites left to process </Typography>)
            }

            </StepContentWrapper>
    )
}

const mapStateToProps = state => {
    return {
        crmLookup: state.identity?.crmLookup,
    };
};


export default connect(mapStateToProps)(Step4);