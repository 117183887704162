import React, { useState, useEffect, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import dataProvider from 'dataProvider';
import {
    Datagrid,
    DateField,
    TextField,
    Button,
    Responsive,
    SimpleList,
    FunctionField,
} from 'react-admin';
import { Box } from '@material-ui/core';
import ListWrapper from '../../components/List/ListWrapper';
import componentStyles from '../../assets/theme/components/cards/cards/card-button';
import { ArgonSelectInput } from 'components/Argon/ArgonSelectInput';
import _ from 'lodash';
import { getServiceTypeAsString } from '../../helpers/safeguard';
import {SERVICE_TYPE_FILTER_FA} from '../../constants/safeguard';
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(componentStyles);
const useStylesCustom = makeStyles(theme => ({
    select: {
        padding: '1rem',
        paddingBottom: 0,
        display: 'flex',
        '& .MuiFormGroup-root': {
            marginLeft: '1rem',
        },
    },
    list: {
        marginTop: '1rem',
        marginBottom: '10rem',
    },

    viewButton: {
        width: 210,
    }
}));

const GoalAnswersTable = forwardRef((props, ref) => {
    const classes = { ...useStyles(), ...useStylesCustom() };
    const [filters, setFilters] = useState([]);

    const [customLabel, setCustomLabel] = useState('Answer(s)');
    const [serviceType, setServiceType] = useState(props.serviceType ? props.serviceType : SERVICE_TYPE_FILTER_FA);

    const history = useHistory();
    const search = history.location.search;
    const params = new URLSearchParams(search);
    const [selectedFilter, setSelectedFilters] = useState(params.get('answer') ? params.get('answer'): 0 );
    const [filterExtra, setFilterExtra] = useState({
        source: 'choice_id',
        defaultValue: 0,
        name: "Filter by answer"
    });

    useEffect(async () => {
        const filterData = await dataProvider.getNone('elevation/answers/answer-choices', {
            driver_id: props.driver?.id,
            goal_id: props.goal?.id,
        });
        if (filterData.data) {
            setFilters(filterData.data.choices);
            setFilterExtra(filterData.data.extra);
            setSelectedFilters(filterData.data.extra.defaultValue);
            if (params.get('answer')) {
                filterData.data.choices.map((choice) =>{
                    if (choice.id == params.get('answer')) {
                        setSelectedFilters(params.get('answer'))
                    }
                })
            }
        }
        if (props.goalAnswersTableScrollInView) {
            props.goalAnswersTableScrollInView();
        }

        setCustomLabel('Answer(s)');

    }, [props.goal, props.driver]);

    useEffect(() => {
        if (props.serviceType) setServiceType(props.serviceType);
    }, [props.serviceType])

    const getFilters = () => {
        const listFilters = {
            driver_id: props.driver?.id,
            goal_id: props.goal?.id,
            ...(serviceType && { service_type: getServiceTypeAsString(serviceType) }),
            ...(props.professional?.filter?.professional_id && {
                professional_id: props.professional.filter.professional_id,
            }),
        };

        return listFilters;
    };

    const selectChangeHandler = e => {
        setSelectedFilters(e.target.value);
        params.set('answer', e.target.value);
        history.push({ search: params.toString() });
    };

    const CustomButton = ({ record }) => {
        if (record) {
            const { review_type, review_id } = record;
            const type = review_type === 'first_impression' ? '/first-impressions/' : '/reviews/';
            const label =
                review_type === 'first_impression' ? 'SEE FIRST IMPRESSION' : 'SEE FULL REVIEW';
            const buttonHref = type + review_id + '/show';

            return <Button className={classes.viewButton} label={label} href={buttonHref} />;
        }
        return null;
    };

    const CustomFilters = () => {
        return (
            <Box className={classes.select}>
                <ArgonSelectInput
                    onChange={selectChangeHandler}
                    defaultValue={selectedFilter}
                    value={selectedFilter}
                    source={filterExtra.source}
                    data={filters}
                    dataLabelName="text"
                    defaultItem={filterExtra.name}
                    selectElementOnly={true}
                    noForm={true}
                    enableDefaultItem={true}
                    defaultItemValue={filterExtra.defaultValue}
                    data-test={"filter-answer"}
                />
            </Box>
        );
    };

    const GetCustomLabel = ({ record }) => {
        if (
            !props.showProfessional &&
            record &&
            record.label_template &&
            customLabel !== record.label_template
        ) {
            setCustomLabel(record.label_template);
        }
        return null;
    };

    return (
        <Box className={classes.list} ref={ref}>
            <ListWrapper
                title={props.driver ? props.driver.goal_id : props.goal.id}
                exporter={false}
                actions={<CustomFilters />}
                basePath="/elevation/answers"
                resource="elevation/answers"
                filterDefaultValues={getFilters()}
                filter={{
                    ...(filterExtra.source == 'choice_id' && { choice_id: selectedFilter }),
                    ...(filterExtra.source == 'risk' && selectedFilter !== 2 && { risk: selectedFilter }),
                }}
                safeguard={props.safeguard}
                sort={{field:'review_date', order: 'DESC'}}
            >
                <Box className={classes.cardRoot}>
                    <Responsive
                        small={
                            <SimpleList
                                primaryText={record =>
                                    props.showProfessional
                                        ? record.professional_name
                                        : record.customer_name
                                }
                                secondaryText={record => record.answer}
                            />
                        }
                        medium={
                            <Datagrid>
                                <DateField label="Review date" source="review_date" />
                                {props.showProfessional && (
                                    <TextField label="Adviser" source="professional_name" />
                                )}
                                <TextField label="Client" source="customer_name" />
                                {filterExtra.source == 'choice_id' && (
                                    <TextField label="Question" source="label_template" />
                                )}
                                {filterExtra.source == 'choice_id' && (
                                    <TextField label={customLabel} source="answer" />
                                )}
                                {filterExtra.source == 'risk' && (
                                    <FunctionField
                                        label="Does this review show markers of risk?"
                                        render={record => (parseInt(record.risk) ? 'No' : 'Yes')}
                                    />
                                )}
                                <GetCustomLabel />
                                <CustomButton />
                            </Datagrid>
                        }
                    />
                </Box>
            </ListWrapper>
        </Box>
    );
});

export default GoalAnswersTable;
