import * as React from 'react';
import { BooleanInput, required } from 'react-admin';
import FirmLogoImage from '../components/FirmLogoImage';
import ArgonTextInput from '../components/Argon/ArgonTextInput';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';

const ProfileEditInputs = (props) => {
    const record = _.get(props, 'record', {});
    const aboutSectionHelperText = "In order for your firm profile to appear in google search results, your about section must have at least 50 words";

    return (
        <>
            <Grid item xs={12} lg={4}>
                <Grid xs={12} lg={12}>
                    <ArgonTextInput source="name" label="Name" defaultValue={record.name}/>

                </Grid>
                <Box display="flex" justifyContent="center" width="100%">
                    <FirmLogoImage source="file" isAdmin={true} record={record} />
                </Box>
            </Grid>
            <Grid item xs={12} lg={8}>
                <ArgonTextInput
                    multiline
                    source="about"
                    defaultValue={record.about}
                    label="About"
                    validate={required()}
                    helperText={aboutSectionHelperText}
                />
            </Grid>
        </>
    );
};

export default ProfileEditInputs;
