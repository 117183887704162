import React from 'react';
import {Create, SimpleForm} from 'react-admin';
import FeeCreateFormContent from "./Pages/Create/FeeCreateFormContent";

const FeeCreate = props => (
        <Create undoable={false} {...props}>
            <SimpleForm redirect="/fees">
                <FeeCreateFormContent />
            </SimpleForm>
        </Create>
    );

export default FeeCreate;
