import boxShadows from "../../assets/theme/box-shadow.js";
import hexToRgb from "../../assets/theme/hex-to-rgb.js";
import themeColors from "../../assets/theme/colors";

const componentStyles = (theme) => ({
    cardRoot: {
        backgroundColor: theme.palette.white.main,
        boxShadow: boxShadows.boxShadow + "!important",
        border: "0!important",
        borderBottom:
            "1px solid rgba(" + hexToRgb(themeColors.black.main) + ",.05)",
        "&:first-child": {
            borderRadius: "calc(.375rem - 1px) calc(.375rem - 1px) 0 0",
        },
    },
    cardRootTranslucent: {
        backgroundColor: theme.palette.transparent.main,
    },
    cardRootStripped: {
        "& tbody tr:nth-child(odd)": {
            backgroundColor: "rgba(" + hexToRgb(theme.palette.gray[100]) + ", 0.3)",
        },
    },
    cardRootCheckboxColors: {
        "& tbody tr:nth-child(1)": {
            backgroundColor: theme.palette.success.tableLight,
            "&:hover": {
                backgroundColor: theme.palette.success.tableLightHover,
            },
        },
        "& tbody tr:nth-child(3)": {
            backgroundColor: theme.palette.warning.tableLight,
            "&:hover": {
                backgroundColor: theme.palette.warning.tableLightHover,
            },
        },
    },
    cardRootDark: {
        backgroundColor: theme.palette.dark.main,
        "& *": {
            color: theme.palette.white.main,
        },
        "& $tableCellRoot, & $tableRoot": {
            color: theme.palette.white.main,
            borderColor: theme.palette.dark.tableBorder,
        },
        "& $tableCellRootHead": {
            color: theme.palette.dark.tableHeadColor,
            backgroundColor: theme.palette.dark.tableHeadBgColor,
        },
    },
    cardHeader: {
        backgroundColor: "initial",
    },
    cardActionsRoot: {
        paddingBottom: "1.5rem!important",
        paddingTop: "1.5rem!important",
        borderTop: "0!important",
    },
    containerRoot: {
        [theme.breakpoints.up("md")]: {
            paddingLeft: "30px",
            paddingRight: "30px",
        },
    },
    tableRoot: {
        marginBottom: "0!important",
    },
    tableCellRoot: {
        verticalAlign: "middle",
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
        borderTop: "0",
    },
    tableCellRootHead: {
        backgroundColor: theme.palette.gray[100],
        color: theme.palette.gray[600],
    },
    tableCellRootBodyHead: {
        textTransform: "unset!important",
        fontSize: ".8125rem",
    },
    linearProgressRoot: {
        height: "3px!important",
        width: "120px!important",
        margin: "0!important",
    },
    bgGradientError: {
        background:
            "linear-gradient(87deg," +
            theme.palette.error.main +
            ",#f56036)!important",
    },
    bgGradientSuccess: {
        background:
            "linear-gradient(87deg," +
            theme.palette.success.main +
            ",#2dcecc)!important",
    },
    bgGradientPrimary: {
        background:
            "linear-gradient(87deg," +
            theme.palette.primary.main +
            ",#825ee4)!important",
    },
    bgGradientInfo: {
        background:
            "linear-gradient(87deg," +
            theme.palette.info.main +
            ",#1171ef)!important",
    },
    bgGradientWarning: {
        background:
            "linear-gradient(87deg," +
            theme.palette.warning.main +
            ",#fbb140)!important",
    },
    bgPrimary: {
        backgroundColor: theme.palette.primary.main,
    },
    bgPrimaryLight: {
        backgroundColor: theme.palette.primary.light,
    },
    bgError: {
        backgroundColor: theme.palette.error.main,
    },
    bgErrorLight: {
        backgroundColor: theme.palette.error.light,
    },
    bgWarning: {
        backgroundColor: theme.palette.warning.main,
    },
    bgWarningLight: {
        backgroundColor: theme.palette.warning.light,
    },
    bgInfo: {
        backgroundColor: theme.palette.info.main,
    },
    bgInfoLight: {
        backgroundColor: theme.palette.info.light,
    },
    bgSuccess: {
        backgroundColor: theme.palette.success.main,
    },
    verticalAlignMiddle: {
        verticalAlign: "middle",
    },
    avatarRoot: {
        width: "36px",
        height: "36px",
        fontSize: ".875rem",
    },
    anchorStyles: {
        fontWeight: "600",
    },
    buttonRootWhite: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.white.main,
        borderColor: theme.palette.white.main,
        "&:hover": {
            backgroundColor: theme.palette.white.main,
            borderColor: theme.palette.white.main,
            color: theme.palette.dark.main,
        },
    },
    gridItemRoot: {
        flexBasis: 0,
        flexGrow: 1,
        maxWidth: "100%",
    },
    listItemRoot: {
        paddingLeft: 0,
        paddingRight: 0,
        "&:not(:last-child)": {
            borderBottom: "1px solid " + theme.palette.gray[200],
        },
        height: 90,
    },

});

export default componentStyles;
