import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    privateQuestions: {
        marginTop: 0,
        marginBottom: 0,
        [theme.breakpoints.down('xs')]: {
            marginTop: "16px",
            marginBottom: "16px",
            '& .MuiTypography-root': {
                marginTop: "0px",
                marginBottom: "0px",
            }
        }
    },
    private: {
        flex: "48%",
        [theme.breakpoints.down('sm')]: {
            flex: "100%",
        }
    }
}));

const SingleQuestion = ({ question, surveyType, isPrivate }) => {
    const classes = useStyles()

    return (
        <div className={isPrivate ? classes.private : null}>
            <b>{surveyType === 'review' ? question.label_template : question.question}</b>
            {Array.isArray(question.answer) ? (
                <ul>
                    {question.answer.map((answer, index) => (
                        <li key={index}><Typography>{answer.value}</Typography></li>
                    ))}
                </ul>
            ) : (
                <Typography>{question.answer}</Typography>
            )}
            <br />
        </div>
    );
};

export default SingleQuestion;