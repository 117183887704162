import React from 'react';
import { CardContent, FormGroup, Typography } from '@material-ui/core';
import { useInput } from 'react-admin';
import Select from '@material-ui/core/Select';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import { AutocompleteInput } from 'react-admin';
import componentStyles from '../../assets/theme/components/cards/cards/card-button';
import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import themeColors from '../../assets/theme/colors';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(componentStyles);
const useStylesCustom = makeStyles((theme) => ({
    selectRoot: {
        '& .MuiSelect-select': {
            color: theme.palette.secondary.dark,
            borderRadius: '2rem'
        }
    },
    selectMenu: {
        '& .MuiListItem-root': {
            color: theme.palette.secondary.dark
        }
    }
}));

const useStylesAutocomplete = makeStyles(() => ({
    label: {
        color: "#525f7f",
        display: 'inline-block',
        padding: 0,
        fontSize: '.875rem',
        fontWeight: 600,
        lineHeight: 1,
        marginBottom: '0.25rem',
        fontFamily: 'Nunito,-apple-system,BlinkMacSystemFont,"Segoe UI",Arial,sans-serif'
    },
    input: {
        '& input': {
            paddingTop: "6px"
        }
    }
}))

const useStylesAutocompleteCustom = makeStyles((theme) => ({
    cardContent :{
        margin:0,
        padding:0,
        position:'relative',
        top: -12,
        color: 'red',
        '& .MuiChip-root' :{
            color: theme.palette.secondary.dark,
            fontSize: 14,
        },
        '& .MuiInputLabel-root[data-shrink="true"]' :{
            color: 'black',
        },
        '& .MuiInputLabel-root' :{
            color: theme.palette.secondary.dark,
            fontWeight: '300',
            fontSize: 14,
        },
        '& .MuiInputBase-input' :{
            backgroundColor: themeColors.gray[100],
        }
    },
    dropDownText: {
        fontSize: 14,
        color: theme.palette.secondary.dark,
    },
}));

// custom props
// selectElementOnly = if passed and true the select input is renderd without a card wrapper
// noForm = if passed and is true the input can be used outside a form (ex. as a filter)
// onChange = custom change handler, needs to be passed if noForm is true
// data = the data for the select options
// dataKeyName = default 'id', pass this if the option value is other than id, pass 'all' if the option value is the whole item
// dataLabelName = default 'title', pass this if the option label is other than title
// defaultItem = string for the unselected first element, omit if not necesarry
// enableDefaultItem = the default first element is selectable
// defaultItemValue = value of default item, 0 if not set

export const ArgonSelectInput = (props) => {
    const classes = useStyles();

    let { noForm, selectElementOnly, ...rest } = props;
    const renderSelect = () => {
        if (noForm) {
            return (<VFSelectCustom {...rest} />)
        } else {
            return (<VFSelectDefault {...rest} />)
        }
    }

    return (
        <>
            {selectElementOnly ?
                <FormGroup>
                    {renderSelect()}
                </FormGroup>
                :
                <CardContent className={classes.cardRoot}>
                    <FormGroup>
                        {renderSelect()}
                    </FormGroup>
                </CardContent>
            }
        </>
    );
};

const VFSelectInput = (props) => {

    const classes = useStylesCustom()

    let { data, dataLabelName, dataKeyName, defaultItem, name, onChange, touched, error, enableDefaultItem, rest, ...remaining } = props;

    if (!dataLabelName) dataLabelName = 'title'
    if (!dataKeyName) dataKeyName = 'id'

    const propsToPassDown = rest ? rest : remaining

    return (
        <Select
            name={name}
            onChange={onChange}
            error={!!(touched && error)}
            helperText={touched && error}
            variant="filled"
            {...propsToPassDown}
            isRequired
            MenuProps={{
                className: classes.selectMenu
            }}
            className={`${classes.selectRoot} ${remaining.className}`}
            IconComponent={KeyboardArrowDown}
        >
            {defaultItem &&
                <MenuItem value={ propsToPassDown.defaultItemValue ? propsToPassDown.defaultItemValue : "0"} disabled={!enableDefaultItem} key={Math.random()}>
                    {defaultItem}
                </MenuItem>
            }
            {data.map((item,index) => (
                <MenuItem data-test-option={index} key={dataKeyName !== "all" ? item[dataKeyName] + Math.random() : item[dataLabelName] + Math.random()} value={dataKeyName !== "all" ? item[dataKeyName] : item}>{item[dataLabelName]}</MenuItem>
            ))}
        </Select>
    )
}

const VFSelectCustom = (props) => {
    return (
        <VFSelectInput {...props} />
    )
}

const VFSelectDefault = (props) => {
    let {
        input: { name, onChange, ...rest },
        meta: { touched, error },
    } = useInput(props);

    return (
        <VFSelectInput {...props} name={name} onChange={onChange} rest={rest} touched={touched} error={error} />
    )
}



export const ArgonSelectInputWithAutoComplete = (props) => {
    const {
        input: { name, label, ...rest },
        meta: { touched, error },
    } = useInput(props);

    const { data } = props;
    const classes = useStylesAutocomplete();

    return (
        <CardContent className={classes.cardRoot}>
            <FormGroup>
                <label className={classes.label}>{props.label}</label>
                <AutocompleteInput
                    label={false}
                    source={name}
                    fullWidth={true}
                    choices={data}
                    optionText={data.name}
                    optionValue={data.id}
                    className={classes.input}
                />
            </FormGroup>
        </CardContent>
    );
};

export const ArgonSelectInputWithAutoCompleteCustom = (props) => {
    const { data, onChange, defaultValue ,label, multiple} = props;
    const classes = useStylesAutocompleteCustom();

    return (
        <CardContent  className={classes.cardContent}>
            <Autocomplete
                onChange={onChange}
                size="small"
                multiple = {multiple}
                options={data}
                disableClearable={true}
                disableCloseOnSelect={false}
                defaultValue={defaultValue}
                getOptionLabel={(data) => data.name}
                renderOption={(option, { selected }) => (
                    <div className={classes.dropDownText}>
                        <Checkbox
                            icon={icon}
                            size='small'
                            checkedIcon={checkedIcon}
                            checked={selected}
                        />
                        {option.name}
                    </div>
                )}
                style={{ width: 490 }}
                renderInput={(params) => (
                    <TextField {...params} variant='outlined' label={label} />
                )}
            />
        </CardContent>
    );
};