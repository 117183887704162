import React, { useEffect, useState } from 'react';
import StackedBarChart from './components/NewComponents/StackedBarChart';
import dataProvider from '../dataProvider';
import { formatRange } from '../utils';
import Loading from './components/NewComponents/Loading';
import Empty from './components/NewComponents/Empty';

const PerformanceByAdviserOrFirm = (props) => {

    const { questionId,
        answers,
        setAnswers,
        getAnswersFromData,
        range,
        formatDataByPercentage,
        dataValueType,
        groupByAdviserOnNetworkView,
        serviceType } = props

    const [formattedData, setFormattedData] = useState({
        count: [],
        percentage: [],
    })
    const [loading, setLoading] = useState(false)
    const [empty, setEmpty] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        setLoading(true)
        setError(false)
        fetchData().then((res) => setLoading(false)).catch(err =>setLoading(false))
    }, [range.to, range.from, serviceType, questionId]);

    const formatData = (dataToFormat) => {
        let formatted = [];

        dataToFormat.map(item => {
            let index = formatted.findIndex(d => d.id === item.id);
            if (index !== -1 && formatted[index][item.question_choice_gka] === undefined) {
                formatted[index] = {
                    ...formatted[index],
                    [item.question_choice_gka]: item.answer_frequency,
                    total: formatted[index].total + item.answer_frequency
                };
            } else if (index === -1) {
                formatted = [
                    ...formatted,
                    {
                        id: item.id,
                        name: item.name,
                        [item.question_choice_gka]: item.answer_frequency,
                        total: item.answer_frequency
                    }];
            }
            return true
        })
        return formatted.sort((a, b) => b.total - a.total);
    }

    const fetchData =  async () => {
        if (!questionId) return Promise.reject()

        try {
            const { data } = await dataProvider.getNone("performance/performance-by-adviser-firm",
                {
                    questionId,
                    ...(serviceType && { serviceTypeId: serviceType.type }),
                    ...(range.to && range.from && { ...formatRange(range) }),
                    ...(groupByAdviserOnNetworkView && {group_by_adviser_on_network_view : true})
                }
            )
            if (!data || !data.length) {
                setEmpty(true)
                return Promise.reject()
            }
            setEmpty(false)
            const formattedAnswers = getAnswersFromData(data);
            setAnswers([...formattedAnswers])

            const formattedDataByFrequency = formatData(data);
            const formattedDataByPercentage = formatDataByPercentage([...formattedDataByFrequency], formattedAnswers)
            setFormattedData({
                count: [ ...formattedDataByFrequency ],
                percentage: [ ...formattedDataByPercentage ],
            })
            return Promise.resolve()
        } catch (err) {
            setError(true)
            return Promise.reject()
        }
    }

    if (!questionId) return null
    if (loading) return <Loading />
    if (empty) return <Empty icon={"chart"} text={"There are no results for the selected filters"} />
    if (error) return <Empty icon={"warning"} text={"An unexpected error occurred"} />


    return (
        <StackedBarChart dataKeyX={"name"} chartType={dataValueType} data={formattedData[dataValueType]} bars={answers} id={questionId} />
    );
};

export default PerformanceByAdviserOrFirm;

