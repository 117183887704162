const permissions = {
    ROLE_VIEW_ADVISER: {
        "professionals": {
            list: true,
        },
        "professionals/performance": {
            list: true,
        },
        "professionals/settings": {
            list: true,
        },
    },

    ROLE_EDIT_ADVISER: {
        "professionals": {
            edit: true,
        },
        "professionals/settings": {
            edit: true,
        },
        "qualifications": {
            create: true,
        },
        "fees": {
            list: true,
            create: true,
            edit: true,
        },
        "fee-level": {
            create: true,
            edit: true,
        },
        "firm": {
            edit: true,
        },
    },

    ROLE_VIEW_FIRM: {
        "firm": {
            list: true,
            view: true,
        },
        "professionals": {
            list: true,
        },
        "professionals/performance": {
            list: true,
        },
        "professionals/settings": {
            list: true,
        },
        "reviews": {
            list: true,
            show: true,
        },
        "review-data": {
            list: true,
            show: true,
        },
        "first-impressions": {
            list: true,
            show: true,
        },
        "invite-requests": {
            list: true,
        },
        "tools": {
            download: true,
        }
    },

    ROLE_EDIT_FIRM: {
        "firm": {
            list: true,
            view: true,
            edit: true,
        },
        "reviews": {
            show: true,
            edit: true,
        },
        "review-data": {
            show: true,
            edit: true,
        },
        "firm-awards": {
            create: true,
            edit: true,
        },
        "firm-accreditations": {
            create: true,
            edit: true,
        }
    },

    ROLE_BULK_INVITER: {
        'bulk-invites': {
            view: true,
            create: true,
            edit: true,
        },
        "review-invitations": {
            list: true,
        },
    },
    ROLE_INSIGHTS_VIEWER: {
        'insights': {
            enabled: true,
        }
    },

    ROLE_ELEVATION: {
        'elevation': {
            enabled: true,
        }
    },

    ROLE_EXPORTER: {
        "professionals": {
            export: true,
        },
        "professionals/performance": {
            export: true,
        },
        "reviews": {
            export: true,
        },
        "review-data": {
            export: true,
        },
        "first-impressions": {
            export: true,
        },
        "review-invitations": {
            export: true,
        },
        "report/opportunities": {
            export: true,
        },
        "report/risks": {
            export: true,
        },
        "report": {
            export: true,
        },
        "invite-requests": {
            export: true,
        },
    },
}

export default permissions;
