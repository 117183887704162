import React from "react";

const VfStarOutlined = ({ fill, width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 143.7 140"
      className={`vfStar ${className}`}
    >
      <g></g>
      <g id="_x3C_Layer_x3E_">
        <g>
          <path
            d="M51.9,2c0.4,0,1.7,0.1,2.3,1.7l10.7,27.3c0.9,2.4,3.1,4,5.6,4.2l28.8,1.8c1.3,0.1,2,1,2.3,1.8
       c0.1,0.4,0.4,1.7-0.8,2.7L78.4,60c-1.9,1.5-2.9,3.9-2.4,6.3l0,0.1l0,0.1l7.3,28.2c0.2,1.1-0.2,1.9-0.5,2.3c-0.5,0.6-1.2,1-1.9,1
       c-0.5,0-0.9-0.1-1.3-0.4L55.2,81.7c-1-0.7-2.2-1.1-3.5-1.1c-1.3,0-2.5,0.4-3.5,1.1L23.9,97.5c-0.4,0.3-0.9,0.4-1.4,0.4
       c-0.8,0-1.5-0.4-2-1c-0.3-0.4-0.7-1.1-0.4-2.2l7.3-28.3c0.7-2.3-0.2-5-2.2-6.7L3,41.4c-1.3-1.1-0.9-2.5-0.8-2.9
       c0.1-0.4,0.7-1.6,2.2-1.7L33.3,35c2.5-0.1,4.6-1.7,5.6-4.2L49.6,3.6C50.2,2.1,51.5,2,51.9,2 M51.9,0c-1.7,0-3.5,1-4.2,2.9
       L37.1,30.1c-0.7,1.7-2.2,2.8-3.9,2.9L4.3,34.8c-4.1,0.2-5.8,5.4-2.6,8.1L24,61.3c1.3,1.1,2,3,1.5,4.6l-7.3,28.3
       c-0.8,3.1,1.6,5.7,4.4,5.7c0.8,0,1.6-0.2,2.4-0.7l24.3-15.8c0.8-0.5,1.6-0.8,2.4-0.8c0.8,0,1.7,0.2,2.4,0.8l24.4,15.8
       c0.8,0.5,1.6,0.7,2.4,0.7c2.7,0,5.1-2.6,4.4-5.7L78,66c-0.3-1.6,0.3-3.3,1.7-4.4L102.1,43c3.2-2.6,1.5-7.8-2.6-8.1l-28.8-1.8
       c-1.7-0.1-3.3-1.2-3.9-2.9L56.1,2.9C55.3,0.9,53.6,0,51.9,0L51.9,0z"
            fill={fill}
          />
        </g>
      </g>
      <g id="Layer_4"></g>
    </svg>
  );
};

export default VfStarOutlined;
