import * as React from 'react';
import { Typography } from '@material-ui/core';
import { useLogin, useNotify, useSafeSetState, SimpleForm } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import { LoginEmailField, LoginPasswordField } from "../../../components/Inputs/LoginFields";
import FormToolbar from "../../../components/FormToolbar";
import AuthCardComponent from '../AuthCardComponent';
import {useHistory, useLocation} from "react-router-dom";

const styles = theme => ({
    card: {
        margin: `${theme.spacing(5)}px 0`,
        width: 464,
        padding: 0,
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: `0 ${theme.spacing(2)}px`,
        },
    },
    cardContent: {
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(3),
        },

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            paddingTop: 0,
        },
    },
    title: {

        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(2),
        },
    },
    form: {
        '& > div': {
            padding: 0,
        },

        '& > .MuiToolbar-root': {
            position: 'relative',
            padding: 0,
            '& > .MuiButton-contained': {
                width: '100%',
                backgroundColor: theme.palette.tertiary.main,
                borderRadius: '2rem',
                "&:hover": {
                    backgroundColor: theme.palette.tertiary.dark,
                    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
                    "@media (hover: none)": {
                        backgroundColor: theme.palette.tertiary.main
                    }
                },

                '& > .MuiButton-label': {
                    ' & > .MuiSvgIcon-root': {
                        display: 'none',
                    },

                    textTransform: 'uppercase',
                    letterSpacing: '1px',
                },
                '&.Mui-disabled': {
                    opacity: 0.85,
                    color: 'white',
                },
            },
        },
    },
    anchor: {
        textDecoration: 'none',
        color: theme.palette.tertiary.main,
    },
    formToolbar: {
        marginTop: 0,
        backgroundColor: 'inherit'
    },
});

const LoginForm = props => {
    const { classes, redirectTo } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const login = useLogin();
    const notify = useNotify();
    const searchParams = new URLSearchParams(window.location.search);
    let previousUrl = '/';
    if (searchParams.get('redirect')) {
        const paramValue = JSON.parse(searchParams.get('redirect'));
        previousUrl = decodeURIComponent(paramValue);
    }

    const submit = values => {
        setLoading(true);
        login(values, previousUrl)
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                            ? 'ra.auth.sign_in_error'
                            : error.message,
                    'warning',
                    {
                        _:
                            typeof error === 'string'
                                ? error
                                : error && error.message
                                    ? error.message
                                    : undefined,
                    }
                );
            });
    };

    return (
        <AuthCardComponent title="Login to your VouchedFor Firm account">
            <SimpleForm
                className={classes.form}
                save={submit}
                saving={loading}
                toolbar={<FormToolbar label="Log In" className={classes.formToolbar} />}
            >
                <LoginEmailField />
                <LoginPasswordField />
            </SimpleForm>
            <Typography variant="body1">
                Forgotten your password? {' '}
                <a href='/forgot-password' className={classes.anchor}>Click here</a>
            </Typography>
        </AuthCardComponent>

    );
};

export default withStyles(styles)(LoginForm);
