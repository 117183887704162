import * as React from 'react';
import {
    HomeOutlined,
    PersonOutlined,
    RateReviewOutlined,
    StarOutline,
    BarChart,
    Settings,
    SearchOutlined,
    ListAlt,
} from '@material-ui/icons';
import {Box, Typography, useTheme} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CreateIcon from '@material-ui/icons/Create';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import PieChartOutlined from '@material-ui/icons/PieChartOutlined';
import HistoryIcon from '@material-ui/icons/History';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const displayIcon = (text, theme) => {
    switch (true) {
        case text === 'Home':
            return <HomeOutlined style={{ fill: theme.palette.blue.navyBlue }} />;
        case text === 'Advisers':
            return <PersonOutlined style={{ fill: theme.palette.blue.navyBlue }} fontSize="medium" />;
        case text === 'Reviews':
            return <RateReviewOutlined style={{ fill: theme.palette.blue.navyBlue }} fontSize="medium" />;
        case text === 'First Impressions':
            return <RateReviewOutlined style={{ fill: theme.palette.blue.navyBlue }} fontSize="medium" />;
        case text === 'Invite History':
            return <HistoryIcon style={{ fill:theme.palette.blue.navyBlue }} fontSize="medium" />;
        case text === 'Invite Requests':
            return <HistoryIcon style={{ fill:theme.palette.blue.navyBlue }} fontSize="medium" />;
        case text === 'Invite Tool':
            return <CreateIcon style={{ fill: theme.palette.blue.navyBlue }} fontSize="medium" />;
        case text === 'Reputation Tools':
            return <StarOutline style={{ fill: theme.palette.blue.navyBlue }} fontSize="medium" />;
        case text === 'Firm Profile Settings':
            return <Settings style={{ fill: theme.palette.blue.navyBlue }} fontSize="medium" />;
        case text === 'SafeGuard':
            return <FavoriteBorderOutlinedIcon style={{ fill: theme.palette.blue.navyBlue }} fontSize="medium" />;
        case text === 'Firm Dashboard':
            return <PieChartOutlined style={{ fill: theme.palette.blue.navyBlue }} />;
        case text === 'Network Dashboard':
            return <PieChartOutlined style={{ fill: theme.palette.blue.navyBlue }} />;
        case text === 'Compare':
            return <BarChart style={{ fill: theme.palette.blue.navyBlue }} fontSize="medium" />;
        case text === 'Client Answers':
            return <PersonOutlined style={{ fill: theme.palette.blue.navyBlue }} fontSize="medium" />;
        case text === 'Consumer Duty Report':
            return <CloudDownloadIcon style={{ fill: theme.palette.blue.navyBlue }} fontSize="medium" />;
        case text === 'Insights':
            return <SearchOutlined style={{ fill: theme.palette.blue.navyBlue }} fontSize="medium" />;
        case text === 'Reports':
            return <ListAlt style={{ fill: theme.palette.blue.navyBlue }} fontSize="medium" />;
        case text === 'Potential Risks':
            return <ListAlt style={{ fill: theme.palette.blue.navyBlue }} fontSize="medium" />;
        case text === 'Opportunities':
        case text === 'Firm Overview':
        case text === 'Firm Detailed':
            return <ListAlt style={{ fill: theme.palette.blue.navyBlue }} fontSize="medium" />;

        default:
            return '';
    }
};

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    text: {
        marginLeft: theme.spacing(2),
        color: theme.palette.blue.navyBlue,
    },
}));

const MenuItemLinkText = props => {
    const classes = useStyles();
    const { text } = props;
    const theme = useTheme();

    return (
        <Box className={classes.container}>
            {displayIcon(text, theme)}
            <Typography data-test= {"menu-" + text} className={classes.text}>{text}</Typography>
        </Box>
    );
};

export default MenuItemLinkText;
