import React from "react";

const VfStar = ({ fill, width, height, className}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 43.296 42.099"
      className={`vfStar ${className}`}
    >
      <path
        d="M225.52,19.761l7.182-5.982a1.459,1.459,0,0,0-.841-2.584l-9.249-.567a1.452,1.452,0,0,1-1.261-.92l-3.4-8.779a1.444,1.444,0,0,0-2.7,0l-3.4,8.744a1.453,1.453,0,0,1-1.261.92l-9.284.567a1.459,1.459,0,0,0-.841,2.584l7.182,5.947a1.5,1.5,0,0,1,.491,1.487l-2.348,9.1a1.447,1.447,0,0,0,2.172,1.593l7.813-5.062a1.362,1.362,0,0,1,1.541,0l7.848,5.062a1.447,1.447,0,0,0,2.172-1.593l-2.348-9.062A1.462,1.462,0,0,1,225.52,19.761Z"
        transform="translate(-199.939 0)"
        fill={fill}
      />
    </svg>
  );
};

export default VfStar;
