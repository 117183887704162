import React from "react";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

// core components
import CardStats from "../../components/Cards/Dashboard/CardStats.js";

import componentStyles from "../../assets/theme/components/headers/stats-header.js";

const useStyles = makeStyles(componentStyles);

const useStylesCustom = makeStyles((theme) => ({
    title: {
        color: theme.palette.secondary.dark,
        marginBottom: 0
    },
    subtitle: {
        color: theme.palette.secondary.dark
    },
    stat: {
        color: theme.palette.primary.main
    }
}));

const contentData = {
    google_profile_visits: {
        title: 'Googlers',
        text: 'People who googled your advisers’ names and clicked onto their profiles'
    },
    direct_profile_visits: {
        title: 'Clickers',
        text: 'People who clicked a link to your advisers’ profiles (i.e. from an email signature)'
    },
    referrers: {
        title: 'Referrers',
        text: 'People who are more likely to refer you, having seen your reviews on VouchedFor'
    },
    direct_enquiries: {
        title: 'Direct Enquiries',
        text: 'The number of direct enquiries you’ve received through VouchedFor'
    },
    potential_enquiries: {
        title: 'Potential Enquiries',
        text: 'People who spend 1+ minutes on an advisers’ profile, then left for another site'
    },
}

const StatsContent = (props) => {

    const classes = { ...useStyles(), ...useStylesCustom() };
    const theme = useTheme();

    const { data } = props

    if (!data) return null

    return (
        <Container
            maxWidth={false}
            component={Box}
            classes={{ root: classes.containerRoot }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h1" component="h1" className={classes.title}>
                        See the impact your reviews are having
                    </Typography>
                </Grid>
                {Object.keys(contentData).map((key) => (
                    <Grid key={key} item xl={3} lg={6} xs={12} >
                        <CardStats
                            statColor={theme.palette.primary.main}
                            subtitle={contentData[key].title}
                            title={data[key] ? data[key] : 0}
                            icon={null}
                            color="bgSuccess"
                            footer={
                                <>
                                    <Box component="span" fontFamily="Nunito">
                                        {contentData[key].text}
                                    </Box>
                                </>
                            }
                        />
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}

export default StatsContent;