import API_KEYS from '../constants/api-keys';

const getDataProviderApiUrl = (resource, params = null) => {
    switch (true) {
        case resource.startsWith('elevation') :
            return API_KEYS.elevation_url + '/' + resource.replace('elevation/', '');
    }

    if (resource.includes('get-records')) {
        return API_KEYS.api_firm_url + '/bulk-invites' + '/' + resource;
    }

    if (resource.includes('qualifications')) {
        return API_KEYS.api_firm_url + '/' + resource;
    }

    switch (resource) {
        case 'login':
            return API_KEYS.user_manager_login_url + '/' + resource;
        case 'professionals/settings':
        case 'professionals/performance':
        case 'professionals':
            return API_KEYS.api_firm_url + '/' + resource;
        case 'follow-up':
            return API_KEYS.api_firm_url + '/' + resource;
        case 'auth/token/refresh':
            return API_KEYS.token_refresh_url + '/' + resource;
        case 'auth/user/switch':
        case 'reset-password':
            return API_KEYS.firm_switch_url + '/' + resource;
        case 'fees':
        case 'fees/choices':
            return API_KEYS.fees_url + '/' + resource;
        case 'fee-level':
            return API_KEYS.fees_url + '/' + resource;
        case 'bulk-invites':
        case 'bulk-invite-records':
        case 'review-invitations':
        case 'review-invitations/csv':
        case 'review-invitation-requests':
        case 'review-invitation-requests/csv':
        case 'first-impressions':
        case 'statistics':
        case 'widget/stars':
        case 'widget/reviews':
        case 'reviews':
        case 'review-data':
        case 'review-data/csv':
        case 'questions':
        case 'answer-choices':
        case 'professionals/professionals-for-filter':
        case 'widget':
        case 'analytics':
        case 'reviews/csv':
        case 'first-impressions/csv':
        case 'review-invitations/toggle-send-reminder':
        case 'action':
        case 'report':
        case 'report/risks':
        case 'report/opportunities':
        case 'report/risk-choices':
        case 'report/opportunity-choices':
        case 'performance/performance-over-time':
        case 'performance/performance-by-adviser-firm':
        case 'questions':
        case 'professionals/professionals-for-filter':
            return API_KEYS.api_firm_url + '/' + resource;

        case 'template':
        case 'test-email':
            return API_KEYS.api_firm_url + '/bulk-invites' + '/' + resource;
        case 'firm':
            return API_KEYS.firm_url + '/' + resource;
        case 'profile':
            return API_KEYS.firm_url + '/firm/' + resource;
        case 'get-user-by-token':
            return API_KEYS.user_manager + '/' + resource;
        case 'confirm-registration':
            return API_KEYS.user_manager + '/confirm-registration';
        case 'reset-password/reset':
            return API_KEYS.user_manager + '/reset-password/reset';
        default:
            return API_KEYS.api_url + '/' + resource;
    }
};

export default getDataProviderApiUrl;
