import React from 'react';
import FeesTieredButtonsField from '../../components/FeesTieredButtonsField';
import FormTextInput from "../../../components/Inputs/Form/FormTextInput";
import FormNumberInput from "../../../components/Inputs/Form/FormNumberInput";
import InputPrefix from "../../../components/Inputs/InputPrefix";
import TrueFalseInput from "../../../components/Inputs/TrueFalseInput";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core";
import PageSectionTitle from "../../../ReputationTools/Tools/components/PageSectionTitle";

const FeeEditFormContent = props => {
    const useStyles = makeStyles((theme) => ({
        longTextInput: {
            '& textarea': {
                minHeight: theme.spacing(15),
            },
        },
    }));

    const data = props.record

    const classes = useStyles()

    return (
        <>
            {data.mortgage && <>
                <PageSectionTitle title="Mortgage Fees" />
                <FormTextInput
                    multiline
                    label="Mortgage fee notes"
                    className={classes.longTextInput}
                    source="mortgage.notes"
                />
                <FormNumberInput
                    label="Minimum mortgage fee"
                    source="mortgage.minimumFee"
                    InputProps={{
                        startAdornment: (
                            <InputPrefix label="£"/>
                        ),
                    }}
                />
            </>}
            {data.investment_and_pension_with_financial_planning && <>
                <PageSectionTitle title="Financial Planning Fees" />
                {data.investment_and_pension_with_financial_planning.subType === 'initial-and-ongoing' && (
                    <FeesTieredButtonsField
                        source="investment_and_pension_with_financial_planning.isWealthBased"
                        label="Please choose which way you tier your financial planning charges"
                    />
                )}

                <FormTextInput
                    multiline
                    label="Notes"
                    source="investment_and_pension_with_financial_planning.notes"
                    className={classes.longTextInput}
                />
                <FormNumberInput
                    label="Minimum financial planning fee"
                    source="investment_and_pension_with_financial_planning.minimumFee"
                    InputProps={{
                        startAdornment: (
                            <InputPrefix label="£" />
                        ),
                    }}
                />

                {data.investment_and_pension_with_financial_planning.subType === 'hourly-charge' && (
                    <FormNumberInput
                        source="investment_and_pension_with_financial_planning.amount"
                        label="Your average hourly fee amount"
                        InputProps={{
                            startAdornment: (
                                <InputPrefix label="£" />
                            ),
                        }}
                    />
                )}

                <div style={{ marginTop: '24px' }}>
                    <TrueFalseInput
                        source="investment_and_pension_with_financial_planning.offsetAgainstInitialFee"
                        label="Financial planning fee can be offset against initial fee for implementation"
                    />
                </div>
                {data.investment_and_pension_with_financial_planning.offsetAgainstInitialFee && (
                    <Typography
                        variant="body1"
                        color="error"
                    >
                        Please note that as your fees can be
                        offset, your fee report and insights
                        won’t quite work yet. You can still
                        add your fees to your profile and
                        display them to prospective clients!
                    </Typography>
                )}
            </>}

            {data.investment_and_pension && <>

                <div>
                    <PageSectionTitle
                        title={data.investment_and_pension_with_financial_planning
                            ? 'Implementation & Ongoing Service Fees'
                            : 'Implementation & Ongoing Service (without Financial Planning) Fees'}
                    />
                    <FormTextInput
                        style={{ display: 'none' }}
                        source="investment_and_pension.id"
                    />
                    <FormTextInput
                        style={{ display: 'none' }}
                        source="investment_and_pension.subType"
                    />
                    {data.investment_and_pension.subType === 'initial-and-ongoing' && (
                        <FeesTieredButtonsField
                            source="investment_and_pension.isWealthBased"
                            label='How do your tier your implementation & ongoing service charges?'
                        />
                    )}
                    <div>
                        {!data.investment_and_pension && [
                            <FormTextInput
                                multiline
                                className={
                                    classes.longTextInput
                                }
                                label="Notes"
                                source="investment_and_pension.notes"
                            />,
                            <FormNumberInput
                                label='Minimum implementation & ongoing service fee'
                                source="investment_and_pension.minimumFee"
                                InputProps={{
                                    startAdornment: (
                                        <InputPrefix label="£" />
                                    ),
                                }}
                            />,
                        ]}
                    </div>
                    <div>
                        {data.investment_and_pension.subType === 'hourly-charge' && (
                            <FormNumberInput
                                source="investment_and_pension.amount"
                                label="Your average hourly fee amount"
                                InputProps={{
                                    startAdornment: (
                                        <InputPrefix label="£" />
                                    ),
                                }}
                            />
                        )}
                    </div>
                </div>
            </>}
        </>
    );
}

export default FeeEditFormContent;
