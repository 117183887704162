import React from 'react';
import { Box } from '@material-ui/core';
import { FOCUS_TYPES } from '../../constants/safeguard';
import AdviserPerformance from './AdviserPerformance';
import FirmPerformance from './FirmPerformance';

const SelectFocus = props => {
    const { classes, graphs, focusType, state, onChange, advisers, drivers } = props;

    return (
        <Box>
            {focusType.type === FOCUS_TYPES.ADVISER ? (
                <AdviserPerformance
                    classes={classes}
                    advisers={advisers}
                    onChange={onChange}
                    drivers={drivers}
                    graphs={graphs}
                    goal={state.goal}
                    serviceType={state.serviceType}
                />
            ) : (
                <FirmPerformance
                    classes={classes}
                    onChange={onChange}
                    graphs={graphs}
                    goal={state.goal}
                    drivers={drivers}
                    serviceType={state.serviceType}
                />
            )}
        </Box>
    );
};

export default SelectFocus;
