import {Resource, usePermissions, NotFound} from "react-admin";
import permissions from "./permissions";
import _ from 'lodash';
import {Route} from "react-router-dom";
import * as React from "react";
import {useSelector} from 'react-redux';
import { hasApplicationFeature } from '../utils';

const ResourceWithPermission = (props) => {
    const { name, list, create, edit, show, options } = props;
    const showVerifiedSidebar = useSelector( async ({identity}) => identity?.showVerifiedSidebar)
    const resource = options?.resource;
    const resourceToAccess = resource ?? name;
    const access = useACL(resourceToAccess);

    if (resourceToAccess === 'reviews') {
        access.edit = showVerifiedSidebar
    }

    return (
        <Resource
            {...props}
            name={name}
            list={access.list ? list : undefined}
            create={access.create ? create : undefined}
            edit={access.edit ? edit : undefined}
            show={access.show ? show : undefined}
        />
    );
}

export default ResourceWithPermission;

export const RouteWithPermission = ({path, component, permission, resource }) => {
    const access = useACL(resource);

    if (!access || access[permission] === undefined) {
        return null;
    }

    return access[permission] === true
        ? <Route exact path={path} component={component}/>
        : <Route exact path={path} component={NotFound}/>;
}

export const RouteWithFeatureFlag = (props) => {
    return hasApplicationFeature(props?.featureFlag)
        ? <RouteWithPermission {...props} />
        : <Route exact path={props?.path} component={NotFound}/>;
}

export const useACL = (resourceName) => {
    const { permissions } = usePermissions();
    return {
        enabled: hasAccess(permissions, resourceName, 'enabled'),
        list: hasAccess(permissions, resourceName, 'list'),
        create: hasAccess(permissions, resourceName, 'create'),
        edit: hasAccess(permissions, resourceName, 'edit'),
        show: hasAccess(permissions, resourceName, 'show'),
        export: hasAccess(permissions, resourceName, 'export'),
        download: hasAccess(permissions, resourceName, 'download'),
    };
}

export const hasAccess = (roles, resource, permission) => {
    let permitted = false;
    if (roles) {
        JSON.parse(roles).forEach(role => {
            permitted = (_.get(permissions, [role, resource, permission], false)) ? true : permitted;
        });
        return permitted;
    }
};
