import React from 'react';
import {
    DeleteButton,
    Responsive,
    SimpleList,
    Datagrid,
    TextField,
    EditButton,
    DateField,
    ReferenceManyField,
} from 'react-admin';
import { formatSimpleListDate } from '../../utils';
import AwardAndAccreditationGridBody from '../../components/AwardAndAccreditationGridBody';

const profileUrl = '/profile';

const AwardGrid = (props) => {
    return (
        <ReferenceManyField label={null} reference="firm-awards" target="firm_id" {...props}>
            <Responsive
                small={
                    <SimpleList
                        primaryText={(record) => record.title}
                        secondaryText={(record) =>
                            `Date received: ${formatSimpleListDate(record.date)}`
                        }
                    />
                }
                medium={
                    <Datagrid size="medium" body={<AwardAndAccreditationGridBody />}>
                        <TextField width="80%" label="Name" source="title" />
                        <DateField width="10%" label="Date received" source="date" />
                        <EditButton width="5%" label={false} />
                        <DeleteButton width="5%" redirect={profileUrl} label={false} />
                    </Datagrid>
                }
            />
        </ReferenceManyField>
    );
};

export default AwardGrid;
