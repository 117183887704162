import React, { useState } from 'react';
import { XAxis, YAxis, Tooltip, Legend, Bar, ComposedChart, ResponsiveContainer } from 'recharts';
import {Box, makeStyles, Card, CardContent, useTheme} from '@material-ui/core';
import componentStyles from '../../assets/theme/components/headers/stats-header.js';
import boxShadows from '../../assets/theme/box-shadow.js';
import ChartXAxisLabel from './components/ChartXAxisLabel';
import { DataFormatter } from './components/YAxisLabel';
import { BarChartTooltip } from './components/BarChartTooltip';
import { isDataSafeToDisplayInCharts } from '../../helpers/safeguard';

const useStyles = makeStyles(componentStyles);

export const CustomBarChart = props => {
    const { data, displaySettings, double, triple } = props;
    const safeToDisplay = isDataSafeToDisplayInCharts(data.length);

    const classes = useStyles();
    const [opacity, setOpacity] = useState({ bar1: 1, bar2: 1, bar3: 1 });
    const theme = useTheme();

    // FORMATTING DATA
    let barData = data;
    barData = barData.map(o => {
        return {
            xAxis: o[displaySettings.xAxis],
            bar1: Math.round(o[displaySettings.bar1]
            ),
            bar2: double || triple
                ? Math.round(o[displaySettings.bar2])
                : null,
            bar3: triple
                ? Math.round(o[displaySettings.bar3])
                : null,
            reviews: o[displaySettings.reviews],
        };
    });

    const fullXAxis = barData.length < 10;

    const hoverLegend = e => {
        let newOpacity = { ...opacity };
        Object.keys(newOpacity).forEach(key => {
            if (key !== e.dataKey) newOpacity[key] = 0.2;
        });
        setOpacity(newOpacity);
    };
    const leaveLegend = e => {
        let newOpacity = { ...opacity };
        Object.keys(newOpacity).forEach(key => {
            newOpacity[key] = 1;
        });
        setOpacity(newOpacity);
    };

    return (
        <Card
            classes={{ root: classes.cardRoot }}
            elevation={0}
            component={Box}
            boxShadow={boxShadows.boxShadow}
            data-test={"custom-chart-bar"}
        >
            <CardContent classes={{ root: classes.cardContentRoot }}>
                <div className={classes.chartTitle}>{displaySettings.title}</div>
                <div className={classes.chartDescription}>{displaySettings.description}</div>
                <ResponsiveContainer width="99%" height={450} className={classes.chart}>
                    <ComposedChart
                        data={barData}
                        barCategoryGap={0}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        style={{ textAlign: 'center' }}>
                        <YAxis
                            type="number"
                            yAxisId={0}
                            domain={[0, 100]}
                            ticks={[0, 50, 100]}
                            tickFormatter={DataFormatter}
                            axisLine={false}
                            tickLine={false}
                            label={{
                                value: displaySettings.yLabel,
                                angle: -90,
                                position: 'insideLeft',
                            }}
                        />

                        {fullXAxis ? (
                            <XAxis
                                dataKey="xAxis"
                                height={150}
                                interval={0}
                                tick={props => <ChartXAxisLabel safeToDisplay={safeToDisplay} {...props} />}
                            />
                        ) : (
                            <XAxis tick={false} tickLine={false} />
                        )}
                        {(double || triple) && (
                            <Legend
                                verticalAlign="top"
                                height={36}
                                onMouseEnter={hoverLegend}
                                onMouseLeave={leaveLegend}
                                wrapperStyle={{ cursor: 'pointer' }}
                            />
                        )}
                        <Tooltip content={<BarChartTooltip {...props}  />} />
                        <Bar
                            dataKey="bar1"
                            fill={theme.palette.secondary.btnActive}
                            name={displaySettings.bar1Label}
                            barSize={double || triple ? 50 : 100}
                            style={{ textAlign: 'center', cursor: 'pointer' }}
                            radius={[8, 8, 0, 0]}
                            opacity={opacity.bar1}
                        />
                        {(double || triple) && (
                            <Bar
                                dataKey="bar2"
                                fill={theme.palette.tertiary.main}
                                name={displaySettings.bar2Label}
                                barSize={50}
                                style={{ textAlign: 'center', cursor: 'pointer' }}
                                radius={[8, 8, 0, 0]}
                                opacity={opacity.bar2}
                            />
                        )}
                        {triple && (
                            <Bar
                                dataKey="bar3"
                                fill={theme.palette.primary.main}
                                name={displaySettings.bar3Label}
                                barSize={50}
                                style={{ textAlign: 'center', cursor: 'pointer' }}
                                radius={[8, 8, 0, 0]}
                                opacity={opacity.bar3}
                            />
                        )}
                    </ComposedChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};
