import React, { Component } from 'react';
import _ from 'lodash';
import { ImageInput } from 'react-admin';
import FirmLogo from './FirmLogo';
import { MAX_FILE_SIZE_TWO_MB } from '../constants/values'
import { withStyles } from '@material-ui/core';
import ImagePreviewCrop from "./Inputs/ImagePreviewCrop";

const styles = theme => ({
    imageInput: {
        maxWidth: 800,
    },
});

class FirmLogoImage extends Component {
    state = {
        showUploadBar: false,
        firmImage: {},
    };

    componentDidMount() {
        const firmImage = _.get(this.props, ['record', 'logo', 'urls', 'standard'], {});

        this.setState({
            firmImage,
            showUploadBar: _.isEmpty(firmImage),
        });
    }

    toggleUploadBar = () => {
        this.setState({
            showUploadBar: !this.state.showUploadBar,
        });
    };

    renderFirmLogo = () => {
        if (this.props.disabled && !this.state.firmImage) {
            return <span className={this.props.classes.inputLabel}>Firm logo</span>;
        }

        return null;
    };

    render() {
        const { showUploadBar } = this.state;
        const firmImage = _.get(this.props, ['record', 'logo', 'urls', 'standard'], {});
        const { isAdmin, classes } = this.props;
        return (
            <div>
                {!_.isEmpty(firmImage) && (
                    <FirmLogo enableEdit={isAdmin} src={firmImage} onClick={this.toggleUploadBar} />
                )}
                {showUploadBar ? (
                    <div>
                        <ImageInput
                            source="file"
                            label="Firm logo"
                            accept="image/*"
                            maxSize={MAX_FILE_SIZE_TWO_MB}
                            className={classes.imageInput}
                            options={{
                                disabled: !isAdmin,
                            }}>
                            <ImagePreviewCrop source="src" />
                        </ImageInput>
                    </div>
                ) : (
                    this.renderFirmLogo()
                )}
            </div>
        );
    }
}

export default withStyles(styles)(FirmLogoImage);
