import React from 'react';
import { PageTitle } from '../components/Typography/Headlines';
import { SaveButton, SimpleForm, TextInput, Toolbar, refreshView, required } from 'react-admin';
import dataProvider from '../dataProvider';
import { useDispatch } from 'react-redux';
import { showClientIdField } from '../helpers/helperFunctions';

const BulkInviteRecordEditToolbar = props => {

    const dispatch = useDispatch();

    const handleSave = (rec) => {
        const {id, ...rest} = rec
        dataProvider.update(props.resource, {
            data: {...rest, bulkInvite: props.bulkInvite},
            id
        }).then((res) => dispatch(refreshView()))
    }

    return (
        <Toolbar {...props} >
            <SaveButton
                label="Save"
                submitOnEnter={false}
                onSave={handleSave}
            />
        </Toolbar>

    )
};

const BulkInviteRecordEdit = props => {
    return (
        <SimpleForm resource={`bulk-invite-records`} record={props.record}
            toolbar={<BulkInviteRecordEditToolbar bulkInvite={props.bulkInvite} />}
        >
            <PageTitle title="Edit client details" />
            <TextInput label="Client name" source="client_name" validate={[required()]}/>
            <TextInput label="Client email" source="client_email" validate={[required()]}/>
            {
                showClientIdField(props.inviteType , props.crmLookup) &&
                <TextInput label="Client ID" source="crm_customer_code" />
            }
        </SimpleForm>
    )
}


export default BulkInviteRecordEdit;
