import { Box } from '@material-ui/core';
import { FOCUS_SELECT, QUERY_ID } from '../../constants/safeguard';
import React, { useEffect, useState, useRef } from 'react';
import GoalAnswersTable from './GoalAnswersTable';
import { ArgonSelectInput } from '../../components/Argon/ArgonSelectInput';
import { CustomBarChart } from '../../SafeGuard/Charts/BarChart';
import { CHART_LABELS } from '../../constants/safeguard';
import Spinner from '../../SafeGuard/Charts/Spinner';
import { getDriverTypeForFilter, isNetworkDashboard } from 'helpers/safeguard';

const AdviserPerformance = props => {
    const { classes, goal, onChange, advisers, drivers, graphs, serviceType } = props;
    const [selectedAdviser, setSelectedAdviser] = useState(null);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [advisersForSelect, setAdvisersForSelect] = useState([]);
    const [displaySettings, setDisplaySettings] = useState([]);
    const [fetched, setFetched] = useState(false);
    const [driverIdsForSelectedAdviser, setDriverIdsForSelectedAdviser] = useState([]);
    const goalAnswersTableRef = useRef();

    useEffect(() => {
        const formattedAdvisers = advisers.map(item => {
            return {
                id: 4,
                name: item.full_name,
                filter: { professional_id: item.id, goal_id: goal.id },
            };
        });
        setAdvisersForSelect(formattedAdvisers);
    }, [advisers]);

    useEffect(() => {
        setSelectedAdviser(null)
    }, [serviceType]);

    useEffect(() => {
        if (graphs.length) {
            setFetched(true)

            if (graphs[0]['data'].length) {
                setDriverIdsForSelectedAdviser(graphs[0]['data'].map(dta => parseInt(dta.Id)))
            } else {
                setDriverIdsForSelectedAdviser([])
            }
        }

    }, [graphs]);

    const goalAnswersTableScrollInView = () => {
        goalAnswersTableRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleAdviserChange = e => {
        const name = e.target.value.name
        const firstName = name.split(' ')[0];

        let tempDisplaySettings = {...CHART_LABELS.goalKeyDriverByAdviser[`goal${goal.id}`]};
        tempDisplaySettings.title = firstName + tempDisplaySettings.title;

        if (isNetworkDashboard()) {
            tempDisplaySettings.bar2Label = "Network"
        }

        setDisplaySettings(tempDisplaySettings);
        setSelectedAdviser(e.target.value);
        setFetched(false)
        onChange(e);
    };

    const handleDriverChange = e => {
        setSelectedDriver(e.target.value);
    };

    return (
        <Box>
            <Box className={classes.dropdownWrapper}>
                <ArgonSelectInput
                    onChange={handleAdviserChange}
                    name={FOCUS_SELECT[1].type}
                    className={classes.dropdown}
                    value={selectedAdviser ? selectedAdviser : 0}
                    data={advisersForSelect}
                    dataLabelName="name"
                    dataKeyName="all"
                    defaultItem="Select an adviser"
                    selectElementOnly={true}
                    noForm={true}
                    data-test={"compare-select-adviser"}
                />
            </Box>
            <Box className={classes.chartWrapper}>
                {(graphs?.length && graphs[0]['data'].length) && selectedAdviser && fetched ? (
                    <CustomBarChart
                        data={graphs.find(item => item.id === QUERY_ID.GOAL_KEY_DRIVER_BY_ADVISER).data}
                        displaySettings={displaySettings}
                        triple
                    />
                ) : selectedAdviser && fetched ? <Box className={classes.noData}>The adviser needs at least 5 reviews to show data</Box>
                    : selectedAdviser ? <Spinner /> : null
                }
            </Box>
            {((graphs?.length && graphs[0]['data'].length) && selectedAdviser) ? (
                <>
                    <Box className={classes.dropdownWrapper}>
                        <ArgonSelectInput
                            onChange={handleDriverChange}
                            className={classes.dropdown}
                            defaultValue={drivers[0]}
                            value={selectedDriver ? selectedDriver : 0}
                            data={drivers.filter(item => {
                                if (item.goal_id === goal.id && getDriverTypeForFilter(serviceType) && driverIdsForSelectedAdviser.includes(item.id)) {
                                    return item;
                                }
                            })}
                            dataLabelName="name"
                            dataKeyName="all"
                            defaultItem="Select a driver"
                            selectElementOnly={true}
                            noForm={true}
                        />
                    </Box>
                    {selectedDriver && (
                        <GoalAnswersTable
                            goalAnswersTableScrollInView={goalAnswersTableScrollInView}
                            ref={goalAnswersTableRef}
                            key={selectedDriver.id + Math.random()}
                            professional={selectedAdviser}
                            driver={selectedDriver}
                            serviceType={serviceType}
                        />
                    )}
                </>
            ) : <Box className={classes.dropdownWrapper} />}
        </Box>
    );
};
export default AdviserPerformance;
