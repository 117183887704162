import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';

import { formatRange } from '../../../utils';
import { makeStyles } from '@material-ui/core/styles';
import DateRangeCustom from './DateRange';
import { Box, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    dateFilterButton: {
        minWidth: 220,
        '& .MuiButton-label': {
            textTransform: 'none'
        },
        boxShadow: '0 1px 3px rgba(71, 68, 68, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
        "&:hover": {
            boxShadow: '0 1px 3px rgba(71, 68, 68, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
            transform: "none",
            backgroundColor: "#fff"
        },
    }
}));

const DateFilter = props => {
    const { shortcutsToShow, range, setRange } = props
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const handleDatepickerButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDatepickerButtonClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.root}>
            <Button
                aria-describedby="datepicket-calendar-popover"
                variant={'filled'}
                className={classes.dateFilterButton}
                onClick={handleDatepickerButtonClick}
            >
                {
                    range.to && range.from ?
                        formatRange(range).from + ' - ' + formatRange(range).to
                        :
                        'Date'
                }

            </Button>
            <Popover
                id="datepicket-calendar-popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handleDatepickerButtonClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: isSmall ? 'center' : 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: isSmall ? 'center' : 'left',
                }}
            >
                <Box>
                    <Box style={{textAlign: 'right'}}><CloseIcon onClick={() => setAnchorEl(null)} /></Box>
                    <DateRangeCustom shortcutsToShow={shortcutsToShow} range={range} setRange={setRange} />
                </Box>
            </Popover>
        </div>
    )
}

export default DateFilter;