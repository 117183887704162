import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, InputAdornment } from '@material-ui/core';
import SectionTitle from './components/SectionTitle';
import DateFilter from './components/NewComponents/DateFilter';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ServiceTypeFilter from './components/ServiceTypeFilter';
import {
    AutocompleteInput,
    ReferenceInput,
    useGetIdentity,
    SimpleForm,
} from 'react-admin';
import PerformanceOverTime from './PerformanceOverTime';
import PerformanceByAdviserOrFirm from './PerformanceByAdviserOrFirm';
import Empty from './components/NewComponents/Empty'
import { isFirmViewOnNetworkPortal, isNetworkView } from '../helpers/safeguard';
import ReactSelect from 'react-select';
import { formatRange } from '../utils';
import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles(theme => ({
    dashboardWrapper: {
        background: 'white',
        padding: 32
    },
    root: {
        '& .MuiButtonBase-root': {
            height: 42
        },
        '& .MuiSelect-select': {
            padding: "8px 16px",
            borderColor: theme.palette.tertiary.contrastText,
            fontWeight: 700,
            color: theme.palette.secondary.dark,
            backgroundColor: "#fff",
            borderRadius: '2rem'
        },
        '& .MuiFilledInput-root': {
            borderColor: theme.palette.tertiary.contrastText,
            '& .MuiFilledInput-inputMarginDense': {
                paddingTop: 9,
                paddingBottom: 9,
                height: 'auto',
                fontWeight: 700,
            }
        },
        '& .MuiInputBase-root': {
            height: 42,
            minWidth: 110,
            width: 'auto',
            '&::before': {
                content: 'none'
            },
            '&::after': {
                borderBottom: 'none',
                border: 'none !important'
            },
        },
        '& .MuiFormGroup-root': {
            marginBottom: 0,
        },
        '& .MuiInputLabel-formControl': {
            marginBottom: 0,
            left: 16,
            top: 22,
            transform: 'translateY(-50%)',
            paddingBottom: 2,
            color: theme.palette.secondary.dark
        },
        '& .MuiInputLabel-animated.Mui-focused': {
            display: 'none'
        }
    },
    selectMenu: {
        '& .MuiListItem-root': {
            color: `${theme.palette.secondary.dark}`,
        }
    },
    filters: {
        paddingTop: 0,
        paddingBottom: 0,
        display: 'flex',
        flexWrap: 'wrap',
        gap: 16,
        alignItems: 'flex-start'
    },
    dropdown: {
        height: 42,
        '&.MuiInputBase-root': {
            minWidth: 160
        }
    },
    label: {
        fontWeight: 'bold',
        color: theme.palette.secondary.dark
    },
    form: {
        minHeight: 'auto',
        marginTop: 0,
        marginBottom: 0,
        '& .MuiCardContent-root': {
            margin: 0,
            padding: 0,
        },
        '& .MuiFormControl-marginDense': {
            margin: 0
        }
    },
    formFullWidth: {
        flexGrow: 1,
        '& .MuiFormControl-root': {
            width: '100%',
        }
    },
    questionWith: {
        '& .MuiFormControl-root': {
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        }
    },
    icon: {
        color: theme.palette.secondary.dark
    },
    reactSelect: {
        maxWidth: '100%',
        fontWeight: 700,
        fontFamily: 'Nunito',
        width: 200,
        "& .react-select__placeholder": {
            color: theme.palette.primary.dark,
        },
        "& .react-select__control": {
            borderColor: theme.palette.tertiary.contrastText,
            boxShadow: '0 1px 3px rgba(71, 68, 68, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
            borderRadius: '2rem',
            height: 42,
        },
        "& .react-select__menu": {
            background: '#fff'
        },
    },
    professionalForm: {
        '& .MuiFormControl-root': {
            width: 'auto'
        }
    }
}));

const overTimePages = ['overTime', 'overTimeAdviser', 'overTimeFirm']
const groupedPages = ['byAdviser', 'byFirm']
const groups = ['monthly', 'quarterly', 'yearly'];
const networkPages = ['overTime','overTimeFirm', 'byFirm', 'overTimeAdviser', 'byAdviser']
const firmPages = ['overTime', 'overTimeAdviser', 'byAdviser']
const showAdviserFilter = ['overTimeAdviser'];
const showFirmFilter = ['overTimeFirm'];
const Performance = (props) => {
    const { location } = props
    const [questionId, setQuestionId] = useState(null);
    const [answers, setAnswers] = useState([])
    const [dataValueType, setDataValueType] = useState('count');
    const [groupDataBy, setGroupDataBy] = useState('quarterly');
    const [range, setRange] = useState({ from: null, to: null });
    const [page, setPage] = useState({type: '', title: ''});
    const [adviser, setAdviser] = useState(null);
    const [firm, setFirm] = useState(null);
    const [serviceType, setServiceType] = useState(null)
    const classes = useStyles();
    const { identity } = useGetIdentity();
    const sortedNetworkFirms = (identity?.networkFirms || []).sort((a, b) =>
        a.label.localeCompare(b.label)
    );
    useEffect(() => {
        if (sortedNetworkFirms.length) {
            const search = location.search;
            const params = new URLSearchParams(search);
            if (params.get('firm') && showFirmFilter.includes(page.type)) {
                const existingFirm = sortedNetworkFirms.find(f => f.value === parseInt(params.get('firm')))
                if (existingFirm) setFirm({...existingFirm})
            }

        }
    }, [sortedNetworkFirms]);
    useEffect(() => {
        switch (location.pathname) {
            case '/performance-over-time':
                setPage({
                    type: 'overTime',
                    title: "Performance over time"
                })
                break;
            case '/performance-adviser-over-time':
                setPage({
                    type: 'overTimeAdviser',
                    title: "Performance over time"
                })
                break;
            case '/performance-firm-over-time':
                setPage({
                    type: 'overTimeFirm',
                    title: "Performance over time"
                })
                break;
            case '/performance-adviser':
                setPage({
                    type: 'byAdviser',
                    title: "Performance by adviser"
                })
                break
            case '/performance-firm':
                setPage({
                    type: 'byFirm',
                    title: "Performance by firm"
                })
                break;
            default:
                setPage({
                    type: '',
                    title: ""
                })
        }
    }, [])
    useEffect(() => {
        if (page.type) {
            (async () => {
                const search = location.search;
                const params = new URLSearchParams(search);
                if (params.get('question')) setQuestionId(parseInt(params.get('question')))
                if (params.get('adviser') && showAdviserFilter.includes(page.type)) setAdviser(params.get('adviser'))
            })();
        }

    }, [page.type])
    const getAnswersFromData = (dataFromServer) => {
        let answers = []
        // eslint-disable-next-line array-callback-return
        dataFromServer.map(item => {
            if (answers.findIndex(answer => answer.gka === item.question_choice_gka && answer.text === item.question_choice_label) === -1) {
                answers = [...answers, {
                    gka: item.question_choice_gka,
                    text: item.question_choice_label,
                }]
            }
        })
        return answers;
    }
    const formatDataByPercentage = (dataToFormat, formattedAnswers) => {
        return dataToFormat.map(item => {
            const formattedItem = {...item}
            formattedAnswers.map(answer => {
                formattedItem[answer.gka] = formattedItem[answer.gka] ? ( (formattedItem[answer.gka] * 100) / formattedItem.total).toFixed(2) : 0
                return true
            })
            return formattedItem
        })
    }
    const handleServiceTypeChange = (e) => {
        setServiceType(e.target.value);
    }
    const renderPage = () => {
       if (overTimePages.includes(page.type)) {
           return <PerformanceOverTime
               questionId={questionId}
               answers={answers}
               getAnswersFromData={getAnswersFromData}
               range={range}
               formatDataByPercentage={formatDataByPercentage}
               dataValueType={dataValueType}
               groupDataBy={groupDataBy}
               serviceType={serviceType}
               setAnswers={setAnswers}
               adviserId={adviser}
               firmId={firm?.value}
           />
       }

        if (groupedPages.includes(page.type)) {
            return <PerformanceByAdviserOrFirm
                questionId={questionId}
                answers={answers}
                setAnswers={setAnswers}
                getAnswersFromData={getAnswersFromData}
                range={range}
                formatDataByPercentage={formatDataByPercentage}
                dataValueType={dataValueType}
                serviceType={serviceType}
                groupByAdviserOnNetworkView={isNetworkView() && page.type === 'byAdviser'}

            />
        }
        return null;
    }
    const handleQuestionChange = selectedQuestion => {
        setQuestionId(selectedQuestion)
    }
    const handleAdviserFilterChange = selectedAdviser => {
        setAdviser(selectedAdviser)
    }
    const handleFirmFilterSelect = selectedFirm => {
        setFirm(selectedFirm)
    }

    if (isNetworkView() && !networkPages.includes(page.type)) return <Empty text={'You are not allowed to view this page'} icon={"warning"} />
    if (!isNetworkView() && !firmPages.includes(page.type)) return <Empty text={'You are not allowed to view this page'} icon={"warning"} />

    return (
        <Box className={classes.root}>
            <SectionTitle title={page.title} header size="2.75rem" />
            <Box className={classes.dashboardWrapper}>
                <Box className={classes.filtersWrapper}>
                    <Box className={classes.filters}>
                        <SimpleForm
                            toolbar={false}
                            resource={"questions"}
                            className={`${classes.form} ${questionId ? classes.questionWith : classes.formFullWidth}`}>
                            <ReferenceInput
                                sort={{field: 'label_template', order: 'DESC' }}
                                filter={{ show_only_questions_with_choices: 1 }}
                                label={false}
                                source="questionId"
                                reference="questions"
                                alwaysOn
                                onChange={handleQuestionChange}
                                allowEmpty={false}
                                initialValue={questionId}
                            >
                                <AutocompleteInput
                                    optionValue={'id'}
                                    optionText={"label_template"}
                                    resettable={false}
                                    options={{
                                        suggestionsContainerProps: {
                                            placement: 'bottom-start',
                                            style: {
                                                maxWidth: '90%'
                                            }
                                        },
                                        InputProps: {
                                            startAdornment: <InputAdornment position="start"><FilterListIcon className={classes.icon} /></InputAdornment>,
                                        }
                                    }}
                                />
                            </ReferenceInput>
                        </SimpleForm>
                        { questionId ?
                            <>
                                    <DateFilter range={range} setRange={setRange} shortcutsToShow={['lastWeek', 'lastMonth', 'last3Months', 'lastYear']} />

                                    {overTimePages.includes(page.type) ?
                                        <Select
                                            id="group-select"
                                            value={groupDataBy}
                                            label="Group by"
                                            variant={'filled'}
                                            MenuProps={{
                                                PaperProps: {
                                                    className: classes.selectMenu
                                                }}}
                                            onChange={(e) => setGroupDataBy(e.target.value)}

                                        >
                                            <MenuItem value="" disabled={true}><em>Group data by</em></MenuItem>
                                            { groups.map(group => <MenuItem value={group}>{group.charAt(0).toUpperCase() + group.slice(1)}</MenuItem>) }
                                        </Select>
                                        : null }
                                    <Select
                                        id="type-select"
                                        value={dataValueType}
                                        label="Chart type"
                                        variant={'filled'}
                                        MenuProps={{
                                            PaperProps: {
                                                className: classes.selectMenu
                                            }}}
                                        onChange={(e) => setDataValueType(e.target.value)}

                                    >
                                        <MenuItem value="" disabled={true}><em>Show data by count or percentage</em></MenuItem>
                                        <MenuItem value="count">Count</MenuItem>
                                        <MenuItem value="percentage">Percentage</MenuItem>
                                    </Select>
                                    <ServiceTypeFilter
                                        defaultItem={"Service Type"}
                                        enableDefaultItem={true}
                                        serviceType={serviceType}
                                        handleServiceTypeChange={handleServiceTypeChange}
                                        classes={classes}
                                    />

                                    { page.type === 'overTimeAdviser'
                                        ?
                                            <SimpleForm
                                                toolbar={false} resource={"professionals/professionals-for-filter"} className={`${classes.form} ${classes.professionalForm}`}>
                                                <ReferenceInput
                                                    sort={{field: 'id', order: 'DESC' }}

                                                    filter = {{
                                                        questionId,
                                                        ...(serviceType && { serviceTypeId: serviceType.type }),
                                                        ...(range.to && range.from && { ...formatRange(range) }),
                                                    }}
                                                    label = {adviser ? "" : "Filter by adviser"}
                                                    source = "professionalId"
                                                    reference = "professionals/professionals-for-filter"
                                                    alwaysOn
                                                    allowEmpty={true}
                                                    onChange={handleAdviserFilterChange}
                                                    initialValue={adviser}
                                                >
                                                    <AutocompleteInput
                                                        optionValue="id"
                                                        optionText="name_only"
                                                        options={{
                                                            suggestionsContainerProps: {
                                                                placement: 'bottom-start',
                                                                style: {
                                                                    maxWidth: '90%'
                                                                }
                                                            },
                                                        }}
                                                    />
                                                </ReferenceInput>
                                            </SimpleForm>
                                        : null }

                                    { page.type === 'overTimeFirm' && sortedNetworkFirms.length && !isFirmViewOnNetworkPortal()
                                        ?
                                        <ReactSelect
                                            className={classes.reactSelect}
                                            value={firm}
                                            onChange={handleFirmFilterSelect}
                                            options={sortedNetworkFirms}
                                            isClearable
                                            placeholder={"Filter by firm"}
                                            classNamePrefix="react-select"
                                        />
                                        : null }
                            </>
                            : null
                        }
                    </Box>
                    { ! questionId ? <Empty text={'Select a question to see results'} /> : null}
                </Box>
                {questionId ? renderPage() : null}
            </Box>
        </Box>
    );
};

export default Performance;

