import React, { useState, useEffect, useRef } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import dataProvider from 'dataProvider';
import DashboardHeader from '../../SafeGuard/components/DashboardHeader';
import SafeguardStatsContent from '../../Dashboard/components/SafeguardStatsContent';
import DriverStatistics from '../../SafeGuard/DriverStatistics';
import Spinner from '../../components/Spinner';
import { formatRange } from 'utils';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { getServiceTypeAsString } from '../../helpers/safeguard';
import setGoalAction from 'Store/goalsAction';
import { connect } from 'react-redux';
import setServiceTypeAction from 'Store/serviceTypeAction';

const useStyles = makeStyles(theme => ({
    wrapper: {
        marginTop: theme.spacing(2),
    },
    containerRoot: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: '30px',
            paddingRight: '30px',
        },
    },
    gridItem: {
        display: 'flex',
        justifyContent: 'center',
    },
    statisticsWrapper: {
        flexBasis: '90%',
    },
}));

const AdviserDashboard = props => {
    const classes = useStyles();
    const [stats, setStats] = useState(null);
    const [statsFetched, setStatsFetched] = useState(false);
    const [compareToIndustry, setCompareToIndustry] = useState(true);
    const [driverStatisticsFetched, setDriverStatisticsFetched] = useState(false);
    const [initialFetch, setInitialFetch] = useState(true);
    const [driverStatistics, setDriverStatistics] = useState({
        strengths: [],
        areas_for_improvement: [],
    });
    const { goal, serviceType, setGoal, setServiceType, identity, location } = props;

    const [range, setRange] = useState(
        location.params?.range
            ? { from: location.params?.range.from, to: location.params?.range.to }
            : { from: null, to: null }
    );

    const driverRef = useRef();
    const goalRef = useRef();

    useEffect(() => {
        if (identity) {
            if (location.params?.serviceType) setServiceType(location.params?.serviceType);
            if (location.params?.goal) setGoal(location.params?.goal);
            fetchStatistics();
            setInitialFetch(false);
        }
    }, [identity]);

    useEffect(() => {
        if (!initialFetch) {
            setDriverStatisticsFetched(false);
            fetchStatistics();
        }
    }, [goal, compareToIndustry]);

    useEffect(() => {
            setDriverStatisticsFetched(false);
            fetchStatistics();
    }, [range]);

    useEffect(() => {
        if (!initialFetch) {
            setDriverStatisticsFetched(false);
            fetchStatistics();
        }
    }, [serviceType]);

    const handleCardClick = goal => setGoal(goal);

    const fetchStatistics = () => {
        if (driverRef.current) driverRef.current.abort();
        if (goalRef.current) goalRef.current.abort();

        const controllerD = new AbortController();
        driverRef.current = controllerD;

        const controllerG = new AbortController();
        goalRef.current = controllerG;

        let params = formatRange(range);
        if (serviceType) {
            params['service_type'] = getServiceTypeAsString(serviceType);
        }

        dataProvider
            .getNone(`elevation/advisers/${props.match.params.professional}/goals`, params, {
                signal: goalRef.current?.signal,
            })
            .then(res => {
                setStats(res.data);
                setStatsFetched(true);
                goalRef.current = null;
            })
            .catch(e => setStatsFetched(true));

        dataProvider
            .getNone(
                `elevation/advisers/${props.match.params.professional}/drivers`,
                {
                    industry: compareToIndustry,
                    goal_id: goal,
                    ...( identity?.showFirmAvgOnAdviserElevationDashboard && { show_firm_avg_on_adviser_elevation_dashboard: identity?.showFirmAvgOnAdviserElevationDashboard }),
                    ...params,
                },
                { signal: driverRef.current?.signal }
            )
            .then(res => {
                setDriverStatistics({
                    strengths: [...res.data.strengths],
                    areas_for_improvement: [...res.data.areas_for_improvement],
                    extra_info: { ...res.data.extra_info },
                    adviser: { ...res.data.adviser },
                });
                setDriverStatisticsFetched(true);
                driverRef.current = null;
            })
            .catch(e => {});
    };

    return (
        <>
            <DashboardHeader
                adviser={driverStatistics?.adviser}
                range={range}
                setRange={setRange}
                serviceType={serviceType}
                setServiceType={setServiceType}
            />
            <Box className={classes.wrapper}>
                {statsFetched ? (
                    <SafeguardStatsContent
                        fullScreen={true}
                        adviserStat={true}
                        data={stats}
                        handleClick={handleCardClick}
                        goal={goal}
                        hideTitle={true}
                        serviceType={serviceType}
                    />
                ) : (
                    <Spinner />
                )}
                <Container
                    maxWidth={false}
                    component={Box}
                    className={classes.containerRoot}
                    marginBottom="1rem"
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12} className={classes.gridItem}>
                            {driverStatisticsFetched ? (
                                <Box className={classes.statisticsWrapper}>
                                    <DriverStatistics
                                        serviceType={serviceType}
                                        forAdviser={driverStatistics.adviser}
                                        driverStatistics={driverStatistics}
                                        goal={goal}
                                    />
                                </Box>
                            ) : (
                                <Spinner />
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

const mapStateToProps = state => {
    return {
        goal: state.goal,
        serviceType: state.serviceType,
        identity: state.identity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setGoal: goal => dispatch(setGoalAction(goal)),
        setServiceType: serviceType => dispatch(setServiceTypeAction(serviceType)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdviserDashboard);
