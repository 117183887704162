import React from 'react';
import { palette }  from '../assets/theme/theme';

const styles = {
    links: {
        textDecoration: 'none',
        color: palette.secondary.main,
    },
    dashboardLink: {
        textDecoration: 'none',
        color: palette.primary.contrastText,
        borderBottom: '1px solid white',
    },
};

export const ExternalLink = ({ href, onClick = () => {}, children, className, target="_blank" }) => (
    <a
        style={styles.links}
        href={href}
        target={target}
        rel="noopener noreferrer"
        onClick={onClick}
        className={className}
    >
        {children}
    </a>
);

export const Link = ({ href, children, onClick = () => {}, dashboardLink }) => (
    <a style={dashboardLink ? styles.dashboardLink : styles.links} href={href} onClick={onClick}>
        {children}
    </a>
);

// export const ChipUrl = ({ source, label = '', record = {} }) => {
//     return (
//         <Chip
//             label={label}
//             onClick={() => {
//                 window.open(record.url);
//             }}
//         />
//     );
// };
