import {
    DRIVER_TYPES,
    FIRM_TYPES_TO_SHOW_CLIENT_TYPE_FILTER,
    SERVICE_TYPE_FILTER_FA, SERVICE_TYPE_FILTER_PROTECTION
} from 'constants/safeguard';
import { MAX_DATA_FOR_CHARTS } from '../constants';
import tokenManager from "../tokenManager";

export const getServiceTypeAsString = (type) => {
    if (!type) return;
    return type['type'];
};

export const getServiceTypeFilter = (query, serviceType) => {
    if (serviceType) {
        query.map(ele => {
            return ele['filter']['service_type'] = getServiceTypeAsString(serviceType);
        });
    } else {
        query.map(ele => {
            return delete ele['filter']['service_type'];
        });
    }
    return query;
};

export const getDriverTypeForFilter = (serviceType) => {
    if (serviceType.type === SERVICE_TYPE_FILTER_FA.type || serviceType.type === SERVICE_TYPE_FILTER_PROTECTION.type) {
        return DRIVER_TYPES.MRG;
    }
    return DRIVER_TYPES.FA;
};

export const getFilteredDrivers = (drivers, goalId, serviceType) => {
    return drivers.filter(item => {
        return (item.goal_id === goalId && item.service_type_id === serviceType.type);
    });
}

export const firmCanSeeFilter = (firmType) => {
    return FIRM_TYPES_TO_SHOW_CLIENT_TYPE_FILTER.includes(firmType);
}

export const isDataSafeToDisplayInCharts = (arraySize) => {
    return arraySize < MAX_DATA_FOR_CHARTS;
}

export const isNetworkView = () => {

    let network = JSON.stringify(tokenManager.getDecodedToken().network_id);

    if (network == 'null') {
        return false;
    }

    return true;
}

export const isFirmViewOnNetworkPortal = () => {
    return tokenManager.getDecodedToken().firm_id && tokenManager.getDecodedToken().network_id;
}

export const isNetworkDashboard = () => {
    const token = tokenManager.getDecodedToken();
    const networkId = token.network_id;
    const firmId = token.firm_id;
    if (networkId) {
        return !firmId;
    }

    return false;
};
