import * as React from 'react';
import {Datagrid, EditButton, FunctionField, Responsive, TextField,} from 'react-admin';
import {useACL} from '../acl/acl';
import {ArgonAdviser} from '../components/Argon/ArgonTableComponents/ArgonAdviserV2';
import { DateFieldWithDefaultValue } from '../components/FieldComponents';
import TableForListComponent from '../components/List/TableForListComponent';
import TableForListComponentWrapper from '../components/List/TableForListComponentWrapper';
import EmailWithTooltip from "../components/EmailWithTooltip";
import {makeStyles} from "@material-ui/styles";

const CustomMediumDataGrid = () => {
    const {edit: canEditAdvisers} = useACL('professionals/settings');
    const classes = useStyles();

    return (
        <Datagrid>
            <ArgonAdviser source="full_name" label="Adviser Name" sortBy="professional.first_name"/>
            <EmailWithTooltip label="Email" source="email" sortBy="professional.email"/>
            <DateFieldWithDefaultValue source="last_login" label="Last Login" sortBy="professional.last_login"/>
            <TextField
                source="public_profile_type"
                label="Public Profile"
            />
            <FunctionField
                label="Elevation"
                sortBy="professional_services.service"
                render={record => record.elevation === true ? 'Yes' : 'No'}
                sortable={true}
            />

            {canEditAdvisers && <EditPublicProfileButton style={classes.button}/> }

        </Datagrid>
    );
};

const EditPublicProfileButton = ({style, ...props}) =>  {
    return (
            < EditButton
                className={style}
                label={'Edit Public Profile'}
                icon={null}
                {...props}
            />
        )
}

const AdviserSettingsList = (props) => {
    return (
        <TableForListComponentWrapper
            title={`Adviser Settings`}
            color={'white'}
        >
            <TableForListComponent
                basePath="/professionals/settings"
                exporter={false}
                bulkActionButtons={false}
                actions={false}
                sort={{field: 'professional.first_name', order: 'ASC'}}
                {...props}
            >
                <Responsive small={<CustomMediumDataGrid/>} medium={<CustomMediumDataGrid/>}/>
            </TableForListComponent>
        </TableForListComponentWrapper>
    );
};

const useStyles = makeStyles(() => ({
    button: {
        '& .MuiButton-label': {
            all: 'none',
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: 200,
            paddingLeft: 0,
        }
    }
}))


export default AdviserSettingsList;
