import React, { useState } from 'react';
import _ from 'lodash';
import ReactCrop from 'react-image-crop';
import { useForm } from 'react-final-form';
import { Typography } from '@material-ui/core';
import 'react-image-crop/dist/ReactCrop.css';

import { makeStyles } from '@material-ui/core';

export const IMAGE_PREVIEW_SIZE = 200;

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 360,
    },
    caption: {
        marginTop: theme.spacing(5),
    },
    cropper: {
        '& > img': {
            maxHeight: 'unset',
        },
    },
    croppedImagePreview: {
        display: 'block',
        borderRadius: '50%',
    },
}));

const ImagePreviewCrop = props => {
    const [crop, setCrop] = useState({ aspect: 1 / 1, unit: '%' });
    const [croppedImagePreview, setCroppedImagePreview] = useState(null);
    const { change } = useForm();
    const classes = useStyles();
    const src = _.get(props, 'record.src');

    const getCroppedImage = () => {
        let image = new Image();

        image.src = src;

        /**
         * Example taken from react-image-crop FAQ
         * https://www.npmjs.com/package/react-image-crop
         */
        const canvas = document.createElement('canvas');
        const cropX = (crop.x / 100) * image.width;
        const cropY = (crop.y / 100) * image.height;
        const cropWidth = (crop.width / 100) * image.width;
        const cropHeight = (crop.height / 100) * image.height;
        const ctx = canvas.getContext('2d');

        canvas.height = IMAGE_PREVIEW_SIZE;
        canvas.width = IMAGE_PREVIEW_SIZE;

        ctx.drawImage(
            image,
            cropX,
            cropY,
            cropWidth,
            cropHeight,
            0,
            0,
            IMAGE_PREVIEW_SIZE,
            IMAGE_PREVIEW_SIZE
        );

        // As Base64 string
        return canvas.toDataURL('image/png');
    };

    const handleOnChange = (_, percentCrop) => {
        setCrop(percentCrop);
    };

    const handleCropComplete = () => {
        const croppedImage = getCroppedImage();
        const imageDataURL = croppedImage !== 'data:,' ? croppedImage : '';
        
        setCroppedImagePreview(imageDataURL);

        change('file.dataURL', imageDataURL);
    };

    if (!src) {
        return null;
    }

    return (
        <div className={classes.root}>
            <Typography variant="caption" className={classes.caption}>
                Drag on the image to crop
            </Typography>
            <ReactCrop
                circularCrop
                src={src}
                crop={crop}
                onChange={handleOnChange}
                onComplete={handleCropComplete}
                alt="File Preview"
                className={classes.cropper}
            />
            {Boolean(croppedImagePreview) && (
                <div>
                    <Typography variant="caption" gutterBottom>
                        Cropped image
                    </Typography>
                    <img
                        src={croppedImagePreview}
                        className={classes.croppedImagePreview}
                        height={IMAGE_PREVIEW_SIZE}
                        width={IMAGE_PREVIEW_SIZE}
                        alt="File Preview"
                    />
                </div>
            )}
        </div>
    );
};

export default ImagePreviewCrop;
