import * as React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

export default function Spinner() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                margin: '5rem',
            }}>
            <CircularProgress />
        </Box>
    );
}
