import * as React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import AddButton from '../components/Buttons/AddButton';
import { Grid } from '@material-ui/core';
import AwardGrid from './components/AwardGrid';
import AccreditationGrid from '../Accreditations/components/AccreditationGrid';

const AwardAndAccreditationsShow = () => {
    return (
        <Grid item md={12} l={12}>
            <Grid>
                <Card>
                    <CardContent>
                        <Typography>Awards</Typography>
                        <AwardGrid />
                        <AddButton
                            resource="firm-awards"
                            label="Add award"
                            to={{ pathname: '/firm-awards/create' }}/>
                    </CardContent>
                </Card>
            </Grid>
            <Grid>
                <Card>
                    <CardContent>
                        <Typography>Accreditations</Typography>
                        <AccreditationGrid />
                        <AddButton
                            resource="firm-accreditations"
                            label="Add accreditation"
                            to={{ pathname: '/firm-accreditations/create' }}/>
                    </CardContent>
                </Card>
            </Grid>
            {/*<Card>*/}

            {/*</Card>*/}
        </Grid>
    );
};

export default AwardAndAccreditationsShow;
