import FeeCreate from './FeeCreate';
import FeeList from "./FeeList";
import FeeEdit from "./FeeEdit";

const fees = {
    list: FeeList,
    create: FeeCreate,
    edit: FeeEdit,
};

export default fees;
