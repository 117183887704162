import * as React from 'react';
import { Edit, SimpleForm, FormDataConsumer } from 'react-admin';
import DialogButton from '../components/Buttons/DialogButton';
import { Typography, Box } from '@material-ui/core';
import UserHeader from '../components/Headers/UserHeader';
import ProfileEditLayout from "./ProfileEditLayout";

const message = (record) => {
    let firmAdvisersCount = record.firm_advisers_count;
    let firmFcaId = record.fca_number;
    const firmFcaIdText = firmFcaId ? `(FCA ID ${firmFcaId})` : null;

    return firmAdvisersCount === 1 || !firmAdvisersCount ? (
        <div>
            <Typography>
                The changes you've made to your firm profile will affect any colleagues from your
                current firm {firmFcaIdText} who sign up in future.
            </Typography>
            <Typography>Do you want to go ahead?</Typography>
        </div>
    ) : (
        <div>
            <Typography>
                The changes you've made will affect you and {firmAdvisersCount - 1} other advisers
                who are linked to your current firm {firmFcaIdText}
            </Typography>
            <Typography>Do you want to go ahead?</Typography>
        </div>
    );
};

const ProfileEdit = (...props) => {
    return (
        <Box>
            <UserHeader title="Firm Profile Settings"
                        description=''
            />
            <Edit id="no-id" resource="firm" basePath="/profile" {...props}>
                <SimpleForm
                    redirect={false}
                    toolbar={null}
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}>
                    <Typography variant="body2" fullWidth>These settings will only apply if your firm has a public profile on VouchedFor</Typography>
                    <ProfileEditLayout {...props}/>

                    <FormDataConsumer>
                        {({ formData }) => (
                            <DialogButton
                                name="Save"
                                title="Confirm Firm Changes"
                                form={message(formData)}
                                label="Yes, save changes"
                                resource="firm"
                                record={formData}/>
                        )}
                    </FormDataConsumer>
                </SimpleForm>
            </Edit>
        </Box>
    );
};

export default ProfileEdit;
