import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { useNotify, useSafeSetState, SimpleForm, Button } from 'react-admin';
import getDataProviderApiUrl from "../../../utils/getDataProviderApiUrl";
import withStyles from '@material-ui/core/styles/withStyles';
import FormToolbar from "../../../components/FormToolbar";
import { useHistory } from 'react-router';
import PasswordConfirm from '../PasswordConfirm';
import { withRouter } from 'react-router-dom';
import AuthCardComponent from '../AuthCardComponent';

const styles = theme => ({
    card: {
        margin: `${theme.spacing(5)}px 0`,
        width: 464,
        padding: 0,
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: `0 ${theme.spacing(2)}px`,
        },
    },
    cardContent: {
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(3),
        },

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            paddingTop: 0,
        },
    },
    title: {

        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(2),
        },
    },
    form: {
        '& > div': {
            padding: 0,
        },

        '& > .MuiToolbar-root': {
            position: 'relative',
            padding: 0,
            '& > .MuiButton-contained': {
                width: '100%',
                backgroundColor: theme.palette.tertiary.main,
                '&:hover': {
                    backgroundColor: theme.palette.tertiary.dark,
                },

                '& > .MuiButton-label': {
                    ' & > .MuiSvgIcon-root': {
                        display: 'none',
                    }
                },
                '&.Mui-disabled': {
                    opacity: 0.85,
                    color: 'white',
                },
            },
        },
    },
    anchor: {
        textDecoration: 'none',
        color: theme.palette.tertiary.main,
    },
    formToolbar: {
        marginTop: 0,
        backgroundColor: 'inherit'
    },
    loginButton: {
        marginTop: theme.spacing(2),
        padding: "6px 16px",
        backgroundColor: theme.palette.tertiary.main,
        color: "#fff",
        '&:hover': {
            backgroundColor: theme.palette.tertiary.dark,
            color: "#fff"
        },
        fontWeight: '700 !important',
        lineHeight: '1.75',
        letterSpacing: '1px',
        borderRadius: '2rem',
    }
});

const ResetPasswordForm = props => {
    const { classes } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const notify = useNotify();
    const [success, setSuccess] = useState(false)
    const history = useHistory();

    if (!props.match.params.token) history.push('/forgot-password')

    const validatePass = (values) => {
        const errors = {};
        if (values.confirmPassword && values.password && (values.confirmPassword !== values.password)) {
            errors.confirmPassword = 'Passwords do not match';
        }
        return errors
    };

    const submit = values => {
        setLoading(true);
        const request = new Request(getDataProviderApiUrl('reset-password/reset'), {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify({
                token: props.match.params.token, plainPassword: {
                    first: values.password,
                    second: values.confirmPassword,
                }
            }),
        });

        return fetch(request)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                setLoading(false);
                if (response.error) {
                    notify(response.error, 'error')
                } else {
                    setSuccess(true)
                }
            });

    };

    return (
        <AuthCardComponent title="Reset password">
            {success ?
                <>
                    <Typography color="primary" variant="body1">Your password has been successfuly changed</Typography>
                    <Button variant="contained" type="button" className={classes.loginButton} onClick={() => history.push('/login')} label="Log In" />
                </>
                :
                <>
                    <SimpleForm
                        validate={validatePass}
                        className={classes.form}
                        save={submit}
                        saving={loading}
                        toolbar={<FormToolbar label="Reset password" className={classes.formToolbar} />}
                    >
                        <PasswordConfirm />
                    </SimpleForm>
                    <Typography variant="body1">
                        <a href="/forgot-password" className={classes.anchor}>Request new forgot password link</a>
                    </Typography>
                </>
            }
        </AuthCardComponent>

    );
};

export default withStyles(styles)(withRouter(ResetPasswordForm));
