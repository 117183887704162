import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: 300,
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems: 'center'
    },
}));


const Loading = props => {
    const classes = useStyles()
    return (
        <Box className={classes.root}>
            <CircularProgress color={'secondary'} />
        </Box>
    )
}

export default Loading