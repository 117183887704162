import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';

const propTypes = {
    label: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
};

const styles = theme => ({
    container: {
        position: 'relative',
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    label: {
        display: 'block',
        position: 'relative',
        top: 'auto',
    },
});

const CustomFormInput = props => {
    const { classes, label, source, component } = props;

    return (
        <div className={classes.container}>
            <InputLabel className={classes.label} shrink>
                {label}
            </InputLabel>
            <Field name={source} component={component} />
        </div>
    );
};

CustomFormInput.propTypes = propTypes;

export default withStyles(styles)(CustomFormInput);
