import React from 'react';
import { useQuery } from 'react-admin';
import CarouselWidget from './CarouselWidget';
import { Grid } from '@material-ui/core';

const CarouselWidgetList = () => {

    const { data } = useQuery({
        type: 'getNone',
        resource: 'widget/reviews',
    });

    if (!data) {
        return null;
    }

    const renderedWidgetList = data.map((widget, index) => {
        return data ? (
                <Grid key={index} item xl={4} lg={6} sm={12} style={{ minWidth: '400px'}}>
                    <CarouselWidget
                        key={widget.id}
                        widget={widget}
                    />
                </Grid>
        ) : null;
    });

    return (
        <>
            {renderedWidgetList}
        </>
    );
};

export default CarouselWidgetList;