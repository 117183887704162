import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { useNotify, useSafeSetState, SimpleForm, BooleanInput, required, Button } from 'react-admin';
import getDataProviderApiUrl from "../../../utils/getDataProviderApiUrl";
import withStyles from '@material-ui/core/styles/withStyles';
import FormToolbar from "../../../components/FormToolbar";
import { useHistory } from 'react-router';
import PasswordConfirm from '../PasswordConfirm';
import AuthCardComponent from '../AuthCardComponent';

const styles = theme => ({
    card: {
        margin: `${theme.spacing(5)}px 0`,
        width: 464,
        padding: 0,
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: `0 ${theme.spacing(2)}px`,
        },
    },
    cardContent: {
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(3),
        },

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            paddingTop: 0,
        },
    },
    title: {

        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(2),
        },
    },
    form: {
        '& > div': {
            padding: 0,
        },

        '& > .MuiToolbar-root': {
            position: 'relative',
            padding: 0,
            '& > .MuiButton-contained': {
                width: '100%',
                backgroundColor: theme.palette.tertiary.main,
                '&:hover': {
                    backgroundColor: theme.palette.tertiary.dark,
                    color: "#fff"
                },
                '& > .MuiButton-label': {
                    ' & > .MuiSvgIcon-root': {
                        display: 'none',
                    }
                },
                '&.Mui-disabled': {
                    opacity: 0.85,
                    color: 'white',
                },
            },
        },
    },
    formInput: {
        width: "100%",
        '& .MuiTypography-body1 ': {
            fontSize: '14px'
        }
    },
    formToolbar: {
        marginTop: 0,
        backgroundColor: 'inherit'
    },
    loginButton: {
        marginTop: theme.spacing(2),
        padding: "6px 16px",
        backgroundColor: theme.palette.tertiary.main,
        color: "#fff",
        '&:hover': {
            backgroundColor: theme.palette.tertiary.dark,
            color: "#fff"
        },
        fontWeight: '700 !important',
        lineHeight: '1.75',
        letterSpacing: '1px',
        borderRadius: '2rem',
    }
});

const CompleteRegistrationForm = props => {
    const { classes, error, token } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const notify = useNotify();
    const [success, setSuccess] = useState(false)
    const history = useHistory();

    const validatePass = (values) => {
        const errors = {};
        if (values.confirmPassword && values.password && (values.confirmPassword !== values.password)) {
            errors.confirmPassword = 'Passwords do not match';
        }
        return errors
    };

    const submit = values => {
        setLoading(true);
        const request = new Request(getDataProviderApiUrl('confirm-registration'), {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify({
                completeRegistrationToken: token, plainPassword: {
                    first: values.password,
                    second: values.confirmPassword

                },
                agreeTerms: values.agreeTerms
            }),
        });

        return fetch(request)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                setLoading(false);
                if (response.error) {
                    notify(response.error, 'error')
                } else {
                    setSuccess(true)
                }
            });
    };

    return (
        <AuthCardComponent title="Set your password for your VouchedFor Firm account" >
            {error ?
                <>
                    <Typography color="error" variant="body1">Your data has already been updated or you're using a wrong token</Typography>
                    <Typography color="error" variant="body1">Please contact VouchedFor support for further details</Typography>
                </>
                : !success ? <SimpleForm validate={validatePass}
                    className={classes.form}
                    save={submit}
                    saving={loading}
                    toolbar={<FormToolbar label="Confirm registration" className={classes.formToolbar} />}
                >
                    <PasswordConfirm />
                    <BooleanInput className={classes.formInput} label="I agree with the terms and conditions" source="agreeTerms" validate={[required()]} />

                </SimpleForm>
                    :
                    <>
                        <Typography color="primary" variant="body1">Thank you! Your password has been successfuly updated. You can now log in to your acccunt</Typography>
                        <Button variant="contained" type="button" className={classes.loginButton} color="secondary" onClick={() => history.push('/login')} label="Log In"
                        />
                    </>

            }
        </AuthCardComponent>

    );
};

export default withStyles(styles)(CompleteRegistrationForm);
