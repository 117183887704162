import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import BubbleChartTwoToneIcon from '@material-ui/icons/BubbleChartTwoTone';
import InsertChartTwoToneIcon from '@material-ui/icons/InsertChartTwoTone';
import HourglassEmptyTwoToneIcon from '@material-ui/icons/HourglassEmptyTwoTone';
import WarningIcon from '@material-ui/icons/Warning';
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: 300,
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 24
    },
    text: {
        color: theme.palette.tertiary.dark
    },
    icon: {
        fontSize: 100,
        color: theme.palette.tertiary.dark
    }
}));


const Empty = props => {
    const classes = useStyles()
    const { icon = '', text } = props


    const renderIcon = () => {
        switch (icon) {
            case 'bubble':
                return <BubbleChartTwoToneIcon className={classes.icon} size={"large"} />
            case 'chart':
                return <InsertChartTwoToneIcon className={classes.icon} size={"large"} />
            case 'warning':
                return <WarningIcon className={classes.icon} size={"large"} />
            default:
                return <HourglassEmptyTwoToneIcon className={classes.icon} size={"large"} />
        }
    }

    return (
        <Box className={classes.root}>
            <Box>{renderIcon}</Box>
            <Typography className={classes.text} variant={'h1'} component={'div'}>{text}</Typography>
        </Box>
    )
}

export default Empty