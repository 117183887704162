function createReplaceVariable(variableName) {
    if (process.env.NODE_ENV === 'production') {
        return variableName;
    } else {
        return process.env[variableName];
    }
}

/**
 * TODO: Make keys all caps
 */
const API_KEYS = {
    api_url: createReplaceVariable('https://api.vouchedfor.co.uk') + '/v2/account',
    api_firm_url: createReplaceVariable('https://api.vouchedfor.co.uk') + '/v2/firm',
    elevation_url: createReplaceVariable('https://elevation.vouchedfor.co.uk') + '/firm',
    firm_url: createReplaceVariable('https://api.vouchedfor.co.uk') + '/v2',
    user_manager_login_url: createReplaceVariable('https://register.vouchedfor.co.uk') + '/auth',
    token_refresh_url: createReplaceVariable('https://register.vouchedfor.co.uk'),
    firm_switch_url: createReplaceVariable('https://register.vouchedfor.co.uk'),
    user_manager: createReplaceVariable('https://register.vouchedfor.co.uk'),
    consumer_url: createReplaceVariable('https://vouchedfor.co.uk'),
    accounts_url: createReplaceVariable('https://accounts.vouchedfor.co.uk'),
    fees_url: createReplaceVariable('https://professional.vouchedfor.co.uk') + '/v2',
    microsoft_clarity: createReplaceVariable('ayyavx3eb6'),
    vf_2023_widget_launch_date: createReplaceVariable('2023-03-08'),
    cdr_url: createReplaceVariable('https://consumer-duty.vouchedfor.co.uk') + '/firm/v2',
    linkedin_pixel_key: createReplaceVariable('478506'),
    mix_panel_project_token:createReplaceVariable('1b1c845750d56c9ca9cb1cc296b9b254'),
};


export const INTEGRATION_KEYS = {
    sentry_dsn: createReplaceVariable('https://57942b40ce234c23880f82c8b0b3e534@o89905.ingest.sentry.io/4504893712629760'),
    sentry_env: createReplaceVariable('prod')
}

export default API_KEYS;
