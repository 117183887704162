import { ListBase, ListToolbar, Pagination } from 'react-admin';
import Card from '@material-ui/core/Card';
import { cloneElement } from 'react';
import * as React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    exportButton: {
        minHeight: theme.spacing(6),
        "& > .MuiToolbar-root": {
            backgroundColor: "#fff",
            borderRadius: "1rem",
            '& > .MuiBox-root': {
                alignSelf: 'center'
            }
        }
    },
    noToolbar: {
        minHeight: 0,
        "& > .MuiToolbar-root": {
            backgroundColor: "#fff",
            borderRadius: "1rem",
            '& > .MuiBox-root': {
                alignSelf: 'center'
            }
        },
        "& > .MuiToolbar-regular": {
            minHeight: "10px"
        }
    },
    borderLast: {
        '& .MuiTableRow-root:last-of-type': {
            '& td': {
                borderBottom: '1px solid #e9ecef'
            }
        },
        '& .MuiTable-root': {
            marginBottom: 0
        }
    },
    pagination: {
        '& .MuiTablePagination-toolbar': {
            overflow: 'hidden'
        },
        '& .MuiTablePagination-caption': {
            fontSize: "12px"
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingTop: "4px",
            paddingBottom: "4px"
        },
        '& .MuiButton-textSizeSmall': {
            fontSize: "12px"
        }
    }

}));

const ListWrapper = ({ children, actions, bulkActionButtons, filters, title, showPagination = true, ...props }) => {
    const classes = useStyles();
    return (
        <ListBase perPage={25} {...props}>
            <Card className={props.safeguard && classes.borderLast}>
                <Box className={props.safeguard ? classes.noToolbar : classes.exportButton}>
                    <ListToolbar filters={filters} actions={actions} />
                </Box>
                {cloneElement(children, {
                    hasBulkActions: bulkActionButtons !== false,
                })}
                {showPagination &&  <Pagination labelRowsPerPage="Show" rowsPerPageOptions={[10,25,50,100]} className={classes.pagination} />}
            </Card>
        </ListBase>
    );
};

export default ListWrapper;
