import React from 'react';
import {
    DeleteButton,
    Responsive,
    SimpleList,
    Datagrid,
    TextField,
    EditButton,
    DateField,
    ReferenceManyField,
} from 'react-admin';
import { formatSimpleListDate } from '../../utils';
import AwardAndAccreditationGridBody from '../../components/AwardAndAccreditationGridBody';

const profileUrl = '/profile';

const AccreditationGrid = (props) => {
    return (
        <ReferenceManyField
            label={null}
            reference="firm-accreditations"
            target="firm_id"
            {...props}
        >
            <Responsive
                small={
                    <SimpleList
                        primaryText={(record) => record.accreditation.title}
                        secondaryText={(record) =>
                            `Date received: ${formatSimpleListDate(record.date)}`
                        }
                    />
                }
                medium={
                    <Datagrid size="medium" body={<AwardAndAccreditationGridBody />}>
                        <TextField width="80%" label="Name" source="accreditation.title" />
                        <DateField width="10%" label="Date received" source="date" />
                        <EditButton width="5%" label={false} />
                        <DeleteButton width="5%" redirect={profileUrl} label={false} />
                    </Datagrid>
                }
            />
        </ReferenceManyField>
    );
};

export default AccreditationGrid;
