import * as React from 'react';
import {
    Datagrid,
    DateField,
    downloadCSV,
    SimpleList,
    TextField,
    FunctionField,
} from 'react-admin';
import UserHeader from '../components/Headers/UserHeader';
import {Box, CircularProgress, makeStyles, Typography, useMediaQuery} from '@material-ui/core';
import ListWrapper from '../components/List/ListWrapper';
import { ArgonAdviser } from '../components/Argon/ArgonTableComponents/ArgonAdviser';
import ReviewInvitationFilter from './ReviewInvitationFilter';
import { useACL } from '../acl/acl';
import ListActions from '../components/ListActions';
import jsonExport from 'jsonexport/dist';
import { formatSimpleListDate } from '../utils';
import Button from '@material-ui/core/Button';
import { SURVEY_TYPE } from 'constants/various';
import {useHistory, useLocation} from "react-router-dom";
import {useState} from "react";
import {parse, stringify} from "query-string";
import CustomBooleanInput from "./CustomBooleanInput";
import {isFirmViewOnNetworkPortal, isNetworkView} from "../helpers/safeguard";

const useStyles = makeStyles(() => ({
    wrapper: {
        display: 'inline-flex',
        minWidth: '80px',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '45px',
    },
    simpleListText: {
        fontSize: '1rem',
    },
}));

const ReviewInvitationHeaders = {
    id: 'Invite Id',
    date: 'Date',
    adviser_name: 'Adviser name',
    client_id: 'Client ID',
    client_first_name: 'Client name',
    client_email: 'Client email',
    type: 'Type',
    review_status: 'Response to Invitation',
    review_last_left: 'Most Recent Review'
};

const ReviewButton = ({ record }) => {
    const surveyId = record.survey?.id;
    const surveyType = record.survey?.reviewer_type;
    if (!surveyId) return null;

    if (record.survey?.review_is_readable) {
        return (
            <Button
                href={
                    surveyType === SURVEY_TYPE.REVIEW
                        ? `/reviews/${surveyId}/show`
                        : `/first-impressions/${surveyId}/show`
                }
                color="secondary"
                style={{"fontSize": "12px", "textAlign":"start", "padding":"0px"}}
            >
                Read Review
            </Button>
        );
    }
    return (
        <Typography variant="body2">
            {record.survey?.message}
        </Typography>
    )
};

const SendReminderToggle = ({ record }) => {
    const classes = useStyles();

    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    if (record.survey.id) {
        // if there is already a review then this toggle is not application anymore
        return (
            <div className={classes.wrapper}>
                <FunctionField render={() => 'N/A'}/>
            </div>
        );
    }


    if ((new Date(record.created_at)).setHours(17, 0, 0, 0) > sevenDaysAgo) {
        return (
            <CustomBooleanInput label="Reminder" source="send_reminder" record={record}/>
        );
    }

    return (
        <div className={classes.wrapper}>
            <FunctionField render={() => 'Sent'}/>
        </div>
    );
}

export const ReviewInvitationList = props => {
    const classes = useStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isMedium = useMediaQuery('(max-width:1231px)');
    const { export: canExportPreviousInvites } = useACL('review-invitations');

    const history = useHistory()
    const location = useLocation();
    const [filter, setFilter] = useState(parse(location.search));

    const [isExporting, setIsExporting] = useState(false);
    const search = history.location.search;
    const params = new URLSearchParams(search);
    const adviserFromUrl = params.get('adviser');
    const firmFromUrl = params.get('firm');
    const network = isNetworkView();
    const firmNetworkView = isFirmViewOnNetworkPortal();

    const exportLoading = () => {
        setIsExporting(true);
    };

    const exporter = reviewInvitations => {
        const reviewInvitationsForExport = reviewInvitations.map(previousInvite => {
            const previousInviteForExport = {
                id : previousInvite.id,
                date : formatSimpleListDate(previousInvite.created_at),
                client_id: previousInvite.crm_customer_code,
                client_first_name : previousInvite.client_first_name,
                client_email : previousInvite.client_email,
                adviser_name : previousInvite.professional_full_name,
                type : (previousInvite.invitation_type.charAt(0).toUpperCase() + previousInvite.invitation_type.slice(1)).replace('_', ' '),
                review_status : previousInvite.review_is_readable ? 'Review Left' : previousInvite.message,
                review_last_left: previousInvite.review_last_left,
            };

            if(network && !firmNetworkView) {
                previousInviteForExport['firm_name'] = previousInvite.firm_name;
            }

            return previousInviteForExport;
        });

        if(network && !firmNetworkView) {
            ReviewInvitationHeaders['firm_name'] = 'Firm';
        }

        const buildHeaders = () => {
            let headers = [];
            let rename = [];
            Object.keys(ReviewInvitationHeaders).map(key => {
                headers = [...headers, key];
                rename = [...rename, ReviewInvitationHeaders[key]];
            });
            return { headers, rename };
        };
        jsonExport(reviewInvitationsForExport, buildHeaders(), (err, csv) => {
            downloadCSV(csv, 'invite-history');
        });
        setIsExporting(false);
    };

    const handleFilterChange = (value) => {
        setFilter({ ...filter, adviser: value });
        history.push({
            pathname: location.pathname,
            search: stringify({ ...filter, adviser: value }),
        });
    };

    const handleFirmFilterChange = (value) => {
        setFilter({ ...filter, firm: value });
        history.push({
            pathname: location.pathname,
            search: stringify({ ...filter, firm: value }),
        });
    };

    const renderXSmallList = () => {
        return (
            <SimpleList
                linkType={false}
                style={{ 'word-break': 'break-all' }}
                primaryText={record => `Client name: ${record.client_first_name}`}
                secondaryText={record => (
                    <>
                        Client email: {record.client_email}
                        <br />
                        <div className={classes.wrapper}>
                            <Typography className={classes.simpleListText}>7 day Reminder: </Typography>
                            <SendReminderToggle label="7 day Reminder" record={record} />
                        </div>
                    </>
                )}
            />
        );
    };

    const renderSmallList = () => {
        return (
            <SimpleList
                linkType={false}
                primaryText={(record) => `Client name: ${record.client_first_name}`}
                secondaryText={(record) =>
                    (
                        <>
                            Adviser name: {record.professional.full_name}
                            <br/>
                            Client email: {record.client_email}
                            <br />
                            <div className={classes.wrapper}>
                                <Typography className={classes.simpleListText}>7 day Reminder: </Typography>
                                <SendReminderToggle label="7 day Reminder" record={record} />
                            </div>
                        </>
                    )}
            />
        );
    };

    const renderMediumList = () => {
        return (
            <Datagrid>
                <ArgonAdviser label="Adviser name" source="professional.full_name" />
                <TextField label="Client email" source="client_email" />
                <SendReminderToggle label="7 day Reminder"/>
            </Datagrid>
        );
    };

    const renderLargeList = () => {
        return (
            <Datagrid>
                <DateField label="Date of review invitation" source="created_at" />
                <ArgonAdviser label="Adviser name" source="professional.full_name" />
                {network && !firmNetworkView && (
                    <TextField label="Firm name" source="firm_name"/>
                )}
                <TextField label="Client name" source="client_first_name" />
                <TextField label="Client email" source="client_email" />
                <FunctionField
                    label="Type"
                    render={record => (record.type.charAt(0).toUpperCase() + record.type.slice(1)).replaceAll('_', ' ')}
                    source="type"
                />
                <ReviewButton label="Response to Invitation" />
                <DateField label="Most Recent Review" source="review_last_left" sortable={false}/>
                <SendReminderToggle label="7 day Reminder"/>
            </Datagrid>
        );
    };

    const InviteHistoryHeader = () => (
        <Typography variant={"body2"}>
            The Response to Invitation column indicates whether the customer left a review via the link in the review invite email, and if so what the status of that review is.
            <br/> <br/>
            The Most Recent Review column is the date on which we last received a review of this adviser from this email, regardless of whether that was via this specific invite or not.  This can be used to exclude customers from future invites based on recency.
        </Typography>
    )

    return (
        <>
            <UserHeader
                title="Invite History"
                description={<InviteHistoryHeader />}
            />
            <ListWrapper
                basePath="/invite-history"
                resource="review-invitations"
                {...props}
                title="Review Invitaitons"
                bulkActionButtons={false}
                actions={
                canExportPreviousInvites ?
                    (isExporting ?
                        ( <Box marginRight="1rem">
                            <CircularProgress />
                        </Box>)
                        : <ListActions resource={"review-invitations/csv"} exporter={exporter} exportLoading={exportLoading} />)
                    : false
                }
                filters={<ReviewInvitationFilter isXSmall={isXSmall}  onFilterChange={handleFilterChange} onFirmFilterChange={handleFirmFilterChange} adviser={adviserFromUrl} firm={firmFromUrl}/>}
                sort={{ field: 'created_at', order: 'DESC' }}
            >
                {isXSmall
                    ? renderXSmallList()
                    : isSmall
                    ? renderSmallList()
                    : isMedium
                    ? renderMediumList()
                    : renderLargeList()}
            </ListWrapper>
        </>
    );
};

export default ReviewInvitationList;
