import React from 'react';
import CopyTextButton from '../../components/Buttons/CopyTextButton';
import ReputationToolItem from './components/ReputationToolItem';

import Button from '@material-ui/core/Button';

const RatingWidget = ({ widget }) => {

    return (
        <ReputationToolItem
            img={widget.widgetUrl}
            actions={
                <>
                    <CopyTextButton clipboardContent={widget.widgetCode} variant="contained">
                        Copy widget code
                    </CopyTextButton>
                </>
            }
        />
    );
}

export default RatingWidget;
