import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { List, Pagination } from 'react-admin';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiToolbar-root': {
            fontSize: '14px',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: 0,
            '& form': {
                alignItems: 'center',
            },
            zIndex: 0,
        },
        '& .MuiPaper-root > .MuiToolbar-root': {
            padding: '0 16px',
            justifyContent: 'flex-end',
            gap: 20,
            flexDirection: 'row-reverse',
            '& .MuiTypography-root': {
                marginTop: 0,
                marginBottom: 0,
            },
        },
        '& .MuiPaper-rounded': {
            padding: 0,
        },
        '& th': {
            backgroundColor: '#daedf3',
            fontSize: '14px',
            letterSpacing: '0px',
            fontWeight: 400,
            textTransform: 'none',
            height: 45,

        },
        '& .MuiTypography-root': {
            fontSize: '14px',
        },
    },
    pagination: {
        '& .MuiTablePagination-toolbar': {
            overflow: 'hidden'
        },
        '& .MuiTablePagination-caption': {
            fontSize: "12px"
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingTop: "4px",
            paddingBottom: "4px"
        },
        '& .MuiButton-textSizeSmall': {
            fontSize: "12px"
        },
    }
}));

const TableForListComponent = (props) => {
    const classes = useStyles();

    return (
        <List
            {...props}
            className={classes.root}
            perPage={25}
            pagination={<Pagination labelRowsPerPage="Show" rowsPerPageOptions={[10,25,50,100]} className={classes.pagination} />}
        >
            {props.children}
        </List>
    );
};

export default TableForListComponent;