import Button from '@material-ui/core/Button';
import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

export const ShowReviewDataButton = ({ canShow, record }) => {
    const reviewId = record.id ? record.id : false;
    const type = record.type;
    const url = type === 'Review'
    ? `/reviews/${reviewId}/show`
    : `/first-impressions/${reviewId}/show`

    const classes = useStyles();

    return (
        canShow ?
            (<Button
                href={url}
                color="secondary"
                className={classes.button}
            >
                Read Review
            </Button>)
            : null
    );
};

const useStyles = makeStyles(theme => ({
    button: {
        '& .MuiButton-label': {
            all: 'none',
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: 200,
        }

    }
}));