import React from 'react';
import _ from 'lodash';

import Typography from '@material-ui/core/Typography';
import AddFeeLevelButton from "../../components/AddFeeLevelButton";
import FeesDeleteButton from '../../../Fees/components/FeesDeleteButton';
import MortgageFeeLevelDataGrid from "./MortgageFeeLevelDataGrid";
import PageSectionTitle from "../../../ReputationTools/Tools/components/PageSectionTitle";

const MortgageFeeLevelList = ({ data }) => {
    const mortgageChargeLevels = data && data.chargeLevels
    const mortgageFeeId = data && data.id

    return (
        <>
            <br />
            <PageSectionTitle title="Mortgage Fees" />
            {_.isEmpty(mortgageChargeLevels) ? (
                <Typography variant="body1">You haven't added any mortgage fee levels</Typography>
            ) : (
                <MortgageFeeLevelDataGrid data={mortgageChargeLevels} feeId={mortgageFeeId} />
            )}
            <AddFeeLevelButton
                to={{
                    pathname: '/fee-level/create',
                    state: {
                        feeType: 'mortgage',
                        subType: 'mortgage'
                    },
                }}
            />
            <FeesDeleteButton record={data} />
        </>
    );
};

export default MortgageFeeLevelList;
