import Grid from '@material-ui/core/Grid';
import CardStatsGradient from '../components/Cards/Widgets/CardStatsGradient';
import { PersonOutline } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import AdviserLogoImage from '../components/AdviserLogoImage';
import ArgonTextInput from '../components/Argon/ArgonTextInput';
import { required } from 'react-admin';

const AdviserInfo = (props) => {
    const theme = useTheme();
    const data = _.get(props, 'record', {});

    const aboutSectionHelperText = "In order for the adviser profile to appear in google search results, the about section must have at least 50 words";

    return (
        <Box style={{ marginBottom: 100 }}>
            <Grid container spacing={4}>
                <Grid item xs={12} lg={4}>
                    <CardStatsGradient
                        title={data.full_name}
                        icon={PersonOutline}
                        color="bgGradientTitle"
                        footer={
                            <>
                                <Box
                                    component="span"
                                    fontSize=".875rem"
                                    color={theme.palette.white.main}
                                    marginRight=".5rem"
                                    display="flex"
                                    alignItems="center"/>
                            </>
                        }
                    />
                    <Box display="flex" justifyContent="center" width="100%">
                        <AdviserLogoImage source="avatar" isAdmin={true} record={data} />
                    </Box>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <ArgonTextInput
                        multiline
                        source="about"
                        defaultValue={data.about}
                        label="About"
                        validate={required()}
                        helperText={aboutSectionHelperText}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default AdviserInfo;
