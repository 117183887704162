import React, { useState } from 'react';
import {
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
    ComposedChart,
    ResponsiveContainer,
} from 'recharts';
import {Box, CardContent, makeStyles, Card, Typography, useTheme} from '@material-ui/core';
import componentStyles from '../../assets/theme/components/headers/stats-header.js';
import boxShadows from '../../assets/theme/box-shadow.js';
import ChartXAxisLabel from './components/ChartXAxisLabel';
import { isDataSafeToDisplayInCharts } from '../../helpers/safeguard';

const useStyles = makeStyles(componentStyles);
const useCustomStyles = makeStyles(theme => ({
    colorGreen: {
        color: theme.palette.green.main,
    },
    colorRed: {
        color: theme.palette.cherryRed,
    }
}));

export const CustomChart = props => {
    const classes = {...useStyles(), ...useCustomStyles() };

    const { data, displaySettings } = props;

    const safeToDisplay = isDataSafeToDisplayInCharts(data.length);

    const [line1Display, setLine1Display] = useState(true);
    const [line2Display, setLine2Display] = useState(true);
    const [barDisplay, setBarDisplay] = useState(true);

    const [opacity, setOpacity] = useState({ line2: 1, line1: 1, bar: 1 });
    const theme = useTheme();

    // FORMATTING DATA
    let chartData = data;

    chartData = chartData.map(o => {
        return {
            line1: o[displaySettings.line1],
            line2: o[displaySettings.line2],
            bar: o[displaySettings.bar],
            xAxis: o[displaySettings.xAxis],
            interpretation: displaySettings.interpretation,
        };
    });

    const yAxisLeftMax = Math.max.apply(
        Math,
        chartData.map(o => o.bar)
    );

    const commonLineProps = {
        type: 'monotone',
        cursor: 'pointer',
        isAnimationActive: false,
    };

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {

            let tooltipData = '';
            switch (displaySettings.tooltipType) {
                case 'driverIndustryAverage':
                    tooltipData = (<> Firm average of <span className={classes.colorGreen}>{payload[2]?.value}%</span>
                        {' '}vs industry average of <span className={classes.colorRed}>{payload[1]?.value}%</span>. This
                        is based on {payload[0]?.value} reviews.</>);
                    break;
                default:
                    tooltipData = (<><span className={classes.colorGreen}>{payload[2]?.value}%</span>{' '}
                {displaySettings.tooltip} vs. the industry average of{' '}
                    <span className={classes.colorRed}>{payload[1]?.value}%</span>. This
                    is based on {payload[0]?.value} reviews.</>);

            }
            return (
                <div className={classes.customTooltip}>
                    <p className={classes.customDescriptionTooltip}>
                        <p variant="body1">{payload[0].payload.xAxis}</p>
                        <Typography variant="body2">
                            {tooltipData}
                        </Typography>
                    </p>
                </div>
            );
        }
        return null;
    };

    const clickLegend = e => {
        if (e.dataKey === 'line1') {
            setLine1Display(!line1Display);
        }

        if (e.dataKey === 'bar') {
            setBarDisplay(!barDisplay);
        }

        if (e.dataKey === 'line2') {
            setLine2Display(!line2Display);
        }
    };

    const hoverLegend = e => {
        let newOpacity = { ...opacity };
        Object.keys(newOpacity).forEach(key => {
            if (key !== e.dataKey) newOpacity[key] = 0.2;
        });
        setOpacity(newOpacity);
    };
    const leaveLegend = e => {
        let newOpacity = { ...opacity };
        Object.keys(newOpacity).forEach(key => {
            newOpacity[key] = 1;
        });
        setOpacity(newOpacity);
    };

    const renderLegendText = (value, entry) => {
        const { color } = entry;

        return (
            <span
                style={{
                    color,
                    textDecoration: entry.payload.strokeWidth === 0 ? 'line-through' : 'none',
                }}>
                {value}
            </span>
        );
    };

    return (
        <Card
            classes={{ root: classes.cardRoot }}
            elevation={0}
            component={Box}
            boxShadow={boxShadows.boxShadow}>
            <CardContent
                classes={{ root: classes.cardContentRoot }}
                boxShadow={boxShadows.boxShadow}
                data-test={"custom-chart"}
            >
                <div className={classes.chartTitle}>{displaySettings.title}</div>
                <div className={classes.chartDescription}>{displaySettings.description}</div>
                <ResponsiveContainer width="98%" height={450} className={classes.chart}>
                    <ComposedChart
                        data={chartData}
                        barCategoryGap={0}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        style={{ textAlign: 'center' }}>
                        <YAxis
                            type="number"
                            yAxisId={0}
                            domain={[0, Math.ceil(yAxisLeftMax / 5) * 5]}
                            axisLine={false}
                            tickLine={false}
                            ticks={[
                                0,
                                Math.floor((Math.ceil(yAxisLeftMax / 5) * 5) / 2),
                                Math.ceil(yAxisLeftMax / 5) * 5,
                            ]}
                            label={{
                                value: displaySettings.yLabelLeft,
                                angle: -90,
                                position: 'insideLeft',
                            }}
                        />
                        <YAxis
                            type="number"
                            orientation="right"
                            yAxisId={1}
                            axisLine={false}
                            tickLine={false}
                            ticks={[0, 50, 100]}
                            label={{
                                value: displaySettings.yLabelRight,
                                angle: -90,
                                position: 'right',
                            }}
                            tickFormatter={tick => {
                                return `${tick}%`;
                            }}
                        />
                        <XAxis
                            dataKey="xAxis"
                            height={120}
                            interval={0}
                            tick={props => <ChartXAxisLabel safeToDisplay={safeToDisplay} {...props} />}
                        />
                        <Legend
                            verticalAlign="top"
                            height={36}
                            onClick={clickLegend}
                            onMouseEnter={hoverLegend}
                            onMouseLeave={leaveLegend}
                            wrapperStyle={{ cursor: 'pointer' }}
                            formatter={renderLegendText}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Bar
                            dataKey="bar"
                            fill={theme.palette.secondary.btnActive}
                            name={displaySettings.barLabel}
                            barSize={100}
                            yAxisId={0}
                            style={{ cursor: 'pointer' }}
                            radius={[8, 8, 0, 0]}
                            opacity={opacity.bar}
                        />
                        <Line
                            type="monotone"
                            dataKey="line1"
                            stroke={theme.palette.warning.badge}
                            strokeWidth={line1Display ? 3 : 0}
                            yAxisId={1}
                            name="Industry Average"
                            dot={line1Display ? { strokeWidth: 2 } : false}
                            activeDot={{ r: line1Display ? 8 : 0, cursor: 'pointer' }}
                            {...commonLineProps}
                        />
                        )
                        <Line
                            dataKey="line2"
                            stroke={theme.palette.primary.main}
                            strokeWidth={line2Display ? 3 : 0}
                            yAxisId={1}
                            name="Firm"
                            strokeOpacity={opacity.line2}
                            dot={line2Display ? { strokeWidth: 2 } : false}
                            activeDot={{ r: line2Display ? 8 : 0, cursor: 'pointer' }}
                            {...commonLineProps}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};
