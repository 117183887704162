import React from 'react';
import get from 'lodash/get';

import { Edit, Toolbar, SimpleForm, useRecordContext, SaveButton, useRedirect } from 'react-admin';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import { transformParams } from '../helpers/fileUploads';
import Step4, { WarningMessage } from './components/Step4';
import Step5 from './components/Step5';
import Step6 from './components/Step6';
import UserHeader from '../components/Headers/UserHeader';
import Step7 from './components/Step7';

const Step = (props) => {
    const record = useRecordContext(props);

    switch (get(record, 'next_step', null)) {
        case 2:
            return <Step2 record={record} />;
        case 3:
            return <Step3 record={record} />;
        case 4:
            return <Step4 record={record} />;
        case 5:
            return <Step5 record={record} />;
        case 6:
            return <Step6 record={record} />;
        case 7:
            return <Step7 />;
        default:
            return null;
    }
};

const Message = (props) =>
    props.record.error_message_html ? (
        props.record.next_step === 3  && <WarningMessage warnings = {props.record.error_message_html}
        data-test={"error-message"}/>
    ) : null;

const transform = (data) => {
    return transformParams(data).then((newdata) => {
        return newdata;
    });
};


const BulkInviteToolbar = (props) => {
    const redirect = useRedirect();
    if (props.record.next_step == 4 && props.record.invite_records_count == 0) {
        return (
            <Toolbar alwaysEnableSaveButton {...props}>
                <SaveButton
                    icon={<></>}
                    label="Return to Step 1"
                    onSuccess={() => null}
                    onClick={() => redirect('/bulk-invites/create')}
                    data-test={"button-return-step1"}
                />
            </Toolbar>
        );
    }

    return (
        <>
            {props.record.next_step < 7 ? (
                <Toolbar alwaysEnableSaveButton {...props}>
                    <SaveButton
                        icon={<></>}
                        label={props.record.next_step === 6 ? 'Send invites' : 'Next step'}
                        redirect="edit"
                        submitOnEnter={true}
                        disabled={props.record.invalid_emails}
                        onSuccess={() => true}
                        data-test="button-next-step"
                    />
                </Toolbar>
            ) : null}
        </>
    );
};

const BulkInviteEdit = (props) => (
    <>
        <UserHeader title="Invite Tool" />
        <Edit undoable={false} {...props} transform={transform}>
            <SimpleForm redirect="edit" toolbar={<BulkInviteToolbar />}>
                <Message />
                <Step />
            </SimpleForm>
        </Edit>
    </>
);

export default BulkInviteEdit;
