import _ from 'lodash';
import {FEE_TYPE, FEE_SUB_TYPE} from "../../constants/professional";

const { INITIAL_AND_ONGOING, FIXED_FEE_ONLY } = FEE_SUB_TYPE;
const {
    INVESTMENT_AND_PENSION,
    INVESTMENT_AND_PENSION_WITH_PLANNING,
} = FEE_TYPE;

export function getFeeLevelFormTitle(
    feeType,
    investmentFeeSubType,
    isCreateForm = false
) {
    const prefix = isCreateForm ? 'Add' : 'Edit';

    if (feeType === '') {
        return `${prefix} Fee Level`;
    }

    if (investmentFeeSubType === FIXED_FEE_ONLY) {
        return `${prefix} Fee Level: ${feeType}, Fixed Fee only`;
    }

    return `${prefix} Fee Level: ${feeType}, Percentage of Assets only`;
}

export function getFeesCreateTypeLabel(type) {
    if (!type) return '';

    if (type === INVESTMENT_AND_PENSION) {
        return 'Implementation & Ongoing Service fees';
    }

    if (type === INVESTMENT_AND_PENSION_WITH_PLANNING) {
        return 'Financial Planning fees';
    }

    return '';
}

export function getFeesCreateStatistics(values) {
    const data = [];
    const type = _.get(values, 'type', '');
    const fees = _.get(values, 'fees', []);

    if (type === INVESTMENT_AND_PENSION_WITH_PLANNING) {
        data.push({
            value: 109,
            label:
                'VouchedFor advisers with Financial Planning with Implementation & Ongoing Service fees',
        });
    }

    if (type === INVESTMENT_AND_PENSION) {
        data.push({
            value: 306,
            label:
                'VouchedFor advisers with Implementation & Ongoing Service (without Financial Planning) fees',
        });
    }

    if (fees.length) {
        values.fees.forEach(fee => {
            if (fee.type === INVESTMENT_AND_PENSION_WITH_PLANNING) {
                if (fee.subType === FIXED_FEE_ONLY) {
                    data.push({
                        value: '81%',
                        label: `also use Fixed Fees for ${getFeesCreateTypeLabel(
                            INVESTMENT_AND_PENSION_WITH_PLANNING
                        )}`,
                    });
                }

                if (fee.subType === INITIAL_AND_ONGOING) {
                    data.push({
                        value: '19%',
                        label: `also use Percentage of Assets for ${getFeesCreateTypeLabel(
                            INVESTMENT_AND_PENSION_WITH_PLANNING
                        )}`,
                    });
                }
            }

            if (fee.type === INVESTMENT_AND_PENSION) {
                if (fee.subType === FIXED_FEE_ONLY) {
                    data.push({
                        value: '8%',
                        label: `also use Fixed Fees for ${getFeesCreateTypeLabel(
                            INVESTMENT_AND_PENSION
                        )}`,
                    });
                }

                if (fee.subType === INITIAL_AND_ONGOING) {
                    data.push({
                        value: '92%',
                        label: `also use Percentage of Assets for ${getFeesCreateTypeLabel(
                            INVESTMENT_AND_PENSION
                        )}`,
                    });
                }

                if (_.has(fee, 'isWealthBased')) {
                    if (fee.isWealthBased === true) {
                        data.push({
                            value: '40%',
                            label: 'also tier their charges this way',
                        });
                    } else {
                        data.push({
                            value: '60%',
                            label: 'also tier their charges this way',
                        });
                    }
                }
            }
        });
    }

    return data;
}

export function getFeesCreateFormLabels(
    type,
    withFinancialPlanning,
    isWidthDown = false
) {
    const typeLabel = getFeesCreateTypeLabel(type, withFinancialPlanning);
    let subType = `Please choose the model you'd like to use for ${typeLabel}`;
    let isWealthBased = `Please choose which way you tier your charges for ${typeLabel}`;

    if (isWidthDown) {
        subType = `Select model for ${typeLabel}`;
        isWealthBased = `How do you tier your charges for this model?`;
    }

    return { subType, isWealthBased };
}
