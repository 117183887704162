import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { FormDataConsumer, required } from 'react-admin';
import InputPrefix from '../../../components/Inputs/InputPrefix';
import {PageTitle} from '../../../components/Typography/Headlines';
import { getFeeLevelFormTitle } from '../../../Fees/components/feesUtils';
import FootNote from '../../../components/Typography/FootNote';
import FormTextInput from '../../../components/Inputs/Form/FormTextInput';
import FormSelectInput from '../../../components/Inputs/Form/FormSelectInput';
import FormNumberInput from '../../../components/Inputs/Form/FormNumberInput';
import {FEE_SUB_TYPE} from "../../../constants/professional";
import {FEE_LEVEL_TYPE_CHOICES} from "../../../constants/lists";

const { FIXED_FEE_ONLY } = FEE_SUB_TYPE;


const InitialAndOngoingFeeLevelForm = ({type, subType, ...props}) => {
    const [redirect] = useState(false);
    const isCreateForm = window.location.href.includes('create');

    let levelType;

    if (isCreateForm) {
        levelType = props.location.state.subType;
    } else {
        levelType = props.record.entityType;
    }

    const inputPrefix = levelType === FIXED_FEE_ONLY ? '£' : '%';

    if (redirect) {
        return <Redirect to="/fees" />;
    }

    return (
        <>
            <PageTitle
                title={getFeeLevelFormTitle('Initial and Ongoing', levelType, isCreateForm)}
            />
            <FormSelectInput
                label="For amounts"
                validate={[required()]}
                source="levelType"
                choices={FEE_LEVEL_TYPE_CHOICES}
            />
            {isCreateForm && <FormTextInput
                style={{display: 'none'}}
                source="type"
                defaultValue={type}
            />}
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    const { levelType } = formData;

                    if (levelType === 'any_amount') {
                        return null;
                    }

                    return (
                        <FormNumberInput
                            source={levelType === 'up_to' ? 'maximumAmount' : 'minimumAmount'}
                            {...rest}
                            label="Value"
                            InputProps={{
                                startAdornment: <InputPrefix label="£" />,
                            }}
                        />
                    );
                }}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    formData.levelType === 'between' && (
                        <FormNumberInput
                            source="maximumAmount"
                            label="Max Value"
                            {...rest}
                            InputProps={{
                                startAdornment: <InputPrefix label="£" />,
                            }}
                        />
                    )
                }
            </FormDataConsumer>
            <FormNumberInput
                label="Initial charge"
                source="initialCharge"
                validate={[required()]}
                InputProps={{
                    startAdornment: <InputPrefix label={inputPrefix} />,
                }}
            />
            <FormNumberInput
                label="Ongoing charge"
                source="ongoingCharge"
                validate={[required()]}
                InputProps={{
                    startAdornment: <InputPrefix label={inputPrefix} />,
                }}
            />
            {type === 'investment_and_pension_with_financial_planning' && (
                <FootNote
                    content={
                        <span>
                            if you only charge an initial fixed fee for financial planning, just
                            enter £0 in the <b>Ongoing Charge</b> field.
                        </span>
                    }
                />
            )}
        </>
    );
};

export default InitialAndOngoingFeeLevelForm;
