import React from 'react';
import { Filter, AutocompleteInput, ReferenceInput, TextInput } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles(() => ({

    xSmallView: {
        marginTop: '20px',
        marginBottom: '10px',
        '& .MuiFilledInput-inputMarginDense': {
            paddingTop: '6px',
        },
    },
    largeView: {
        marginTop: '20px',
        marginLeft: '20px',
        marginBottom: '20px',
        display: 'flex',
        '& .MuiFilledInput-inputMarginDense': {
            paddingTop: '6px',
        },
    },
    label: {
        fontWeight: 'bold',
        fontFamily: 'Nunito',
    }
}));

export const AutocompleteInputCustom = (props) => {
    const { adviser } = props
    const val = props.choices.find(ch => {
        return ch.id == parseInt(adviser)
    })

    return (
        <AutocompleteInput
            {...props}
            optionValue="id"
            optionText="name_only"
            resettable={false}
            defaultValue={val && val.id}
            inputProps={{ placeholder: 'Test' }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label="test"
                    placeholder="Favorites"
                />
            )}
        />
    )
}

const ReviewsFilter = ({ isXSmall, adviser, onFilterChange }, props) => {
    const classes = useStyles();
    return (
    <div>
        <div className={isXSmall ? classes.xSmallView : classes.largeView}>
            <label className={classes.label}> Filter by</label>
        </div>
        <div className={isXSmall ? classes.xSmallView : classes.largeView}>
            <div data-test={"filter-adviser"}>
                <label className={classes.label}>Adviser</label>
                <Filter {...props}>
                    <ReferenceInput
                        filter = {{ minimal_fields: true }}
                        label = {""}
                        source = "professional_id"
                        reference = "professionals"
                        alwaysOn
                        onChange = {onFilterChange}
                        defaultValues = {adviser}
                    >
                        <AutocompleteInputCustom adviser={adviser} />
                    </ReferenceInput>
                </Filter>
            </div>
            <div data-test={"filter-client-email"}>
                <label className={classes.label}>Client email</label>
                <Filter {...props}>
                    <TextInput label={false} alwaysOn source="client_email" />
                </Filter>
            </div>
            <div data-test={"filter-client-name"}>
                <label className={classes.label}>Client name</label>
                <Filter {...props}>
                    <TextInput label={false} alwaysOn source="client_first_name" />
                </Filter>
            </div>
        </div>
    </div>
    );
};

export default ReviewsFilter;
