import React from 'react';
import PropTypes from 'prop-types';
import { SaveButton, useRedirect } from 'react-admin';
import Toolbar from '../components/Forms/FormToolbar';
import UserHeader from "./Headers/UserHeader";

const propTypes = {
    label: PropTypes.string.isRequired,
    validForm: PropTypes.bool,
    style: PropTypes.object,
};

const defaultProps = {
    validForm: true,
    label: 'Save',
    style: null,
    submitOnEnter: true,
};

const FormToolbar = ({ label, validForm, style, submitOnEnter, ...props }) => {

    return (
        <Toolbar {...props}>
            <SaveButton
                label={label}
                disabled={!validForm}
                invalid={true}
                submitOnEnter={submitOnEnter}
                style={style}
            />
        </Toolbar>
    );
};

export const BulkInviteToolbar = ({ disabled = false, label = 'Next Step', redirect, ...props }) => {
 
    const redirectTo = useRedirect()

    return (
        <>
            <UserHeader title="Invite Tool" description='Send review invites to prospects or clients on behalf of your advisers.'/>
        <Toolbar {...props} >
            <SaveButton
                // style={{ 'height': '50px', 'width': '280px', 'fontSize': '20px' }}
                disabled={disabled}
                // variant='outlined'
                label={label}
                redirect={redirect}
                icon={<></>}
                onSuccess={(res) => {
                    redirectTo(`${props.basePath}/${res.data.id}`)
                }}
                data-test={"button-" + label}
            />
        </Toolbar>
        </>
    )
};

FormToolbar.propTypes = propTypes;
FormToolbar.defaultProps = defaultProps;

export default FormToolbar;
