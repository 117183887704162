import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { FOCUS_SELECT, QUERY_ID, CHART_LABELS } from '../../constants/safeguard';
import { ArgonSelectInput } from '../../components/Argon/ArgonSelectInput';
import { CustomBarChart } from '../../SafeGuard/Charts/BarChart';
import { CustomChart } from '../../SafeGuard/Charts/CustomChart';
import { getFilteredDrivers } from 'helpers/safeguard';
import Spinner from 'components/Spinner';

const FirmPerformance = props => {
    const { classes, goal, onChange, drivers, graphs, serviceType } = props;

    const [selectedDriver, setSelectedDriver] = useState(null);
    const [driversForSelect, setDriversForSelect] = useState([]);
    const [displaySettings, setDisplaySettings] = useState([]);

    useEffect(() => {
        if(drivers) {
            const filteredDrivers = getFilteredDrivers(drivers, goal.id, serviceType);
            const formattedDrivers = filteredDrivers.map(item => {
                return {
                    id: 3,
                    name: item.name,
                    filter: { driver_id: item.id },
                };
            });
            setDriversForSelect(formattedDrivers);
        }
    }, [drivers, serviceType]);

    const handleChange = e => {
        let displaySettings = {
            avgIndustry: CHART_LABELS.driverVsIndustryAverage,
            allAdvisers: CHART_LABELS.driverForAllAdvisers
        };

        const avgIndustryTitle = e.target.value.name;
        const allAdvisersTitle = e.target.value.name + ' across all advisers at the firm ';

        displaySettings.avgIndustry.title = avgIndustryTitle;
        displaySettings.allAdvisers.title = allAdvisersTitle;

        setDisplaySettings(displaySettings);
        setSelectedDriver(e.target.value);
        onChange(e);
    };

    const renderSelectedDriverGraphs = () => {
        const driverFirmAvgData = graphs.find(item => item.id === QUERY_ID.DRIVER_FIRM_AVERAGE);
        const driverAcrossAdvisersData = graphs.find(item => item.id === QUERY_ID.DRIVER_ACROSS_ALL_ADVISERS_AT_FIRM)
        return (
            <>
                {driverFirmAvgData && driverFirmAvgData.data && driverFirmAvgData.data.length ?
                    <CustomChart
                        data={graphs.find(item => item.id === QUERY_ID.DRIVER_FIRM_AVERAGE).data}
                        displaySettings={displaySettings.avgIndustry}
                    />
                    : null}
                {driverAcrossAdvisersData && driverAcrossAdvisersData.data && driverAcrossAdvisersData.data.length ?
                    <CustomBarChart
                        data={graphs.find(item => item.id === QUERY_ID.DRIVER_ACROSS_ALL_ADVISERS_AT_FIRM).data}
                        displaySettings={displaySettings.allAdvisers}
                    />
                    : null}

            </>
        )
    }

    const renderNoDriverGraph = () => {
        return (
            <>
                {(graphs.length && graphs[0]['data'].length) ? (
                    <CustomBarChart
                        data={graphs.find(item => item.id === QUERY_ID.FIRM_PERFORMANCE).data}
                        displaySettings={CHART_LABELS.firmPerformance[`goal${goal.id}`]}
                        double
                    />
                ) 
                : (null)}
            </>
        )
    }

    return (
        <Box>
            { (graphs.length || selectedDriver) ? <Box className={classes.dropdownWrapper}>
                <ArgonSelectInput
                    onChange={handleChange}
                    name={FOCUS_SELECT[0].type}
                    className={classes.dropdown}
                    value={selectedDriver ? selectedDriver : '0'}
                    data={driversForSelect}
                    dataLabelName="name"
                    dataKeyName="all"
                    defaultItem="Select a driver"
                    selectElementOnly={true}
                    noForm={true}
                    data-test={"compare-select-driver"}
                /> 
            </Box> : null}
            <Box className={classes.chartWrapper}>
                {!graphs.length ? <Spinner /> : (selectedDriver && graphs.length) ? renderSelectedDriverGraphs() : renderNoDriverGraph() }
            </Box>
        </Box >
    );
};
export default FirmPerformance;
