import React, { useState } from 'react';
import cn from 'classnames';
import CopyTextButton from '../../components/Buttons/CopyTextButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import ReputationToolItem from "./components/ReputationToolItem";

const useStyles = makeStyles(theme => ({
    content: {
        textAlign: 'center',
        borderRadius: theme.spacing(2)
    },
    widgetWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        minHeight: 150,
    },
    widget: {
        position: 'absolute',
        transition: 'opacity 0.2s ease',
    },
    themeToggle: {
        marginBottom: theme.spacing(2),
        marginRight: 0,
        display: 'block',
        textAlign: 'center',
        color: theme.palette.primary.textPrimary,
    },
    themeToggleWhite: {
        color: theme.palette.primary.contrastText,
    },
    actions: {
        marginTop: theme.spacing(2),
    },
}));

const StarWidget = ({ widget }) => {
    const classes = useStyles();
    const [variant, setVariant] = useState('dark');

    if (!widget) {
        return null;
    }

    return (
        <ReputationToolItem
            CardProps={{
                style: {
                    background: variant === 'dark' ? '#fff' : '#282828',
                },
            }}
            content={
                <div className={classes.content}>
                    <FormControlLabel
                        className={cn({
                            [classes.themeToggle]: true,
                            [classes.themeToggleWhite]: variant === 'light',
                        })}
                        label={variant === 'dark' ? <span style={{'fontSize': '16px'}}>Switch to light colours</span> : <span style={{'fontSize':'16px'}}>Switch to dark colours</span>}
                        control={
                            <Switch
                                checked={variant === 'light'}
                                onChange={() => setVariant(variant === 'light' ? 'dark' : 'light')}
                                name="variant-toggle"
                                color="secondary"
                            />
                        }
                    />
                    <div className={classes.widgetWrapper}>
                        <div
                            dangerouslySetInnerHTML={{ __html: widget.content.light.html }}
                            className={classes.widget}
                            style={{
                                visibility: variant === 'light' ? 'visible' : 'hidden',
                                opacity: variant === 'light' ? 1 : 0,
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{ __html: widget.content.dark.html }}
                            className={classes.widget}
                            style={{
                                visibility: variant === 'dark' ? 'visible' : 'hidden',
                                opacity: variant === 'dark' ? 1 : 0,
                            }}
                        />
                    </div>
                    <div className={classes.actions}>
                        <CopyTextButton
                            clipboardContent={widget.content[variant].code}
                            variant="contained"
                        >
                            Copy widget code
                        </CopyTextButton>
                    </div>
                </div>
            }
        />
    );
};

export default StarWidget;
