import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { AutocompleteInput, Filter, ReferenceInput, TextField } from 'react-admin';
import Button from '@material-ui/core/Button';
import DateRangeDialogFilter from '../../SafeGuard/components/DateRangeDialogFilter';
import { formatRange } from '../../utils';
import { isNetworkDashboard } from '../../helpers/safeguard';
import { useSelector } from 'react-redux';
import { MAX_GET_LIMIT } from '../../constants';

const useStyles = makeStyles((theme) => ({
    container: {
        margin: '20px',
    },
    label: {
        paddingTop: '10px',
        fontWeight: 'bold',
    },
    allFilters: {
        display: 'flex',
        gap: '10px',
        '& .MuiFilledInput-inputMarginDense': {
            paddingTop: '6px',
        },
        '& .MuiInputLabel-root': {
            fontWeight: 'normal',
            color: theme.palette.secondary.dark,
        },
        '& .MuiButtonBase-root': {
                boxShadow: '0 1px 3px rgba(71, 68, 68,0.15)',
            },
    },
    filter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    },
    dateFilterButton: {
        textAlign: 'left',
        background: '#fff',
        color: theme.palette.blue.navyBlue,
        textTransform: 'none',
        width: 190,
        lineHeight: '2.1',
        fontWeight: 400,
        letterSpacing: '0',
        '&:hover': {
            background: '#fff',
            color: theme.palette.blue.navyBlue,
        },
        padding: '6px 12px',
    },
}));

export default ({ onAdviserChange, onDateChange, adviser, dateRange, firm, onFirmChange }, props) => {
    const classes = useStyles();
    const [showDialog, setShowDialog] = useState(false);
    const [tempDateRange, setTempDateRange] = useState({ from: null, to: null });
    const [range, setRange] = useState(dateRange);
    const networkFirms = useSelector(({ identity }) => identity.networkFirms);

    useEffect(() => {
        onDateChange(range);
    }, [range]);

    const sortedFirms = (networkFirms || []).sort((a, b) =>
        a.label.localeCompare(b.label)
    );

    return (
        <>
            <div className={classes.container}>
                <label className={classes.label}>Filters</label>
                <div className={classes.allFilters}>
                    <div className={classes.filter}>
                        <label className={classes.label}>Date</label>
                        <Button
                            variant={'contained'}
                            className={classes.dateFilterButton}
                            onClick={() => setShowDialog(true)}
                        >
                            {
                                range.to && range.from ?
                                    formatRange(range).from + ' - ' + formatRange(range).to
                                    :
                                    'Past 7 days'
                            }
                        </Button>
                    </div>
                    <div className={classes.filter}>
                    <label className={classes.label}> Adviser</label>
                    <Filter {...props}>
                        <ReferenceInput
                            filter={{ minimal_fields: true }}
                            label={'Select an adviser'}
                            source="professional"
                            reference="professionals"
                            alwaysOn
                            onChange={onAdviserChange}
                            defaultValues={adviser}
                            sort={{ field:'first_name', order:'ASC' }}
                            perPage={MAX_GET_LIMIT}
                        >
                            <AutocompleteInputCustomProfessional adviser={adviser}  />
                        </ReferenceInput>
                    </Filter>
                    </div>
                    {isNetworkDashboard() &&
                        <div className={classes.filter}>
                            <label className={classes.label}> Firms</label>
                            <Filter {...props}>
                                <AutocompleteInputCustomFirm
                                    alwaysOn
                                    label={'Select a firm'}
                                    source={'firm'}
                                    suggestionLimit={50}
                                    choices={sortedFirms}
                                    onChange={onFirmChange}
                                    firm={firm}
                                />
                            </Filter>
                        </div>
                    }
                </div>
            </div>
            <DateRangeDialogFilter
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                setRange={setRange}
                setTempDateRange={setTempDateRange}
                tempDateRange={tempDateRange}
            />
        </>
    );
}

const AutocompleteInputCustomProfessional = (props) => {
    const { adviser } = props;
    let val = props.choices.find(ch => {
        return ch.id === parseInt(adviser);
    });

    if (adviser) {
        val = adviser;
    }

    return (
        <AutocompleteInput
            {...props}
            optionValue="id"
            optionText="name_only"
            resettable={false}
            defaultValue={val && val.id}
            inputProps={{ placeholder: 'Test' }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label="test"
                    placeholder="Favorites"
                />
            )}
        />
    )
}

const AutocompleteInputCustomFirm = (props) => {
    let { firm } = props;

    const val = firm ? props.choices.find(ch => {
        return ch.value === parseInt(firm);
    }) : null;

    return (
        <AutocompleteInput
            {...props}
            optionText={'label'}
            optionValue={'value'}
            resettable={false}
            defaultValue={val && val.value}
            inputProps={{ placeholder: 'Test' }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label="test"
                    placeholder="Favorites"
                />
            )}
        />
    );
};
