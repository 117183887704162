import { PageTitle } from '../../components/Typography/Headlines';
import { Grid } from '@material-ui/core';
import { required } from 'react-admin';
import ArgonDatePicker from '../../components/Argon/ArgonDatePicker';
import { pastDateValidation } from '../../validation';
import React from 'react';
import AccreditationSelectField from './AccreditationSelectField';

const AccreditationCreateLayout = () => {
    return (
        <Grid container spacing={2}>
            <Grid item>
                <PageTitle title="Add accreditation" />
            </Grid>
            <Grid item xs={12} lg={12}>
                <AccreditationSelectField />
            </Grid>
            <Grid item xs={12} lg={12}>
                <ArgonDatePicker
                    label="Date Received"
                    source="date"
                    validate={[required(), pastDateValidation]}
                />
            </Grid>
        </Grid>
    );
};

export default AccreditationCreateLayout;
