import {
    FEE_TYPE,
    FEE_SUB_TYPE,
} from './professional';

import {
    FIND_ADVISER_URL,
    SOCIAL_URL,
    TERMS_AND_CONDITIONS,
    PRIVACY_POLICY,
    COMPANY_URL,
    ADVISER_URL,
} from './external-urls';

export const FEE_LEVEL_TYPE_CHOICES = [
    { id: 'up_to', name: 'Up to' },
    { id: 'between', name: 'Between' },
    { id: 'over', name: 'Over' },
    { id: 'any_amount', name: 'For any amount' },
];

export const FEES_REGISTER_ROUTE = {
    LANDING: '/register/fees',
    CREATE: '/register/fees/create',
    LIST: '/fees',
    REPORT: '/register/fees/report',
    INVESTMENT_LEVEL: {
        CREATE: '/register/investment-fee-levels/create',
    },
};

export const FEE_TYPE_CHOICES = [
    {
        id: FEE_TYPE.INVESTMENT_AND_PENSION_WITH_PLANNING,
        name: 'Financial Planning with Implementation & Ongoing Service',
    },
    {
        id: FEE_TYPE.INVESTMENT_AND_PENSION,
        name: 'Implementation & Ongoing Service (without Financial Planning)',
    },
];

export const FEE_SUB_TYPE_CHOICES = [
    { id: FEE_SUB_TYPE.INITIAL_AND_ONGOING, name: 'Percentage of Assets only' },
    { id: FEE_SUB_TYPE.FIXED_FEE_ONLY, name: 'Fixed Fee Only' },
    { id: FEE_SUB_TYPE.HOURLY_CHARGE, name: 'Hourly Charge' },
];

export const COMPANY_URLS = [
    {
        text: 'About Us',
        url: COMPANY_URL.ABOUT,
    },
    {
        text: 'Tips & Guides',
        url: COMPANY_URL.BLOG,
    },
    {
        text: 'Contact',
        url: COMPANY_URL.EMAIL_SUPPORT,
    },
];

export const FIND_ADVISER_URLS = [
    {
        text: 'IFAs / Financial Advisers',
        url: FIND_ADVISER_URL.FINANCIAL_ADVISER,
    },
    {
        text: 'Mortgage Advisers',
        url: FIND_ADVISER_URL.MORTGAGE_ADVISER,
    },
    {
        text: 'Legal Advisers',
        url: FIND_ADVISER_URL.LEGAL_ADVISER,
    },
    {
        text: 'Accountants',
        url: FIND_ADVISER_URL.ACCOUNTANT,
    },
];

export const LEGAL_URLS = [
    {
        text: 'Terms & Conditions',
        url: TERMS_AND_CONDITIONS,
    },
    {
        text: 'Privacy Policy',
        url: PRIVACY_POLICY,
    },
];

export const SOCIAL_URLS = [
    {
        text: 'Facebook',
        url: SOCIAL_URL.FACEBOOK,
    },
    {
        text: 'Twitter',
        url: SOCIAL_URL.TWITTER,
    },
    {
        text: 'LinkedIn',
        url: SOCIAL_URL.LINKEDIN,
    },
];

export const ADVISER_URLS = [
    {
        text: 'Sign Up',
        url: ADVISER_URL.ADVISER_SIGNUP,
    },
    {
        text: 'Log In',
        url: ADVISER_URL.ADVISER_LOGIN,
    },
];
